import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/Store";
import Axios from "axios";
import { ConsentAPIs } from "../../../sources/apiEndPoints";
import AsolviModal from "../AsolviModal/AsolviModal";
import { useTranslation } from "react-i18next";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import { ConsentDto, ConsentTemplateDto } from "../../seedComponents/Pages/ConsentsPageSeedComponent";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import "./AsolviConsentModal.css"
import { useLocation } from "react-router";

// Note: this code assumes that GetAvailableConsentsByUser request returns the consent array with
//       the required consents first.

const AsolviConsentModal: FC = () => {
  const { appUrl } = (window as any)["appConfig"];
  const { t } = useTranslation();

  const email = useSelector((state: IAppState) => state.generalState.General.currentUserEmail);
  const token = useSelector((state: IAppState) => state.generalState.General.currentAuth0Token);

  const [userHasAllRequiredConsents, setUserHasAllRequiredConsents] = useState<boolean>(true);
  const [consentIndex, setConsentIndex] = useState<number>();
  const [availableConsents, setAvailableConsents] = useState<ConsentTemplateDto[]>([]);
  const [currentConsent, setCurrentConsent] = useState<ConsentTemplateDto>();
  const [showConsentIsRequiredNotification, setShowConsentIsRequiredNotification] = useState<boolean>(false);
  const [isSavingConsent, setIsSavingConsent] = useState<boolean>(false);

  const location = useLocation();
  useEffect(() => {
    if (
      !userHasAllRequiredConsents
      || !token
      || !email
    )
      return;

    Axios.get<ConsentTemplateDto[]>(
      appUrl + ConsentAPIs.GetAvailableConsentsByUser(email),
      { headers: { token } },
    ).then( (response) => {

      const availableConsents = response.data;
      setAvailableConsents(availableConsents);

      if (availableConsents.length) {
        setUserHasAllRequiredConsents(
          !availableConsents.some(x => x.Required)
        );
        setConsentIndex(0);
        setCurrentConsent(availableConsents[0])
      }
    })
  }, [appUrl, email, location, token, userHasAllRequiredConsents])


  return (
    <>
      { !userHasAllRequiredConsents && currentConsent
        && <AsolviModal
          modalSize="xl"
          header={consentHeader(currentConsent)}
          showModal={!userHasAllRequiredConsents}
          scrollable
          animation={false}
          shouldCloseOnBackdrop
          handleClose={() => {
            exitConsentModal(currentConsent)
          }}
          footer={ <div>
            { currentConsent?.Required
              ? <div style={{ display: 'flex', justifyContent: 'end', margin: '8px' }}><AsolviButton
                buttonType="submit"
                className="k-primary"
                buttonAction={() => saveConsent(currentConsent, true)}
                buttonDisabled={isSavingConsent}
              >
                {t("Consents.Continue")}
              </AsolviButton></div>
              : <div style={{ display: 'flex', justifyContent: 'end', margin: '8px'}}>
                <AsolviButton
                  buttonType="submit"
                  className="k-primary"
                  buttonAction={() => saveConsent(currentConsent, true)}
                  buttonDisabled={isSavingConsent}
                >
                  {t("Consents.Accept")}
                </AsolviButton>
                <div style={{width: '8px'}} />
                <AsolviButton
                  buttonType="submit"
                  buttonAction={() => saveConsent(currentConsent, false)}
                  buttonDisabled={isSavingConsent}
                >
                  {t("Consents.Reject")}
                </AsolviButton>
              </div> }
          </div> }
        >
          <div>
            <div dangerouslySetInnerHTML={{ __html: currentConsent?.Text }} />
          </div>
          <div>
          </div>
        </AsolviModal> }
    </>
  );

  async function exitConsentModal(consent: ConsentTemplateDto) {
    if (consent.Required) {
      setShowConsentIsRequiredNotification(true)

    } else {
      if (isSavingConsent)
        return;

      const consentsToReject = availableConsents.slice(consentIndex);

      setIsSavingConsent(true);
      const promises = consentsToReject.map((consent) => {
        return Axios.post<ConsentDto>(
          appUrl + ConsentAPIs.SaveConsent(),
          {
            Accepted: false,
            Email: email,
            Id: consent.Id,
            DataController: consent.DataController,
            Purpose: consent.Purpose,
            Version: consent.Version,
          },
          { headers: { token } }
        )
      })
      await Promise.all(promises);
      setIsSavingConsent(false);
      setUserHasAllRequiredConsents(true);
    }
  }

  async function saveConsent(consent: ConsentTemplateDto, accepted: boolean) {
    setIsSavingConsent(true);
    await Axios.post<ConsentDto>(
      appUrl + ConsentAPIs.SaveConsent(),
      {
        Accepted: accepted,
        Email: email,
        Id: consent.Id,
        DataController: consent.DataController,
        Purpose: consent.Purpose,
        Version: consent.Version,
      },
      { headers: { token } }
    )
    setIsSavingConsent(false);

    const isOnLastConsent
      = typeof consentIndex === "number"
      && consentIndex === availableConsents.length-1;

    if (isOnLastConsent) {
      setUserHasAllRequiredConsents(true);

    } else {
      if (consentIndex == null)
        throw new Error('consentIndex is not a number, unexpected');

      setConsentIndex(consentIndex + 1);
      setCurrentConsent(availableConsents[consentIndex + 1]);
    }
  }

  function consentHeader(consent: ConsentTemplateDto) {
    if (consent.Required)
      return (
        <div style={{zIndex: 100}}>
          <NotificationGroup
            style={{ top: 50, left: "50%", transform: "translateX(-50%)" }}
          >
            <Fade>
              { showConsentIsRequiredNotification &&
                <Notification
                  closable
                  onClose={() => setShowConsentIsRequiredNotification(false)}
                  type={{ style: 'none' }}
                >
                  <div style={{marginTop: '3px'}}>
                    { t("Consents.ConsentIsRequiredNotification") }
                  </div>
                </Notification>
              }
            </Fade>
          </NotificationGroup>
          {consent.Name} ({t('Consents.Required')})
        </div>
      )

    else
      return consent.Name;
  }
}

export default AsolviConsentModal
