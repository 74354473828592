import * as React from 'react';
import { Label } from "@progress/kendo-react-labels";
import './AsolviLabel.css';
import { Col, Row } from 'react-bootstrap';

interface IProps {
  labelText: string,
  value?: string | number | undefined,
  labelColSize?: number,
  valueColSize?: number
  valueColor?: string | undefined
}
const AsolviLabel: React.FunctionComponent<IProps> = (props) => {
  const { labelText, value, labelColSize = 4,  valueColSize = 8} = props
  return (
    <Row>
      <Col sm={labelColSize}>
        <Label>{labelText}</Label>
      </Col>
      <Col sm={valueColSize}>
        <span style={{color: props.valueColor?? ""}}>{value}</span>
      </Col>
    </Row>
    
  )
}

export default AsolviLabel;