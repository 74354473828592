import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AsolviGrid, { IGridLayout } from "../../../Elements/AsolviGrid/AsolviGrid";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import "./TaskPropertiesGroup.css";
import { ITaskProperyItem } from "../../../../reducers/TaskReducer";

export interface IProps extends ReactI18Next.WithTranslation {  
  t: I18Next.TFunction;
  showMachine: boolean;  
  customerProperties?: ITaskProperyItem[];
  machineProperties?: ITaskProperyItem[];  
}

const createLayout = (t: I18Next.TFunction) => {
  const layout: IGridLayout[] = [
    {
      fieldName: "Id",
      fieldLanguageKey: "id",
      colWidth: 0,
      editable: false,
      visible: false
    },
    {
      fieldName: "Description",
      fieldLanguageKey: t("Properties.description"),
      colWidth: 180,
      editable: false,
      visible: true
    },
    {
      fieldName: "Value",
      fieldLanguageKey: t("Properties.value"),
      editable: false,
      visible: true
    }
  ]
  return layout;
}

const TaskPropertiesGroup: React.FunctionComponent<IProps> = (props) => {
    const { t, showMachine } = props; 
    const layout = createLayout(t);    
    return (        
        <Container fluid>           
            <Row>
              <Col sm={6}>
                  <p>{t("Properties.customerLabel")}</p>
                  <AsolviGrid gridLayout={layout} data={props.customerProperties?? []} handleChange={(e) => {}} dataKeyField={"PropertyGuid"} />            
              </Col>              
              { showMachine && 
              <Col sm={6}>
                  <p>{t("Properties.machineLabel")}</p>
                  <AsolviGrid gridLayout={layout} data={props.machineProperties?? []} handleChange={(e) => {}} dataKeyField={"PropertyGuid"} />            
              </Col>}
            </Row>  
        </Container>             
    );
}

export default ReactI18Next.withTranslation()(TaskPropertiesGroup);