import * as React from 'react';
import * as ReactRedux from 'react-redux';
import * as GeneralReducer from '../../../reducers/GeneralReducer';
import * as Store from '../../../store/Store';
import { clearOrderLog, clearSearchFilter, getOrderLog, getVisibilitySettings, IOrderLog, IOrderSelectedCustomer, selectCustomer } from '../../../slices/OrderSlice';
import OrderLogPage from '../../Pages/OrderLogPage/OrderLogPage';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export interface IOrderSearchDataContract {
  Company?: string;
  CustomerGuid?: string;
  DatePeriod?: string;
  DateFrom?: Date;
  DateTo?: Date;
  OrderNo?: number;
  MachineNo?: string;
  ModelType?: string;
  MachineIdNo?: number;
  CustomerReferenceInfo?: string;
  PropertyFilterCode1?: string;
  PropertyFilterValue1?: string;
  PropertyFilterCode2?: string;
  PropertyFilterValue2?: string;
}

export interface IOrderLineLogDataContract {
  LineNo: number;
  ArticleNo: string;
  Description: string;
  SalesPrice: number;
  Discount: number;
  QtyDelivered: number;
  VatCode: number;
  VatPrice: number;
  Sum: number;
  MachineNo: string;
  ModelType: string;
  MachineGuid: string;
  MachineIdNo: string;
}

export interface IOrderLogDataContract {
  Company: string;
  OrderNo: number;
  InvoiceDate: Date;
  CustomerNo: string;
  CustomerName: string;
  DeliveryName: string;
  DeliveryAddress1: string;
  CustomerReference: string;
  CustomerReferenceInfo: string;
  InvoiceName: string;
  InvoiceAddress1: string;
  OrderLines: IOrderLineLogDataContract[]  
}

interface IProps {  
  OrderLog: IOrderLog;
  General: GeneralReducer.IGeneral;
  shouldShowCustomerSelect: boolean;
  selectedCustomer?: string;
  getOrderLog: (token: string, appUrl: string, email: string, activeCustomer: string, searchData: IOrderSearchDataContract) => void;
  clearOrdersAndSearchFilter: () => void;  
}

const OrderLogPageSeedComponent: React.FunctionComponent<IProps> = (Props) => {
  const { appUrl } = (window as any)["appConfig"];
  const { General, OrderLog, getOrderLog, clearOrdersAndSearchFilter, shouldShowCustomerSelect, selectedCustomer } = Props;
  const { currentAuth0Token, activeCustomer, currentUserEmail, activeCustomerInfo } = General;
  const { searchData, loadingLog } = OrderLog;
  
  const dispatch = useDispatch();

  const getOrderVisibilitySettings = useCallback(
    (token: string, appUrl: string, email: string) => dispatch(getVisibilitySettings({ token, appUrl, email })), [dispatch]
  );

  const handleSelectedCustomer = useCallback(
    (customer: IOrderSelectedCustomer) => dispatch(selectCustomer(customer)), [dispatch]
  );

  useEffect(() => {    
    getOrderVisibilitySettings(currentAuth0Token, appUrl, currentUserEmail);
  }, [currentAuth0Token, currentUserEmail, appUrl, getOrderVisibilitySettings]);

  useEffect(() => {    
    if (selectedCustomer === undefined && shouldShowCustomerSelect) {
      let activeCustomerInfoObj = JSON.parse(activeCustomerInfo);
      const customer: IOrderSelectedCustomer = {
        customerGuid: activeCustomer,
        customerName: activeCustomerInfoObj.CustomerNo,
        customerNo: activeCustomerInfoObj.Name
      };
      handleSelectedCustomer(customer);
    }
  }, [handleSelectedCustomer, selectedCustomer, shouldShowCustomerSelect, activeCustomerInfo, activeCustomer]);
  
  return (
    <OrderLogPage      
      loadingOrders={loadingLog}
      clearOrdersAndSearchFilter={() => clearOrdersAndSearchFilter()}
      getOrders={() => getOrderLog(currentAuth0Token, appUrl, currentUserEmail, selectedCustomer ?? activeCustomer, searchData)}  
      shouldShowCustomerSelect={shouldShowCustomerSelect}    
      />
  );
}

const mapStateToProps = (store: Store.IAppState) => {
  return {
    General: store.generalState.General,
    OrderLog: store.orderState.OrderLog,
    shouldShowCustomerSelect: store.orderState.Order.visibilitySettings.ShowSelectCustomer,
    selectedCustomer: store.orderState.Order.selectedCustomer?.customerGuid
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getOrderLog: (token: string, appUrl: string, email: string, activeCustomer: string, searchData: IOrderSearchDataContract) => dispatch(getOrderLog({ token, appUrl, email, searchData: { CustomerGuid: activeCustomer, ...searchData} })),
    clearOrdersAndSearchFilter: () => {
      dispatch(clearOrderLog());
      dispatch(clearSearchFilter());
    }
  };
};

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(OrderLogPageSeedComponent);