import i18n from "../i18n";
import { AsolviDropDownItem } from "../components/Elements/AsolviDropDownList/AsolviDropDownList";
//import flags media
import GBFlag from "../assets/Images/Flags/en-GB.gif";
import USFlag from "../assets/Images/Flags/en-US.gif";
import DEFlag from "../assets/Images/Flags/de-DE.gif";
import ESFlag from "../assets/Images/Flags/es-ES.gif";
import FIFlag from "../assets/Images/Flags/fi-FI.gif";
import FRFlag from "../assets/Images/Flags/fr-FR.gif";
import NOFlag from "../assets/Images/Flags/nb-NO.gif";
import NLFlag from "../assets/Images/Flags/nl-NL.gif";
import SEFlag from "../assets/Images/Flags/sv-SE.gif";
import TRFlag from "../assets/Images/Flags/tr-TR.gif";
////



export default function (action: any) {
    const items = [] as Array<AsolviDropDownItem>;

    const englishItemUS = { itemText: "English (United States)", itemAction: (selectedLanguage: string) => { i18n.changeLanguage('en'); action(selectedLanguage); }, itemImage: USFlag, itemId: "en-US" } as AsolviDropDownItem
    items.push(englishItemUS);

    const englishItemGB = { itemText: "English (United Kingdom)", itemAction: (selectedLanguage: string) => { i18n.changeLanguage('enGB'); action(selectedLanguage); }, itemImage: GBFlag, itemId: "en-GB" } as AsolviDropDownItem
    items.push(englishItemGB);

    const germanItemDE = { itemText: "Deutsche (Deutschland)", itemAction: (selectedLanguage: string) => { i18n.changeLanguage('de'); action(selectedLanguage); }, itemImage: DEFlag, itemId: "de-DE" } as AsolviDropDownItem
    items.push(germanItemDE);

    const spanishItemES = { itemText: "Española (España)", itemAction: (selectedLanguage: string) => { i18n.changeLanguage('es'); action(selectedLanguage); }, itemImage: ESFlag, itemId: "es-ES" } as AsolviDropDownItem
    items.push(spanishItemES);

    const finnishItemFI = { itemText: "Suomen (Suomi)", itemAction: (selectedLanguage: string) => { i18n.changeLanguage('fi'); action(selectedLanguage); }, itemImage: FIFlag, itemId: "fi-FI" } as AsolviDropDownItem
    items.push(finnishItemFI);

    const frenchItemFR = { itemText: "Française (France)", itemAction: (selectedLanguage: string) => { i18n.changeLanguage('fr'); action(selectedLanguage); }, itemImage: FRFlag, itemId: "fr-FR" } as AsolviDropDownItem
    items.push(frenchItemFR);

    const norwegianItemNO = { itemText: "Norsk Bokmål (Norge)", itemAction: (selectedLanguage: string) => { i18n.changeLanguage('nb'); action(selectedLanguage); }, itemImage: NOFlag, itemId: "nb-NO" } as AsolviDropDownItem
    items.push(norwegianItemNO);

    const dutchItemDE = { itemText: "Nederlandse (Nederland)", itemAction: (selectedLanguage: string) => { i18n.changeLanguage('nl'); action(selectedLanguage); }, itemImage: NLFlag, itemId: "nl-NL" } as AsolviDropDownItem
    items.push(dutchItemDE);

    const swedishItemDE = { itemText: "Svenska (Sverige)", itemAction: (selectedLanguage: string) => { i18n.changeLanguage('sv'); action(selectedLanguage); }, itemImage: SEFlag, itemId: "sv-SE" } as AsolviDropDownItem
    items.push(swedishItemDE);

    const turkishItemDE = { itemText: "Türk (Türkiye)", itemAction: (selectedLanguage: string) => { i18n.changeLanguage('tr'); action(selectedLanguage); }, itemImage: TRFlag, itemId: "tr-TR" } as AsolviDropDownItem
    items.push(turkishItemDE);
    return items;
}

