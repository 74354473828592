import * as React from "react";
import styles from "./OrderDetailsGrid.module.css";
import * as I18Next from "i18next";
import * as ReactI18Next from 'react-i18next';
import { Col, Row, Spinner } from "react-bootstrap";
import { GridCellProps, GridItemChangeEvent, GridRowClickEvent } from "@progress/kendo-react-grid";
import AsolviFadeInOut from "../../../Elements/AsolviFadeInOut/AsolviFadeInOut";
import AsolviGrid, { IGridLayout } from "../../../Elements/AsolviGrid/AsolviGrid";
import { parseDate } from '@telerik/kendo-intl';
import { IArticleInfo } from "../../../seedComponents/Groups/Order/OrderDetailsGroupSeedComponent";
import AsolviAdvancedGrid, { IGridColumn } from "../../../Elements/AsolviAdvancedGrid/AsolviAdvancedGrid";
import InvoiceableCell from "../InvoiceableCell";

export interface Props extends ReactI18Next.WithTranslation{
    t: I18Next.TFunction;
    orderLines: IArticleInfo[];
    loadingArticles: boolean;
    showSalesPrice: boolean;
    onRowClick: (event:any) => void;
    orderGridItemChange: (item: IArticleInfo, field?: any, newValue?: any) => void;
}

const OrderDetailsGrid: React.FunctionComponent<Props> = (Props) => {  
    let { t, orderLines, loadingArticles, onRowClick, orderGridItemChange, showSalesPrice } = Props;
    const [editID, setEditID] = React.useState<string | null>(null);
    const [selectedArticle, setSelectedArticle] = React.useState<IArticleInfo | null>(null);

    let gridLayout = [] as IGridColumn<IArticleInfo>[];

  gridLayout.push({ fieldName: "ArticleNo", fieldLanguageKey: t("Order.ArticleNo"), colWidth: 200, editable: false, visible: true });
  gridLayout.push({ fieldName: "ArticleDescription", fieldLanguageKey: t("Order.ArticleDescription"), colWidth: 620, editable: false, visible: true });
  gridLayout.push({ fieldName: "SalesPrice", fieldLanguageKey: t("Order.SalesPrice"), colWidth: 150, editable: false, visible: showSalesPrice });
  gridLayout.push({ cellStyle: InvoiceableCell, fieldName: "Invoiceable", fieldLanguageKey: t("Order.Invoiceable"), colWidth: 120, editable: false,  visible: true});
  gridLayout.push({ fieldName: "Qty", fieldLanguageKey: t("Order.Qty"), colWidth: 150, editor: "numeric", format: "{0:n}", editable: true, visible: true });

  const rowClick = (event: GridRowClickEvent) => {
      setSelectedArticle(event.dataItem);
      setEditID(event.dataItem.ArticleObjNo);
  };

    return (
        <Row>
          <Col sm={12} className={styles.gridContainer}>    
          <AsolviFadeInOut show={loadingArticles} lazyChildren>
            <div className={styles.gridOverlay}>
              <Spinner
                animation="border" variant="dark" 
                />
            </div>
          </AsolviFadeInOut>
          <AsolviAdvancedGrid 
              sortable={true}
              filterable={false}
              data={orderLines}
              gridLayout={gridLayout}
              dataKeyField={"ArticleObjNo"}
              handleItemChange={orderGridItemChange}
              enableExcelExport={false}
              editType="always"
            />
        </Col>
        </Row>
      )
}
  
export default ReactI18Next.withTranslation()(OrderDetailsGrid);