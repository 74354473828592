import * as React from 'react';
import "./AsolviRadioButton.css";
import "@progress/kendo-theme-material/dist/all.scss";
import AsolviRadioButton from './AsolviRadioButton';

export interface IRadioButtonItem{
    id: string,
    label: string
}

export interface IProps{
    groupId: string,
    defaultValue: string,
    optionsList: IRadioButtonItem[],
    onChange?: (e: any) => void;
}

const AsolviRadioButtonGroup: React.FunctionComponent<IProps> = (props) => {
    const [selectedId, setselectedId] = React.useState(props.defaultValue);
    const { onChange } = props;
    const handleChange = React.useCallback(
      (e) => {
        setselectedId(e.value);
        if (onChange) {
            onChange(e.value);
        }
      },
      [setselectedId, onChange]
    );
    return (      
        <div>
        { props.optionsList.map((option) =>                   
            <AsolviRadioButton
                groupId={props.groupId} 
                key={option.id} 
                itemId={option.id}                                               
                selectedId={selectedId}
                label={option.label}
                onChange={handleChange}
            />                      
        )}
        </div>      
    );
}

export default AsolviRadioButtonGroup;