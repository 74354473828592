import React from "react";
import * as Store from "../../../../store/Store";
import * as TaskReducer from "../../../../reducers/TaskReducer";
import * as ReactRedux from "react-redux";
import TaskSymptomGroup, {ISymptomCodeSetup, ISymptomData} from "../../../Groups/Task/TaskSymptomGroup/TaskSymptomGroup";
import * as TaskActions from "../../../../actions/TaskActions";
import { TaskTypeEnums } from "../../Pages/TaskCreatePageSeedComponent";

interface IProps
 {
    TaskVisibilitySettings: TaskReducer.ITaskVisibilitySettings;
    SymptomCodeDropDownItems: TaskReducer.ITaskSymptomCodeItems;
    getTaskSymptomCodes(token: string, email:string, appUrl: string, taskType: number): (token: string, email:string)  => void;
    extractData?: (data : ISymptomData) => void;
    clearData?: boolean;
    currentAuth0Token: string;
    currentUserEmail: string;
    taskType?: any;
}

const TaskSymptomGroupSeedComponent: React.FunctionComponent<IProps> = (Props) => {        
    const {taskType = TaskTypeEnums.Service, getTaskSymptomCodes} = Props; 
    const token = Props.currentAuth0Token;
    const email = Props.currentUserEmail;    
    const { appUrl } = (window as any)["appConfig"];
    const setup:ISymptomCodeSetup = {        
        symptomCodeStrongRelation: false,
        showSymptomCode1: true, 
        showSymptomCode2: true,
        showSymptomCode3: true,
        showSymptomCode4: true && (taskType === TaskTypeEnums.Helpdesk)       
    };

    if(Props.TaskVisibilitySettings !== undefined && Props.TaskVisibilitySettings.ShowSymptomGroup !== undefined){  
        setup.symptomCodeStrongRelation = (Props.TaskVisibilitySettings.ShowSymptomGroup.UseStrongRelationService && (taskType === TaskTypeEnums.Service))       
                                            ||
                                          (Props.TaskVisibilitySettings.ShowSymptomGroup.UseStrongRelationHelpDesk && (taskType === TaskTypeEnums.Helpdesk));
        setup.showSymptomCode1 = Props.TaskVisibilitySettings.ShowSymptomGroup.ShowSymptomCode1;
        setup.showSymptomCode2 = Props.TaskVisibilitySettings.ShowSymptomGroup.ShowSymptomCode2;
        setup.showSymptomCode3 = Props.TaskVisibilitySettings.ShowSymptomGroup.ShowSymptomCode3; 
        setup.showSymptomCode4 = Props.TaskVisibilitySettings.ShowSymptomGroup.ShowSymptomCode4 && (taskType === TaskTypeEnums.Helpdesk); 
    }

    React.useEffect(()=>{
        if(token)
            getTaskSymptomCodes(token, email, appUrl, taskType);        
    },[token, email, appUrl, getTaskSymptomCodes, taskType]);
   
    return (    
        <TaskSymptomGroup setup={setup} symptomCode1DropDownItems={Props.SymptomCodeDropDownItems.SymptomCode1Items} 
                                    symptomCode2DropDownItems={Props.SymptomCodeDropDownItems.SymptomCode2Items} 
                                    symptomCode3DropDownItems={Props.SymptomCodeDropDownItems.SymptomCode3Items} 
                                    symptomCode4DropDownItems={Props.SymptomCodeDropDownItems.SymptomCode4Items} 
                                    extractData={Props.extractData}  
                                    clearData={Props.clearData} 
                                    taskType={taskType} />        
    );
}

const mapStateToProps = (store: Store.IAppState) => {
    return {    
        TaskVisibilitySettings: store.taskState.TaskVisibilitySettings,
        SymptomCodeDropDownItems: store.taskState.TaskSymptomCodes,
        currentAuth0Token: store.generalState.General.currentAuth0Token,
        currentUserEmail: store.generalState.General.currentUserEmail,
    }
  }

  const mapDispatchToProps = (dispatch: any) => {
    return {
        getTaskSymptomCodes: (token: string, email:string, appUrl: string, taskType: number) => dispatch(TaskActions.GetTaskSymptomCodes(token, email, appUrl, taskType)),      
    }
  }

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(TaskSymptomGroupSeedComponent);