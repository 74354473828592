import React, { FC, useEffect, useState } from "react";
import ConsentsPage from "../../Pages/ConsentsPage/ConsentsPage";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/Store";
import Axios from "axios";
import { ConsentAPIs } from "../../../sources/apiEndPoints";

export interface ConsentDto {
  Id: number;
  UserIdentifier: string;
  DataController: string;
  Purpose: string;
  Name: string;
  Text: string;
  Version: number;
  Accepted: boolean;
  ValidFrom: Date;
  ValidTo: Date;
}

export interface IConsent extends ConsentDto {
  ConsentWithdrawn?: boolean;
}

export interface ConsentTemplateDto {
  Id: number;
  DataController: string;
  Name: string;
  Text: string;
  Purpose: string;
  Required: boolean;
  Version: number;
  DaysValid: number;
}

export interface ConsentResponseDto {
  Email: string;
  DataController: string;
  Purpose: string;
  Version: number;
  Accepted: boolean;
}

const ConsentsPageSeedComponent: FC = () => {
  const { appUrl } = (window as any)["appConfig"];

  const email = useSelector((state: IAppState) => state.generalState.General.currentUserEmail);
  const token = useSelector((state: IAppState) => state.generalState.General.currentAuth0Token);

  const [consents, setConsents] = useState<IConsent[]>([]);
  const [loadingUserConsents, setLoadingUserConsents] = useState<boolean>(true);

  useEffect(() => {
    if (!token || !email)
      return;

    Axios.get<ConsentDto[]>(
      appUrl + ConsentAPIs.GetConsentsByUser(email),
      { headers: { token } }
    )
      .then((x) => {
        const newConsents = convertDtosToDisplayObjects(x.data);
        setConsents(newConsents);
        setLoadingUserConsents(false);
      });
  }, [email, token, appUrl]);


  return (
    <ConsentsPage
      loading={loadingUserConsents}
      consents={consents}
      onWithdrawConsent={withdrawConsent}
      onGiveConsent={expireDeclinedConsentAndRecreateItAsAccepted}
      onRefresh={refreshConsents}
    />
  );

  async function withdrawConsent(consentId: number) {
    let consent = (
      await Axios.post<ConsentDto>(
        appUrl + ConsentAPIs.WithdrawConsent(),
        { Email: email, Id: consentId },
        { headers: { token } }
      )
    ).data as IConsent;
    consent = convertConsentDate(consent);
    consent.ConsentWithdrawn = true;

    const newConsents = [...consents];
    const index = newConsents.findIndex((consentInner => consentInner.Id === consent.Id));
    newConsents[index] = consent;

    setConsents(newConsents);
  }

  async function expireDeclinedConsentAndRecreateItAsAccepted(consentId: number) {
    const expiredDeclinedConsent = (
      await Axios.post<ConsentDto>(
        appUrl + ConsentAPIs.WithdrawConsent(),
        { Email: email, Id: consentId },
        { headers: { token } }
      )
    ).data as IConsent;

    const availableConsents = (
      await Axios.get<ConsentTemplateDto[]>(
        appUrl + ConsentAPIs.GetAvailableConsentsByUser(email),
        { headers: { token } },
      )
    ).data as ConsentTemplateDto[];

    const matchingAvailableConsent
      = availableConsents.find(
        (x) => x.Purpose === expiredDeclinedConsent.Purpose
          && x.Version === expiredDeclinedConsent.Version
    );
    if (matchingAvailableConsent === undefined)
      throw new Error('Could not find matching consent template');

    const consents = (
      await Axios.post<ConsentDto[]>(
      appUrl + ConsentAPIs.SaveConsent(),
      {
        Accepted: true,
        Email: email,
        Id: matchingAvailableConsent.Id,
        DataController: matchingAvailableConsent.DataController,
        Purpose: matchingAvailableConsent.Purpose,
        Version: matchingAvailableConsent.Version,
      },
      { headers: { token } }
    )).data

    setConsents(convertDtosToDisplayObjects(consents));
  }

  function refreshConsents() {
    if (!token || !email)
      return;

    Axios.get<ConsentDto[]>(
      appUrl + ConsentAPIs.GetConsentsByUser(email),
      { headers: { token } }
    )
      .then((x) => {
        const newConsents = convertDtosToDisplayObjects(x.data);
        setConsents(newConsents);
        setLoadingUserConsents(false);
      });
  }
}

export default ConsentsPageSeedComponent;

function convertDtosToDisplayObjects(consentDtos: ConsentDto[]): IConsent[] {
  let consents: IConsent[] = JSON.parse(JSON.stringify(consentDtos));

  consents = consents.map(convertConsentDate);

  consents = sortConsents(consents);
  return consents;
}

function convertConsentDate(consent: IConsent): IConsent {

  consent.ValidFrom = new Date(consent.ValidFrom);
  consent.ValidTo = new Date(consent.ValidTo);
  return consent;
}

function sortConsents(consents: IConsent[]): IConsent[] {
  consents.sort((a, b) => b.ValidFrom.valueOf() - a.ValidFrom.valueOf());
  return consents;
}
