import * as Redux from 'redux';
import * as FrameActions from "../actions/FrameActions";

export interface IFrame{
  url: string;
}

export interface IFrameState {
  readonly Frame: IFrame
}

const initialFrameState: IFrameState = {
  Frame: {
    url : "",
  }
}

export const frameReducer: Redux.Reducer<IFrameState, FrameActions.FrameActions> = (
  state = initialFrameState,
  action
) => {
  switch (action.type) {
    case FrameActions.FrameActionTypes.SETHOMEURL: {
      return {
        ...state,
        Frame: {
          ...state.Frame,
          url: action.Frame.url,
        }
      }
    }
    case FrameActions.FrameActionTypes.SETLOGOUTURL: {
      return {
        ...state,
        Frame: {
          ...state.Frame,
          url: action.Frame.url
        }
      }
    }
    default: {
      return state;
    }
  }
}