import * as React from 'react';
import "./OrderLogResultGroup.css";
import { Col, Container, Row } from "react-bootstrap";
import * as ReactI18Next from 'react-i18next';
import { IOrderLogDataContract } from '../../../seedComponents/Pages/OrderLogPageSeedComponent';
import * as I18Next from "i18next";
import AsolviLabel from '../../../Elements/AsolviLabel/AsolviLabel';
import { useInternationalization } from '@progress/kendo-react-intl';
import AsolviGrid, { IGridLayout } from '../../../Elements/AsolviGrid/AsolviGrid';
import { parseDate } from '@telerik/kendo-intl';

export interface IProps extends ReactI18Next.WithTranslation {
    t: I18Next.TFunction;         
    orderData: IOrderLogDataContract;  
    showSalesPrice: boolean;
    showDiscount: boolean;
    showVat: boolean;   
}

const OrderLogResultDetailGroup: React.FunctionComponent<IProps> = (Props) => {
    const { t, orderData, showSalesPrice, showDiscount, showVat } = Props;    
    const intlService = useInternationalization();

    let gridLayout = [] as IGridLayout[];

    const totalQty = orderData.OrderLines.reduce((total, currentValue) => total = total + (currentValue?.QtyDelivered ?? 0),0);
    const totalPrice = orderData.OrderLines.reduce((total, currentValue) => total = total + (currentValue?.Sum?? 0),0);    
    const totalVat = orderData.OrderLines.reduce((total, currentValue) => total = total + (currentValue?.VatPrice?? 0),0);    

    gridLayout.push({ fieldName: "LineNo", fieldLanguageKey: "#", colWidth: 80, editable: false, visible: true, initialSort: "asc" });
    gridLayout.push({ fieldName: "ArticleNo", fieldLanguageKey: t("Order.ArticleNo"), colWidth: 150, editable: false, visible: true });
    gridLayout.push({ fieldName: "Description", fieldLanguageKey: t("Order.ArticleDescription"), editable: false, visible: true });
    if (showSalesPrice)
      gridLayout.push({ fieldName: "SalesPrice", fieldLanguageKey: t("Order.SalesPrice"), colWidth: 150, editable: false, visible: true });
    if (showSalesPrice && showDiscount)
      gridLayout.push({ fieldName: "Discount", fieldLanguageKey: t("Order.Discount"), colWidth: 150, editable: false, visible: true });
    gridLayout.push({ fieldName: "QtyDelivered", fieldLanguageKey: t("Order.Qty"), colWidth: 150, editable: false, visible: true });
    if (showSalesPrice)
      gridLayout.push({ fieldName: "Sum", fieldLanguageKey: t("Order.Sum"), colWidth: 150, editable: false, visible: true });
    
    let invoiceDate = parseDate(orderData.InvoiceDate.toString());

    return (
      <Container fluid>
        <Row>
          <Col sm={6}>
            <AsolviLabel labelText={t("Order.CustomerNo")} value={orderData.CustomerNo} />
          </Col>
          <Col sm={6}>
            <AsolviLabel labelText={t("Order.Customer")} value={orderData.CustomerName} />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <AsolviLabel labelText={t("Order.InvoiceDate")} value={intlService.formatDate(invoiceDate)} />
          </Col>      
        </Row>
        <Row>
          <Col sm={6}>
            <AsolviLabel labelText={t("Order.YourRef")} value={orderData.CustomerReference} />
          </Col>      
          <Col sm={6}>
            <AsolviLabel labelText={t("Order.ReferenceInfo")} value={orderData.CustomerReferenceInfo} />
          </Col>      
        </Row>
        <Row>
          <Col sm={6}>
            <AsolviLabel labelText={t("Order.InvoiceAddress")} value={orderData.InvoiceName + ", " + orderData.InvoiceAddress1} />
          </Col>      
          <Col sm={6}>
            <AsolviLabel labelText={t("Order.DeliveryAddress")} value={orderData.DeliveryName + ", " + orderData.DeliveryAddress1} />
          </Col>      
        </Row>
        <Row className="OrderLogResultDetailGrid">
          <AsolviGrid 
            sortable={true}
            filterable={false}
            data={orderData.OrderLines}
            gridLayout={gridLayout}
            dataKeyField={"LineNo"}
            handleChange={() => { }}
            enableExcelExport={false}                                              
          />
        </Row>
        <Row className="OrderLogSumRow"> 
          {showSalesPrice &&  
            <Col sm={2}>    
                  <AsolviLabel labelText={t("Order.Total")+ ": "} 
                      value={Number((totalPrice ?? 0)).toFixed(2)}
                      labelColSize={5} 
                      valueColSize={3}                       
                  /> 
            </Col>          
          }  
          {showSalesPrice && showVat &&
            <Col sm={2}>                
                <AsolviLabel labelText={t("Order.VAT")+ ": "} 
                    value={Number((totalVat ?? 0)).toFixed(2)}
                    labelColSize={5} 
                    valueColSize={3}                                        
                /> 
            </Col>
          }
          <Col sm={2}>                
            <AsolviLabel labelText={t("Order.Articles")+ ": "} 
                value={(totalQty ?? 0)}
                labelColSize={6} 
                valueColSize={3}                
            />
          </Col>
        </Row>        
      </Container>
    );
}

export default ReactI18Next.withTranslation()(OrderLogResultDetailGroup);