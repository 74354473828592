import * as React from "react";
import "./LoginPage.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import reindeerImage from "../../../assets/Images/welcome.png";
import LoginGroupSeedComponent from "../../seedComponents/Groups/LoginGroupSeedComponent";


export interface Props {
}

class LoginPage extends React.Component<Props, object>{    
    render() {        
        return (
            <Container className="loginContainer" fluid>
                <Row className="loginRow">
                    <Col xl="4" className="loginLeftCol">
                        <Row>
                            <Col xl="1" />
                            <Col xl="10" className="loginDisplayText"> 
                                <LoginGroupSeedComponent />
                            </Col>
                            <Col xl="1" />
                        </Row>
                    </Col>
                    <Col xl="8" className="loginRightCol">
                        <Image className="reindeerLoginImage" alt="Login image" fluid src={reindeerImage} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default LoginPage;

