import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AsolviButton from "../../../Elements/AsolviButton/AsolviButton";
import AsolviInput from "../../../Elements/AsolviInput/AsolviInput";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import AsolviAdvancedDropdownList, { IColumn } from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import AsolviLabel from "../../../Elements/AsolviLabel/AsolviLabel";
import { ITaskContactGroupData } from "../../../../reducers/TaskReducer";



interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  contactList: ITaskContactGroupData[],
  allowUpdateContact: boolean,
  contactSearchLayout: IColumn[],
  handleUpdateClick?: (data: ITaskContactGroupData) => void;
  extractData?: (data: ITaskContactGroupData) => void;
  clearData?: boolean;
}

const TaskContactGroup: React.FunctionComponent<IProps> = (props) => {
  const { t, contactList, clearData, allowUpdateContact, extractData } = props;
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [fullName, setFullName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [selectedContact, setSelectedContact] = useState<ITaskContactGroupData>();

  const updateValues = (e: any) => {
    setSelectedContact(e);
    setFirstName(e === null ? "" : e.FirstName);
    setLastName(e === null ? "" : e.LastName);
    setFullName(e === null ? "" : e.FullName);
    setPhone(e === null ? "" : e.Phone);
    setEmail(e === null ? "" : e.Email);
  }

  const sendData =  useCallback(() => {
    if(extractData)
    {
      let data = {} as ITaskContactGroupData;
      data.CustomerGuid = selectedContact?.CustomerGuid ?? "";
      data.ContactNo = selectedContact?.ContactNo ?? 0;
      data.FirstName = firstName ?? "";
      data.LastName = lastName ?? "";
      data.FullName = fullName ?? "";
      data.Phone = phone ?? "";
      data.Email = email ?? "";

      extractData(data);
    }
  }, [extractData, selectedContact, firstName, lastName, fullName, phone, email]);

  const buttonClick = () => {
    if(props.handleUpdateClick)
    {
      let data = {} as ITaskContactGroupData;
      data.CustomerGuid = selectedContact?.CustomerGuid ?? "";
      data.ContactNo = selectedContact?.ContactNo ?? 0;
      data.FirstName = firstName ?? "";
      data.LastName = lastName ?? "";
      data.FullName = fullName ?? "";
      data.Phone = phone ?? "";
      data.Email = email ?? "";

      props.handleUpdateClick(data);
    }
  }

  useEffect(() => {
    sendData();
  }, [selectedContact, sendData]);

  useEffect(() => {
    allowUpdateContact && sendData();
  }, [firstName, lastName, fullName, phone, email, allowUpdateContact, sendData]);

  useEffect(() => {        
    const nodata = {CustomerGuid: "", ContactNo: 0, FirstName: "", LastName: "", FullName: "", Phone: "", FaxNo: "", Email: ""} as ITaskContactGroupData;
    updateValues(nodata);
  }, [contactList]);

  useEffect(() => {    
    if (clearData !== undefined && clearData) {       
      const nodata = {CustomerGuid: "", ContactNo: 0, FirstName: "", LastName: "", FullName: "", Phone: "", FaxNo: "", Email: ""} as ITaskContactGroupData;                 
      updateValues(nodata); 
    }
}, [clearData]);

  return (
    <Container fluid>
      <Row>
      <Col sm={6}>
          <AsolviAdvancedDropdownList
            labelText={t("Contact.SelectLabel")}
            shownText="FullName"
            data={contactList}
            columns={props.contactSearchLayout}
            onSelect={(e: any) => { updateValues(e.target.value);}}
            selectedItem={selectedContact}
          />
      </Col>
      <Col sm={6} />
    </Row>
      <Row>
        <Col sm={6}>
          { allowUpdateContact ?
            <AsolviInput labelText={t("Contact.FirstName")} inputValue={firstName} onChange={(e: any) => { setFirstName(e.target.value); }} />
          : <AsolviLabel labelText={t("Contact.FirstName")} value={firstName ?? ""} />
          }
        </Col>       
        <Col sm={6} /> 
      </Row>
      <Row>
        <Col sm={6}>
            { allowUpdateContact ?
              <AsolviInput labelText={t("Contact.LastName")} inputValue={lastName} onChange={(e: HTMLInputElement) => { setLastName(e.value); }} />
            : <AsolviLabel labelText={t("Contact.LastName")} value={lastName ?? ""} />
          }
        </Col>
        <Col sm={6} />
      </Row>
      <Row>
        <Col sm={6}>
          { allowUpdateContact ?
            <AsolviInput labelText={t("Contact.Phone")} inputValue={phone} onChange={(e: HTMLInputElement) => { setPhone(e.value); }} />
          : <AsolviLabel labelText={t("Contact.Phone")} value={phone ?? ""} />
          }
        </Col>
        <Col sm={6} />
      </Row>
      <Row>
        <Col sm={6}>
          { allowUpdateContact ?
            <AsolviInput labelText={t("Contact.Email")} inputValue={email} onChange={(e: HTMLInputElement) => { setEmail(e.value); }} />
          : <AsolviLabel labelText={t("Contact.Email")} value={email ?? ""} />
          }
        </Col>
        
        <Col sm={2}></Col>
        <Col sm={2}>
          {allowUpdateContact && <AsolviButton buttonText={t("Contact.UpdateContact")} buttonAction={buttonClick} buttonType="button" className="k-primary" />}
        </Col>
        <Col sm={2} />
      </Row>
    </Container>
  );
}

export default ReactI18Next.withTranslation()(TaskContactGroup);