import * as React from "react";
import * as ReactRedux from 'react-redux';
import * as Store from "../../../store/Store";
import * as DisplaySettings from "../../../sources/settingsDisplay";



import OrderPage from "../../Pages/OrderPage/OrderPage";

const OrderPageSeedComponent: React.FunctionComponent<{}> = () => {
    const General = ReactRedux.useSelector((state: Store.IAppState) => state.generalState.General)
    const Settings = ReactRedux.useSelector((state: Store.IAppState) => state.settingsState.Settings)
    const ShowOrderLog:boolean = DisplaySettings.default("SHOW_ORDER_LOG",Settings.defaultSettings);

    let WebAccessOrderLog: boolean = false; 
    let WebAccessOrderCreateNew: boolean = true; 
    let loggedInName: string = "";
 
    if(Settings.contactList){
        let contactList = JSON.parse(Settings.contactList);
        if(contactList.length>1){
            let currentContact: any = contactList.find((item:any) => item.CustomerGuid === General.activeCustomer);
            WebAccessOrderLog = (ShowOrderLog && currentContact.WebAccessOrderLog === 1);
            WebAccessOrderCreateNew = (currentContact.WebAccessOrder === 1);
            loggedInName = currentContact.ContactName;
         }else if(contactList.length === 1){
            WebAccessOrderLog = (ShowOrderLog && contactList[0].WebAccessOrderLog === 1);
            WebAccessOrderCreateNew = (contactList[0].WebAccessOrder === 1);
            loggedInName = contactList[0].ContactName;
        }
    }

    return (
        <OrderPage showOrderLog={WebAccessOrderLog} showOrderCreateNew={WebAccessOrderCreateNew} loggedInName={loggedInName}/>
   )
}

export default OrderPageSeedComponent;