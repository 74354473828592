import * as React from 'react';
import "./AsolviInput.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Input} from '@progress/kendo-react-inputs';
import "@progress/kendo-theme-material/dist/all.scss";

declare interface ILabelSizes {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

export interface IProps{
  labelText: string ,
  inputValue?: string | number | string[],
  required?: boolean,
  disabled?:boolean
  onChange?: (e: any) => void;
  type?: string;
  labelSize?: number | ILabelSizes;
}

const AsolviInput: React.FunctionComponent<IProps> = (props) => {
  let labelSize = props.labelSize ?? 4;
  if(typeof labelSize !== "object") {
    labelSize = {
      xs: labelSize,
    };
  }
  let inputSize = {
    xs: labelSize.xs ? 12 - labelSize.xs : undefined,
    sm: labelSize.sm ? 12 - labelSize.sm : undefined,
    md: labelSize.md ? 12 - labelSize.md : undefined,
    lg: labelSize.lg ? 12 - labelSize.lg : undefined,
    xl: labelSize.xl ? 12 - labelSize.xl : undefined
  }
  return (
    <Row style={{alignItems: 'baseline'}}>
      <Col xs={labelSize.xs} sm={labelSize.sm} md={labelSize.md} lg={labelSize.lg} xl={labelSize.xl}>
        <label>
          {props.labelText}
        </label>
      </Col>
      <Col xs={inputSize.xs} sm={inputSize.sm} md={inputSize.md} lg={inputSize.lg} xl={inputSize.xl}>
        <Input type={props.type} onChange={props.onChange} value={props.inputValue ?? ""} required={props.required ?? false} disabled = {props.disabled ?? false}/>
      </Col>
    </Row>
  );
}

export default AsolviInput;
