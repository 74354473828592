import React, { useState } from "react";
import './MachineDetailsGroup.css';
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import AsolviGrid, { IGridLayout } from "../../Elements/AsolviGrid/AsolviGrid";
import { GridCellProps, GridItemChangeEvent, GridRowClickEvent } from "@progress/kendo-react-grid";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { DatePicker, DatePickerProps } from "@progress/kendo-react-dateinputs";
import { ModalContext } from "../../Auxiliary/AsolviModal/AsolviModal";
import { DateInputsPopupSettings } from "@progress/kendo-react-dateinputs/dist/npm/PopupSettings";
import { IMachinePropertyItem } from "../../seedComponents/Pages/MachinePageSeedComponent";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import i18n from "../../../i18n";
import { parseDate } from "@telerik/kendo-intl";

export interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  machineProperties: IMachinePropertyItem[];
  propertyCodes: IPropertyCodes[];
  updateMachinePropertiesData: (properties: IMachinePropertyItem[]) => void;
  allowEdit: boolean;
}

export interface IPropertyCodes {
  Code: string,
  Description: string,
  DataType: string,  
  DropdownValues: string,
  UseValues: boolean,
  Min: number,
  Max: number
}

const MachinePropertiesGroup: React.FunctionComponent<IProps> = (props) => {
  const { t, machineProperties, propertyCodes, updateMachinePropertiesData, allowEdit } = props;
  const [properties, setProperties] = useState<IMachinePropertyItem[]>([]);
  const [editID, setEditID] = React.useState<string | null>(null);
  const [currentEditor, setCurrentEditor] = React.useState<string>("");

  const ModalDatePicker: React.FunctionComponent<DatePickerProps> = (props) => {
    const context = React.useContext(ModalContext);
    let popupSettings = {} as DateInputsPopupSettings;
    if (context && context.current) {
      popupSettings.appendTo = context.current
    }

    return <DatePicker
      {...props}
      popupSettings={popupSettings} 
    />
  };

  const valueCell = React.useMemo(
      () => (cellProps: GridCellProps) => {  
    const { dataItem } = cellProps;      
    const dataValue = dataItem["Value"] === null ? null : dataItem["Value"];
    
    const handleValuehange = (e: any) => {   
      const value = dataItem.DataType === "D" && parseDate(e.target.value, "d", i18n.language) !== null ?  parseDate(e.target.value, "d", i18n.language).toLocaleDateString() : e.target.value;      
      const newData = properties.map((item) =>
        item.PropertyGuid === dataItem.PropertyGuid ? { ...item, Value: value } : item
      );      
      updateMachinePropertiesData(newData);
      setProperties(newData);        
    };    
    
    let dropdownValues: String[];
    dropdownValues = [];
    if (dataItem.DropdownValues && dataItem.UseValues) {
      const values = dataItem.DropdownValues as String;
      dropdownValues = values.split(";");
    }
    
    return (      
      <td>        
        {dataItem.inEdit ? (
          dropdownValues.length > 0 ? ( 
            <DropDownList
              style={{ width: "100px" }}
              onChange={handleValuehange}
              value={dataValue ? dataValue : ""}
              data={dropdownValues}
          />
          )
          : ( 
            dataItem.DataType === "D" ? ( 
            <ModalDatePicker value={parseDate(dataValue, "d", i18n.language) ? parseDate(dataValue, "d", i18n.language) : null} width="100%" onChange={handleValuehange} />
          ) :
            ( dataItem.DataType === "N" ? (
               <NumericTextBox value={parseFloat(dataValue) ? parseFloat(dataValue) : 0 } 
                                          max={dataItem.Max ? dataItem.Max : undefined }  
                                          min={dataItem.Min ? dataItem.Min : undefined }  
                                          onChange={handleValuehange} /> 
              ):
                ( <Input value={dataValue ? dataValue : ""}  
                                          maxLength={dataItem.Max ? dataItem.Max : undefined }  
                                          minLength={dataItem.Min ? dataItem.Min : undefined }                                           
                                          onChange={handleValuehange} />) 
            )
          )
        ) 
        : 
        (
          dataItem.DataType === "D" ? ( 
            parseDate(dataValue, "d", i18n.language) ? parseDate(dataValue, "d", i18n.language).toLocaleDateString() : ""
          ) : ( dataItem.DataType === "N" ? (
            parseFloat(dataValue).toLocaleString()
            ) :
            ( dataValue.toString() )
          )
        )}
      </td>
    );
  }, [currentEditor]);

  const dropDownCell = (cellProps: GridCellProps) => {
    const { dataItem } = cellProps;
    const dataValue = dataItem["Description"] === null ? "" : dataItem["Description"];

    const handleDropDownChange = (e: DropDownListChangeEvent) => {      
      const newData = properties.map((item) =>
        item.PropertyGuid === dataItem.PropertyGuid ? { ...item, Id: e.target.value.Code, Description: e.target.value.Description, 
                                                                DataType: e.target.value.DataType, DropdownValues: e.target.value.DropdownValues,
                                                                UseValues: e.target.value.UseValues,
                                                                Min: e.target.value.Min, Max: e.target.value.Max  } : item
      );      
      setCurrentEditor(e.target.value.DataType ? dataItem.PropertyGuid + "-" + e.target.value.DataType : dataItem.PropertyGuid + "-" + "A" );
      updateMachinePropertiesData(newData);
      setProperties(newData);                  
    };

    return (
      <td>
        {dataItem.inEdit ? (
          <DropDownList
            style={{ width: "100px" }}
            onChange={handleDropDownChange}
            value={propertyCodes.find((c) => c.Description === dataValue)}
            data={propertyCodes}
            textField="Description"
          />
        ) : (
          dataValue.toString()
        )}
      </td>
    );
  };

  let propLayout: IGridLayout[] = [
    {
      fieldName: "Id",
      fieldLanguageKey: "id",
      colWidth: 0,
      editable: false,
      visible: false
    },
    {
      fieldName: "Description",
      fieldLanguageKey: t("Properties.description"),
      colWidth: 180,
      editable: true,
      cellStyle: dropDownCell,
      visible: true
    },
    {
      fieldName: "Value",
      fieldLanguageKey: t("Properties.value"),
      editable: true,
      cellStyle: valueCell,
      visible: true
    }
  ];

  const rowClick = (event: GridRowClickEvent) => {
    if (allowEdit) {
      setCurrentEditor(event.dataItem.DataType ? event.dataItem.PropertyGuid + "-" + event.dataItem.DataType : event.dataItem.PropertyGuid + "-" + "A" );
      setEditID(event.dataItem.PropertyGuid);
    }
  };

  const addNew = (e: any) => {
    const newRecord = { PropertyGuid: (properties.length + 1).toString(), Id: "", Value: "",
                                                         Description: "", DataType: "", 
                                                         DropdownValues: "", UseValues: false,
                                                         Min: 0, Max: 0 };
    setProperties([newRecord, ...properties]);
    updateMachinePropertiesData([newRecord, ...properties]);
    setEditID(newRecord.PropertyGuid);
  };

  const remove = (item: any) => {
    const data = [...properties];
    let index = data.findIndex((record) => record.PropertyGuid === item.PropertyGuid);
    data.splice(index, 1);
    updateMachinePropertiesData(data);
    setProperties(data);
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const inEditID = event.dataItem.PropertyGuid;
    const field = event.field || "";

    const newData = properties.map((item) =>
      item.PropertyGuid === inEditID ? { ...item, [field]: event.value } : item
    );
    updateMachinePropertiesData(newData);
    setProperties(newData);    
  };

  React.useEffect(() => {
    updateMachinePropertiesData(machineProperties);
    setProperties(machineProperties);
  }, [machineProperties]);

  return (    
    <AsolviGrid gridLayout={propLayout}
      data={properties.map((item: IMachinePropertyItem) => ({
        ...item,
        inEdit: item.PropertyGuid === editID,
      }))}
      handleItemChange={itemChange}
      handleChange={() => { }}
      handleRowClick={rowClick}
      enableAddNew={allowEdit}
      handleAddNew={addNew}
      dataKeyField={"PropertyGuid"}
      enableDelete={allowEdit}
      handleDelete={remove}      
    />    
  );
}

export default ReactI18Next.withTranslation()(MachinePropertiesGroup);