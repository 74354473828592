import { IMeterInfo, IMachineInfo } from "../components/MeterReport/seedComponents/Pages/MeterReportingPageSeedComponent";
import { parseDate } from '@telerik/kendo-intl';
import { useInternationalization } from "@progress/kendo-react-intl";

export default function (machinesAndMeters: any){
    const items = [] as Array<IMachineInfo>;
    const intl = useInternationalization();

    if (machinesAndMeters) {
        for (let machine of JSON.parse(machinesAndMeters)) {
            let resultMeters = undefined;
            if(machine.meters){
                resultMeters = machine.meters.map((meter: IMeterInfo) => {
                    const newReadingDateFormatted = parseDate(meter.newReadingDate);
                    const oldReadingDateFormatted = meter.oldReadingDate ? intl.formatDate(parseDate(meter.oldReadingDate), "d") : undefined;
                    const oldCounter = meter.oldCounter ? meter.oldCounter : undefined;
                    const newCounter = meter.newCounter ? meter.newCounter : undefined;
                    return {
                        ...meter, newReadingDateFormatted: newReadingDateFormatted,
                        oldReadingDateFormatted: oldReadingDateFormatted, oldCounter: oldCounter, newCounter: newCounter
                    }
                });
            }
            items.push({ ...machine, meters: resultMeters });
          }
    }
    return items;
  }