import * as Redux from 'redux';
import * as TaskActions from "../actions/TaskActions";

export interface ITaskVisibilitySettings {
  ShowSelectCustomer: boolean,
  ShowSelectMachine: {
    Group: boolean,
    ShowMachineId: boolean,
    ShowSortGroup1: boolean,
    ShowSortGroup2: boolean,
    ShowSortGroup3: boolean,
    ShowSortGroup4: boolean,
    ShowSortGroup5: boolean,
    SortGroup1Caption: string,
    SortGroup2Caption: string,
    SortGroup3Caption: string,
    SortGroup4Caption: string,
    SortGroup5Caption: string
  }
  ShowProperties: {
    Group: boolean,
    ShowMachineProperties: boolean
  },
  ShowInfoFields: {
    Group: boolean,
    ShowInfoField1: boolean,
    ShowInfoField2: boolean,
    ShowInfoField3: boolean,
    ShowInfoField4: boolean,
    ShowInfoField5: boolean,
    ShowInfoField6: boolean
  },
  ShowSymptomGroup: {
    UseStrongRelationService: boolean,
    UseStrongRelationHelpDesk: boolean,
    ShowSymptomCode1: boolean,
    ShowSymptomCode2: boolean,
    ShowSymptomCode3: boolean,
    ShowSymptomCode4: boolean
  },
  ShowContact: {
    AllowUpdate: boolean
  },
  ShowUploadFiles: boolean,
  MaxFileSize: number
}

export interface ITaskRadioButtonProp {
  label: string;
  value: string;
}

export interface ITaskRadioButtonProps {
  radioButtonProps: ITaskRadioButtonProp[];
}

export interface ITaskInfoLabels {
  labelList: string[];
}

export interface ITaskPostalCode {
  Code: string,
  Description: string,
  CodeAndDescription?: string
}

export interface ITaskPostalCodes {
  PostalCodes: ITaskPostalCode[];
}

export interface ICreateTask {
  ProjectNo?: string | undefined;
  error?: string | undefined;
}

export interface ITaskState {
  readonly TaskRadioButtonProps: ITaskRadioButtonProps;
  readonly TaskInfoLabels: ITaskInfoLabels;
  readonly TaskVisibilitySettings: ITaskVisibilitySettings;
  readonly TaskPostalCodes: ITaskPostalCodes;
  readonly TaskMachines: ITaskMachines;
  readonly TaskSymptomCodes: ITaskSymptomCodeItems;
  readonly CreateTask: ICreateTask;
  readonly TaskCustomers: ITaskCustomerItem[];
  readonly TaskCustomerProperties: ITaskProperyItem[];
  readonly TaskMachineProperties: ITaskProperyItem[];
  readonly SelectedMachine?: string;
  readonly SelectedCustomer?: string;
  readonly TaskContacts: ITaskContactGroupData[];
  readonly TaskUpdateContact: ITaskUpdateContact;
  readonly TaskLog: ITaskLog
}

export interface ITaskMachines {
  machinesList: ITaskMachineItem[],
  error?: string
}

export interface ITaskMachineItem {
  ModelType: string,
  MachineNo: string,
  MachineGuid: string,
  MachineId: string,
  Location: string,
  AddressName: string,
  Address1: string,
  Address2: string,
  Address3: string,
  AddressCountry: string,
  PostalCode: string,
  PostalDescription: string,
  customerNo: string,
  customerName: string,
  SortGroup1: string,
  SortGroup2?: string,
  SortGroup3?: string,
  SortGroup4?: string,
  SortGroup5?: string
}

export interface ITaskSymptomCode {
  Code: string,
  Description: string,
  CodeAndDescription: string
}

export interface ITaskSymptomCodeItems {
  SymptomCode1Items: ITaskSymptomCode[],
  SymptomCode2Items: ITaskSymptomCode[],
  SymptomCode3Items: ITaskSymptomCode[],
  SymptomCode4Items: ITaskSymptomCode[]
}

export interface ITaskCustomerItem {
  CustomerGuid: string,
  CustomerNo: string,
  CustomerName: string,
  Address1: string,
  Address2: string,
  PostalCode: string,
  PostDescription: string,
  AddressPhone: string,
  AddressFax: string,
  InvoiceCustomerGuid: string,
  InvoiceCustomerAddressRowNo: number
}

export interface ITaskProperyItem {
  PropertyGuid: string
  Id: string,
  Description: string,
  Value: string
}

export interface ITaskContactGroupData {
  CustomerGuid: string,
  ContactNo: number,
  FirstName: string,
  LastName: string,
  FullName: string,
  Phone: string,
  FaxNo: string,
  Email: string
}

export interface ITaskUpdateContact {
  error?: string | undefined;
}

export interface ITaskLogItem {
  TaskGuid: string,
  ReceivedDate: Date
  ProjectNo: number,
  TaskNo: number,
  TaskTitle: string,
  CustomerName: string,
  CustomerReferenceInfo: string,
  MachineNo: string,
  MachineIdNo: number | undefined,
  ModelType: string,
  MachineLocation: string,
  Symptom1: string,
  Symptom2: string,
  Symptom3: string,
  Symptom4: string,
  SymptomText: string,
  ActionCode1: string,
  ActionCode2: string,
  ActionCode3: string,
  ActionCode4: string,
  ActionText: string,
  Status: string,
  ObjDate: Date,
  EndWorkedDate: Date | undefined,
  ProjectInfo01: string,
  ProjectInfo02: string,
  ProjectInfo03: string,
  ProjectInfo04: string,
  ProjectInfo05: string ,
  ProjectInfo06: string,
  PlannedFromDate: Date | undefined,
  PlannedToDate: Date | undefined,
  PlannedDuration: number | undefined,
  ContactName: string,
  Technician: string,
  TaskType:number
}

export interface ITaskLog {
  TaskLog: ITaskLogItem[],
  Loading: boolean
}

export interface ITaskLogFilter {
  ProjectNo: number | undefined,
  MachineNo: string,
  ModelType: string,
  MachineIdNo: number | undefined,
  ReferenceInfo: string,
  CustomerName: string,
  DatePeriod: string,
  OpenJobs: boolean,
  ClosedJobs: boolean,
  ServiceJobs: boolean,
  HelpdeskJobs: boolean,
  DateFrom: Date,
  DateTo: Date,
  ClientTimeZoneOffset: number | undefined
}

const initialTaskState: ITaskState = {
  TaskRadioButtonProps: {
    radioButtonProps: []
  },
  TaskPostalCodes: {
    PostalCodes: []
  },
  TaskInfoLabels: {
    labelList: []
  },
  TaskVisibilitySettings: {
    ShowSelectCustomer: true,
    ShowSelectMachine: {
      Group: true,
      ShowMachineId: false,
      ShowSortGroup1: false,
      ShowSortGroup2: false,
      ShowSortGroup3: false,
      ShowSortGroup4: false,
      ShowSortGroup5: false,
      SortGroup1Caption: "Sort group 1",
      SortGroup2Caption: "Sort group 2",
      SortGroup3Caption: "Sort group 3",
      SortGroup4Caption: "Sort group 4",
      SortGroup5Caption: "Sort group 5"
    },
    ShowProperties: {
      Group: true,
      ShowMachineProperties: true
    },
    ShowInfoFields: {
      Group: true,
      ShowInfoField1: true,
      ShowInfoField2: true,
      ShowInfoField3: true,
      ShowInfoField4: true,
      ShowInfoField5: true,
      ShowInfoField6: true
    },
    ShowSymptomGroup: {
      UseStrongRelationService: true,
      UseStrongRelationHelpDesk: true,
      ShowSymptomCode1: true,
      ShowSymptomCode2: true,
      ShowSymptomCode3: true,
      ShowSymptomCode4: true
    },
    ShowContact: {
      AllowUpdate: true
    },
    ShowUploadFiles: true,
    MaxFileSize: 0
  },
  TaskMachines: {
    machinesList: [] as ITaskMachineItem[],
    error: undefined
  },
  TaskSymptomCodes: {
    SymptomCode1Items: [] as ITaskSymptomCode[],
    SymptomCode2Items: [] as ITaskSymptomCode[],
    SymptomCode3Items: [] as ITaskSymptomCode[],
    SymptomCode4Items: [] as ITaskSymptomCode[]
  },
  CreateTask: {
    ProjectNo: undefined,
    error: ""
  },
  TaskCustomers: [] as ITaskCustomerItem[],
  TaskCustomerProperties: [] as ITaskProperyItem[],
  TaskMachineProperties: [] as ITaskProperyItem[],
  SelectedCustomer: undefined,
  SelectedMachine: undefined,
  TaskContacts: [] as ITaskContactGroupData[],
  TaskUpdateContact: {
    error: ""
  },
  TaskLog: {
    TaskLog: [] as ITaskLogItem[],
    Loading: false
  }
}

export const TaskReducer: Redux.Reducer<ITaskState, TaskActions.TaskActions> = (
  state = initialTaskState,
  action
) => {
  switch(action.type){
    case TaskActions.TaskActionTypes.GETRADIOBUTTONLIST: {
      return {
        ...state,
        TaskRadioButtonProps: {
          ...state.TaskRadioButtonProps,
          radioButtonProps: action.TaskRadioButtonProps.radioButtonProps
        }
      };
    }
    case TaskActions.TaskActionTypes.GETINFOLABELLIST: {
      return {
        ...state,
        TaskInfoLabels: {
          ...state.TaskInfoLabels,
          labelList: action.TaskInfoLabels.labelList
        }
      };
    }
    case TaskActions.TaskActionTypes.GETTASKVISIBILITYSETTINGS: {
      return {
        ...state,
        TaskVisibilitySettings: {
          ...state.TaskVisibilitySettings,
          ...action.TaskVisibilitySettings
        }
      };
    }
    case TaskActions.TaskActionTypes.GETLIMITEDPOSTALCODES:{
      return {
        ...state,
        TaskPostalCodes: {
          ...state.TaskPostalCodes,
          PostalCodes: action.TaskPostalCodes.PostalCodes
        }
      };
    }
    case TaskActions.TaskActionTypes.GETMACHINESLIST: {
      return {
        ...state,
        TaskMachines: {
          ...state.TaskMachines,
          machinesList: action.TaskMachines.machinesList,
          error: action.TaskMachines.error
        },
        SelectedMachine: undefined
      };
    }
    case TaskActions.TaskActionTypes.GETSYMPTOMCODES: {
      return {
        ...state,
        TaskSymptomCodes: {
          ...state.TaskSymptomCodes,
          ...action.TaskSymptomCodes
        }
      };
    }
    case TaskActions.TaskActionTypes.GETSYMPTOMCODES2: {
      return {
        ...state,
        TaskSymptomCodes: {
          ...state.TaskSymptomCodes,
          SymptomCode2Items: action.SymptomCode2Items
        }
      };
    }
    case TaskActions.TaskActionTypes.GETSYMPTOMCODES3: {
      return {
        ...state,
        TaskSymptomCodes: {
          ...state.TaskSymptomCodes,
          SymptomCode3Items: action.SymptomCode3Items
        }
      };
    }
    case TaskActions.TaskActionTypes.GETSYMPTOMCODES4: {
      return {
        ...state,
        TaskSymptomCodes: {
          ...state.TaskSymptomCodes,
          SymptomCode4Items: action.SymptomCode4Items
        }
      };
    }
    case TaskActions.TaskActionTypes.SUBMITNEWTASK: {
      return{
        ...state,
        CreateTask: {
          ...state.CreateTask,
          ProjectNo: action.CreateTask.ProjectNo
        }
      };
    }
    case TaskActions.TaskActionTypes.TASKRESPONSEERROR: {
      return{
        ...state,
        CreateTask: {
          ...state.CreateTask,
          error: action.CreateTask.error
        }
      };
    }
    case TaskActions.TaskActionTypes.GETCUSTOMERLIST: {
      return {
        ...state,
        TaskCustomers: action.TaskCustomers
      };
    }
    case TaskActions.TaskActionTypes.GETCUSTOMERPROPERTIES: {
      return {
        ...state,
        TaskCustomerProperties: action.TaskCustomerProperties
      };
    }
    case TaskActions.TaskActionTypes.GETMACHINEPROPERTIES: {
      return {
        ...state,
        TaskMachineProperties: action.TaskMachineProperties
      };
    }
    case TaskActions.TaskActionTypes.HANDLESELECTEDTASKCUSTOMER: {
      return {
        ...state,
        SelectedCustomer: action.SelectedCustomer,
        SelectedMachine: undefined
      };
    }
    case TaskActions.TaskActionTypes.GETCONTACTS: {
      return {
        ...state,
        TaskContacts: action.TaskContacts
      };
    }
    case TaskActions.TaskActionTypes.SUBMITUPDATECONTACT: {
      return{
        ...state,
        TaskUpdateContact: {
          error: ""
        }
      };
    }
    case TaskActions.TaskActionTypes.UPDATECONTACTRESPONSEERROR: {
      return{
        ...state,
        TaskUpdateContact: {
          error: action.TaskUpdateContact.error
        }
      }
    }
    case TaskActions.TaskActionTypes.HANDLESELECTEDMACHINE: {
      return {
        ...state,
        SelectedMachine: action.SelectedMachine
      };
    }
    case TaskActions.TaskActionTypes.GETTASKLOG: {
      return {
        ...state,
        TaskLog: {
          TaskLog: action.TaskLog,
          Loading: false
        }
      };
    }

    case TaskActions.TaskActionTypes.SETTASKLOGLOADING: {
      return {
        ...state,
        TaskLog: {
          TaskLog: [] as ITaskLogItem[],
          Loading: action.Loading
        }
      };
    }

    default: {
      return state;
    }
  }
}