import * as React from "react";
import { useCallback } from "react";
import * as Store from "../../../store/Store";
import { useDispatch, useSelector } from 'react-redux';
import * as Auth0 from '@auth0/auth0-react';
import AsolviModal from "../../Auxiliary/AsolviModal/AsolviModal";
import LoginPageSeedComponent from "../../seedComponents/Pages/LoginPageSeedComponent";
import HomePageSeedComponent from "../../seedComponents/Pages/HomePageSeedComponent";
import SettingsPageSeedComponent from "../../seedComponents/Pages/SettingsPageSeedComponent";
import * as SettingsActions from "../../../actions/SettingsActions";
import { useTranslation } from 'react-i18next';
import * as DisplaySettings from "../../../sources/settingsDisplay";
import * as GeneralActions from "../../../actions/GeneralActions";
import { useApi } from "../../../utils/useApi";
import * as MeterLoginActions from "../../../actions/MeterLoginActions";
import MeterReportingPageSeedComponent from "../../MeterReport/seedComponents/Pages/MeterReportingPageSeedComponent";
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { converti18nToKendoLanguage } from "../../../utils/languageConverter"
import i18n from "../../../i18n";
import AsolviConsentModal from "../../Auxiliary/AsolviConsentModal/AsolviConsentModal";
import { HashRouter } from "react-router-dom";

const AppSeedComponent: React.FunctionComponent = () => {

  const Settings = useSelector((state: Store.IAppState) => state.settingsState.Settings);
  const General = useSelector((state: Store.IAppState) => state.generalState.General);
  const dispatch = useDispatch();
  const getAvailableDbs = useCallback((appUrl: string) => dispatch(SettingsActions.GetAvailableDbs(appUrl)), [dispatch]);
  const getDefaultSettings = useCallback((token: string, appUrl: string, email: string) => dispatch(SettingsActions.GetAvailableSettings(token, appUrl, email)), [dispatch]);
  const skipSettings = useCallback(() => dispatch(GeneralActions.skipSettings()), [dispatch]);
  const showSettings = useCallback(() => dispatch(GeneralActions.showSettings()), [dispatch]);
  const getContactDropdownList = useCallback((token: string, email: string, appUrl: string) => dispatch(SettingsActions.GetContactDropDownList(token, email, appUrl)), [dispatch]);
  const getContactList = useCallback((token: string, email: string, appUrl: string) => dispatch(SettingsActions.GetContactList(token, email, appUrl)), [dispatch]);
  const saveAuth0TokenToStore = useCallback((token: string) => dispatch(GeneralActions.SaveAuth0TokenToStore(token)), [dispatch]);
  const saveCurrentUserEmailToStore = useCallback((email: string) => dispatch(GeneralActions.SaveCurrentUserEmailToStore(email)), [dispatch]);
  const setMeterLoginToken = useCallback((token: string) => dispatch(MeterLoginActions.SetMeterLoginToken(token)), [dispatch]);

  const dbInfo = Settings.dbInfo;
  const [renderHomePageSeedComponent, setRenderHomePageSeedComponent] = React.useState(false);
  const { appUrl } = (window as any)["appConfig"];
  const { token } = useApi();
  const defaultSettings = Settings.defaultSettings;
  const { t } = useTranslation();

  const { user, isAuthenticated, isLoading } = Auth0.useAuth0();
  const showModal = !isAuthenticated && isLoading;
  const showHomePage = isAuthenticated && !isLoading;
  const showLoginPage = !isAuthenticated;

  const showSettingsPage = General.showSettingsPage;
  const pathArray = window.location.pathname.split('/');
  const pathToken = pathArray.pop();

  let meterToken = "";
  if(pathToken)
  {
    meterToken = pathToken;
  }
  let decoded = "";
  try{
    decoded = window.atob(meterToken);
  }
  catch{}


  React.useEffect(()=>{
    if(dbInfo)
      getAvailableDbs(appUrl);
  },[appUrl, dbInfo, getAvailableDbs])

  React.useEffect(() => {
    if(token !== General.currentAuth0Token) {
      saveAuth0TokenToStore(token);
    }
    if(user?.email !== General.currentUserEmail) {
      saveCurrentUserEmailToStore(user?.email as string);
    }
  }, [token, user, General, saveAuth0TokenToStore, saveCurrentUserEmailToStore]);

  React.useEffect(()=>{
    if(token && isAuthenticated && user?.email && !defaultSettings)
    {
      const { email } = user;
      getDefaultSettings(token, appUrl, email);
      getContactDropdownList(token, email, appUrl);
      getContactList(token, email, appUrl);
    }
  },[token, user, getDefaultSettings, getContactDropdownList, getContactList, appUrl,isAuthenticated, defaultSettings ])


  React.useEffect(() => {
    if(defaultSettings)
    {
      let hideDatabase = DisplaySettings.default("HIDE_DROPDOWN_DATABASE", defaultSettings);
      let hideLanguage = DisplaySettings.default("HIDE_DROPDOWN_LANGUAGE", defaultSettings);
      let hideCustomers = DisplaySettings.default("HIDE_DROPDOWN_CUSTOMER", defaultSettings);
      if (hideDatabase && hideLanguage && hideCustomers) {
        skipSettings();
        setRenderHomePageSeedComponent(true);
      } else {
        showSettings();
      }
    }
  },[defaultSettings, skipSettings, setRenderHomePageSeedComponent, showSettings])

  if(decoded.indexOf('@') > -1)
  {
    setMeterLoginToken(meterToken);
    return(
      <>
        <LocalizationProvider language={converti18nToKendoLanguage(i18n.language)}>
          <IntlProvider locale={converti18nToKendoLanguage(i18n.language)}>
            <MeterReportingPageSeedComponent />
          </IntlProvider>
        </LocalizationProvider>
      </>
    )
  }
  else
  {
    return (
      <>
        <LocalizationProvider language={converti18nToKendoLanguage(i18n.language)}>
          <IntlProvider locale={converti18nToKendoLanguage(i18n.language)}>
            <HashRouter>
              <AsolviConsentModal />
              <AsolviModal
                header={t("Standard.authenticating")}
                showModal={showModal}
                message={t("Standard.authenticatingMessage")}
                shouldDisplayFooter={false}
                shouldDisplaySpinner={true}
                handleClose={() => { }}
              />
              {showLoginPage && <LoginPageSeedComponent />}
              {(showHomePage && !showSettingsPage) &&
                <HomePageSeedComponent renderHomePageSeedComponent={renderHomePageSeedComponent} />}
              {(showHomePage && showSettingsPage) &&
                <SettingsPageSeedComponent setRenderHomePageSeedComponent={setRenderHomePageSeedComponent} />}
            </HashRouter>
          </IntlProvider>
        </LocalizationProvider>
      </>
    );
  }
}


// connecting the component with mapping of Store and the Actions
export default AppSeedComponent;
