import * as React from 'react';
import "./AsolviUpload.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "@progress/kendo-theme-material/dist/all.scss";
import {
    Upload,
    UploadOnAddEvent,
    UploadOnRemoveEvent,
    UploadOnProgressEvent,
    UploadOnStatusChangeEvent,
    UploadFileInfo    
} from "@progress/kendo-react-upload";

export interface IProps{
    batch: boolean,
    multiple: boolean,
    labelText: string,
    saveUrl?: string,
    removeUrl?: string,
    maxFileSize?: number,
    onBeforeUpload: (e: any) => void;
    onChange: (e: any) => void;
    clearData?: boolean;
}

const AsolviUpload: React.FunctionComponent<IProps> = (props) => {
    const {clearData, onChange} = props;
    const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);    

    const onAdd = (event: UploadOnAddEvent) => {  
      setFiles(event.newState);
      onChange(event);   
    };
  
    const onRemove = (event: UploadOnRemoveEvent) => { 
      setFiles(event.newState);
      onChange(event);  
    };
  
    const onProgress = (event: UploadOnProgressEvent) => {
      setFiles(event.newState);
    };
  
    const onStatusChange = (event: UploadOnStatusChangeEvent) => {
      setFiles(event.newState);
    };

    React.useEffect(() => {
      if (clearData !== undefined && clearData) {            
         setFiles([]);  
      }
    }, [clearData]);

    return (
        <Row>        
            <Col sm={2}> 
            <Row>  
            <Col>       
                <label>
                    {props.labelText}
                </label> 
              </Col>
            </Row>
            <Row> 
              <Col>                   
                <label>
                    {"(<"}{props.maxFileSize === undefined ? 0:props.maxFileSize/1024}{" KB)"}
                </label>  
              </Col>                            
            </Row>                                        
            </Col> 
            <Col sm={10}>
            <Upload
                batch={props.batch}
                multiple={props.multiple}
                files={files}
                autoUpload={false}                                
                onAdd={onAdd}
                onRemove={onRemove}
                onProgress={onProgress}
                onStatusChange={onStatusChange}
                onBeforeUpload={props.onBeforeUpload}
                withCredentials={false}
                saveUrl={props.saveUrl}
                removeUrl={props.removeUrl}
                restrictions={{
                  maxFileSize: props.maxFileSize,
                }}    
                showActionButtons={false}                               
            />
            </Col>  
        </Row>
    );
}

export default AsolviUpload;