import * as React from "react";
import { FC, useState } from "react";
import './OrderCreatePage.css'
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import OrderMachineGroupSeedComponent from "../../seedComponents/Groups/Order/OrderMachineGroupSeedComponent";
import { ITaskMachineGroupData } from "../../Groups/Task/TaskMachineGroup/TaskMachineGroup";
import OrderDetailsGroupSeedComponent from "../../seedComponents/Groups/Order/OrderDetailsGroupSeedComponent";
import AsolviModal from "../../Auxiliary/AsolviModal/AsolviModal";
import { useTranslation } from "react-i18next";
import styles from "./OrderCreatePage.module.css";
import CustomerGroupSeedComponent from "../../seedComponents/Groups/Order/CustomerGroupSeedComponent";
import { IOrderVisibilitySettings } from "../../../slices/OrderSlice";
import OrderSummaryModalSeedComponent from "../../seedComponents/Groups/Order/OrderSummaryModalSeedComponent";
import { IMeterReportingSettings } from "../../../sources/MachineMeterReportingSettings";
import { IMachineInfo } from "../../seedComponents/Pages/MeterReadingPageSeedComponent";


export interface IProps {
  clearData?: boolean;
  shouldShowCurrentOrder: boolean;
  hideCurrentOrder: () => void;
  visibilitySettings: IOrderVisibilitySettings;
  isLoadingMeterReadings: boolean;
  meterReadingSettings?: IMeterReportingSettings;
  machinesMetersNeeded?: IMachineInfo[];
  onConfirm: () => void;
  onAcceptMeterReadings: (readings: IMachineInfo[]) => void;
  loggedInName: string;
}

const OrderCreatePage : FC<IProps> = ({ clearData, shouldShowCurrentOrder, hideCurrentOrder, visibilitySettings, isLoadingMeterReadings, meterReadingSettings, machinesMetersNeeded, onConfirm, onAcceptMeterReadings, loggedInName}) =>
{
  const { t } = useTranslation();
  const shouldShowCustomerSelect = visibilitySettings?.ShowSelectCustomer;
  const [machineGroupData, setMachineGroupData] = useState<ITaskMachineGroupData>();

  return (
    <Container fluid className={styles.OrderCreatePageContainer}>
      { shouldShowCustomerSelect
        && <Row>
          <Col sm={12}>
            <CustomerGroupSeedComponent />
          </Col>
        </Row>
      }
      <Row>
        <Col sm={12}>
          <OrderMachineGroupSeedComponent
            extractData={setMachineGroupData}
            clearData={clearData}
          />
        </Col>
      </Row>
      <Row>
        <OrderDetailsGroupSeedComponent
          machineDetails={machineGroupData}
          isLoadingMeterReadings={isLoadingMeterReadings}
          onConfirm={onConfirm}
          loggedInName={loggedInName}
        />
      </Row>
      <AsolviModal
        id = 'orderConfirmModal'
        header={t("Order.CurrentOrder")}
        shouldDisplayFooter={false}
        shouldDisplaySpinner={false}
        showModal={shouldShowCurrentOrder}
        message={shouldShowCurrentOrder &&
          <OrderSummaryModalSeedComponent
            machinesMetersNeeded={machinesMetersNeeded}
            onAcceptMeterReadings={onAcceptMeterReadings}
          />
        }
        handleClose={() => hideCurrentOrder()}
        modalSize="xl"
      />
    </Container>
  );
}

export default OrderCreatePage;
