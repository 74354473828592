import { FC } from "react";
import React from "react";
import { IConsent } from "../../seedComponents/Pages/ConsentsPageSeedComponent";

export interface Props {
  consent: IConsent,
}

const Consent: FC<Props> = ({ consent }) => {
  const { Name, Text } = consent;

  return (
    <div>
      <div>
        <div>{Name}</div>
      </div>
      <div dangerouslySetInnerHTML={{__html: Text}}/>
    </div>
  )
}
export default Consent;
