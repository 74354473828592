import * as React from "react";
import "./SettingsPage.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import reindeerImage from "../../../assets/Images/welcome.png";
import SettingsGroupSeedComponent from "../../seedComponents/Groups/SettingsGroupSeedComponent";

interface IProps{
  setRenderHomePageSeedComponent: (value: boolean) => void;
}


const SettingsPage: React.FunctionComponent<IProps> = (Props) => {
  return(
    <Container className="settingsContainer" fluid>
        <Row className="settingsRow">
          <Col xl="4" className="settingsLeftCol">
            <Row>
              <Col xl="1" />
              <Col xl="10" className="settingsDisplayText">
                <SettingsGroupSeedComponent setRenderHomePageSeedComponent={Props.setRenderHomePageSeedComponent}/>
              </Col>
              <Col xl="1" />
            </Row>
          </Col>
          <Col xl="8" className="settingsRightCol">
            <Image className="settingsReindeerImage" alt="Settingsimage" fluid src={reindeerImage} />
          </Col>
        </Row>
      </Container>
  )
}

export default SettingsPage;