import * as React from 'react';
import { useState } from "react";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import "./TaskUploadGroup.css";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import AsolviUpload from '../../../Elements/AsolviUpload/AsolviUpload';
import { UploadFileInfo } from "@progress/kendo-react-upload";

export interface ITaskUploadGroupData {
    Files: UploadFileInfo[];

}

interface IProps extends ReactI18Next.WithTranslation {
    t: I18Next.TFunction;
    MaxFileSize: number;
    extractData?: (data: ITaskUploadGroupData) => void;
    clearData?: boolean;
    currentAuth0Token: string;
}


const TaskUploadGroup: React.FunctionComponent<IProps> = (props) => { 
    const { extractData } = props;
    const [Files, setFiles] = useState<UploadFileInfo[]>([]);

    const onBeforeUpload = (event:any) => {
        event.headers = { token: props.currentAuth0Token};
      };

    const handleChange = (e: any) => {         
        setFiles(e.newState);      
    }
    
    React.useEffect(()=>{
        const sendData = () => {
            if(extractData){
                let data = {} as ITaskUploadGroupData;
                data.Files = Files;
                extractData(data);
            }
        }

        sendData();
      },[Files, extractData]);

    const { t } = props; 
    return (
        <Container fluid>
            <Row>
                <Col sm={12}>
                    <AsolviUpload onChange={handleChange} batch = {false} multiple = {true}
                        maxFileSize = {props.MaxFileSize}
                        labelText = {t("Upload.attachFiles")} 
                        onBeforeUpload= {onBeforeUpload} 
                        clearData={props.clearData}
                    />
                </Col>
                
            </Row>
        </Container>             
    );
};

export default (ReactI18Next.withTranslation()(TaskUploadGroup));          