import * as React from 'react';
import "./TaskLogPage.css";
import TaskLogFilterGroupSeedComponent from "../../seedComponents/Groups/Task/TaskLogFilterGroupSeedComponent";
import TaskLogGridGroupSeedComponent from '../../seedComponents/Groups/Task/TaskLogGridGroupSeedComponent';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

export interface IProps{
    showServiceLog: boolean;
    showHelpdeskLog: boolean;
}

const TaskLogPage: React.FunctionComponent<IProps> = (Props) =>
{
    return(
        <Container fluid>
            <Row>
                <Col sm={12}>
                    <TaskLogFilterGroupSeedComponent showServiceLog={Props.showServiceLog} showHelpdeskLog={Props.showHelpdeskLog}/>
                </Col>
                <Row>
                    <Col sm={12}>
                        <TaskLogGridGroupSeedComponent showServiceLog={Props.showServiceLog} showHelpdeskLog={Props.showHelpdeskLog}/>
                    </Col>
                </Row>
            </Row>
        </Container>
    )

}

export default TaskLogPage;