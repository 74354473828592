import * as React from "react";
import * as ReactI18Next from "react-i18next";
import * as I18Next from "i18next";
import { Container, Row, Col } from "react-bootstrap";
import OrderLogFilterSeedComponent from "../../seedComponents/Groups/Order/OrderLogFilterSeedComponent";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import OrderLogResultSeedComponent from "../../seedComponents/Groups/Order/OrderLogResultSeedComponent";
import CustomerGroupSeedComponent from "../../seedComponents/Groups/Order/CustomerGroupSeedComponent";

export interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;    
  loadingOrders: boolean;
  getOrders: () => void;
  clearOrdersAndSearchFilter: () => void;  
  shouldShowCustomerSelect: boolean;  
}

const OrderLogPage: React.FunctionComponent<IProps> = (Props) => {
  let { t, loadingOrders, getOrders, clearOrdersAndSearchFilter, shouldShowCustomerSelect } = Props;
    
  return (
    <Container fluid >
      { shouldShowCustomerSelect
        && <Row>
          <Col sm={12}>
            <CustomerGroupSeedComponent />
          </Col>
        </Row>
      }
      <Row>
        <Col sm={12}>
          <OrderLogFilterSeedComponent />
        </Col>
      </Row>
      <Container fluid>
        <Row>
          <Col sm={3} >
            <AsolviButton 
              buttonText={t("OrderLog.SearchButton")}
              buttonType="button"
              buttonDisabled={loadingOrders}
              buttonAction={getOrders}
              className={"k-primary"}
              />
            </Col>
            <Col sm={3} >
            <AsolviButton 
              buttonText={t("OrderLog.ClearButton")}
              buttonType="button"
              buttonAction={clearOrdersAndSearchFilter}              
              />
          </Col>          
        </Row>
        <Row>
          <Col sm={12}>
            <OrderLogResultSeedComponent />
          </Col>
        </Row>
      </Container>
      <Row>
        
      </Row>           
    </Container>
  )
};

export default ReactI18Next.withTranslation()(OrderLogPage);