import React, { FC, useCallback, useEffect, useState } from "react";
import OrderCustomerGroup from "../../../Groups/Order/OrderCustomerGroup/OrderCustomerGroup";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store/Store";
import axios from "axios";
import { OrderAPIs } from "../../../../sources/apiEndPoints";
import { IOrderSelectedCustomer, selectCustomer, setOrderLines } from "../../../../slices/OrderSlice";
import {
  IAsolviAdvancedDropdownListChangeEvent
} from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";


const CustomerGroupSeedComponent: FC = () => {

  const { appUrl } = (window as any)["appConfig"];

  const { currentUserEmail: email, currentAuth0Token: token, activeCustomer }
    = useSelector((state: IAppState) => state.generalState.General)

  const { selectedCustomer }
    = useSelector((state: IAppState) => state.orderState.Order);

  const orderLines = useSelector((state: IAppState) => state.orderState.Order.currentOrder.Lines)

  const [customers, setCustomers] = useState<any[]>([]);

  const dispatch = useDispatch();
  const handleSelectedCustomer = useCallback(
    (customer: IOrderSelectedCustomer) => {
      dispatch(selectCustomer(customer));
      dispatch(setOrderLines([]));
    }, [dispatch]
  );

  useEffect(() => {
    axios.get(
      appUrl + OrderAPIs.GetCustomersLinkedToMain(activeCustomer, email),
      { headers: { token } }
    ).then( (customersResponse) => {
      setCustomers(customersResponse.data);
    })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div>
      <OrderCustomerGroup
        customers={customers}
        selectedCustomerGuid={selectedCustomer?.customerGuid}
        onSelected={(e: IAsolviAdvancedDropdownListChangeEvent<any>) => {
          const customer: IOrderSelectedCustomer = {
            customerGuid: e.value.CustomerGuid,
            customerName: e.value.CustomerName,
            customerNo: e.value.CustomerNo
          };

          handleSelectedCustomer(customer)
        }}
        orderLines={orderLines}
      />
    </div>
  )
}

export default CustomerGroupSeedComponent;
