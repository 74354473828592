import * as React from 'react';
import AppSeedComponent from "./components/seedComponents/App/AppSeedComponent";

//Stateless function component to wrap the whole application
const App: React.FunctionComponent<{}> = () => {
    return (
        <>
            <AppSeedComponent />
        </>
    )
};

export default App;