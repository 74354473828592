import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import "./TaskSymptomGroup.css";
import AsolviInput from "../../../Elements/AsolviInput/AsolviInput";
import AsolviTextArea from "../../../Elements/AsolviTextArea/AsolviTextArea";
import { ITaskSymptomCode } from "../../../../reducers/TaskReducer";
import AsolviAdvancedDropdownList, { IColumn } from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import { useEffect } from "react";
import * as TaskActions from "../../../../actions/TaskActions";
import * as ReactRedux from "react-redux";
import * as  Store from "../../../../store/Store";


export interface ISymptomCodeSetup {
    showSymptomCode1: boolean;
    showSymptomCode2: boolean;
    showSymptomCode3: boolean;
    showSymptomCode4: boolean;
    symptomCodeStrongRelation: boolean;
}

export interface ISymptomData {
    symptomCode1: ITaskSymptomCode | undefined,
    symptomCode2: ITaskSymptomCode | undefined,
    symptomCode3: ITaskSymptomCode | undefined,
    symptomCode4: ITaskSymptomCode | undefined,
    referenceInfo: string | undefined,
    taskTitle: string | undefined,
    symptomText: string | undefined
}

export interface IProps extends ReactI18Next.WithTranslation {  
    t: I18Next.TFunction;
    setup: ISymptomCodeSetup; 
    symptomCode1DropDownItems: ITaskSymptomCode[],
    symptomCode2DropDownItems: ITaskSymptomCode[],
    symptomCode3DropDownItems: ITaskSymptomCode[],
    symptomCode4DropDownItems: ITaskSymptomCode[],
    getTaskSymptomCodes2(token: string, email:string, appUrl: string, selectedSymptomCode1: string, taskType: number): 
                                (token: string, email:string)  => void;
    getTaskSymptomCodes3(token: string, email:string, appUrl: string, selectedSymptomCode1: string , selectedSymptomCode2: string, taskType: number): 
                                (token: string, email:string)  => void;
    getTaskSymptomCodes4(token: string, email:string, appUrl: string, selectedSymptomCode1: string , selectedSymptomCode2: string, selectedSymptomCode3: string, taskType: number): 
                                (token: string, email:string)  => void;
    extractData?: (data: ISymptomData) => void;
    clearData?: boolean;
    currentAuth0Token: string;
    currentUserEmail: string;
    taskType: number;
}

const TaskSymptomGroup: React.FunctionComponent<IProps> = (props) => {
    const { t, extractData, getTaskSymptomCodes2, getTaskSymptomCodes3, getTaskSymptomCodes4, taskType } = props;     
    const token = props.currentAuth0Token;
    const email = props.currentUserEmail;
    const symptomCodeStrongRelation = props.setup.symptomCodeStrongRelation;
    const showSymptomCode2 = props.setup.showSymptomCode2;
    const showSymptomCode3 = props.setup.showSymptomCode3;
    const showSymptomCode4 = props.setup.showSymptomCode4;

    const { appUrl } = (window as any)["appConfig"];

    const [symptomCode1, setSymptomCode1] = React.useState<ITaskSymptomCode>();    
    const [symptomCode2, setSymptomCode2] = React.useState<ITaskSymptomCode>();    
    const [symptomCode3, setSymptomCode3] = React.useState<ITaskSymptomCode>();    
    const [symptomCode4, setSymptomCode4] = React.useState<ITaskSymptomCode>();    
    const [referenceInfo, setReferenceInfo] = React.useState<string>();
    const [taskTitle, setTaskTitle] = React.useState<string>();
    const [symptomText, setSymptomText] = React.useState<string>();
    
    useEffect(() => {
        const sendData = () => {
            if(extractData)
            {
              let data = {} as ISymptomData;
              data.symptomCode1 = symptomCode1;
              data.symptomCode2 = symptomCode2;
              data.symptomCode3 = symptomCode3;
              data.symptomCode4 = symptomCode4;
              data.referenceInfo = referenceInfo;
              data.taskTitle = taskTitle;
              data.symptomText = symptomText;
    
              extractData(data);
            }
            
        }

        sendData();
      }, [symptomCode1, symptomCode2, symptomCode3, symptomCode4, referenceInfo, taskTitle, symptomText, extractData]); 

    useEffect(() => {
        if (symptomCodeStrongRelation && showSymptomCode2) {  
            const data = {} as ITaskSymptomCode;
            setSymptomCode2(data);            
            getTaskSymptomCodes2(token, email, appUrl, symptomCode1 ? symptomCode1.Code : "", taskType);
        }
    }, [symptomCode1, token, email, appUrl, symptomCodeStrongRelation, showSymptomCode2, getTaskSymptomCodes2, taskType]);
     
    useEffect(() => {
        if (symptomCodeStrongRelation && showSymptomCode3) {  
            const data = {} as ITaskSymptomCode;
            setSymptomCode3(data);            
            getTaskSymptomCodes3(token, email, appUrl, symptomCode1 ? symptomCode1.Code : "", symptomCode2 ? symptomCode2.Code : "", taskType);
        }
    }, [symptomCode1, symptomCode2, token, email, appUrl, symptomCodeStrongRelation, showSymptomCode3, getTaskSymptomCodes3, taskType]);

    useEffect(() => {
        if (symptomCodeStrongRelation && showSymptomCode4) {  
            const data = {} as ITaskSymptomCode;
            setSymptomCode4(data);            
            getTaskSymptomCodes4(token, email, appUrl, symptomCode1 ? symptomCode1.Code : "", symptomCode2 ? symptomCode2.Code : "", 
                                        symptomCode3 ? symptomCode3.Code : "", taskType);
        }
    }, [symptomCode1, symptomCode2, symptomCode3, token, email, appUrl, symptomCodeStrongRelation, showSymptomCode4, getTaskSymptomCodes4, taskType]);

    useEffect(() => {
        if (props.clearData !== undefined && props.clearData) {            
            const data = {} as ITaskSymptomCode;
            setSymptomCode1({ Code: "", Description: ""} as ITaskSymptomCode);
            setSymptomCode2(data);
            setSymptomCode3(data);
            setSymptomCode4(data);
            setReferenceInfo("");
            setTaskTitle("");
            setSymptomText(" ");    
        }
    }, [props.clearData]);

    useEffect(() => {        
        const data = {} as ITaskSymptomCode;
        setSymptomCode1({ Code: "", Description: ""} as ITaskSymptomCode);
        setSymptomCode2(data);
        setSymptomCode3(data);
        setSymptomCode4(data);
    }, [taskType]);

    let symptomCodeColumns = [] as IColumn[];
    symptomCodeColumns.push({ field: "Code", header: t("TaskSymptomGroup.symptomCodeColumnLabel"), width: 150,uniqueKey: "Code" });
    symptomCodeColumns.push({ field: "Description", header: t("TaskSymptomGroup.symptomDescriptionColumnLabel"), width: 300, uniqueKey: "Description" });  

    return (        
        <Container fluid>           
            { (props.setup.showSymptomCode1 || showSymptomCode2 || showSymptomCode3)  &&
                <>                               
                    {props.setup.showSymptomCode1 &&
                        <Row>
                        <Col sm={6}>
                            <>
                            <AsolviAdvancedDropdownList
                                labelText={t("TaskSymptomGroup.symptomCodeLabel")}
                                shownText={"CodeAndDescription"}  
                                columns={symptomCodeColumns}                              
                                data={props.symptomCode1DropDownItems}
                                onSelect={(e: any) => { setSymptomCode1(e.target.value); }}
                                selectedItem={symptomCode1}
                            />
                            </>                            
                        </Col>
                        <Col sm={6}></Col>                                  
                        </Row>
                    }  
                    
                    {(showSymptomCode2 && (!symptomCodeStrongRelation || (symptomCode1 ? symptomCode1.Code.length : 0) > 0)) &&
                        <Row>
                        <Col sm={6}>
                        <AsolviAdvancedDropdownList
                            labelText={""}
                            shownText={"CodeAndDescription"}  
                            columns={symptomCodeColumns}                              
                            data={props.symptomCode2DropDownItems}
                            onSelect={(e: any) => { setSymptomCode2(e.target.value); }}
                            selectedItem={symptomCode2}
                        />                            
                        </Col>
                        <Col sm={6}></Col>                                  
                        </Row>
                    }         
                    {(showSymptomCode3 && (!symptomCodeStrongRelation || (symptomCode2 && symptomCode2.Code ? symptomCode2.Code.length : 0) > 0)) &&
                        <Row>
                        <Col sm={6}>
                        <AsolviAdvancedDropdownList
                            labelText={""}
                            shownText={"CodeAndDescription"}  
                            columns={symptomCodeColumns}                              
                            data={props.symptomCode3DropDownItems}
                            onSelect={(e: any) => { setSymptomCode3(e.target.value); }}
                            selectedItem={symptomCode3}
                        />                            
                        </Col>                        
                        <Col sm={6}></Col>                                  
                        </Row>
                    }
                    {(showSymptomCode4 && (!symptomCodeStrongRelation || (symptomCode3 && symptomCode3.Code ? symptomCode3.Code.length : 0) > 0)) &&
                        <Row>
                        <Col sm={6}>
                        <AsolviAdvancedDropdownList
                            labelText={""}
                            shownText={"CodeAndDescription"}  
                            columns={symptomCodeColumns}                              
                            data={props.symptomCode4DropDownItems}
                            onSelect={(e: any) => { setSymptomCode4(e.target.value); }}
                            selectedItem={symptomCode4}
                        />                            
                        </Col>                        
                        <Col sm={6}></Col>                                  
                        </Row>
                    }                                                        
                </>             
            }

            <Row>
                <Col sm={6}>
                    <AsolviInput labelText={t("TaskSymptomGroup.titleLabel")} 
                                onChange={(e:any) => { setTaskTitle(e.target.value); }}  
                                inputValue={taskTitle}                                
                    />           
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <AsolviInput labelText={t("TaskSymptomGroup.referenceInfoLabel")} 
                                onChange={(e:any) => { setReferenceInfo(e.target.value); }}  
                                inputValue={referenceInfo} 
                    />        
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <AsolviTextArea rows={3} 
                                    inputText={symptomText} 
                                    onChange={(e:any) => { setSymptomText(e.value); }}  
                                    labelText={t("TaskSymptomGroup.symptomTextLabel")} 
                    />        
                </Col>                
            </Row>
        </Container>             
    );
}

const mapStateToProps = (store: Store.IAppState) => {
    return {
        currentAuth0Token: store.generalState.General.currentAuth0Token,
        currentUserEmail: store.generalState.General.currentUserEmail
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {        
        getTaskSymptomCodes2: (token: string, email:string, appUrl: string, selectedSymptomCode1: string, taskType: number) => dispatch(TaskActions.GetTaskSymptomCodes2(token, email, appUrl, selectedSymptomCode1, taskType)),      
        getTaskSymptomCodes3: (token: string, email:string, appUrl: string, selectedSymptomCode1: string, selectedSymptomCode2: string, taskType: number) => dispatch(TaskActions.GetTaskSymptomCodes3(token, email, appUrl, selectedSymptomCode1, selectedSymptomCode2, taskType)),      
        getTaskSymptomCodes4: (token: string, email:string, appUrl: string, selectedSymptomCode1: string, selectedSymptomCode2: string, selectedSymptomCode3: string, taskType: number) => dispatch(TaskActions.GetTaskSymptomCodes4(token, email, appUrl, selectedSymptomCode1, selectedSymptomCode2, selectedSymptomCode3, taskType)),      
    }
  }

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ReactI18Next.withTranslation()(TaskSymptomGroup));