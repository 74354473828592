import * as Redux from 'redux';
import * as ReduxThunk from 'redux-thunk';
import * as MeterReadingReducer from '../reducers/MeterReadingReducer';
import * as Axios from 'axios';
import * as ApiEndPoints from '../sources/apiEndPoints';
import { IMachineInfo } from "../components/seedComponents/Pages/MeterReadingPageSeedComponent"

export enum MeterReadingActionTypes {
    GETSETTINGS = "ReadingGetSettings",
    GETMACHINESANDMETERS = "ReadingGetMachinesAndMeters",
    SUBMITMETERSREADING = "ReadingSubmitMeterReadings",
    SETRESPONSEERROR = "ReadingSetResponseError"
}

interface IMeterReadingGetSettings {
    type: MeterReadingActionTypes.GETSETTINGS;
    MeterReading: MeterReadingReducer.IMeterReading;
}

interface IMeterReadingMachinesAndMetersInfo{
    type: MeterReadingActionTypes.GETMACHINESANDMETERS;
    MeterReading: MeterReadingReducer.IMeterReading;
}

interface ISubmitMetersReadings{
    type: MeterReadingActionTypes.SUBMITMETERSREADING | MeterReadingActionTypes.SETRESPONSEERROR;
    MeterReading: MeterReadingReducer.IMeterReading;
}

export type MeterReadingActions = IMeterReadingGetSettings | IMeterReadingMachinesAndMetersInfo | ISubmitMetersReadings;

export const GetSettings: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, MeterReadingReducer.IMeterReadingState, null, IMeterReadingGetSettings>
    > = (token: string, appUrl: string, email: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try{
                const response = await Axios.default.get(appUrl + ApiEndPoints.MeterReadingAPIs.GetSettings(email), { headers: { token }});
                dispatch({
                    MeterReading: {settings: JSON.stringify(response.data)},
                    type: MeterReadingActionTypes.GETSETTINGS
                });
            }
            catch(err)
            {
                console.log(err);
                dispatch({
                    MeterReading: {machinesAndMeters: null},
                    type: MeterReadingActionTypes.GETSETTINGS
                })
            }
        }
    }

export const GetMachinesAndMeters: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, MeterReadingReducer.IMeterReadingState, null, IMeterReadingMachinesAndMetersInfo>
    > = (token: string, appUrl: string, customerGuid: string, email: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try{
                const response = await Axios.default.get(appUrl + ApiEndPoints.MeterReadingAPIs.GetMachinesAndMeters(customerGuid, email), { headers: { token }});
                dispatch({
                    MeterReading: {machinesAndMeters: JSON.stringify(response.data)},
                    type: MeterReadingActionTypes.GETMACHINESANDMETERS
                });
            }
            catch(err)
            {
                console.log(err);
                dispatch({
                    MeterReading: {machinesAndMeters: null},
                    type: MeterReadingActionTypes.GETMACHINESANDMETERS
                })
            }
        }
    }

export const SubmitMetersReading: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, MeterReadingReducer.IMeterReadingState, null, ISubmitMetersReadings>
    > = (token: string, appUrl: string, language: string, customerGuid: string, email: string, body: IMachineInfo) => {
        return async (dispatch: Redux.Dispatch) => {
            try{
                const response = await Axios.default.post(appUrl + ApiEndPoints.MeterReadingAPIs.SubmitMetersReading(language, customerGuid, email),
                    body,
                    { headers: { token } }
                )
                dispatch({
                    MeterReading: {machinesAndMeters: JSON.stringify(response.data)},
                    type: MeterReadingActionTypes.SUBMITMETERSREADING 
                }) 
                dispatch({
                    MeterReading: {error: ""},
                    type: MeterReadingActionTypes.SETRESPONSEERROR 
                })                    
            }
            catch (err){
                console.log(err);
                if(err.response !== undefined && err.response.status !== undefined && err.response.status === 400){                              
                    dispatch({
                        MeterReading: {error: err.response.data.Status.Reason},
                        type: MeterReadingActionTypes.SETRESPONSEERROR
                    })
                }else if(err.response !== undefined && err.response.status !== undefined && err.response.status === 500){                                
                    dispatch({
                        MeterReading: {error: err.response.data.ExceptionMessage},
                        type: MeterReadingActionTypes.SETRESPONSEERROR
                    })
                }else{
                    dispatch({
                        MeterReading: {error: JSON.stringify(err)},
                        type: MeterReadingActionTypes.SETRESPONSEERROR
                    })                   
                }
            }
        }
    }