import * as React from "react";
import "./AsolviDropDownList.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DropDownList, ListItemProps, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import "@progress/kendo-theme-material/dist/all.scss";

export interface Props {
  dropDownTitle: string,
  asolviDropDownItems: AsolviDropDownItem[]
}

export interface AsolviDropDownItem {
  itemHref?: string,
  itemText: string,
  itemAction: () => void,
  itemImage?: string,
  itemId: string
}

class AsolviDropDownList extends React.Component<Props, object>
{
  // render the Children items for the Dropdown list to override 
  itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const index = itemProps.index;
    let itemChildren = null;
    itemChildren = <div>{this.props.asolviDropDownItems[index].itemImage &&
      <img src={this.props.asolviDropDownItems[index].itemImage} alt={this.props.asolviDropDownItems[index].itemText} />}<span> {li.props.children} </span></div>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  //handle change function for kendo DropDownList
  handleChange = (event: DropDownListChangeEvent) => {
    if (typeof event.target.value.itemAction === 'function') {
      event.target.value.itemAction(event.target.value.itemId);
    }
  }

  render() {
    const {  dropDownTitle, asolviDropDownItems } = this.props;

    return (
      <Row>      
        <Col sm={12}>
          <DropDownList
            className="asolviDropDownList"
            label={dropDownTitle}
            data={asolviDropDownItems}
            textField="itemText"
            itemRender={this.itemRender}
            onChange={this.handleChange}
            dataItemKey="itemId"
          />
        </Col>
      </Row>
    );
  }
}

export default AsolviDropDownList;