import React, { FC } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { IArticleInfo } from "../../seedComponents/Groups/Order/OrderDetailsGroupSeedComponent";
import OrderDetailsGrid from '../../Groups/Order/OrderDetailsGrid/OrderDetailsGrid';
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import { IOrderLine, IOrderVisibilitySettings } from '../../../slices/OrderSlice';
import OrderSumGroup from "./OrderSumGroup";


export interface Props {
  currentOrderLines: IOrderLine[];
  quickOrderLines: IArticleInfo[];
  isLoadingArticles: boolean;
  isLoadingDiscounts: boolean;
  visibilitySettings: IOrderVisibilitySettings;
  isLoadingMeterReadings: boolean;
  onAddOrderLines: () => void;
  orderGridItemChange: (item: IArticleInfo, field?: any, newValue?: any) => void;
  onConfirm: () => void;
}


const OrderDetailsGroup: FC<Props> = ({visibilitySettings, currentOrderLines, quickOrderLines, isLoadingArticles, isLoadingDiscounts, isLoadingMeterReadings, onAddOrderLines, orderGridItemChange, onConfirm}) => {
  const { t } = useTranslation();
  const [selectedArticle] = React.useState<IArticleInfo | null>(null);

  const handleUpdateOrderAction = (e:any) => {

  };
  
  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <OrderDetailsGrid
            showSalesPrice={visibilitySettings.ShowAddOrderLine?.ShowSalesPrice}
            orderLines={quickOrderLines}
            loadingArticles={isLoadingArticles}
            orderGridItemChange={orderGridItemChange}
            onRowClick={(e:any) => {
              handleUpdateOrderAction(e);
            }}
          />
        </Col>
      </Row>
      <Container fluid>
        <OrderSumGroup
          lines={currentOrderLines}
          visibilitySettings={visibilitySettings}
          style={{ marginBottom: '14px' }}
        />
      </Container>
      <div style={{display: "flex", justifyContent: "flex-end"}}>
        <div style={{width: "300px", marginRight: "25px"}}>
          <AsolviButton
            className="k-primary"
            buttonType="submit"
            disabled={isLoadingDiscounts}
            buttonAction={onAddOrderLines} buttonDisabled={((selectedArticle?.ArticleObjNo === ""))}
          >
            { isLoadingDiscounts ? <Spinner animation="border" size="sm" /> : null }
            { t("Order.AddOrderLines") }
          </AsolviButton>
        </div>
        <div style={{width: "300px"}}>
          <AsolviButton
            className="k-primary"
            buttonType="submit"
            disabled={isLoadingMeterReadings}
            buttonAction={onConfirm}
            buttonDisabled={currentOrderLines.every(x => x.Qty <= 0)}
          >
            { isLoadingMeterReadings ? <Spinner animation="border" size="sm" /> : null }
            { t("Order.Confirm") }
          </AsolviButton>
        </div>
      </div>
    </Container>
  );
}

export default OrderDetailsGroup;
