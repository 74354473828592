import React, { FC, useEffect, useRef, useState } from "react";
import AsolviAdvancedDropdownList, {
  IAsolviAdvancedDropdownListChangeEvent,
  IColumn,
} from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import { useTranslation } from "react-i18next";
import { Col, Overlay, Row, Tooltip } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { IOrderLine } from "../../../../slices/OrderSlice";

interface Props {
  customers: any[],
  selectedCustomerGuid: string | undefined,
  onSelected: (e: IAsolviAdvancedDropdownListChangeEvent<any>) => void,
  orderLines: IOrderLine[],
}

const OrderCustomerGroup: FC<Props> = ({ customers, selectedCustomerGuid, onSelected, orderLines}) => {

  const { t } = useTranslation();
  const [selectedCustomer, setSelectedCustomer] = useState();

  useEffect(() =>{
    setSelectedCustomer(
      customers.find( (customer) => customer.CustomerGuid === selectedCustomerGuid)
    );
  }, [customers, selectedCustomerGuid])

  const columns: IColumn[] = [
    {
      field: "CustomerNo",
      header: t("TaskCustomerGroup.customerNumberInputLabel"),
      width: 150,
      uniqueKey: "CustomerNo"
    },
    {
      field: "CustomerName",
      header: t("TaskCustomerGroup.customerNameInputLabel"),
      width: 250,
      uniqueKey: "CustomerName"
    },
    {
      field: "Address1",
      header: t("TaskCustomerGroup.customerAddress1Label"),
      width: 350,
      uniqueKey: "Address1"
    },
    {
      field: "PostDescription",
      header: t("TaskCustomerGroup.customerCityLabel"),
      width: 200,
      uniqueKey: "PostDescription"
    },
    {
      field: "AddressPhone",
      header: t("TaskCustomerGroup.customerPhoneNumberLabel"),
      width: 150,
      uniqueKey: "AddressPhone"
    },
  ];


  const orderHasLines = !!orderLines.length;
  const tooltipTarget = useRef(null);

  const [showOrderHasLinesTooltip, setShowOrderHasLinesTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (showOrderHasLinesTooltip) {
      const timeout = setTimeout(() => {

        setShowOrderHasLinesTooltip(false);

      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [showOrderHasLinesTooltip])


  return (
    <Container fluid>
      <Row>
        <Col sm={6}>
          <Overlay target={tooltipTarget.current} show={showOrderHasLinesTooltip} placement="bottom">
            <Tooltip id="overlay-example">
              {t("Order.CannotChangeCustomerWhenOrderHasLines")}
            </Tooltip>
          </Overlay>
          <div
            ref={tooltipTarget}
            onClick={() => {
              if (orderHasLines)
                setShowOrderHasLinesTooltip(true);
            }}
          >
            <AsolviAdvancedDropdownList
              labelText={t("TaskCustomerGroup.selectCustomerDropdownLabel")}
              columns={columns}
              data={customers}
              shownText={'CustomerName'}
              onSelect={onSelected}
              selectedItem={selectedCustomer}
              clearButton={false}
              disabled={orderHasLines}
              required={true}
            />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default OrderCustomerGroup;
