import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Store from '../../../../store/Store';
import { setSearchFilter } from '../../../../slices/OrderSlice';
import axios from 'axios';
import * as apiEndPoints from '../../../../sources/apiEndPoints';
import { ICodeDescription } from '../../Pages/MachinePageSeedComponent';
import OrderLogFilterGroup from '../../../Groups/Order/OrderLogFilterGroup/OrderLogFilterGroup';
import { IOrderSearchDataContract } from '../../Pages/OrderLogPageSeedComponent';

interface IProps {  
}

const OrderLogFilterSeedComponent: React.FunctionComponent<IProps> = (Props) => {
  const { appUrl } = (window as any)["appConfig"];
  const General = useSelector((state: Store.IAppState) => state.generalState.General);
  const searchData = useSelector((state: Store.IAppState) => state.orderState.OrderLog.searchData);
  const showMachineId = useSelector((state: Store.IAppState) => state.orderState.Order.visibilitySettings.ShowSelectMachine.ShowMachineId);
  const showProperties = useSelector((state: Store.IAppState) => state.orderState.Order.visibilitySettings.ShowProperties.ShowMachineProperties);
  const dispatch = useDispatch();
  
  const { currentAuth0Token, currentUserEmail, activeCustomer } = General;

  let [ machineProperties, setMachineProperties ] = React.useState<ICodeDescription[]>([]);
  
  React.useEffect(() => {
    if(!currentAuth0Token) return;
    if(!appUrl) return;
    if(!activeCustomer) return;
    if(!currentUserEmail) return;

    if (showProperties) {
      getMachineFilterProperties(currentAuth0Token, appUrl, activeCustomer, currentUserEmail)
        .then(x => checkSearchDataMachineFilterProperties(x, searchData))
        .then(x => setMachineProperties(x));    
    }
  }, [currentAuth0Token, appUrl, activeCustomer, currentUserEmail, showProperties, getMachineFilterProperties, checkSearchDataMachineFilterProperties, setMachineProperties]);

  return (
    <>    
    <OrderLogFilterGroup
      showMachineId={showMachineId}
      searchData={searchData}
      machineProperties={machineProperties}
      setSearchFilter={(searchData) => dispatch(setSearchFilter(searchData))}
    />
    </>
  );
  
}

function checkSearchDataMachineFilterProperties(properties: ICodeDescription[], searchData: IOrderSearchDataContract): ICodeDescription[] {
  let newSearchData = { ...searchData };
  if(searchData.PropertyFilterCode1 && !properties.find(x => x.Code === searchData.PropertyFilterCode1)) {
    newSearchData.PropertyFilterCode1 = undefined;
    newSearchData.PropertyFilterValue1 = undefined;
  }
  if(searchData.PropertyFilterCode2 && !properties.find(x => x.Code === searchData.PropertyFilterCode2)) {
    newSearchData.PropertyFilterCode2 = undefined;
    newSearchData.PropertyFilterValue2 = undefined;
  }
  setSearchFilter(newSearchData);
  return properties;
}

async function getMachineFilterProperties(token: string, appUrl: string, customerGuid: string, email: string): Promise<ICodeDescription[]> {
  const url = appUrl + apiEndPoints.MachineAPIs.GetFilterPropertyCodes(customerGuid, email);
  const response = await axios.get(url, { headers: { token }});
  return (response.data as ICodeDescription[]).filter(x => x.Code);
}

export default OrderLogFilterSeedComponent;

