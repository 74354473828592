import React from "react";
import * as ReactI18Next from "react-i18next";
import * as I18Next from "i18next";
import AsolviAdvancedDropdownList from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import { ICodeDescription, ICustomerSortGroupItem } from "../../../seedComponents/Pages/MachinePageSeedComponent";

export interface Props extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  sortGroup: ICustomerSortGroupItem;
  selectedCode?: string;
  onSelected: (e: ICodeDescription) => void
}

const CustomerSortGroupFilter: React.FunctionComponent<Props> = (Props) => {
  const { t, sortGroup, selectedCode } = Props;
  let selectedCustomerSortGroup = sortGroup.SortGroupValues.find(x => x.Code === selectedCode);

  const onCustomerSortGroupSelected = (model: ICodeDescription) => {
    Props.onSelected(model);
  };

  const sortGroupColumns = [
    {
      field: "Code",
      header: t("Machine.CustomerSortGroupCode"),
      width: 150,
      uniqueKey: "Code"
    },
    {
      field: "Description",
      header: t("Machine.CustomerSortGroupDescription"),
      width: 400,
      uniqueKey: "Description"
    }
  ];

  return (
    <AsolviAdvancedDropdownList
    labelText={sortGroup.LabelText}
    shownText="CodeAndDescription"
    columns={sortGroupColumns}
    data={sortGroup.SortGroupValues}
    onSelect={(e:any) => { onCustomerSortGroupSelected(e.value); }}
    selectedItem={selectedCustomerSortGroup}
    />
  );
};

export default ReactI18Next.withTranslation()(CustomerSortGroupFilter);