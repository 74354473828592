import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import * as Store from "../../../store/Store";
import { IAppState } from "../../../store/Store";
import OrderCreatePage from "../../Pages/OrderCreatePage/OrderCreatePage";
import {
  getVisibilitySettings,
  hideCurrentOrder,
  IOrderSelectedCustomer,
  selectCustomer,
  showCurrentOrder as showCurrentOrderAction,
} from '../../../slices/OrderSlice';
import { useDispatch, useSelector } from 'react-redux';
import Axios from "axios";
import { IMeterReportingSettings } from "../../../sources/MachineMeterReportingSettings";
import * as ApiEndPoints from "../../../sources/apiEndPoints";
import { IMachineInfo } from "./MeterReadingPageSeedComponent";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import { parseDate } from "@telerik/kendo-intl";
import i18n from "../../../i18n";

export interface IProps {
  loggedInName: string;
}

const OrderCreatePageSeedComponent: FC<IProps> = ({loggedInName}) => {
  const { appUrl } = (window as any)["appConfig"];

  const currentOrder = useSelector((state: IAppState) => state.orderState.Order.currentOrder);

  const { activeCustomer, activeCustomerInfo, currentAuth0Token: token, currentUserEmail: email }
    = useSelector((state: Store.IAppState) => state.generalState.General);

  const { shouldShowCurrentOrder, visibilitySettings, selectedCustomer }
    = useSelector((state: Store.IAppState) => state.orderState.Order);

  const dispatch = useDispatch();

  const [clearData, setClearData]= React.useState<boolean>(false);

  const handleSelectedCustomer = useCallback(
    (customer: IOrderSelectedCustomer) => dispatch(selectCustomer(customer)), [dispatch]
  );

  const getOrderVisibilitySettings = useCallback(
    (token: string, appUrl: string, email: string) => dispatch(getVisibilitySettings({ token, appUrl, email })), [dispatch]
  );
  const hideCurrentOrderCallback = useCallback(
    () => dispatch(hideCurrentOrder()), [dispatch]
  );

  const orderLines = currentOrder?.Lines?.length;

  useEffect(() => {
    if(orderLines === 0){
      setClearData(true);
    }else{
      setClearData(false);
    }
  }, [orderLines, setClearData]);


  useEffect(() => {
    getOrderVisibilitySettings(token, appUrl, email);
  }, [token, email, appUrl, getOrderVisibilitySettings]);

  useEffect(() => {
    if (selectedCustomer === undefined) {
      let activeCustomerInfoObj = JSON.parse(activeCustomerInfo);
      const customer: IOrderSelectedCustomer = {
        customerGuid: activeCustomer,
        customerName: activeCustomerInfoObj.CustomerNo,
        customerNo: activeCustomerInfoObj.Name
      };
      handleSelectedCustomer(customer);
    }
  }, [handleSelectedCustomer, selectedCustomer, activeCustomerInfo, activeCustomer]);

  const showCurrentOrder = useCallback(() => dispatch(showCurrentOrderAction()), [dispatch]);

  const headingCustomerGuid = currentOrder.Heading.CustomerGuid;

  const [meterReadingSettings, setMeterReadingSettings] = useState<IMeterReportingSettings>();
  const [isLoadingMeterReadings, setIsLoadingMeterReadings] = useState<boolean>(false);
  const intl = useInternationalization();

  useEffect(()=>{

    getMeterReadingSettings(token, appUrl, email).then( (response) => {

      setMeterReadingSettings(response);
    })
  }, [appUrl, email, token])


  const [machinesMetersNeeded, setMachinesMetersNeeded] = useState<IMachineInfo[] | undefined>(undefined);
  const onConfirm = () => {

    setIsLoadingMeterReadings(true);
    let uniqueMachineGuids = Array.from(new Set(currentOrder.Lines.map(x => x.MachineGuid)));

    let meterPromise = getMachinesMetersNeeded(
      token,
      appUrl,
      headingCustomerGuid ?? activeCustomer,
      email,
      uniqueMachineGuids,
    ).then(x => formatDates(x, intl));

    meterPromise
      .then((meters) => {
        setMachinesMetersNeeded(meters);

        setIsLoadingMeterReadings(false);

        showCurrentOrder();
      });
  }

  function acceptMeterReadings(readings: IMachineInfo[]) {
    submitMeterReadings(token, appUrl, headingCustomerGuid ?? activeCustomer, email, readings);
    setMachinesMetersNeeded([]);
  }

  return (
    <OrderCreatePage
      clearData={clearData}
      shouldShowCurrentOrder={shouldShowCurrentOrder}
      hideCurrentOrder={hideCurrentOrderCallback}
      visibilitySettings={visibilitySettings}
      isLoadingMeterReadings={isLoadingMeterReadings}
      meterReadingSettings={meterReadingSettings}
      machinesMetersNeeded={machinesMetersNeeded}
      onConfirm={onConfirm}
      onAcceptMeterReadings={acceptMeterReadings}
      loggedInName={loggedInName}
    />
  );
}

export default OrderCreatePageSeedComponent;



const submitMeterReadings = async (token: string, appUrl: string, selectedCustomer: string, email: string, machines: IMachineInfo[]) => {
  let language = i18n.language;
  let responsePromises = machines.map(async (machine) => {
    await Axios.post<IMachineInfo>(
      appUrl + ApiEndPoints.MeterReadingAPIs.SubmitMetersReadingOnOrder(language, selectedCustomer, email),
      machine,
      { headers: { token } }
    );
  });

  for(let promise of responsePromises) {
    try {
      await promise
    } catch(e) {
      console.error(e);
    }
  }
}

const getMeterReadingSettings = async (token: string, appUrl: string, email: string) => {
  let response = await Axios.get<IMeterReportingSettings>(appUrl + ApiEndPoints.MeterReadingAPIs.GetSettings(email), { headers: { token } });
  return response.data;
};

const getMachinesMetersNeeded = async (token: string, appUrl: string, loggedInCustomerGuid: string, email: string, machineGuids: string[]) => {
  let responsePromises = machineGuids.map(async (machineGuid) => {
    try {
      const response = await Axios.get<IMachineInfo[]>(appUrl + ApiEndPoints.MeterReadingAPIs.GetAvailableMetersOnOrder(loggedInCustomerGuid, machineGuid, email), { headers: { token }});
      return response.data;
    } catch(e) {
      return [];
    }
  });
  let result: IMachineInfo[] = [];

  for(let promise of responsePromises) {
    let response = await promise;
    result = result.concat(response);
  }
  return result;
}

const formatDates = (machines: IMachineInfo[], intl: IntlService)  => {
  machines.forEach(x => {
    x.meters.forEach(y => {
      y.oldReadingDateFormatted = y.oldReadingDate ? intl.formatDate(parseDate(y.oldReadingDate), "d") : undefined;
      y.newReadingDateFormatted = new Date();
    });
  });
  return machines;
}
