import React from "react";
import * as ReactI18Next from "react-i18next";
import * as I18Next from "i18next";
import AsolviAdvancedDropdownList from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import { IMachineModelItem } from "../../../seedComponents/Pages/MachinePageSeedComponent";

export interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  models: IMachineModelItem[];
  selectedModelGuid?: string;
  onModelSelected: (e: any) => void;
}

const ModelFilter: React.FunctionComponent<IProps> = (Props) => {
  const { t, models, selectedModelGuid } = Props;
  let selectedModel = models.find(x => x.ModelGuid === selectedModelGuid);

  const onModelSelected = (model: IMachineModelItem) => {
    Props.onModelSelected(model);
  };

  const modelColumns = [
    {
      field: "ModelType",
      header: t("Machine.ModelType"),
      width: 150,
      uniqueKey: "ModelType"
    },
    {
      field: "Description",
      header: t("Machine.ModelDescription"),
      width: 400,
      uniqueKey: "Description"
    }
  ];

  return (
    <>
      <AsolviAdvancedDropdownList
        labelText={t("Machine.Model")}
        shownText="ModelType"
        columns={modelColumns}
        data={models}
        onSelect={(e:any) => { onModelSelected(e.value); }}
        selectedItem={selectedModel}
        />
    </>
  );
};

export default ReactI18Next.withTranslation()(ModelFilter);