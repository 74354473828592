import i18n  from "i18next";
import langDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

/** 
 * This File is used to set and configure i18next instance which is used to handle translation 
 */
i18n.use(langDetector)
.use(initReactI18next)
.use(HttpBackend)
.init({  
  debug: false,
  interpolation: { escapeValue: false },
  fallbackLng:"en",  
  },
);

export default i18n;