import * as Redux from 'redux';
import * as MeterLoginActions from "../actions/MeterLoginActions";

export interface IMeterLogin{
    barFooterInfo: string;
    machinesAndMeters: string;
    error?: string;
    token: string;
}

export interface IMeterLoginState{
    readonly MeterLogin: IMeterLogin;
}

const initialMeterLoginstate: IMeterLoginState = {
    MeterLogin:{
        barFooterInfo: "",
        machinesAndMeters: "",
        error: undefined,
        token: "",
    }
}

export const MeterLoginReducer: Redux.Reducer<IMeterLoginState, MeterLoginActions.MeterLoginActions> = (
    state = initialMeterLoginstate,
    action
) => {
    switch (action.type){
        case MeterLoginActions.MeterLoginActionTypes.GETFOOTERANDBARINFO :{
            return{
                ...state,
                MeterLogin : { ...state.MeterLogin, barFooterInfo: action.MeterLogin.barFooterInfo}
            };
        }
        case MeterLoginActions.MeterLoginActionTypes.GETMACHINESANDMETERS : {
            return {
                ...state,
                MeterLogin: { ...state.MeterLogin, machinesAndMeters: action.MeterLogin.machinesAndMeters}
            };
        }
        case MeterLoginActions.MeterLoginActionTypes.SUBMITMETERSREADING : {
            return{
                ...state,
                MeterLogin: {...state.MeterLogin, machinesAndMeters: action.MeterLogin.machinesAndMeters}
            };
        }
        case MeterLoginActions.MeterLoginActionTypes.SETMETERLOGINTOKEN: {
            return{
                ...state,
                MeterLogin: { ...state.MeterLogin, token: action.MeterLogin.token}
            };
        }
        case MeterLoginActions.MeterLoginActionTypes.SETRESPONSEERROR: {
            return{
                ...state,
                MeterLogin: { ...state.MeterLogin, error: action.MeterLogin.error}
            };
        }
        default: {
            return state;
        }
    }
}