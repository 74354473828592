import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { ICreateMachine, IMachineInfo, IMachineSearchDataContract } from "../components/seedComponents/Pages/MachinePageSeedComponent";
import * as ApiEndPoints from '../sources/apiEndPoints';

const prefix = 'machine';

export interface IMachine{
    machines: IMachineInfo[];
    loadingMachines: boolean;
    searchData: IMachineSearchDataContract;
    error?: string;
    token: string;
}

export interface IMachineState{
    readonly Machine: IMachine;
    readonly CreateMachine: ICreateMachine;
}

const initialState: IMachineState = {
    Machine:{
        machines: [],
        loadingMachines: false,
        searchData: {},
        error: undefined,
        token: "",
    },
    CreateMachine: {
        Result: undefined,
        IsMachineExist: false,
        MachineGuid: undefined
    }
};

export const setSearchFilter = createAction<IMachineSearchDataContract>(`${prefix}/setSearchFilter`);
export const clearSearchFilter = createAction(`${prefix}/clearSearchFilter`);
export const clearMachines = createAction(`${prefix}/clearMachines`);
export const setResponseError = createAction<string>(`${prefix}/setResponseError`);

interface IMachineGetMachinesArguments {
    token: string;
    appUrl: string;
    loggedInCustomerGuid: string;
    email: string;
    searchData: IMachineSearchDataContract;
}

export const getMachines = createAsyncThunk(`${prefix}/getMachines`, async ({ token, appUrl, loggedInCustomerGuid, email, searchData}: IMachineGetMachinesArguments) => {
    const response = await Axios.post<IMachineInfo[]>(appUrl + ApiEndPoints.MachineAPIs.GetMachinesSearch(loggedInCustomerGuid, email), searchData, { headers: { token }});
    return response.data;
});

interface ICreateMachineArgs {
    token: string;
    appUrl: string;
    email: string;
    body: IMachineInfo;
}

export const insertMachine = createAsyncThunk(`${prefix}/insertMachine`, async ({token, appUrl, email, body}:ICreateMachineArgs, ThunkApi) => {
    try{
        const response = await Axios.post<ICreateMachine>(appUrl + ApiEndPoints.MachineAPIs.InsertMachine(email), body, { headers: { token }});
        return response.data;
    }catch(err){
        if(err.response?.data?.DataContract){
            return ThunkApi.rejectWithValue(err.response?.data?.DataContract?.Message);   
        }else if (err.response?.data.ExceptionMessage){
            return ThunkApi.rejectWithValue(JSON.stringify(err.response?.data.ExceptionMessage));          
        }else if (err.response?.data.Message){
            return ThunkApi.rejectWithValue(JSON.stringify(err.response?.data.Message));
        }else{
            return ThunkApi.rejectWithValue("An error has occured, and it has been logged.");
        }
    }
    
});

export const updateMachine = createAsyncThunk(`${prefix}/updateMachine`, async ({token, appUrl, email, body}: {token: string, appUrl: string, email:string, body: IMachineInfo}, ThunkApi) => {
    try{
        const response = await Axios.post<ICreateMachine>(appUrl + ApiEndPoints.MachineAPIs.UpdateMachine(email), body, { headers: { token }});
        return response.data;
    }catch(err){
        if(err.response?.data?.DataContract){
            return ThunkApi.rejectWithValue(err.response?.data?.DataContract?.Message);   
        }else if (err.response?.data.ExceptionMessage){
            return ThunkApi.rejectWithValue(JSON.stringify(err.response?.data.ExceptionMessage));          
        }else if (err.response?.data.Message){
            return ThunkApi.rejectWithValue(JSON.stringify(err.response?.data.Message));
        }else{
            return ThunkApi.rejectWithValue("An error has occured, and it has been logged.");
        }
    }
    
});

export const clearMachine = createAsyncThunk(`${prefix}/clearMachine`, async (ThunkApi) => { 
    return {};   
});

const machineSlice = createSlice({
    name: prefix,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setSearchFilter, (state, { payload }) => {
            state.Machine.searchData = payload;
        });
        builder.addCase(clearSearchFilter, (state) => {
            state.Machine.searchData = {};
        });
        builder.addCase(clearMachines, (state) => {
            state.Machine.machines = [];
        });
        builder.addCase(setResponseError, (state, { payload }) => {
            state.CreateMachine.error = payload;
        });

        builder.addCase(getMachines.pending, (state) => {
            state.Machine.loadingMachines = true;
        });
        builder.addCase(getMachines.rejected, (state, action) => {
            state.Machine.loadingMachines = false;
            state.Machine.error = action.error.message;
        });
        builder.addCase(getMachines.fulfilled, (state, action) => {
            let machines = action.payload;
            state.Machine.machines = machines;
            state.Machine.loadingMachines = false;
        });

        builder.addCase(insertMachine.fulfilled, (state, action) => {
            state.CreateMachine = action.payload;
        });

        builder.addCase(insertMachine.rejected, (state, action) => {      
            let error = action?.payload as string;
            state.CreateMachine.error = error;
        });
        builder.addCase(updateMachine.fulfilled, (state, action) => {
            state.CreateMachine = action.payload;
        });

        builder.addCase(updateMachine.rejected, (state, action) => {
            let error = action?.payload as string;
            state.CreateMachine.error = error;
        });
        builder.addCase(clearMachine.fulfilled, (state, action) => {
            state.CreateMachine = {};
        });

    }
});

export default machineSlice.reducer;