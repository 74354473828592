import * as React from "react";
import "./AsolviFooter.css";
import Nav from "react-bootstrap/Nav";

export interface Props {
    logoUrl: string,
    appName: string,
    buildNumber: string,
    buildDate: string,
    companyName: string,
    year: string
}

class AsolviFooter extends React.Component<Props, object>
{
    render() {
        const { logoUrl, appName, buildNumber, buildDate, companyName, year } = this.props;
        return (
            <Nav className="asolviFooter justify-content-center" fill navbar>
                <Nav.Item>
                        <img
                            src={logoUrl}
                            className="d-inline-block align-top"
                            alt="Logo"
                        />
                </Nav.Item>
                <Nav.Item>
                        {appName} - Build: {buildNumber} / {buildDate}
                </Nav.Item>
                <Nav.Item>
                        &#169; Copyright {companyName} {year}
                </Nav.Item>
            </Nav>
        );
    }
}

export default AsolviFooter;