import * as React from 'react';
import TaskCustomerGroup, { ITaskCustomerGroupData } from "../../../Groups/Task/TaskCustomerGroup/TaskCustomerGroup";
import * as I18Next from "i18next";
import * as ReactI18Next from 'react-i18next';
import * as Store from "../../../../store/Store";
import * as ReactRedux from "react-redux";
import * as TaskActions from "../../../../actions/TaskActions";
import * as TaskReducer from "../../../../reducers/TaskReducer";

interface IProps extends ReactI18Next.WithTranslation{
  t: I18Next.TFunction;
  loggedInCustomer: string,
  customerList: TaskReducer.ITaskCustomerItem[];
  getCustomerList(token: string, email:string, appUrl: string, mainCustomerGuid: string): (token: string, email:string)  => void;
  handleSelectedTaskCustomer(customerGuid: string): (customerGuid: string)  => void;
  extractData?: (data: ITaskCustomerGroupData) => void;
  currentAuth0Token: string;
  currentUserEmail: string;
}
const TaskCustomerGroupSeedComponent: React.FunctionComponent<IProps> = (Props) => {
  const{ t, loggedInCustomer, getCustomerList } = Props;
  const token = Props.currentAuth0Token;
  const email = Props.currentUserEmail;
  const { appUrl } = (window as any)["appConfig"];

  const columns = [
    {
      field: "CustomerNo",
      header: t("TaskCustomerGroup.customerNumberInputLabel"),
      width: 150,
      uniqueKey: "CustomerNo"
    },
    {
      field: "CustomerName",
      header: t("TaskCustomerGroup.customerNameInputLabel"),
      width: 250,
      uniqueKey: "CustomerName"
    },
    {
      field: "Address1",
      header: t("TaskCustomerGroup.customerAddress1Label"),
      width: 350,
      uniqueKey: "Address1"
    },
    {
      field: "PostDescription",
      header: t("TaskCustomerGroup.customerCityLabel"),
      width: 200,
      uniqueKey: "PostDescription"
    },
    {
      field: "AddressPhone",
      header: t("TaskCustomerGroup.customerPhoneNumberLabel"),
      width: 150,
      uniqueKey: "AddressPhone"
    },
  ];

  React.useEffect(()=>{
    if(token)
      getCustomerList(token, email, appUrl, loggedInCustomer);
  },[token, email, appUrl, loggedInCustomer, getCustomerList]);

  return (
    <TaskCustomerGroup
      columnsData={columns}
      dropdownData={Props.customerList}
      dropdownShownText={"CustomerName"}
      extractData={Props.extractData}
      handleSelectedCustomer={Props.handleSelectedTaskCustomer}
      loggedInCustomer={loggedInCustomer}
    />
  );
}

const mapStateToProps = (store: Store.IAppState) => {
  return {
    loggedInCustomer: store.generalState.General.activeCustomer,
    customerList: store.taskState.TaskCustomers,
    currentAuth0Token: store.generalState.General.currentAuth0Token,
    currentUserEmail: store.generalState.General.currentUserEmail
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCustomerList:
      (token: string, email:string, appUrl: string, mainCustomerGuid: string) => dispatch(TaskActions.GetTaskCustomerList(token, email, appUrl, mainCustomerGuid)),
    handleSelectedTaskCustomer:
      (customerGuid: string) => dispatch(TaskActions.TaskHandleSelectedCustomer(customerGuid)),
  }
}

export default  ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ReactI18Next.withTranslation()(TaskCustomerGroupSeedComponent));
