import React from "react";
import TaskLogFilterGroup from "../../../Groups/Task/TaskLogFilterGroup/TaskLogFilterGroup";
import * as TaskReducer from "../../../../reducers/TaskReducer";
import * as ReactRedux from "react-redux";
import * as Store from "../../../../store/Store";
import * as TaskActions from "../../../../actions/TaskActions";

interface IProps {
  showServiceLog: boolean;
  showHelpdeskLog: boolean;
  TaskVisibilitySettings: TaskReducer.ITaskVisibilitySettings;  
  loggedInCustomer: string;
  loadingData: boolean;
  GetTaskLog(token: string, email: string, appUrl: string,  customerGuid: string, filter: TaskReducer.ITaskLogFilter): 
                    (token: string, email: string, appUrl: string,  customerGuid: string, filter: TaskReducer.ITaskLogFilter) => void;
  clearData?: boolean;
  currentAuth0Token: string;
  currentUserEmail: string;
}

const TaskLogFilterGroupSeedComponent: React.FunctionComponent<IProps> = (props) => {  
  const showMachine = props.TaskVisibilitySettings.ShowSelectMachine.Group;
  const showMachineId = props.TaskVisibilitySettings.ShowSelectMachine.ShowMachineId;
  
  const token = props.currentAuth0Token;
  const email = props.currentUserEmail;
  const { appUrl } = (window as any)["appConfig"];
  const [filter, setFilter] = React.useState<TaskReducer.ITaskLogFilter>();

  
  const handleSearch = () => {
    if (filter) {
      props.GetTaskLog(token, email, appUrl, props.loggedInCustomer, filter);
    }
  }

  return (
    <TaskLogFilterGroup
      showServiceLog={props.showServiceLog}
      showHelpdeskLog={props.showHelpdeskLog}
      showMachine={showMachine}
      showMachineId={showMachineId}
      searchFunction={handleSearch}
      extractData={setFilter}
      clearData={props.clearData}
      loadingData={props.loadingData}
    />
  )
}

const mapStateToProps = (store: Store.IAppState) => {
  return {
    TaskVisibilitySettings: store.taskState.TaskVisibilitySettings,
    loggedInCustomer: store.generalState.General.activeCustomer,
    loadingData: store.taskState.TaskLog.Loading,
    currentAuth0Token: store.generalState.General.currentAuth0Token,
    currentUserEmail: store.generalState.General.currentUserEmail
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    GetTaskLog: (token: string, email: string, appUrl: string,  customerGuid: string, filter: TaskReducer.ITaskLogFilter) => dispatch(TaskActions.GetTaskLog(token, email, appUrl, customerGuid, filter))    
  }
}



export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(TaskLogFilterGroupSeedComponent);
