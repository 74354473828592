export interface IMeterReportingSettings{
    CompanyName: string;
    CustomerNo: string;
    CustomerName: string;
    OrgNo: string;
    Version: string;
    VersionDate: string;
    MeterReportingUseLetterDate: boolean;
    MeterReadingDateReadOnly: boolean;
    ShowMachineId: boolean;
}

export default function (settings: any){
    let items = {} as IMeterReportingSettings;
    if(settings)
    {
        items = JSON.parse(settings);
    }
    return items;
}