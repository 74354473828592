import * as Redux from 'redux';
import * as SettingsActions from "../actions/SettingsActions";

export interface ISettings {
    dbInfo: string;
    defaultSettings: string;
    contactList: string;
    contactDropdownList: string;
    buildInfo: string;
    menuItems: string;
    departmentCodes: ISettingsDepartmentCode[];
}

export interface ISettingsState {
    readonly Settings: ISettings;
}

const initialSettingsState: ISettingsState = {
    Settings: {
        dbInfo: "",
        defaultSettings: "",
        contactList: "",
        contactDropdownList: "",
        buildInfo: "",
        menuItems: "",
        departmentCodes: []                  
    }
}

export interface ISettingsDepartmentCode {
    Code: string,
    Description: string,
    CodeAndDescription?: string
}

export const settingsReducer: Redux.Reducer<ISettingsState, SettingsActions.SettingsActions> = (
    state = initialSettingsState, 
    action
) => {
    switch(action.type){
        case SettingsActions.SettingsActionTypes.GETAVAIALBLEDBS: {
            return {
                ...state, 
                Settings: {
                    ...state.Settings,
                    dbInfo: action.Settings.dbInfo,
                }
            }
        }
        case SettingsActions.SettingsActionTypes.GETDEFAULTSETTINGS: {
            return {
                ...state, 
                Settings: {
                    ...state.Settings,
                    defaultSettings: action.Settings.defaultSettings
                }
            }
        }
        case SettingsActions.SettingsActionTypes.GETCONTACTLIST: {
            return {
                ...state, 
                Settings: {
                    ...state.Settings,
                    contactList: action.Settings.contactList
                }
            }
        }
        case SettingsActions.SettingsActionTypes.GETCONTACTDROPDOWNLIST: {
            return {
                ...state, 
                Settings: {
                    ...state.Settings,
                    contactDropdownList: action.Settings.contactDropdownList
                }
            }
        }
        case SettingsActions.SettingsActionTypes.GETBUILDINFORMATION: {
            return {
                ...state, 
                Settings: {
                    ...state.Settings,
                    buildInfo: action.Settings.buildInfo
                }
            }
        }
        case SettingsActions.SettingsActionTypes.GETMENUITEMS: {
            return {
                ...state, 
                Settings: {
                    ...state.Settings,
                    menuItems: action.Settings.menuItems
                }
            }
        }
        case SettingsActions.SettingsActionTypes.GETDEPARTMENTS: {
            return {
                ...state, 
                Settings: {
                    ...state.Settings,
                    departmentCodes: action.Settings.departmentCodes
                }
            }
        }
        default: { 
            return state;
        }
    }
}