import { Input } from "@progress/kendo-react-inputs";
import { useInternationalization } from "@progress/kendo-react-intl";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IOrderHeading, IOrderLine, IOrderVisibilitySettings } from "../../../../slices/OrderSlice";
import AsolviAdvancedDropdownList, {
  ITypedColumn,
} from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import AsolviCheckbox from "../../../Elements/AsolviCheckbox/AsolviCheckbox";
import AsolviInput from "../../../Elements/AsolviInput/AsolviInput";
import AsolviTextArea from "../../../Elements/AsolviTextArea/AsolviTextArea";
import { ICustomerContact } from "../../../seedComponents/Groups/Order/OrderSummaryGroupSeedComponent";
import { ICustomerVisitAddress } from "../../../seedComponents/Pages/MachinePageSeedComponent";

import styles from "./OrderHeadingGroup.module.css";
import { IOrderMachineInfo } from "../../../seedComponents/Groups/Order/OrderDetailsGroupSeedComponent";

export interface Props {
  heading: IOrderHeading;
  contacts: ICustomerContact[];
  deliveryAddresses: ICustomerVisitAddress[];
  invoiceAddresses: ICustomerVisitAddress[];
  visibilitySettings: IOrderVisibilitySettings;
  orderLines: IOrderLine[];
  onChange: (newHeading: IOrderHeading) => void;
  machines: IOrderMachineInfo[];
  onDeliveryAddressChange: (newAddress?: ICustomerVisitAddress) => void;
  onInvoiceAddressChange: (newAddress?: ICustomerVisitAddress) => void;
}

const OrderHeadingGroup: FC<Props> = ({heading, contacts, deliveryAddresses, invoiceAddresses, visibilitySettings, onChange, orderLines, machines, onDeliveryAddressChange, onInvoiceAddressChange}) => {
  const {t} = useTranslation();
  const intlService = useInternationalization();

  const {
    UseMachineAddressAsDelivery: useMachineAddressAsDeliverySetting,
    AllowOtherContactOrder: allowOtherContactOrder,
    MachineAddressAsDefault: machineAddressAsDefaultSetting,
  } = visibilitySettings.ShowApprove;

  const [selectedContact, setSelectedContact] = useState<ICustomerContact>();
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState<ICustomerVisitAddress>();
  const [selectedInvoiceAddress, setSelectedInvoiceAddress] = useState<ICustomerVisitAddress>();

  const firstOrderLine = orderLines && orderLines[0];

  const firstMachine = firstOrderLine
    && machines.find((machine) => machine.MachineGuid === firstOrderLine.MachineGuid);


  useEffect(() => {
    if(heading.Email === selectedContact?.Email && heading.YourReference === selectedContact?.FullName) return;

    let contact = contacts.find(x => x.FullName === heading.YourReference);
    if(contact !== selectedContact)
      setSelectedContact(contact);
  }, [heading.Email, contacts, selectedContact, setSelectedContact, heading.YourReference]);

  useEffect(() => {
    if (invoiceAddresses !== null && invoiceAddresses !== undefined
      && invoiceAddresses.length > 0) {
        setSelectedInvoiceAddress(invoiceAddresses[0]);    
        onInvoiceAddressChange(invoiceAddresses[0]);
      }
  }, [invoiceAddresses]);

  const updateOrderHeadingField = useCallback((property: Extract<keyof IOrderHeading, string>, value: any) => {
    let newHeading = {...heading, [property]: value};
    onChange(newHeading);
  }, [heading, onChange]);

  const updateOrderHeading = useCallback((partialHeading: Partial<IOrderHeading>) => {
    let newHeading = {...heading, ...partialHeading};
    onChange(newHeading);
  }, [heading, onChange]);

  const onContactSelected = useCallback((item: ICustomerContact) => {
    setSelectedContact(item);
    updateOrderHeading({ Email: item?.Email, YourReference: item?.FullName });
  }, [updateOrderHeading]);

  const onInvoiceAddressSelected = useCallback((item?: ICustomerVisitAddress) => {
    setSelectedInvoiceAddress(item);    
    onInvoiceAddressChange(item);
  }, [onInvoiceAddressChange]);

  const onDeliveryAddressSelected = useCallback((item?: ICustomerVisitAddress) => {
    setSelectedDeliveryAddress(item);
    onDeliveryAddressChange(item);

  }, [onDeliveryAddressChange]);

  const onUseMachineAddressChange = useCallback((shouldUseMachineAddress: boolean) => {

    if(shouldUseMachineAddress) {
      
      const machineVisitAddress = deliveryAddresses.find(
        (deliveryAddress) => firstMachine?.AddressRowNo === deliveryAddress.RowNo
      );

      if (machineVisitAddress) {

        updateOrderHeadingField("MachineAddressAsDeliveryAddress", shouldUseMachineAddress);
        onDeliveryAddressSelected(machineVisitAddress);
        
      } else {
        console.error("Could not find machine's matching ICustomerVisitAddress");
      }
    } else {
      updateOrderHeadingField("MachineAddressAsDeliveryAddress", shouldUseMachineAddress);
    }
  }, [updateOrderHeadingField, deliveryAddresses, firstMachine, onDeliveryAddressSelected]);

  // TODO Matias: rewrite this so addresses are available when first render happens.
  //  Or maybe move logic to a higher level in redux
  const [machineAddressAsDefaultSettingHasBeenChecked, setMachineAddressAsDefaultSettingHasBeenChecked] = useState<boolean>(false);

  useEffect(() => {
    if (!deliveryAddresses || deliveryAddresses.length === 0 || !machineAddressAsDefaultSetting || machineAddressAsDefaultSettingHasBeenChecked)
      return;

    setMachineAddressAsDefaultSettingHasBeenChecked(true);

    const machineVisitAddress = deliveryAddresses.find(
      (deliveryAddress) => firstMachine?.AddressRowNo === deliveryAddress.RowNo
    );

    if (machineVisitAddress) {
      updateOrderHeadingField("MachineAddressAsDeliveryAddress", machineAddressAsDefaultSetting);
      onDeliveryAddressSelected(machineVisitAddress);

    } else {
      console.error("Could not find machine's matching ICustomerVisitAddress");
    }
    // eslint-disable-next-line
  }, [deliveryAddresses, updateOrderHeadingField, onDeliveryAddressSelected, machineAddressAsDefaultSetting, orderLines, machineAddressAsDefaultSettingHasBeenChecked])


  const contactColumns: ITypedColumn<ICustomerContact>[] = [{
    field: "FullName",
    header: t("Order.ContactName"),
    uniqueKey: "ContactName",
    width: 250,
  },{
    field: "Email",
    header: t("Contact.Email"),
    uniqueKey: "ContactEmail",
    width: 450,
  },{
    field: "Phone",
    header: t("Contact.Phone"),
    uniqueKey: "ContactPhone",
    width: 200,
  }];

  const deliveryAddressColumns: ITypedColumn<ICustomerVisitAddress>[] = [{
    field: "AddressName",
    header: t("Order.DeliveryAddressName"),
    uniqueKey: "AddressName",
    width: 150,
  },{
    field: "Address1",
    header: t("Order.DeliveryAddress1"),
    uniqueKey: "Address1",
    width: 150,
  },{
    field: "Address2",
    header: t("Order.DeliveryAddress2"),
    uniqueKey: "Address2",
    width: 150,
  },{
    field: "Address3",
    header: t("Order.DeliveryAddress3"),
    uniqueKey: "Address3",
    width: 150,
  },{
    field: "PostalCode",
    header: t("Order.DeliveryPostCode"),
    uniqueKey: "PostalCode",
    width: 150,
  },{
    field: "PostalLocation",
    header: t("Order.DeliveryPostLocation"),
    uniqueKey: "PostalLocation",
    width: 150,
  }];

  return (
    <Container fluid className={styles.container}>
      <AsolviInput
        labelSize={3}
        labelText={t("Order.Customer")}
        inputValue={heading.CustomerName}
        disabled
      />
      <AsolviInput
        labelSize={3}
        labelText={t("Order.CustomerNo")}
        inputValue={heading.CustomerNo}
        disabled
      />
      <AsolviInput
        labelSize={3}
        labelText={t("Order.ReferenceInfo")}
        inputValue={heading.CustomerReferenceInfo}
        onChange={(e) => updateOrderHeadingField("CustomerReferenceInfo", e.value)}
      />
      <AsolviInput
        labelSize={3}
        labelText={t("Order.Date")}
        inputValue={intlService.formatDate(new Date())}
        disabled
      />
      <AsolviAdvancedDropdownList
        labelSize={3}
        labelText={t("Order.YourRef")}
        shownText="FullName"
        columns={contactColumns}
        data={contacts}
        selectedItem={selectedContact}
        onSelect={(e) => onContactSelected(e.value)}
        required={visibilitySettings?.ShowApprove.MandatoryOurReference}
        disabled={!allowOtherContactOrder}
      />
      <AsolviInput
        labelSize={3}
        labelText={t("Contact.Email")}
        inputValue={heading.Email}
        onChange={(e) => updateOrderHeadingField("Email", e.value)}
        required={visibilitySettings?.ShowApprove.MandatoryOrderEMail}
        disabled={!allowOtherContactOrder}
      />
      <Row style={{ paddingTop: "1.5em" }} />

      { useMachineAddressAsDeliverySetting &&

        <Row className={`d-none d-xl-flex ${styles.checkboxRow}`}>
          <Col sm={6} style={{ paddingLeft: "calc(15px + 25%)" }}>
            <AsolviCheckbox
              value={Boolean(heading.MachineAddressAsDeliveryAddress)}
              label={t("Order.UseMachineAddress")}
              onChange={(e) => onUseMachineAddressChange(e.value)}
            />
          </Col>
        </Row> }

      <Row>
        <Col xl={6}>
          { useMachineAddressAsDeliverySetting &&

            <div className={`d-flex d-xl-none ${styles.checkboxRow}`} style={{ paddingLeft: "calc(15px + 25%)" }}>
              <AsolviCheckbox
                value={Boolean(heading.MachineAddressAsDeliveryAddress)}
                label={t("Order.UseMachineAddress")}
                onChange={(e) => onUseMachineAddressChange(e.value)}
              />
            </div> }

          <AsolviAdvancedDropdownList
            disabled={heading.MachineAddressAsDeliveryAddress}
            labelSize={{ sm: 3, xl: 6 }}
            labelText={t("Order.DeliveryAddress")}
            shownText="AddressName"
            columns={deliveryAddressColumns}
            data={deliveryAddresses}
            selectedItem={selectedDeliveryAddress}
            onSelect={(e) => onDeliveryAddressSelected(e.value)}
            required={true}
          />
          <AsolviInput
            labelSize={{ sm: 3, xl: 6 }}
            labelText={t("Order.DeliveryAddress1")}
            inputValue={heading.DeliveryAddress1}
            disabled
          />
          <AsolviInput
            labelSize={{ sm: 3, xl: 6 }}
            labelText={t("Order.DeliveryAddress2")}
            inputValue={heading.DeliveryAddress2}
            disabled
          />
          <AsolviInput
            labelSize={{ sm: 3, xl: 6 }}
            labelText={t("Order.DeliveryAddress3")}
            inputValue={heading.DeliveryAddress3}
            disabled
          />
          <Container fluid className={styles.postContainer}>
            <Row>
              <Col sm={3} xl={6}>
                {t("Order.DeliveryPostCode")}
              </Col>
              <Col sm={3} xl={2} className={styles.headingPostCode}>
                <Input
                  type="text"
                  value={heading.DeliveryPostalCode}
                  disabled
                />
              </Col>
              <Col sm={6} xl={4} className={styles.headingPostLocation}>
                <Input
                  type="text"
                  value={heading.DeliveryPostLocation}
                  disabled
                />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col xl={6}>
          <div className="d-flex d-xl-none" style={{ paddingTop: "1.5em" }}/>
          <AsolviAdvancedDropdownList
            labelSize={{ sm: 3, xl: 6 }}
            labelText={t("Order.InvoiceAddress")}
            shownText="AddressName"
            columns={deliveryAddressColumns}
            data={invoiceAddresses}
            selectedItem={selectedInvoiceAddress}
            onSelect={(e) => onInvoiceAddressSelected(e.value)}
            required={true}
          />
          <AsolviInput
            labelSize={{ sm: 3, xl: 6 }}
            labelText={t("Order.InvoiceAddress1")}
            inputValue={heading.InvoiceAddress1}
            disabled
          />
          <AsolviInput
            labelSize={{ sm: 3, xl: 6 }}
            labelText={t("Order.InvoiceAddress2")}
            inputValue={heading.InvoiceAddress2}
            disabled
          />
          <AsolviInput
            labelSize={{ sm: 3, xl: 6 }}
            labelText={t("Order.InvoiceAddress3")}
            inputValue={heading.InvoiceAddress3}
            disabled
          />
          <Container fluid className={styles.postContainer}>
            <Row>
              <Col sm={3} xl={6}>
                {t("Order.InvoicePostCode")}
              </Col>
              <Col sm={3} xl={2} className={styles.headingPostCode}>
                <Input
                  type="text"
                  value={heading.InvoicePostalCode}
                  disabled
                />
              </Col>
              <Col sm={6} xl={4} className={styles.headingPostLocation}>
                <Input
                  type="text"
                  value={heading.InvoicePostLocation}
                  disabled
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <AsolviTextArea
        labelColSize={3}
        valueColSize={9}
        labelText={t("Order.GeneralInformation")}
        inputText={heading.Information}
        rows={3}
        onChange={(e) => updateOrderHeadingField("Information", e.value)}
      />
    </Container>
  );
};

export default OrderHeadingGroup;
