import * as React from "react";
import "./NavBarGroup.css";
import AsolviNavBar from "../../Auxiliary/AsolviNavBar/AsolviNavBar";
import { FC } from 'react';

export interface Props {
  logOutFunction: () => void,
  passwordFunction: () => void,
  customerInfo: string,
  customerInfoOrgNo: string,
}

const NavBarGroup: FC<Props> = ({ logOutFunction, passwordFunction, customerInfo, customerInfoOrgNo }) => {
  return (
    <>
      <AsolviNavBar
        customerInfo={customerInfo}
        customerInfoOrgNo={customerInfoOrgNo}
        logOutFunction={logOutFunction}
        onShowConsent={()=>{}}
        passwordFunction={passwordFunction}
      />
    </>
  );
}

export default NavBarGroup;
