import React, { FC, useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import * as ReactRedux from 'react-redux';
import { useSelector } from 'react-redux';
import * as Store from '../../../../store/Store';
import { ITaskMachineGroupData } from '../../../Groups/Task/TaskMachineGroup/TaskMachineGroup';
import OrderDetailsGroup from "../../../Groups/Order/OrderDetailsGroup";
import {
  addOrderLine,
  clearCurrentOrder as clearCurrentOrderAction,
  clearQuickOrders as clearQuickOrdersAction,
  getQuickOrders as getQuickOrdersAction,
  hideCurrentOrder as hideCurrentOrderAction,
  IOrderHeading,
  IOrderLine,
  resetCreateOrderResponse as resetCreateOrderResponseAction,
  updateOrderHeading,
} from '../../../../slices/OrderSlice';
import AsolviNotification from "../../../Elements/AsolviNotification/AsolviNotification";


interface IProps {
  machineDetails?: ITaskMachineGroupData;
  isLoadingMeterReadings: boolean;
  onConfirm: () => void;
  loggedInName: string;
}

export interface IArticleInfo {
  ArticleObjNo?: string;
  ArticleNo?: string;
  ArticleDescription?: string;
  Invoiceable?: boolean;
  Qty?: number;
  SalesPrice?: number;
  VatCode?: number;
  SalesPriceWithDiscount?: number;
  Discount?: number;
  DiscountType: DiscountTypeEnums;

}

export enum DiscountTypeEnums {
  None = 0,
  Percentage = 1,
  FixedPrice = 2,
  FixedAmount = 3
}

export interface IOrderMachineInfo {
  ModelType: string,
  MachineNo: string,
  MachineGuid: string,
  MachineId: string,
  Location: string,
  AddressRowNo: number,
  AddressName: string,
  Address1: string,
  Address2: string,
  Address3: string,
  AddressCountry: string,
  PostalCode: string,
  PostalDescription: string,
  customerNo: string,
  customerName: string,
  SortGroup1: string,
  SortGroup2?: string,
  SortGroup3?: string,
  SortGroup4?: string,
  SortGroup5?: string,
  Properties: IOrderMachineProperyItem[];
}

export interface IOrderMachineProperyItem {
  PropertyGuid: string
  Id: string,
  Description: string,
  Value: string
}

export interface IQuickOrderSearchDataContract {
  CustomerGuid?: string;
  MachineGuid?: string;
  ModelGuid?: string;
  ArticleCount?: number;
  SortGroupNo?: string;
}

export interface ICreateOrder{
  Success?: boolean | undefined;
  Company?: string | undefined;
  OrderNo?: number | undefined;
  OrderGuid?: string | undefined;
  ErrorCode?: string | undefined;
}

const OrderDetailsGroupSeedComponent: FC<IProps> = ({ machineDetails, onConfirm, isLoadingMeterReadings, loggedInName }) => {
  const { appUrl } = (window as any)["appConfig"];
  const { t } = useTranslation();
  const { currentAuth0Token, activeCustomer, activeCustomerInfo, currentUserEmail } = useSelector((state: Store.IAppState) => state.generalState.General);  
  const { articles, currentOrder, isLoadingDiscounts, loadingArticles, visibilitySettings, selectedCustomer } = useSelector((state: Store.IAppState) => state.orderState.Order);
  const CreateOrder = useSelector((state: Store.IAppState) => state.orderState.CreateOrder);

  const dispatch = ReactRedux.useDispatch();
  const getQuickOrders = useCallback((token: string, appUrl: string, machineGuid: string, email: string) => dispatch(getQuickOrdersAction({ token, appUrl, machineGuid, email })), [dispatch]);
  const clearQuickOrders = useCallback(() => dispatch(clearQuickOrdersAction()), [dispatch]);
  const hideCurrentOrder = useCallback(() => dispatch(hideCurrentOrderAction()), [dispatch]);
  const clearCurrentOrder = useCallback(() => dispatch(clearCurrentOrderAction()), [dispatch]);
  const resetCreateOrderResponse = useCallback(() => dispatch(resetCreateOrderResponseAction()), [dispatch]);
  // const resetSelectedMachine = useCallback(() => dispatch(selectMachineAction(undefined)), [dispatch]);

  const [orderLines, setOrderLines] = React.useState<IArticleInfo[]>([]);
  const [showNotify, setShowNotify] = React.useState<boolean>(false);
  const [notifyMessage, setNotifyMessage] = React.useState<string>("");
  const [notifyStyle, setNotifyStyle] = React.useState<any>(undefined);

  const handleAddOrderLines = () => {
    let orderHeading = {}  as IOrderHeading;
    
    if (selectedCustomer !== undefined && selectedCustomer) {
      orderHeading.CustomerGuid = selectedCustomer.customerGuid;      
      orderHeading.CustomerNo = selectedCustomer.customerNo;
      orderHeading.CustomerName = selectedCustomer.customerName;
    } else {
      orderHeading.CustomerGuid = activeCustomer;
      let activeCustomerInfoObj = JSON.parse(activeCustomerInfo);
      orderHeading.CustomerNo = activeCustomerInfoObj.CustomerNo;
      orderHeading.CustomerName = activeCustomerInfoObj.Name;
    }
    
    if (currentOrder.Heading.YourReference === null || currentOrder.Heading.YourReference === undefined 
      || currentOrder.Heading.YourReference === "") {
        orderHeading.YourReference = loggedInName;
        orderHeading.Email = currentUserEmail;
      }
      

    dispatch(updateOrderHeading(orderHeading));

    orderLines.forEach((orderLine, i) => {
      let newOrderLine = {} as IOrderLine
      newOrderLine.ArticleGuid = orderLine?.ArticleObjNo ?? "";
      newOrderLine.ArticleNo = orderLine?.ArticleNo ?? "";
      newOrderLine.Description = orderLine?.ArticleDescription ?? "";
      newOrderLine.Invoiceable = orderLine?.Invoiceable ?? false;
      newOrderLine.Price = orderLine?.SalesPrice ?? 0;
      newOrderLine.Qty = orderLine?.Qty ?? 0;
      newOrderLine.MachineGuid = machineDetails?.MachineGuid ?? "";
      newOrderLine.LineNo = i;
      newOrderLine.MachineFriendlyName = [machineDetails?.MachineNo, machineDetails?.ModelType].filter(x => x).join(' - ');

      dispatch(addOrderLine({
        token: currentAuth0Token,
        appUrl: appUrl,
        email: currentUserEmail,
        line: newOrderLine,
      }));
    });
  }

  const handleOrderGridItemChange = (item: IArticleInfo, field?: string, newValue?: any) => {
    const inEditID = item.ArticleObjNo;
    if(field === undefined)
      field = "";
    if(field === "Qty" && newValue <0){
      setNotifyMessage(t("Order.NegativeNumbersNotAllowed"));
      setNotifyStyle('error');
      setShowNotify(true);
      setTimeout(() => {
        handleCloseNotification();
      }, 6000);
        return;
    }
    const newData = orderLines.map((item) =>
      item.ArticleObjNo === inEditID ? { ...item, [field as string]: newValue } : item
    );
    setOrderLines(newData);
  }

  useEffect(()=>{
      let articlesWithCounters= articles.map(x => {
        let orderLine = currentOrder.Lines.find(y => ((y.ArticleGuid === x.ArticleObjNo) && (y.MachineGuid === machineDetails?.MachineGuid)));
        if(!orderLine)
            return x;
        return {
            ...x,
            Qty: orderLine.Qty
        };
    })    
    setOrderLines(articlesWithCounters);
  },[articles, currentOrder.Lines, machineDetails]);

  useEffect(()=>{
    if(machineDetails?.MachineGuid){
      getQuickOrders(currentAuth0Token, appUrl, machineDetails?.MachineGuid,currentUserEmail);     
    } else {
      clearQuickOrders();
    }
  },[currentAuth0Token, appUrl, currentUserEmail, machineDetails, getQuickOrders, clearQuickOrders]);

  useEffect(()=>{
    if((CreateOrder?.ErrorCode === null || CreateOrder?.ErrorCode === "") && CreateOrder?.OrderNo !== undefined){
      setNotifyMessage(t("Standard.order") + " ["+CreateOrder?.OrderNo+"] "+ t("Order.RegisteredSuccessfully") + "!");
      setNotifyStyle('success');
      setShowNotify(true);      
      hideCurrentOrder();
      clearCurrentOrder();
      clearQuickOrders();
      
      setTimeout(() => {
        handleCloseNotification();
      }, 6000);
    }else if (CreateOrder?.ErrorCode !== undefined && CreateOrder?.ErrorCode !== ""){    
      let error = "";            
      switch (String(CreateOrder?.ErrorCode)) {
          case "MissingCustomerGuid": 
          case "MissingInvoiceAddressRow": 
          case "MissingDeliveryAddressRow":
          case "DidNotFindInvoiceAddress": 
          case "MissingYourReference":
          case "MissingEmail":
          case "MissingMachineGuid":       
          {
              error = t("Order." + String(CreateOrder?.ErrorCode));
              break;
          }
          case "MaxQtyExceeded":  {
            error = t("Order." + String(CreateOrder?.ErrorCode)+ " "+ visibilitySettings.ShowAddOrderLine?.MaxQty);
            break;
          }        
          default: {                                   
              error = CreateOrder?.ErrorCode;
          }

      }
      setNotifyMessage(error);
      setNotifyStyle('error');
      setShowNotify(true);
    }
  },[CreateOrder]); // TODO fix eslint warning

  const handleCloseNotification = useCallback(() => {
    resetCreateOrderResponse();
    setShowNotify(false);
  },[resetCreateOrderResponse]);

  return (
    <>
      <OrderDetailsGroup
        visibilitySettings={visibilitySettings}
        currentOrderLines={currentOrder.Lines}
        quickOrderLines = {orderLines}
        orderGridItemChange = {handleOrderGridItemChange}
        onAddOrderLines= {handleAddOrderLines}
        onConfirm= {onConfirm}
        isLoadingArticles= {loadingArticles}
        isLoadingDiscounts = {isLoadingDiscounts}
        isLoadingMeterReadings={isLoadingMeterReadings}
      />
      <AsolviNotification
        message = {notifyMessage}
        showNotify = {showNotify}
        showIcon = {true}
        closeAction = {handleCloseNotification}
        style = {notifyStyle} />
    </>
  );
}

export default OrderDetailsGroupSeedComponent;
