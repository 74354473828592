import * as React from 'react';
import { IColumn } from '../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList';
import TaskContactGroup from '../../../Groups/Task/TaskContactGroup/TaskContactGroup';
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import * as ReactRedux from "react-redux";
import * as Store from "../../../../store/Store";
import * as TaskReducer from "../../../../reducers/TaskReducer";
import * as TaskActions from "../../../../actions/TaskActions";
import AsolviNotification from '../../../Elements/AsolviNotification/AsolviNotification';
import { useCallback } from 'react'
import { TaskTypeEnums } from '../../Pages/TaskCreatePageSeedComponent';

interface IProps extends ReactI18Next.WithTranslation {
    t: I18Next.TFunction;
    loggedInCustomer: string;
    selectedMachine?: string;
    selectedCustomer?: string;
    TaskVisibilitySettings: TaskReducer.ITaskVisibilitySettings;
    contactList: TaskReducer.ITaskContactGroupData[];
    TaskUpdateContactResponse: TaskReducer.ITaskUpdateContact;
    getContacts(token: string, email:string, appUrl: string, customerGuid: string, machineGuid: string, taskType: number): (token: string, email:string)  => void;
    updateContact(token: string, email:string, appUrl: string, body: TaskReducer.ITaskContactGroupData): (token: string, email:string)  => void;
    resetTaskUpdateContactResponse(error: string | undefined) : (error: string | undefined) => void;
    extractData?: (data: TaskReducer.ITaskContactGroupData) => void;
    clearData?: boolean;
    currentAuth0Token: string;
    currentUserEmail: string;
    taskType?: any;
}

const TaskContactGroupSeedComponent: React.FunctionComponent<IProps> = (props) => {
    const { t, getContacts, selectedCustomer, selectedMachine, 
            loggedInCustomer, resetTaskUpdateContactResponse,
            taskType = TaskTypeEnums.Service} = props;
    const allowUpdate = props.TaskVisibilitySettings.ShowContact.AllowUpdate;
    const token = props.currentAuth0Token;
    const email = props.currentUserEmail;
    const { appUrl } = (window as any)["appConfig"];
    const [showNotify, setShowNotify] = React.useState<boolean>(false);
    const [notifyMessage, setNotifyMessage] = React.useState<string>("");
    const [notifyStyle, setNotifyStyle] = React.useState<any>(undefined);
    const [contactUpdating, setContactUpdating] = React.useState<boolean>(false);

    let layout = [] as IColumn[];
    layout.push({ field: "FirstName", header: t("Contact.FirstName"), width: 150, uniqueKey: "FirstName" });
    layout.push({ field: "LastName", header: t("Contact.LastName"), width: 150, uniqueKey: "LastName" });
    layout.push({ field: "Phone", header: t("Contact.Phone"), width: 150, uniqueKey: "Phone" });
    layout.push({ field: "FaxNo", header: t("Contact.Fax"), width: 150, uniqueKey: "FaxNo" });
    layout.push({ field: "Email", header: t("Contact.Email"), width: 300, uniqueKey: "Email" });

    React.useEffect(()=>{
        
        if(token && selectedCustomer)
        {
            getContacts(token, email, appUrl, selectedCustomer, selectedMachine?? "", taskType);                  
        }
    },[token, email, appUrl, getContacts, selectedCustomer, selectedMachine, loggedInCustomer, taskType]);

    const handleSubmitButtonClicked = (data: TaskReducer.ITaskContactGroupData) => {
        setShowNotify(false);
        setContactUpdating(true);
        
        if(props.TaskUpdateContactResponse !== undefined)
            props.TaskUpdateContactResponse.error = undefined;
        props.updateContact(token, email, appUrl, data);
    }

    const handleCloseNotification = useCallback(() => {          
        resetTaskUpdateContactResponse(undefined);
        setShowNotify(false);
        setContactUpdating(false);
    },[resetTaskUpdateContactResponse])   

    React.useEffect(() => {
        let timeOut = {} as NodeJS.Timeout;
        if (contactUpdating && props.TaskUpdateContactResponse?.error === "") {
            setNotifyMessage(t("Contact.ContactUpdated"));
            setNotifyStyle('success');
            setShowNotify(true);
            timeOut = setTimeout(() => {
                handleCloseNotification();
            }, 2000);
        }else if (contactUpdating && props.TaskUpdateContactResponse?.error !== undefined && props.TaskUpdateContactResponse?.error !== ""){
            let error = "";            
            switch (String(props.TaskUpdateContactResponse.error)) {
                case "ErrorMissingName": {
                    error = t("Contact.ErrorMissingName");
                    break;
                }
                case "ErrorMissingEmail": {
                    error = t("Contact.ErrorMissingEmail");
                    break;
                }
                case "ErrorMissingPhone": {                    
                    error = t("Contact.ErrorMissingPhone");
                    break;
                }
                case "ErrorInvalidEmail": {
                    error = t("Contact.ErrorInvalidEmail");
                    break;
                }
                case "ErrorMissingContactNo":
                case "ErrorMissingCustomer": {
                    error = t("Contact.ErrorMissingContact");
                    break;
                }                                
                default: {                    
                    error = props.TaskUpdateContactResponse.error;
                }

            }

            setNotifyMessage(error);
            setNotifyStyle('error');
            setShowNotify(true);
            timeOut = setTimeout(() => {
                handleCloseNotification();
            }, 4000);
        }
        return () => {clearTimeout(timeOut)}
      },[t, props.TaskUpdateContactResponse, handleCloseNotification, contactUpdating]);

    return (
        <>
            <TaskContactGroup
                contactList={props.contactList}
                allowUpdateContact={allowUpdate}
                contactSearchLayout={layout}
                extractData={props.extractData} 
                handleUpdateClick={handleSubmitButtonClicked}
                clearData={props.clearData}
                />
            <AsolviNotification
                message={notifyMessage}
                showNotify={showNotify}
                showIcon={true}
                closeAction={handleCloseNotification}
                style={notifyStyle}
                setPosition={{ position: "relative", left: "67.5%" }}
            />
        </>
    )
 
}

const mapStateToProps = (store: Store.IAppState) => {
    return {
        TaskVisibilitySettings: store.taskState.TaskVisibilitySettings,
        loggedInCustomer: store.generalState.General.activeCustomer,
        selectedMachine: store.taskState.SelectedMachine?? undefined,
        selectedCustomer: store.taskState.SelectedCustomer?? undefined,
        contactList: store.taskState.TaskContacts,
        TaskUpdateContactResponse: store.taskState.TaskUpdateContact,
        currentAuth0Token: store.generalState.General.currentAuth0Token,
        currentUserEmail: store.generalState.General.currentUserEmail,
    }
  }

const mapDispatchToProps = (dispatch: any) => {
    return {        
        getContacts: (token: string, email:string, appUrl: string, customerGuid: string, machineGuid: string, taskType: number) => 
                dispatch(TaskActions.GetTaskContacts(token, email, appUrl, customerGuid, machineGuid, taskType)),  
        updateContact: (token: string, email:string, appUrl: string, body: TaskReducer.ITaskContactGroupData) => 
                dispatch(TaskActions.SubmitTaskUpdateContact(token, email, appUrl, body)),  
        resetTaskUpdateContactResponse: (error: string) =>    
                dispatch(TaskActions.ResetTaskUpdateContactResponse(error)),                
    }
}
  
export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ReactI18Next.withTranslation()(TaskContactGroupSeedComponent));