import * as React from 'react';
import { useState } from "react";
import "./TaskInfoGroup.css";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import AsolviInput from "../../../Elements/AsolviInput/AsolviInput";

export interface ITaskInfoGroupData{
    ProjectInfo01: string | undefined;
    ProjectInfo02: string | undefined;
    ProjectInfo03: string | undefined;
    ProjectInfo04: string | undefined;
    ProjectInfo05: string | undefined;
    ProjectInfo06: string | undefined;
}

export interface IProps{
    projectInfoLabel: string[];
    projectInfoShow: boolean []; 
    extractData?: (data: ITaskInfoGroupData) => void; 
    clearData?: boolean;
}

const TaskInfoGroup: React.FunctionComponent<IProps> = (Props) => {
    const { extractData, clearData } = Props;
    const [ProjectInfo01, setProjectInfo01] = useState<string>();
    const [ProjectInfo02, setProjectInfo02] = useState<string>();
    const [ProjectInfo03, setProjectInfo03] = useState<string>();
    const [ProjectInfo04, setProjectInfo04] = useState<string>();
    const [ProjectInfo05, setProjectInfo05] = useState<string>();
    const [ProjectInfo06, setProjectInfo06] = useState<string>();

    const projectInfo01Changed = (e:any) => {
        setProjectInfo01(e.target.value);
    }
    const projectInfo02Changed = (e:any) => {
        setProjectInfo02(e.value);
    }
    const projectInfo03Changed = (e:any) => {
        setProjectInfo03(e.target.value);
    }
    const projectInfo04Changed = (e:any) => {
        setProjectInfo04(e.target.value);;
    }
    const projectInfo05Changed = (e:any) => {
        setProjectInfo05(e.target.value);
    }
   const projectInfo06Changed = (e:any) => {
        setProjectInfo06(e.target.value);          
    }

    React.useEffect(()=>{

        const sendData = () =>{
            if(extractData)
            {
                let data = {} as ITaskInfoGroupData;
                data.ProjectInfo01 = ProjectInfo01; 
                data.ProjectInfo02 = ProjectInfo02; 
                data.ProjectInfo03 = ProjectInfo03; 
                data.ProjectInfo04 = ProjectInfo04; 
                data.ProjectInfo05 = ProjectInfo05; 
                data.ProjectInfo06 = ProjectInfo06;       
                extractData(data);
            }       
        }

        sendData();
      },[ProjectInfo01, ProjectInfo02, ProjectInfo03, ProjectInfo04, ProjectInfo05, ProjectInfo06, extractData]);
    
    React.useEffect(() => {
        if (clearData !== undefined && clearData) {            
            setProjectInfo01("");
            setProjectInfo02("");
            setProjectInfo03("");
            setProjectInfo04("");
            setProjectInfo05("");
            setProjectInfo06("");
        }
    }, [clearData]);

    return (
        <>
        <Container fluid>
        <Row>
                { Props.projectInfoShow[0] &&
                <Col sm={6}>
                    <AsolviInput labelText = {Props.projectInfoLabel[0]} onChange={projectInfo01Changed} inputValue={ProjectInfo01}/>
                </Col>
                }
                { Props.projectInfoShow[1] &&
                <Col sm={6}>
                    <AsolviInput labelText = {Props.projectInfoLabel[1]} onChange={projectInfo02Changed} inputValue={ProjectInfo02}/>
                </Col>
}
                { Props.projectInfoShow[2] &&
                <Col sm={6}>
                    <AsolviInput labelText = {Props.projectInfoLabel[2]} onChange={projectInfo03Changed} inputValue={ProjectInfo03}/>
                </Col>
                }
                { Props.projectInfoShow[3] &&
                <Col sm={6}>
                    <AsolviInput labelText = {Props.projectInfoLabel[3]} onChange={projectInfo04Changed} inputValue={ProjectInfo04}/>
                </Col>
                }
                { Props.projectInfoShow[4] &&
                <Col sm={6}>
                    <AsolviInput labelText = {Props.projectInfoLabel[4]} onChange={projectInfo05Changed} inputValue={ProjectInfo05}/>
                </Col>
                }
                { Props.projectInfoShow[5] &&
                <Col sm={6}>
                    <AsolviInput labelText = {Props.projectInfoLabel[5]} onChange={projectInfo06Changed} inputValue={ProjectInfo06}/>
                </Col>
                }
            </Row>
        </Container>  
        </>                             
        );
}

export default (TaskInfoGroup);