import * as Redux from 'redux';
import * as ReduxThunk from 'redux-thunk';
import * as GeneralReducer from "../reducers/GeneralReducer";
import * as SettingsReducer from './../reducers/SettingsReducer';
import * as FrameReducer from "../reducers/FrameReducer";
import * as MeterLoginReducer from "../reducers/MeterLoginReducer";
import * as MeterReadingReducer from "../reducers/MeterReadingReducer";
import machineReducer, { IMachineState } from '../slices/MachineSlice';
import orderReducer, { IOrderState } from '../slices/OrderSlice';
import * as TaskReducer from '../reducers/TaskReducer';
import storage from 'redux-persist/lib/storage';
import * as ReduxPersist from 'redux-persist';

export interface IAppState{
  generalState: GeneralReducer.IGeneralState;
  settingsState: SettingsReducer.ISettingsState;
  frameState: FrameReducer.IFrameState;
  machineState: IMachineState;
  meterLoginState: MeterLoginReducer.IMeterLoginState;
  meterReadingState: MeterReadingReducer.IMeterReadingState;
  taskState: TaskReducer.ITaskState;
  orderState: IOrderState;
}


const appReducer = Redux.combineReducers<IAppState>({
  generalState: GeneralReducer.GeneralReducer,
  settingsState: SettingsReducer.settingsReducer,
  frameState: FrameReducer.frameReducer,
  machineState: machineReducer,
  meterLoginState: MeterLoginReducer.MeterLoginReducer,
  meterReadingState: MeterReadingReducer.MeterReadingReducer,
  taskState: TaskReducer.TaskReducer,
  orderState: orderReducer
});


export const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['generalState', 'settingsState', 'frameState']
}
const rootReducer: Redux.Reducer = (state, action) => {
  if (action.type === 'LOGOUT')
   {
    storage.removeItem('root');
    storage.removeItem('persist:root');
    storage.removeItem('i18nextLng');
    state = undefined;
  }
  return appReducer(state, action);
}


const persistedReducer = ReduxPersist.persistReducer(persistConfig, rootReducer);


export default () => {
  const composeEnhancers = (
    (process.env["NODE_ENV"] !== 'production'
      && ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    ) || Redux.compose);

  const store = Redux.createStore(
    persistedReducer,
    undefined,
    composeEnhancers(
      Redux.applyMiddleware(
        ReduxThunk.default,
      )
    )
  );
  const persistor = ReduxPersist.persistStore(store);
  return { store, persistor };
}