import * as Redux from 'redux';
import * as ReduxThunk from 'redux-thunk';
import * as MeterLoginReducer from '../reducers/MeterLoginReducer';
import * as Axios from 'axios';
import * as ApiEndPoints from '../sources/apiEndPoints';
import { IMachineInfo } from "../components/MeterReport/seedComponents/Pages/MeterReportingPageSeedComponent"

export enum MeterLoginActionTypes{
    GETFOOTERANDBARINFO = "GetFooterAndBarInfo",
    GETMACHINESANDMETERS = "GetMachinesAndMeters",
    SUBMITMETERSREADING = "SubmitMeterReadings",
    SETMETERLOGINTOKEN = "SetMeterLoginToken",
    SETRESPONSEERROR = "SetResponseError"
}

interface IMeterLoginGetFooterAndBarInfo{
    type: MeterLoginActionTypes.GETFOOTERANDBARINFO;
    MeterLogin: MeterLoginReducer.IMeterLogin;
}
interface IMeterLoginMachinesAndMetersInfo{
    type: MeterLoginActionTypes.GETMACHINESANDMETERS;
    MeterLogin: MeterLoginReducer.IMeterLogin;
}

interface ISubmitMetersReadings{
    type: MeterLoginActionTypes.SUBMITMETERSREADING | MeterLoginActionTypes.SETRESPONSEERROR;
    MeterLogin: MeterLoginReducer.IMeterLogin;
}

interface ISetMeterLoginToken{
    type: MeterLoginActionTypes.SETMETERLOGINTOKEN;
    MeterLogin: MeterLoginReducer.IMeterLogin;
}

export type MeterLoginActions = IMeterLoginGetFooterAndBarInfo | IMeterLoginMachinesAndMetersInfo | ISubmitMetersReadings | ISetMeterLoginToken;

export const GetFooterAndBarInfo: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, MeterLoginReducer.IMeterLoginState, null, IMeterLoginGetFooterAndBarInfo>
    > = (appUrl: string, token: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try{
                const response = await Axios.default.get(appUrl + ApiEndPoints.MeterLoginAPIs.GetFooterAndBarInfo(token));
                dispatch({
                    MeterLogin: {barFooterInfo : JSON.stringify(response.data)},
                    type: MeterLoginActionTypes.GETFOOTERANDBARINFO
                });
            }
            catch(err){
                console.log(err);
                dispatch({
                    MeterLogin: {barFooterInfo :null},
                    type: MeterLoginActionTypes.GETFOOTERANDBARINFO
                })
            }
        }
    };

export const GetMachinesAndMeters: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, MeterLoginReducer.IMeterLoginState, null, IMeterLoginMachinesAndMetersInfo>
    > = (appUrl: string, token: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try{
                const response = await Axios.default.get(appUrl + ApiEndPoints.MeterLoginAPIs.GetMachinesAndMeters(token));
                dispatch({
                    MeterLogin: {machinesAndMeters: JSON.stringify(response.data)},
                    type: MeterLoginActionTypes.GETMACHINESANDMETERS
                });
            }
            catch(err)
            {
                console.log(err);
                dispatch({
                    MeterLogin: {machinesAndMeters: null},
                    type: MeterLoginActionTypes.GETMACHINESANDMETERS
                })
            }
        }
    }

export const SubmitMetersReading: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, MeterLoginReducer.IMeterLoginState, null, ISubmitMetersReadings>
    > = (appUrl: string, token: string, language: string, body: IMachineInfo) => {
        return async (dispatch: Redux.Dispatch) => {
            try{
                const response = await Axios.default.post(appUrl + ApiEndPoints.MeterLoginAPIs.SubmitMetersReading(language, token),
                    body             
                )
                dispatch({
                    MeterLogin: {machinesAndMeters: JSON.stringify(response.data)},
                    type: MeterLoginActionTypes.SUBMITMETERSREADING 
                }) 
                dispatch({
                    MeterLogin: {error: ""},
                    type: MeterLoginActionTypes.SETRESPONSEERROR 
                })                    
            }
            catch (err){
                console.log(err);
                if(err.response !== undefined && err.response.status !== undefined && err.response.status === 400){                              
                    dispatch({
                        MeterLogin: {error: err.response.data.Status.Reason},
                        type: MeterLoginActionTypes.SETRESPONSEERROR
                    })
                }else if(err.response !== undefined && err.response.status !== undefined && err.response.status === 500){                                
                    dispatch({
                        MeterLogin: {error: err.response.data.ExceptionMessage},
                        type: MeterLoginActionTypes.SETRESPONSEERROR
                    })
                }else{
                    dispatch({
                        MeterLogin: {error: JSON.stringify(err)},
                        type: MeterLoginActionTypes.SETRESPONSEERROR
                    })                   
                }
            }
        }
    }

export const SetMeterLoginToken: Redux.ActionCreator<
    ReduxThunk.ThunkAction<any, MeterLoginReducer.IMeterLoginState, null, ISetMeterLoginToken>
> = (token: string) => {
    return (dispatch: Redux.Dispatch) =>{
        dispatch({
            MeterLogin : {token: token},
            type: MeterLoginActionTypes.SETMETERLOGINTOKEN
        })
    };
};