import * as Redux from 'redux';
import i18n from "../i18n";
import * as GeneralActions from "../actions/GeneralActions";
import {convertLanguage} from "../utils/languageConverter";


export interface IGeneral {
  showSettingsPage?: boolean;
  activeDatabase: string;
  activeLanguage: string;
  activeCustomer: string;
  activeCustomerInfo: string,  
  showAccessDenied: boolean;
  showPasswordReset: boolean;
  currentAuth0Token: string;
  currentUserEmail: string;
}

export interface IGeneralState {
  readonly General: IGeneral;
}

const initialGeneralState: IGeneralState = {
  General: {
    showSettingsPage: true,
    activeDatabase: "1",
    activeLanguage: convertLanguage(i18n.language),
    activeCustomer: "",
    activeCustomerInfo: "",    
    showAccessDenied: false,
    showPasswordReset: false,
    currentAuth0Token: "",
    currentUserEmail: ""
  }
}

export const GeneralReducer: Redux.Reducer<IGeneralState, GeneralActions.GeneralActions> = (
  state = initialGeneralState,
  action
) => {
  switch (action.type) {
    case GeneralActions.GeneralActionTypes.SHOWSETTINGSPAGE: {
      return {
        ...state,
        General: { ...state.General, showSettingsPage: action.General.showSettingsPage }
      };
    }
    case GeneralActions.GeneralActionTypes.SKIPSETTINGSPAGE: {
      return {
        ...state,
        General: { ...state.General, showSettingsPage: action.General.showSettingsPage }
      };
    }
    case GeneralActions.GeneralActionTypes.RESETPASSWORD: {
      return {
        ...state
      }
    }
    case GeneralActions.GeneralActionTypes.HANDLEACCESSDENIEDMODAL: {
      return {
        ...state,
        General: { ...state.General, showAccessDenied: action.General.showAccessDenied }
      }
    }
    case GeneralActions.GeneralActionTypes.HANDLESELECTEDDATABASE: {
      return {
        ...state,
        General: {...state.General, activeDatabase: action.General.activeDatabase}
      }
    }
    case GeneralActions.GeneralActionTypes.HANDLESELECTEDLANGUAGE: {
      return {
        ...state,
        General: {...state.General, activeLanguage: action.General.activeLanguage}
      }
    }
    case GeneralActions.GeneralActionTypes.HANDLESELECTEDCUSTOMER: {
      return {
        ...state,
        General: {...state.General, activeCustomer: action.General.activeCustomer} 
      }
    }
    case GeneralActions.GeneralActionTypes.HANDLEPASSWORDRESET: {
      return {
        ...state,
        General: { ...state.General, showPasswordReset: action.General.showPasswordReset }
      }
    }
    case GeneralActions.GeneralActionTypes.GETACTIVECUSTOMERINFO: {
      return {
        ...state,
        General: { ...state.General, activeCustomerInfo: action.General.activeCustomerInfo }
      }
    }
    case GeneralActions.GeneralActionTypes.SAVEAUTH0TOKENTOSTORE: {
      return {
        ...state,
        General: { ...state.General, currentAuth0Token: action.General.currentAuth0Token }
      }
    }
    case GeneralActions.GeneralActionTypes.SAVECURRENTUSEREMAILTOSTORE: {
      return {
        ...state,
        General: { ...state.General, currentUserEmail: action.General.currentUserEmail }
      }
    }  

    default: {
      return state;
    }
  }
}