import * as Redux from 'redux';
import * as ReduxThunk from 'redux-thunk';
import * as FrameReducer from "../reducers/FrameReducer";

export enum FrameActionTypes {
  SETHOMEURL = 'SETHOMEURL',
  SETLOGOUTURL = 'SETLOGOUTURL',
}

export interface ISetFrameHomeUrl {
  type: FrameActionTypes.SETHOMEURL;
  Frame: FrameReducer.IFrame;
}

export interface ISetFrameLogoutUrl {
  type: FrameActionTypes.SETLOGOUTURL;
  Frame: FrameReducer.IFrame;
}


export type FrameActions = ISetFrameHomeUrl | ISetFrameLogoutUrl ;

export const SetHomeUrl: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, FrameReducer.IFrameState, null, ISetFrameHomeUrl>
  > = (email: string, timeOffSet: string, language: string, selectedDatabaseId: string, accessToken: string, appUrl: string, cust: string, page: string) => {
  return (dispatch: Redux.Dispatch) => {
    dispatch({
      type: FrameActionTypes.SETHOMEURL,
      Frame: { url: appUrl + "Login.aspx?db=" + selectedDatabaseId + "&lang=" + language + "&email=" + email + "&timeZoneOffset=" + timeOffSet + 
      "&token=" + accessToken + "&custumer=" + cust + "&page=" + page}
    });
  }
}

export const SetLogoutUrl: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, FrameReducer.IFrameState, null, ISetFrameLogoutUrl>
> = (appUrl: string) => {
  return (dispatch: Redux.Dispatch) => {
    let url = removeTrailingSlash(appUrl);
    dispatch({
      type: FrameActionTypes.SETLOGOUTURL,
      Frame: { url: url + "" }
    })
  }
}

function removeTrailingSlash(url: string): string {
  if(moreThanThreeSlashes(url) && url.charAt(url.length - 1) == '/')
    return url.substring(0, url.length - 1);
  return url;
}

function moreThanThreeSlashes(url: string): boolean {
  let count = 0;
  for(let c of url) {
    if(c == '/')
      count += 1;
    if(count > 3)
      return true;
  }
  return false;
}