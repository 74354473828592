import React, { FC } from "react";
import ConsentItem from './ConsentItem';
import { IConsent } from "../../seedComponents/Pages/ConsentsPageSeedComponent";

import styles from "./ConsentsCollection.module.css";
import { useTranslation } from "react-i18next";

export interface Props {
  consents: IConsent[];
  displayConsent: (consent: IConsent) => void;
  onWithdrawConsent: (consentId: number) => void;
  onGiveConsent: (consentId: number) => void;
}


const ConsentsCollection: FC<Props> = ({ consents, displayConsent, onWithdrawConsent, onGiveConsent }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.collection}>
      <div className={styles.item} key="consents-header">
        <span className={styles.header}>{t("Consents.AcceptedAt")}</span>
        <span className={styles.header}>{t("Consents.Title")}</span>
        <span className={styles.header}>{t("Consents.Status")}</span>
        <span className={styles.header}>{t("Consents.Until")}</span>
        <span/>
      </div>
      {
        consents.map((consent, index) => (
          <div className={styles.item} key={index}>
            <ConsentItem
              consent={consent}
              displayConsent={displayConsent}
              onWithdrawConsent={onWithdrawConsent}
              onGiveConsent={onGiveConsent}
            />
          </div>
        ))
      }
    </div>
  )
}

export default ConsentsCollection;
