import React, { FC, useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import OrderMachineGroup, { IOrderMachineGroupData } from "../../../Groups/Order/OrderMachineGroup/OrderMachineGroup";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store/Store";
import { IColumn } from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import { getMachines as getMachinesAction, selectMachine } from '../../../../slices/OrderSlice';

interface Props {
  extractData?: (data: IOrderMachineGroupData) => void;
  clearData?: boolean;
}

const OrderMachineGroupSeedComponent: FC<Props> = ({ extractData, clearData}) => {
  const { t } = useTranslation();
  const { appUrl } = (window as any)["appConfig"];

  const Order = useSelector((state: IAppState) => state.orderState.Order);
  const machineVisibilitySettings = Order.visibilitySettings?.ShowSelectMachine;
  const showMachineId = Order.visibilitySettings?.ShowSelectMachine?.ShowMachineId;
  const selectedCustomerGuid = Order.selectedCustomer?.customerGuid;

  const General = useSelector((state: IAppState) => state.generalState.General);
  const { currentAuth0Token: token, currentUserEmail: email } = General;


  const dispatch = useDispatch();

  const getMachines = useCallback((token: string, appUrl: string, customerGuid: string, email: string) => {
    dispatch(getMachinesAction({ token, appUrl, customerGuid, email}))
  }, [dispatch]);

  const handleSelectedMachine = useCallback((machineGuid) => {
    dispatch(selectMachine(machineGuid))
  }, [dispatch]);


  let machineSearchColumns = [] as IColumn[];
  machineSearchColumns.push({ field: "ModelType", header: t("MachineSelect.ModelType"), width: 170, uniqueKey:"ModelType" });
  machineSearchColumns.push({ field: "MachineNo", header: t("MachineSelect.MachineNo"), width: 170, uniqueKey:"MachineNo" });
  showMachineId && machineSearchColumns.push({ field: "MachineId", header: t("MachineSelect.IdNo"), width: 70, uniqueKey:"MachineId" });
  machineSearchColumns.push({ field: "Location", header: t("MachineSelect.Location"), width: 150, uniqueKey:"Location" });
  machineSearchColumns.push({ field: "Address1", header: t("MachineSelect.Address1"), width: 150, uniqueKey:"Address1" });
  machineSearchColumns.push({ field: "PostDescription", header: t("MachineSelect.PostLocation"), width: 150, uniqueKey:"PostalDescription" });
  machineSearchColumns.push({ field: "CustomerNo", header: t("Machine.CustomerNo"), width: 150, uniqueKey:"customerNo" });
  machineSearchColumns.push({ field: "AddressName", header: t("MachineSelect.VisitName"), width: 150, uniqueKey:"AddressName" });
  machineVisibilitySettings.ShowSortGroup1 && machineVisibilitySettings.SortGroup1Caption.length > 0 && machineSearchColumns.push({ field: "SortGroup1", header: machineVisibilitySettings.SortGroup1Caption, width: 60, uniqueKey:"SortGroup1" });
  machineVisibilitySettings.ShowSortGroup2 && machineVisibilitySettings.SortGroup2Caption.length > 0 && machineSearchColumns.push({ field: "SortGroup2", header: machineVisibilitySettings.SortGroup2Caption, width: 60, uniqueKey:"SortGroup2" });
  machineVisibilitySettings.ShowSortGroup3 && machineVisibilitySettings.SortGroup3Caption.length > 0 && machineSearchColumns.push({ field: "SortGroup3", header: machineVisibilitySettings.SortGroup3Caption, width: 60, uniqueKey:"SortGroup3" });
  machineVisibilitySettings.ShowSortGroup4 && machineVisibilitySettings.SortGroup4Caption.length > 0 && machineSearchColumns.push({ field: "SortGroup4", header: machineVisibilitySettings.SortGroup4Caption, width: 60, uniqueKey:"SortGroup4" });
  machineVisibilitySettings.ShowSortGroup5 && machineVisibilitySettings.SortGroup5Caption.length > 0 && machineSearchColumns.push({ field: "SortGroup5", header: machineVisibilitySettings.SortGroup5Caption, width: 60, uniqueKey:"SortGroup5" });


  useEffect(() => {
    if(token && selectedCustomerGuid && selectedCustomerGuid !== ""){
      getMachines(token, appUrl, selectedCustomerGuid, email);
    }
  }, [token, getMachines, selectedCustomerGuid, email, appUrl]);
  
  return (
    <OrderMachineGroup
      machinesList={Order.machines}
      machineSelectColumns={machineSearchColumns}
      extractData={extractData}
      handleSelectedMachine={handleSelectedMachine}
      clearData={clearData}
    />
  )
}

export default OrderMachineGroupSeedComponent;
