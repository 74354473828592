import * as React from "react";
import LoginPage from "../../Pages/LoginPage/LoginPage";
import * as ReactRedux from 'react-redux';

interface IProps{
  clearStore: () => void;
}

const LoginGroupSeedComponent: React.FunctionComponent<IProps> = (props) => {
  return (
    <>
      <LoginPage />
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearStore: () => dispatch({type: "LOGOUT"})
  }
}

export default ReactRedux.connect(null,mapDispatchToProps)(LoginGroupSeedComponent);