import * as React from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import "@progress/kendo-theme-material/dist/all.scss";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ModalContext } from "../../Auxiliary/AsolviModal/AsolviModal";
import { DateInputsPopupSettings } from "@progress/kendo-react-dateinputs/dist/npm/PopupSettings";

export interface IProps{
    labelText?: string,
    noLabel?: boolean,
    inputValue?: Date,
    onSelect?: (e: any) => void,
    required?: boolean
}
const AsolviDatepicker: React.FunctionComponent<IProps> = (Props) => {
    const context = React.useContext(ModalContext);
    const today = new Date();
    let popupSettings = {} as DateInputsPopupSettings;
    if(context && context.current) {
        popupSettings.appendTo = context.current
    }

    const datePicker = (
        <DatePicker  
            popupSettings={popupSettings}
            defaultValue={Props.inputValue ?? today}
            defaultShow={false}
            onChange={Props.onSelect}
            required={Props.required?? false}
            />
    );

    if(Props.noLabel) {
        return (
            <Row>
                <Col sm={12}>
                    {datePicker}
                </Col>
            </Row>
        );
    } else {
        return (
            <Row>
                <Col sm={4}>
                    <label>
                        {Props.labelText}
                    </label>
                </Col>
                <Col sm={8}>
                    {datePicker}
                </Col>  
            </Row>
        );
    }
}

export default AsolviDatepicker;