import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AsolviInput from "../../../Elements/AsolviInput/AsolviInput";
import "./TaskMachineGroup.css";
import AsolviAdvancedDropdownList, { IColumn } from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import { ITaskPostalCode, ITaskMachineItem } from "../../../../reducers/TaskReducer";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';

interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  machinesList: ITaskMachineItem[];
  machineSelectColumns: IColumn[];
  postCodeItems: ITaskPostalCode[];
  postCodeSelectColumns: IColumn[];
  extractData?: (data: ITaskMachineGroupData) => void;
  handleSelectedMachine?: (selectedMachine: string) => void;
  clearData?: boolean;
}

export interface ITaskMachineGroupData {
  MachineGuid: string | undefined,
  MachineNo: string | undefined,
  ModelType: string | undefined,
  AddressName: string | undefined,
  Address1: string | undefined,
  Address2: string | undefined,
  Address3: string | undefined,
  AddressCountry: string | undefined;
  Location: string | undefined,
  PostalCode: string | undefined,
  PostalDescription: string | undefined

}

const TaskMachineGroup: React.FunctionComponent<IProps> = (props) => {
  const { t, extractData, handleSelectedMachine, machinesList, clearData } = props;
  const [machineGuid, setMachineGuid] = React.useState<string>();
  const [visitName, setVisitName] = useState<string>();
  const [address1, setAddress1] = useState<string>();
  const [address2, setAddress2] = useState<string>();
  const [address3, setAddress3] = useState<string>();
  const [machineLocation, setMachineLocation] = useState<string>();
  const [selectedPostCode, setSelectedPostCode] = useState<ITaskPostalCode>();
  const [selectedMachine, setSelectedMachine] = useState<ITaskMachineItem>();

  const updateFields = useCallback((e: any) => {
    setSelectedMachine(e);
    setMachineGuid(e === null ? "" : e.MachineGuid);
    setVisitName(e === null ? "" : e.AddressName);
    setAddress1(e === null ? "" : e.Address1);
    setAddress2(e === null ? "" : e.Address2);
    setAddress3(e === null ? "" : e.Address3);
    setMachineLocation(e === null ? "" : e.Location);

    const postItem = (e !== null) && props.postCodeItems.find((postcode: ITaskPostalCode) => postcode.Code === e.PostalCode);
    !postItem && setSelectedPostCode(undefined);
    postItem && setSelectedPostCode(postItem);

  },[props.postCodeItems]);
    
  useEffect(() => {
    const sendData = () => {
      if(extractData)
      {
        let data = {} as ITaskMachineGroupData;
        data.MachineGuid = selectedMachine?.MachineGuid;
        data.MachineNo = selectedMachine?.MachineNo;
        data.ModelType = selectedMachine?.ModelType
        data.AddressName = visitName;
        data.Address1 = address1;
        data.Address2 = address2;
        data.Address3 = address3
        data.AddressCountry = selectedMachine?.AddressCountry;
        data.PostalCode = selectedPostCode?.Code;
        data.PostalDescription = selectedPostCode?.Description;
        data.Location = machineLocation;
  
        extractData(data);
      }
    }
    sendData();
  },[selectedMachine, selectedPostCode, visitName, address1, address2, address3, machineLocation, extractData])

  useEffect(() => {
    if (handleSelectedMachine) {        
        handleSelectedMachine(machineGuid?? "");
    }
  }, [machineGuid, handleSelectedMachine]); 

  const setNoData = useCallback(() => {
    const nodata = {MachineGuid: "", MachineNo: "", 
                AddressName: "", Address1: "", Address2: "", Address3: "", 
                Location: "", PostalCode: "",  PostalDescription: "" } as ITaskMachineGroupData;
    updateFields(nodata);
  },[updateFields]);
  
  useEffect(() => {    
    setNoData();
  }, [machinesList, setNoData]);

  useEffect(() => {
    if (clearData !== undefined && clearData) {                  
      setNoData();
    }
  }, [clearData, setNoData]);

  return (
    <Container fluid>
      <Row>
        <Col sm={6} >
          <AsolviAdvancedDropdownList
            labelText={t("MachineSelect.SelectLabel")}
            shownText={"MachineNo"}
            columns={ props.machineSelectColumns }
            data={machinesList}
            onSelect={(e: any) => { updateFields(e.target.value); }}
            selectedItem={selectedMachine}
          />
        </Col>
        <Col sm={6} />
      </Row>
      <Row>
        <Col sm={6}>
          <AsolviInput labelText={t("MachineSelect.VisitName")} onChange={(e: any) => { setVisitName(e.target.value) } } inputValue={visitName} />
        </Col>
        <Col sm={6}>
          <AsolviInput labelText={t("MachineSelect.Address1")} onChange={(e:any) => { setAddress1(e.target.value); }}  inputValue={address1} />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <AsolviInput labelText={t("MachineSelect.Address2")} onChange={(e:any) => { setAddress2(e.target.value); }}  inputValue={address2} />
        </Col>  
        <Col sm={6}>
          <AsolviInput labelText={t("MachineSelect.Address3")} onChange={(e:any) => { setAddress3(e.target.value); }}  inputValue={address3} />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <AsolviAdvancedDropdownList
            labelText={t("MachineSelect.PostCode")}
            shownText={"CodeAndDescription"}
            columns={props.postCodeSelectColumns}
            data={props.postCodeItems}
            onSelect={(e: any) => { setSelectedPostCode(e.target.value); }}
            selectedItem={selectedPostCode}
          />
        </Col>
        <Col sm={6}>
          <AsolviInput labelText={t("MachineSelect.Location")} onChange={(e:any) => { setMachineLocation(e.target.value); }}  inputValue={machineLocation} />
        </Col>
        <Col sm={6} />
      </Row>
    </Container>
  );
}

export default (ReactI18Next.withTranslation()(TaskMachineGroup));