import * as React from "react";
import "./AsolviNotification.css";

import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import "@progress/kendo-theme-material/dist/all.scss";

export type notifyStyleType = 'success' | 'warning' | 'info' | 'none' | 'error' | undefined;

export interface Props{    
    message: string,
    showNotify: boolean,
    showIcon: boolean
    closeAction: () => void,
    style: notifyStyleType,
    setPosition?: any
}

const position = {
    topLeft: { top: 0, left: 0, alignItems: 'flex-start' },
    topCenter: { top: 0, left: '50%', transform: 'translateX(-50%)' },
    topRight: { top: 0, right: 0, alignItems: 'flex-end' },
    bottomLeft: { bottom: 0, left: 0, alignItems: 'flex-start' },
    bottomCenter: { bottom: 0, left: '50%', transform: 'translateX(-50%)' },
    bottomMedium: { bottom: '10%', left: '50%', transform: 'translateX(-50%)' },
    bottomRight: { bottom: 0, right: 0, alignItems: 'flex-end' }
};

class AsolviNotification extends React.Component<Props, object> {    
    
    render() {
        const { message, showNotify, showIcon, style, closeAction, setPosition } = this.props;
          return (
            <React.Fragment>
            <Fade enter={true} exit={true}>
            {showNotify &&
            <NotificationGroup style={setPosition ?? position.bottomMedium}>
            <Notification
                type={{ style: style, icon: showIcon }}
                closable={true}
                onClose={closeAction} 
            >
                <span>{message}</span>
            </Notification>
            </NotificationGroup>}
        </Fade>
        </React.Fragment>
        );
    }
}

export default AsolviNotification;