import React, { FC } from "react";
import OrderMeterReadingGroup from "../../../Groups/Order/OrderMeterReadingGroup";
import { IMachineInfo } from "../../Pages/MeterReadingPageSeedComponent";
import OrderSummaryGroupSeedComponent from "./OrderSummaryGroupSeedComponent";
import { IMeterReportingSettings } from "../../../../sources/MachineMeterReportingSettings";

interface Props {
  machinesMetersNeeded?: IMachineInfo[]
  meterReadingSettings?: IMeterReportingSettings
  onAcceptMeterReadings: (readings: IMachineInfo[]) => void
}

const OrderSummaryModalSeedComponent: FC<Props> = ({machinesMetersNeeded, meterReadingSettings, onAcceptMeterReadings}) => {

  if(machinesMetersNeeded && machinesMetersNeeded.length > 0) {
    return (
      <OrderMeterReadingGroup
        machines={machinesMetersNeeded}
        meterReadingDateReadOnly={meterReadingSettings?.MeterReadingDateReadOnly ?? false}
        showMachineId={meterReadingSettings?.ShowMachineId ?? false}
        onAccept={onAcceptMeterReadings}
      />
    );
  } else {
    return (
      <OrderSummaryGroupSeedComponent />
    )
  }
};

export default OrderSummaryModalSeedComponent;
