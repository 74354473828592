import * as React from 'react';
import FooterGroup from "../../Groups/FooterGroup/FooterGroup";
import * as Store from "../../../store/Store";
import * as ReactRedux from 'react-redux';
import * as ReactI18Next from 'react-i18next';
import logoImage from "../../../assets/Images/evatic_logo.png";
import * as SettingsReducer from "../../../reducers/SettingsReducer";

interface IProps extends ReactI18Next.WithTranslation {    
    Settings: SettingsReducer.ISettings;
}

const FooterGroupSeedComponent: React.FunctionComponent<IProps> = (Props) => {
    const buildInfo = Props.Settings.buildInfo?JSON.parse(Props.Settings.buildInfo):"";
    return (
        <>
            <FooterGroup
                logoUrl={logoImage} appName={buildInfo.ApplicationName} buildNumber={buildInfo.Version} buildDate={buildInfo.VersionDate} 
                companyName={buildInfo.CompanyName} year={buildInfo.Year}
            />
        </>
    )
}

const mapStateToProps = (store: Store.IAppState) => {
    return {
        Settings: store.settingsState.Settings,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ReactI18Next.withTranslation()(FooterGroupSeedComponent));