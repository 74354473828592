import * as React from 'react';
import "./AsolviRadioGroup.css";
import {RadioButtonProps, RadioGroup } from '@progress/kendo-react-inputs';
import "@progress/kendo-theme-material/dist/all.scss";

export interface IProps{
    data?: Array<RadioButtonProps>;
    layout?: 'horizontal' | 'vertical';
    value?: any;
    disabled?:boolean;
    onChange?: (e: any) => void;
}

const AsolviRadioGroup: React.FunctionComponent<IProps> = (props) => {
    const handleChange = (e:any) => {
        if (props.onChange) {
            props.onChange(e.value);
        }
    };
    
    return (        
        <RadioGroup         
            value={props.value}
            layout={props.layout}
            data = {props.data}
            disabled = {props.disabled}
            onChange = {handleChange}
        />
    );
}

export default AsolviRadioGroup;