import * as React from 'react';
import styles from "./AsolviDrawer.module.css";
import { withRouter } from 'react-router-dom';
import { Drawer, DrawerContent, DrawerSelectEvent } from '@progress/kendo-react-layout';
import { AsolviDrawerItem } from "../../seedComponents/Groups/DrawerGroupSeedComponent"; 
import NavBarGroupSeedComponent from "../../seedComponents/Groups/NavBarGroupSeedComponent";
import { Container, Row } from "react-bootstrap";

export type ModeEnums = "overlay" | "push" | undefined;
export type PositionEnums = "start" | undefined;

export interface IProps {
    expanded?: boolean;
    position?: PositionEnums;
    mode?: ModeEnums;
    animation?: { duration: 400 };
    items: AsolviDrawerItem[];
    mini: boolean;
    frameUrl: string;
    history: any;
    location: any;
    children: any;
    match: any;
    className?: string;
    onSelect?: (ev: DrawerSelectEvent) => void;
  }

const AsolviDrawer: React.FunctionComponent<IProps> = (props) =>{
    const [expanded, setExpanded] = React.useState(true);

    const onSelect = (e: DrawerSelectEvent) => {
        if(e.itemTarget.props.route === "/")
            setExpanded(!expanded);
        else{
            props.history.push(e.itemTarget.props.route);
            if(props.onSelect !== undefined)
                props.onSelect(e);
        }
    }
  
    const setSelectedItem = (pathName: string) => {
        let currentPath: any = props.items.find(item => item.path === pathName);
        if (currentPath && currentPath.text) {
            return currentPath.text;
        }
    }
    let selected = setSelectedItem(props.location.pathname);
    
  return (
    <Drawer
      expanded={expanded}
      position={props.position}
      mode={props.mode}
      animation={props.animation}
      mini={props.mini}
      miniWidth={65}
      width={300}
      items={props.items.map(
                (item:AsolviDrawerItem) => ({ ...item, selected: item.text === selected }))}
      onSelect={onSelect}
        >
      <DrawerContent>
        <Container fluid className={styles.fullHeightContainer}>
            <Row className={styles.staticRow}>
              <NavBarGroupSeedComponent />
            </Row>
            <Row className={styles.flexRow}>
              {props.children}
            </Row>
          </Container>
      </DrawerContent>
    </Drawer>
  );
}

export default withRouter(AsolviDrawer);