import { CompositeFilterDescriptor, DataResult, GroupDescriptor, process, SortDescriptor } from "@progress/kendo-data-query";
import { setExpandedState, setGroupIds } from "@progress/kendo-react-data-tools";
import { GridExpandChangeEvent, GridFilterChangeEvent, GridGroupChangeEvent, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { useCallback, useEffect, useState } from "react";
import { IGridCompositeFilter, IGridGroup, IGridSort } from "./interfaces";

export interface GridDataProcessingOptions<T> {
  data: T[];
  dataKeyField: Extract<keyof T, string>;
  editType: "always" | "click" | "cell" | "manual";
  editField: string;
  defaultGroupBy?: IGridGroup<T>[];
  groupable?: boolean;
  lockedGroups: boolean;
  defaultSort?: IGridSort<T>[];
  sortable?: boolean;
  defaultFilter?: IGridCompositeFilter<T>;
  filterable?: boolean;
}

export interface GridDataProcessingResult<T> {
  state: GridData<T>;

  editRow(item: T): void;
  finishEditRow(item: T): void;

  groupState: GroupDescriptor[];
  onGroupChange?(e: GridGroupChangeEvent): void;
  
  sortState: SortDescriptor[];
  onSortChange(e: GridSortChangeEvent): void;

  filterState: CompositeFilterDescriptor;
  onFilterChange(e: GridFilterChangeEvent): void;

  onExpandChange(e: GridExpandChangeEvent): void;
  toggleGroup(groupId: string): void;
}

export interface TypedDataResult<T> extends Omit<DataResult, "data"> {
  data: T[]
}

export type GridData<T> = T[] | TypedDataResult<T>[];

export default function useGridDataProcessing<T>({ data, dataKeyField, editType, editField, defaultGroupBy, groupable, lockedGroups, defaultSort, sortable, defaultFilter, filterable }: GridDataProcessingOptions<T>): GridDataProcessingResult<T> {
  const [editingRows, setEditingRows] = useState<Set<any>>(new Set());
  const [filterState, setFilterState] = useState<CompositeFilterDescriptor>(defaultFilter ?? { logic: "and", filters: [] });
  const [groupState, setGroupState] = useState<GroupDescriptor[]>(defaultGroupBy ?? []);
  const [sortState, setSortState] = useState<SortDescriptor[]>(defaultSort ?? []);
  const [collapsedState, setCollapsedState] = useState<string[]>([]);
  const [state, setState] = useState<GridData<T>>(data);

  const onGroupChange = useCallback((e: GridGroupChangeEvent) => {
    let hasDuplicates = e.group.some((x,i1) => e.group.find((y,i2) => i1 !== i2 && x.field === y.field));
    if(!hasDuplicates)
      setGroupState(e.group);
  }, [setGroupState]);

  const onSortChange = useCallback((e: GridSortChangeEvent) => {
    setSortState(e.sort);
  }, [setSortState]);

  const onFilterChange = useCallback((e: GridFilterChangeEvent) => {
    if(e.filter)
      setFilterState(e.filter);
    else
      setFilterState({ logic: "and", filters: [] });
      
  }, [setFilterState]);

  const onExpandChange = useCallback((e: GridExpandChangeEvent) => {
    let item = e.dataItem;
    if (!item.groupId)
      return;

    let nextState = [...collapsedState];
    if (e.value)
      nextState = nextState.filter(groupId => groupId !== item.groupId);
    else
      nextState.push(item.groupId);
    setCollapsedState(nextState);
  }, [collapsedState, setCollapsedState]);

  const toggleGroup = useCallback((groupId: string) => {
    let index = collapsedState.findIndex(x => groupId === x);
    if(index === -1) {
      setCollapsedState(collapsedState.concat(groupId));
    } else {
      setCollapsedState(collapsedState.filter((_,i) => i !== index));
    }
  }, [collapsedState, setCollapsedState]);

  useEffect(() => {
    if(editType === "always") {
      let set = new Set(data.map(x => dataKeyField ? x[dataKeyField] : x));
      setEditingRows(set);
    } else {
      let alreadyEditableRows = data.filter((x: any) => x[editField]) as T[];
      let set = new Set(alreadyEditableRows.map(x => dataKeyField ? x[dataKeyField] : x));
      setEditingRows(set);
    }
  }, [data, dataKeyField, editField, editType, setEditingRows]);

  useEffect(() => {
    let editableData = data.map(x => ({
      ...x,
      [editField]: dataKeyField ? editingRows.has(x[dataKeyField]) : editingRows.has(x)
    }));
    let group: GroupDescriptor[] = [];
    if (groupable && groupState.length > 0) {
      group = groupState;
    }

    let sort: SortDescriptor[] = [];
    if (sortable && sortState.length > 0) {
      sort = sortState;
    }

    let filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
    if (filterable && filterState.filters?.length > 0) {
      filter = filterState;
    }

    let newState: TypedDataResult<T> = process(editableData, {
      group,
      sort,
      filter
    });
    setGroupIds({ data: newState.data, group: groupState });
    let expandedNewState = setExpandedState({ data: newState.data, collapsedIds: collapsedState }) as TypedDataResult<T>[];

    setState(expandedNewState);
  }, [data, dataKeyField, editingRows, sortable, filterable, groupable, groupState, filterState, sortState, collapsedState, setState, editField]);

  return {
    state,

    editRow(item) {
      var id = dataKeyField ? item[dataKeyField] : item;
      var newRows = new Set(editingRows);
      newRows.add(id);
      setEditingRows(newRows);
    },
    finishEditRow(item) {
      var id = dataKeyField ? item[dataKeyField] : item;
      if(!editingRows.has(id)) return;

      var newRows = new Set(editingRows);
      newRows.delete(id);
      setEditingRows(newRows);
    },

    groupState,
    onGroupChange: lockedGroups ? undefined : onGroupChange,

    sortState,
    onSortChange,

    filterState,
    onFilterChange,

    onExpandChange,
    toggleGroup
  };
}