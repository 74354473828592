interface SettingDetails {
    Name: string,
    Value: string,
    ValueBool: boolean,
}

export default function (itemToDisplay: string, defaultSettings: any) {
    let result: boolean = true;
    if (defaultSettings) {
        const settings: Array<SettingDetails> = JSON.parse(defaultSettings);
        for (let setting of settings) {
            if (setting.Name === itemToDisplay) {
                result = setting.ValueBool;
            }
        }
    }
    
    return result;
}
