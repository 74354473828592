import Axios from "axios";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateOrderDeliveryAddress as updateOrderDeliveryAddressAction,
  updateOrderHeading as updateOrderHeadingAction,
  updateOrderInvoiceAddress as updateOrderInvoiceAddressAction,
} from "../../../../../slices/OrderSlice";
import { IAppState } from "../../../../../store/Store";
import { ICustomerVisitAddress } from "../../../Pages/MachinePageSeedComponent";
import * as ApiEndPoints from "../../../../../sources/apiEndPoints";
import OrderHeadingGroup from "../../../../Groups/Order/OrderSummaryGroup/OrderHeadingGroup";

export interface IProps {
}

export interface IOrderCreateEmailReceiptLabels {
  ArticleNoLabel: string;
  ArticleDescriptionLabel: string;
  MachineNoLabel: string;
  ModelTypeLabel: string;
  QtyLabel: string;
}

export interface ICustomerContact {
  CustomerGuid: string;
  ContactNo: number;
  FirstName: string;
  LastName: string;
  FullName: string;
  Phone: string;
  FaxNo: string;
  Email: string;
}

const OrderHeadingGroupSeedComponent: FC<IProps> = () => {
  const { appUrl } = (window as any)["appConfig"];

  const { currentAuth0Token: token, currentUserEmail: email, activeCustomer: loggedInCustomerGuid } = useSelector((state: IAppState) => state.generalState.General);
  const currentOrder = useSelector((state: IAppState) => state.orderState.Order.currentOrder);
  const shouldShowCurrentOrder = useSelector((state: IAppState) => state.orderState.Order.shouldShowCurrentOrder);
  const visibilitySettings = useSelector((state: IAppState) => state.orderState.Order.visibilitySettings);
  const orderLines = useSelector((state: IAppState) => state.orderState.Order.currentOrder.Lines);
  const machines = useSelector((state: IAppState) => state.orderState.Order.machines);

  const dispatch = useDispatch();
  const updateOrderHeading = useCallback(head => dispatch(updateOrderHeadingAction(head)), [dispatch]);
  const updateOrderDeliveryAddress = useCallback(address => dispatch(updateOrderDeliveryAddressAction(address)), [dispatch]);
  const updateOrderInvoiceAddress = useCallback(address => dispatch(updateOrderInvoiceAddressAction(address)), [dispatch]);

  const [contacts, setContacts] = useState<ICustomerContact[]>([]);
  const [visitAddresses, setVisitAddresses] = useState<ICustomerVisitAddress[]>([]);
  const [invoiceAddresses, setInvoiceAddresses] = useState<ICustomerVisitAddress[]>([]);

  const headingCustomerGuid = currentOrder.Heading.CustomerGuid;

  useEffect(() => {
    if(!shouldShowCurrentOrder || !appUrl || !token || (!loggedInCustomerGuid && !headingCustomerGuid)) return;
    
    let finished = false;
    let controller = new AbortController();
    getContacts(token, appUrl, headingCustomerGuid ?? loggedInCustomerGuid, email, controller)
      .then(x => {
        if(finished) return;
        setContacts(x)
      });

    getVisitAddresses(token, appUrl, headingCustomerGuid ?? loggedInCustomerGuid, email, controller)
      .then(x => {
        if(finished) return;
        setVisitAddresses(x)
      });

    getInvoiceAddresses(token, appUrl, headingCustomerGuid ?? loggedInCustomerGuid, email, controller)
      .then(x => {
        if(finished) return;
        setInvoiceAddresses(x)
      });

    return () => {
      controller.abort();
      finished = true;
    };
  }, [appUrl, token, email, loggedInCustomerGuid, headingCustomerGuid, setContacts, setVisitAddresses, setInvoiceAddresses, shouldShowCurrentOrder]);

  return (
    <OrderHeadingGroup
      heading={currentOrder.Heading}
      contacts={contacts}
      deliveryAddresses={visitAddresses}
      invoiceAddresses={invoiceAddresses}
      visibilitySettings={visibilitySettings}
      machines={machines}
      orderLines={orderLines}
      onChange={updateOrderHeading}
      onDeliveryAddressChange={(address) => updateOrderDeliveryAddress(address)}
      onInvoiceAddressChange={(address) => updateOrderInvoiceAddress(address)}
    />
  )
}

const getContacts = async (token: string, appUrl: string, loggedInCustomerGuid: string, email: string, controller: AbortController) => {
  const response = await Axios.get<ICustomerContact[]>(appUrl + ApiEndPoints.CustomerAPIs.GetContacts(loggedInCustomerGuid, email), { headers: { token }, signal: controller.signal });
  return response.data;
};

const getVisitAddresses = async (token: string, appUrl: string, loggedInCustomerGuid: string, email: string, controller: AbortController) => {
  const response = await Axios.get<ICustomerVisitAddress[]>(appUrl + ApiEndPoints.CustomerAPIs.GetVisitAddresses(loggedInCustomerGuid, email), { headers: { token }, signal: controller.signal });
  return response.data;
};

const getInvoiceAddresses = async (token: string, appUrl: string, loggedInCustomerGuid: string, email: string, controller: AbortController) => {
  const response = await Axios.get<ICustomerVisitAddress[]>(appUrl + ApiEndPoints.CustomerAPIs.GetInvoiceAddresses(loggedInCustomerGuid, email), { headers: { token }, signal: controller.signal });
  return response.data;
};

export default OrderHeadingGroupSeedComponent;