import * as Redux from 'redux';
import * as ReduxThunk from 'redux-thunk';
import * as GeneralReducer from "../reducers/GeneralReducer";
import * as Axios from 'axios';
import * as ApiEndPoints from "../sources/apiEndPoints";


export enum GeneralActionTypes {
  SHOWSETTINGSPAGE = 'SHOWSETTINGSPAGE',
  SKIPSETTINGSPAGE = 'SKIPSETTINGSPAGE',
  RESETPASSWORD = 'RESETPASSWORD',
  HANDLEACCESSDENIEDMODAL = 'HANDLEACCESSDENIEDMODAL',
  HANDLESELECTEDDATABASE = 'HANDLESELECTEDDATABASE',
  HANDLESELECTEDLANGUAGE = 'HANDLESELECTEDLANGUAGE',
  HANDLESELECTEDCUSTOMER = 'HANDLESELECTEDCUSTOMER',
  HANDLEPASSWORDRESET = 'HANDLEPASSWORDRESET',
  GETACTIVECUSTOMERINFO = 'GETACTIVECUSTOMERINFO',
  SAVEAUTH0TOKENTOSTORE = 'SAVEAUTH0TOKENTOSTORE',
  SAVECURRENTUSEREMAILTOSTORE = 'SAVECURRENTUSEREMAILTOSTORE'
}

interface IShowSettingsPage {
  type: GeneralActionTypes.SHOWSETTINGSPAGE;
  General: GeneralReducer.IGeneral;
}

interface ISkipSettingsPage {
  type: GeneralActionTypes.SKIPSETTINGSPAGE;
  General: GeneralReducer.IGeneral;
}

interface IResetPassword {
  type: GeneralActionTypes.RESETPASSWORD;
}

interface IHandleAccessDeniedModal {
  type: GeneralActionTypes.HANDLEACCESSDENIEDMODAL;
  General: GeneralReducer.IGeneral;
}

interface IHandleSelectedDatabase{
  type: GeneralActionTypes.HANDLESELECTEDDATABASE;
  General: GeneralReducer.IGeneral;
}

interface IHandleSelectedLanguage{
  type: GeneralActionTypes.HANDLESELECTEDLANGUAGE;
  General: GeneralReducer.IGeneral;
}

interface IHandleSelectedCustomer{
  type: GeneralActionTypes.HANDLESELECTEDCUSTOMER;
  General: GeneralReducer.IGeneral;
}

interface IHandlePasswordResetModal {
  type: GeneralActionTypes.HANDLEPASSWORDRESET;
  General: GeneralReducer.IGeneral;
}

export interface IGetActiveCustomerInfo {
  type: GeneralActionTypes.GETACTIVECUSTOMERINFO;
  General: GeneralReducer.IGeneral;
}

export interface ISaveAuth0TokenToStore {
  type: GeneralActionTypes.SAVEAUTH0TOKENTOSTORE;
  General: GeneralReducer.IGeneral;
}

export interface ISaveCurrentUserEmailToStore {
  type: GeneralActionTypes.SAVECURRENTUSEREMAILTOSTORE;
  General: GeneralReducer.IGeneral;
}

export type GeneralActions = IShowSettingsPage | ISkipSettingsPage | IResetPassword | IHandleAccessDeniedModal | IHandleSelectedDatabase
  | IHandleSelectedLanguage | IHandleSelectedCustomer | IHandlePasswordResetModal | IGetActiveCustomerInfo | ISaveAuth0TokenToStore |
  ISaveCurrentUserEmailToStore;

export const showSettings: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, GeneralReducer.IGeneralState, null, IShowSettingsPage>
> = () => {
  return (dispatch: Redux.Dispatch) => {
    dispatch({
      General: { showSettingsPage: true },
      type: GeneralActionTypes.SHOWSETTINGSPAGE
    });
  };
};

export const skipSettings: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, GeneralReducer.IGeneralState, null, ISkipSettingsPage>
> = () => {
  return (dispatch: Redux.Dispatch) => {
    dispatch({
      General: { showSettingsPage: false },
      type: GeneralActionTypes.SKIPSETTINGSPAGE
    })
  };
};

export const resetPassword: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, GeneralReducer.IGeneralState, null, IResetPassword>
> = (email: string, issuer: string, clientId: string) => {
  return async (dispatch: Redux.Dispatch) => {

    try {
      await Axios.default.post(issuer + ApiEndPoints.ExternalAPIs.pwdReset(),
        {
          "client_id": clientId,
          "email": email, 
          "connection": "Username-Password-Authentication",
        }
      )
      dispatch({
        type: GeneralActionTypes.RESETPASSWORD
      });
    }
    catch (err) {
      console.log(err);
    }
  }
};

export const handleAccessDeniedModal: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, GeneralReducer.IGeneralState, null, IHandleAccessDeniedModal>
  > = (newAccessDeniedState) => {
    return (dispatch: Redux.Dispatch) => {
      dispatch({
        General: { showAccessDenied: newAccessDeniedState },
        type: GeneralActionTypes.HANDLEACCESSDENIEDMODAL
      })
    };
};

export const handleSelectedDatabase: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, GeneralReducer.IGeneralState, null, IHandleSelectedDatabase>
  > = (selectedDb:string) => {
    return (dispatch: Redux.Dispatch) => {
      dispatch({
        General: { activeDatabase: selectedDb },
        type: GeneralActionTypes.HANDLESELECTEDDATABASE
      })
    };
};

export const handleSelectedLanguage: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, GeneralReducer.IGeneralState, null, IHandleSelectedDatabase>
  > = (selectedLang:string) => {
    return (dispatch: Redux.Dispatch) => {
      dispatch({
        General: { activeLanguage: selectedLang },
        type: GeneralActionTypes.HANDLESELECTEDLANGUAGE
      })
    };
};

export const handleSelectedCustomer: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, GeneralReducer.IGeneralState, null, IHandleSelectedCustomer>
  > = (selectedCustomer:string) => {
    return (dispatch: Redux.Dispatch) => {
      dispatch({
        General: { activeCustomer: selectedCustomer },
        type: GeneralActionTypes.HANDLESELECTEDCUSTOMER
      })
    };
};

export const handlePasswordResetModal: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, GeneralReducer.IGeneralState, null, IHandlePasswordResetModal>
  > = (newPasswordResetState) => {
    return (dispatch: Redux.Dispatch) => {
      dispatch({
        General: { showPasswordReset: newPasswordResetState },
        type: GeneralActionTypes.HANDLEPASSWORDRESET
      })
    };
};

export const GetActiveCustomerInfo: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, GeneralReducer.IGeneralState, null, IGetActiveCustomerInfo>
    > = (token: string, appUrl: string, email: string, customerGuid: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetCustomerInfo(customerGuid, email), { headers: { token: token } });
                dispatch({
                    General: { activeCustomerInfo: JSON.stringify(response.data) },
                    type: GeneralActionTypes.GETACTIVECUSTOMERINFO
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    General: { activeCustomerInfo: null },
                    type: GeneralActionTypes.GETACTIVECUSTOMERINFO
                });
            }
        }
  };
    
export const SaveAuth0TokenToStore: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, GeneralReducer.IGeneralState, null, ISaveAuth0TokenToStore >
  > = (token: string) => {
      return (dispatch: Redux.Dispatch) =>{
          dispatch({
              General : { currentAuth0Token: token },
              type: GeneralActionTypes.SAVEAUTH0TOKENTOSTORE
          })
      };
  };

  export const SaveCurrentUserEmailToStore: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, GeneralReducer.IGeneralState, null, ISaveCurrentUserEmailToStore >
  > = (email: string) => {
      return (dispatch: Redux.Dispatch) =>{
          dispatch({
              General : { currentUserEmail: email },
              type: GeneralActionTypes.SAVECURRENTUSEREMAILTOSTORE
          })
      };
};