import * as React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import * as Store from "../../../../store/Store";
import * as ReactRedux from "react-redux";
import MeterReportingPage from "../../Pages/MeterReportingPage/MeterReportingPage";
import * as MeterLoginReducer from "../../../../reducers/MeterLoginReducer";
import * as MachinesMetersItems from "../../../../sources/MachinesMetersitems";
import * as MeterLoginActions from "../../../../actions/MeterLoginActions";
import * as IMeterReportingSettings from "../../../../sources/MachineMeterReportingSettings";
import AsolviNotification from "../../../Elements/AsolviNotification/AsolviNotification";
import * as I18Next from "i18next";
import * as ReactI18Next from 'react-i18next';
import i18n from "../../../../i18n";
import { parseDate } from '@telerik/kendo-intl';

interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  MeterLogin: MeterLoginReducer.IMeterLogin;
  getMachinesAndMeters: (appUrl: string, token: string) => void;
  getFooterAndBarInfo: (appUrl: string, token: string) => void;
  submitMetersReading: (appUrl: string, token: string, language: string, body: IMachineInfo) => void;
}

interface IGridState {
  data : IMeterInfo[]
}

export interface IMeterInfo {
  meterType: string;
  meterDescription: string;
  meterGuid: string;
  oldCounter?: number;
  oldReadingDate?: string;
  oldReadingDateFormatted?: string;
  newCounter?: number;
  newReadingDate: string;
  newReadingDateFormatted: Date;
  inEdit: boolean
}
 
export interface IMachineInfo {
  modelType: string;
  machineNo: string;
  machineGuid: string;
  machineAddress?: string;
  location?: string;
  meters: IMeterInfo[];
}


  const MeterReportingPageSeedComponent: React.FunctionComponent<IProps> = (Props) => {
    const { t, getMachinesAndMeters, getFooterAndBarInfo } = Props;
    const token = Props.MeterLogin.token;
    const error = Props.MeterLogin.error;
    const footerAndBarInfo = IMeterReportingSettings.default(Props.MeterLogin.barFooterInfo);
    const meterGridEditField = "inEdit";
    const { appUrl } = (window as any)["appConfig"];
    const machinesList = MachinesMetersItems.default(Props.MeterLogin.machinesAndMeters);
    
    const emptyMachine: IMachineInfo = { modelType: "", machineNo: "", machineGuid: "", meters: [], location: "", machineAddress: ""};
    const [selectedMachine, setSelectedMachine] = React.useState(emptyMachine);
    const [gridState, setGridState] = React.useState<IGridState>({ data: selectedMachine.meters });
    const [showSearchGrid, setShowSearchGrid] = React.useState<boolean>(false);
    const [showNotify, setShowNotify] = React.useState<boolean>(false);
    const [notifyMessage, setNotifyMessage] = React.useState<string>("");
    const [notifyStyle, setNotifyStyle] = React.useState<any>(undefined);

    React.useEffect(()=>{
      Props.MeterLogin.error = undefined;
      if(token){
        getMachinesAndMeters(appUrl, token);
        getFooterAndBarInfo(appUrl, token);
      }
    },[token, Props.MeterLogin.error, appUrl, getMachinesAndMeters, getFooterAndBarInfo]);

    React.useEffect(()=>{
      if(error === ""){
        setSelectedMachine(emptyMachine);
        setNotifyMessage(t("MeterReporting.meterReportSuccess"));
        setNotifyStyle('success');
        setShowNotify(true);
      } else if (error !== undefined && error !== ""){
        setNotifyMessage(t(error));
        setNotifyStyle('error');
        setShowNotify(true);
      }
    },[error, t, emptyMachine]);

    const handleSelectedMachine = (event: string) => {
      Props.MeterLogin.error = undefined;
      setShowNotify(false);
      const ix = machinesList.map(m => { return m.machineGuid }).indexOf(event);
      setSelectedMachine(machinesList[ix]);
    }
    
      const handleAcceptButtonClicked = () => {
      // post meters to backend
      Props.MeterLogin.error = undefined;
      Props.submitMetersReading(appUrl,token, i18n.language, selectedMachine);
    }

    const handleMeterGridItemChange = (event: any) => {
      Props.MeterLogin.error = undefined;
      setShowNotify(false);
      const inEditID = event.dataItem.meterGuid;
      const editingDate = (event.field === "newReadingDateFormatted");

      const data = gridState.data.map((item: IMeterInfo) =>
        editingDate ?
          item.meterGuid === inEditID ? { ...item, [event.field]: event.value, "newReadingDate": parseDate(event.value).toDateString() } : item
        :
          item.meterGuid === inEditID ? { ...item, [event.field]: event.value } : item
      );
      setGridState({ data });

      let updatedMachine = selectedMachine;
      const index = updatedMachine.meters.findIndex((record: IMeterInfo) => record.meterGuid === inEditID);
      editingDate ?
        updatedMachine.meters[index] = { ...updatedMachine.meters[index], [event.field]: event.value, "newReadingDate": parseDate(event.value).toDateString() }
      :
        updatedMachine.meters[index] = { ...updatedMachine.meters[index], [event.field]: event.value }

      setSelectedMachine(updatedMachine);
    };
      
    const onMachineSearchRowClick = (e: any) => {
      handleSelectedMachine(e.dataItem.machineGuid);
      setShowSearchGrid(false);
    };

    const handleSelectButtonClicked = () => {
      setShowSearchGrid(!showSearchGrid);
    }

    const handleCloseNotification = () => {
      Props.MeterLogin.error = undefined;
      setShowNotify(false);
    }

    return (
      <>
        <Container fluid>
          <Row>
          <AsolviNotification
            message = {notifyMessage}
            showNotify = {showNotify}
            showIcon = {true}
            closeAction = {handleCloseNotification}
            style = {notifyStyle}/>
            <MeterReportingPage
              machineSearchGridItems={machinesList}
              acceptButtonAction={handleAcceptButtonClicked}
              selectedMachineMeters={selectedMachine}
              meterGridItemChange={handleMeterGridItemChange}
              meterGridEditField={meterGridEditField}
              footerAndBarInfo={footerAndBarInfo}
              onMachineSearchRowDoubleClick={onMachineSearchRowClick}
              showSearchGrid={showSearchGrid}
              selectButtonAction={handleSelectButtonClicked}
            />
            </Row>
        </Container>
        
      </>
    )
}
const mapStateToProps = (store: Store.IAppState) => {
  return {
    MeterLogin: store.meterLoginState.MeterLogin,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMachinesAndMeters: (appUrl: string, token: string) => dispatch(MeterLoginActions.GetMachinesAndMeters(appUrl, token)),
    getFooterAndBarInfo: (appUrl: string, token: string) => dispatch(MeterLoginActions.GetFooterAndBarInfo(appUrl, token)),
    submitMetersReading: (appUrl: string, token: string, language: string, body: IMachineInfo) => dispatch(MeterLoginActions.SubmitMetersReading(appUrl, token, language, body))
  }
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ReactI18Next.withTranslation()(MeterReportingPageSeedComponent));