import * as React from "react";
import * as Auth0 from '@auth0/auth0-react';
import { LoginGroup } from "../../Groups/LoginGroup/LoginGroup";

interface IProps{

}

const LoginGroupSeedComponent: React.FunctionComponent<IProps> = () => {
  const loginWithAuth0 = Auth0.useAuth0().loginWithRedirect;

  return <LoginGroup loginButtonAction={loginWithAuth0}/>
}

export default LoginGroupSeedComponent;