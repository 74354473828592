import { AsolviDropDownItem } from "../components/Elements/AsolviDropDownList/AsolviDropDownList";

interface DatabaseDetails {
  Id: string,
  Name: string
}

export default function (dbInformation: any, action: any) {
  const items = [] as Array<AsolviDropDownItem>;
  if (dbInformation) {
    let dbInfo: DatabaseDetails[] = JSON.parse(dbInformation) as DatabaseDetails[];
    for (let db of dbInfo) {
      items.push(
        {
          itemText: db.Name,
          itemAction: action,
          itemImage: undefined,
          itemId: db.Id
        } as AsolviDropDownItem
      );
    }
  }

  return items;
}
