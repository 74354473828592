import TaskPageSeedComponent from "./Pages/TaskPageSeedComponent";
import MeterReadingPageSeedComponent from "./Pages/MeterReadingPageSeedComponent";
import MachinePageSeedComponent from "./Pages/MachinePageSeedComponent";
import OrderPageSeedComponent from "./Pages/OrderPageSeedComponent";

export default new Map<string, any>([
    ['TaskPageSeedComponent', TaskPageSeedComponent],
    ['MeterReadingPageSeedComponent', MeterReadingPageSeedComponent],
    ['MachinePageSeedComponent', MachinePageSeedComponent],
    ['OrderPageSeedComponent', OrderPageSeedComponent],
])