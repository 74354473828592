import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AsolviAdvancedDropdownList from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import AsolviInput from "../../../Elements/AsolviInput/AsolviInput";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import {IColumn} from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import { useEffect } from 'react';
import AsolviLabel from '../../../Elements/AsolviLabel/AsolviLabel';
import { ITaskCustomerItem } from '../../../../reducers/TaskReducer';

export interface ITaskCustomerGroupData{
  CustomerGuid: string | undefined,
  CustomerNo: string | undefined,
  CustomerName: string | undefined,
  Address1: string | undefined,
  Address2: string | undefined,
  PostalCode: string | undefined,
  PostDescription: string | undefined,
  PhoneNumber: string | undefined,
  FaxNumber: string | undefined

}

export interface IProps extends ReactI18Next.WithTranslation{
  t: I18Next.TFunction;
  columnsData: IColumn[];
  dropdownData: Array<any>;
  dropdownShownText: string;
  loggedInCustomer?: string;
  extractData?: (data: ITaskCustomerGroupData) => void;
  handleSelectedCustomer?: (selectedCustomer: string) => void;
}



const TaskCustomerGroup: React.FunctionComponent<IProps> = (Props) =>{
  const{t, extractData, handleSelectedCustomer, dropdownData, loggedInCustomer} = Props
  const [CustomerGuid, setCustomerGuid] = React.useState<string>();
  const [CustomerNo, setCustomerNo] = React.useState<string>();
  const [CustomerName, setCustomerName] = React.useState<string>();
  const [Address1, setAddress1] = React.useState<string>();
  const [Address2, setAddress2] = React.useState<string>();
  const [PostalCode, setPostalCode] = React.useState<string>();
  const [PostDescription, setPostDescription] = React.useState<string>();
  const [PhoneNumber, setPhoneNumber] = React.useState<string>();
  const [FaxNumber, setFaxNumber] = React.useState<string>();
  const [selectedCustomer, setSelectedCustomer] = React.useState<any>();

  const setfields = (e: any) => {
    setSelectedCustomer(e);
    setCustomerGuid(e === null ? "" : e.CustomerGuid);
    setCustomerNo(e === null ? "" : e.CustomerNo);
    setCustomerName(e === null ? "" : e.CustomerName);
    setAddress1(e === null ? "" : e.Address1);
    setAddress2(e === null ? "" : e.Address2);
    setPostalCode(e === null ? "" : e.PostalCode);
    setPostDescription(e === null ? "" : e.PostDescription);
    setPhoneNumber(e === null ? "" : e.AddressPhone);
    setFaxNumber(e === null ? "" : e.AddressFax);
  }

  useEffect(() => {
    const sendData = () =>{
      if(extractData)
      {
        let data = {} as ITaskCustomerGroupData;
        data.CustomerGuid = CustomerGuid;
        data.CustomerNo = CustomerNo;
        data.CustomerName = CustomerName;
        data.Address1 = Address1;
        data.Address2 = Address2;
        data.PostalCode = PostalCode;
        data.PostDescription = PostDescription;
        data.PhoneNumber = PhoneNumber;
        data.FaxNumber = FaxNumber;
        extractData(data);
      }

    }

    sendData();
  }, [CustomerGuid, CustomerNo, CustomerName, Address1, Address2, PostalCode, PostDescription, PhoneNumber, FaxNumber, extractData]);

  useEffect(() => {
    if (handleSelectedCustomer && CustomerGuid) {
      handleSelectedCustomer(CustomerGuid);
    }
  }, [CustomerGuid, handleSelectedCustomer]);

  useEffect(() => {
    const nodata = {CustomerGuid: "", CustomerNo: "", CustomerName: "",
      Address1: "", Address2: "", PostalCode: "", PostDescription: "", AddressPhone: "", AddressFax: ""} as ITaskCustomerItem;
    if (dropdownData && dropdownData.length === 1) {
      //if only one customer->autoselect
      setfields(dropdownData[0]);
    } else if (dropdownData && dropdownData.length > 1 && loggedInCustomer ) {
      //if many, set to logged in customer?
      const tempData = dropdownData.find((item: ITaskCustomerItem) => item.CustomerGuid === loggedInCustomer);
      if (tempData) {
        setfields(tempData);
      } else {
        setfields(nodata);
      }
    } else {
      //set to blank
      setfields(nodata);
    }

  }, [dropdownData, loggedInCustomer]);

  return(
    <Container fluid>
      <Row>
        <Col sm={6}>
          <AsolviAdvancedDropdownList
            labelText={t("TaskCustomerGroup.selectCustomerDropdownLabel")}
            columns={Props.columnsData}
            data={dropdownData}
            shownText={Props.dropdownShownText}
            onSelect={(e) => {setfields(e.target.value); }}
            selectedItem={selectedCustomer}
          />
        </Col>
        <Col sm={6}>
          <AsolviLabel labelText={t("TaskCustomerGroup.customerNumberInputLabel")} value={CustomerNo ? CustomerNo : ""} />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <AsolviInput labelText={t("TaskCustomerGroup.customerNameInputLabel")} inputValue={CustomerName} onChange= {e=>{setCustomerName(e.target.value); }}/>
        </Col>
        <Col sm={6}>

        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <AsolviInput labelText={t("TaskCustomerGroup.customerAddress1Label")} inputValue={Address1} onChange= {e=>{setAddress1(e.target.value); }}/>
        </Col>
        <Col sm={6}>
          <AsolviInput labelText={t("TaskCustomerGroup.customerAddress2Label")} inputValue={Address2} onChange= {e=>{setAddress2(e.target.value); }}/>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <AsolviInput labelText={t("TaskCustomerGroup.customerPostalCodeLabel")} inputValue={PostalCode} onChange= {e=>{setPostalCode(e.target.value); }}/>
        </Col>
        <Col sm={6}>
          <AsolviInput labelText={t("TaskCustomerGroup.customerCityLabel")} inputValue={PostDescription} onChange= {e=>{setPostDescription(e.target.value); }}/>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <AsolviInput labelText={t("TaskCustomerGroup.customerPhoneNumberLabel")} inputValue={PhoneNumber} onChange= {e=>{setPhoneNumber(e.target.value); }}/>
        </Col>
        <Col sm={6}>
          <AsolviInput labelText={t("TaskCustomerGroup.CustomerFaxNumberLabel")} inputValue={FaxNumber} onChange= {e=>{setFaxNumber(e.target.value); }}/>
        </Col>
      </Row>
    </Container>
  );
}

export default ReactI18Next.withTranslation()(TaskCustomerGroup);
