import * as React from "react";
import SettingsGroup from "../../Groups/SettingsGroup/SettingsGroup";
import * as languageDropDownItems from "../../../sources/languageDropDownItems";
import * as databasesDropDownItems from "../../../sources/databaseDropDownItems";
import * as contactDropDownItems from "../../../sources/contactDropDownItems";
import * as SettingsReducer from './../../../reducers/SettingsReducer';
import * as Store from "../../../store/Store";
import * as ReactRedux from "react-redux";
import * as GeneralActions from "../../../actions/GeneralActions";
import * as GeneralReducer from "../../../reducers/GeneralReducer";
import * as DisplaySettings from "../../../sources/settingsDisplay";

interface IProps{
    skipSettingsPage: () => void;    
    Settings: SettingsReducer.ISettings;
    General: GeneralReducer.IGeneral;
    handleSelectedDatabase(selectedDb:string): (selectedDb:string) => void;
    handleSelectedLanguage(selectedLang:string): (selectedLang:string) => void;
    handleSelectedCustomer(selectedCust:string): (selectedCust:string) => void;
    setRenderHomePageSeedComponent: (value: boolean) => void;
}

const SettingsGroupSeedComponent: React.FunctionComponent<IProps> = (Props) => { 
  const hideCustomers = DisplaySettings.default("HIDE_DROPDOWN_CUSTOMER", Props.Settings.defaultSettings);
  const hideDatabase = DisplaySettings.default("HIDE_DROPDOWN_DATABASE",Props.Settings.defaultSettings);
  const hideLanguage = DisplaySettings.default("HIDE_DROPDOWN_LANGUAGE",Props.Settings.defaultSettings);
  const handleContinueButton = () => {
    Props.skipSettingsPage();
    Props.setRenderHomePageSeedComponent(true);
  }
  return (
    <>
      <SettingsGroup
        languageDropDownItems={languageDropDownItems.default(Props.handleSelectedLanguage)}
        databasesDropDownItems={databasesDropDownItems.default(Props.Settings.dbInfo, Props.handleSelectedDatabase)}
        customersDropDownItems={contactDropDownItems.default(Props.Settings.contactDropdownList, Props.handleSelectedCustomer)}
        continueButton={handleContinueButton}
        hideLanguage = {hideLanguage}
        hideDatabase = {hideDatabase}
        hideCustomers = {hideCustomers}
      />
    </>
  )
}

const mapStateToProps = (store: Store.IAppState) => {
  return {
    Settings: store.settingsState.Settings,
    General: store.generalState.General,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
      skipSettingsPage: () => dispatch(GeneralActions.skipSettings()),
      handleSelectedDatabase: (selectedDb:string) => dispatch(GeneralActions.handleSelectedDatabase(selectedDb)),
      handleSelectedLanguage: (selectedLng:string) => dispatch(GeneralActions.handleSelectedLanguage(selectedLng)),
      handleSelectedCustomer: (selectedCust:string) => dispatch(GeneralActions.handleSelectedCustomer(selectedCust)),
  }
}



export default ReactRedux.connect(mapStateToProps,mapDispatchToProps)(SettingsGroupSeedComponent);