import * as React from 'react';
import {PanelBar, PanelBarItem} from "@progress/kendo-react-layout";
import "@progress/kendo-theme-material/dist/all.scss";
import "./AsolviPanelBar.css";

export interface IPanelItem{
    key: number,
    itemTitle: string,
    ItemBody: React.ReactNode,
    expanded: boolean
}

export interface IProps{
    childs: IPanelItem[],
}

const AsolviPanelBar: React.FunctionComponent<IProps> = (props) =>{
    return(
        <PanelBar>
            {props.childs.map((child) => 
                <PanelBarItem expanded={child.expanded} title={child.itemTitle} key={child.key}>
                    {child.ItemBody}
                </PanelBarItem>
            )}
        </PanelBar>
    );
}

export default AsolviPanelBar;