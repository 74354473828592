import * as React from "react";
import { FC } from "react";
import "./AsolviNavBar.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import "@progress/kendo-theme-material/dist/all.scss";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";

export interface Props {
  customerInfo: string,
  customerInfoOrgNo: string,
  logOutFunction: () => void,
  passwordFunction: () => void,
  onShowConsent: () => void,
}

const AsolviNavBar: FC<Props> = ({ customerInfo, customerInfoOrgNo, logOutFunction, passwordFunction }) =>  {
  const { t } = useTranslation();
  const history = useHistory();

  const showConsents = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push("/consents");
  }

  return (
    <Navbar className="asolviNavBar" sticky="top" >
        <div className = "AsolviNavCustomerInfo">
          <div className = "AsolviNavCustomerInfoDiv">
              {t("Standard.customerInfo")} {customerInfo}
          </div>
          <div className = "AsolviNavCustomerInfoDiv">
              {t("Standard.customerInfoOrgNo")} {customerInfoOrgNo}
          </div>
        </div>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Dropdown drop="down" alignRight>
              <Dropdown.Toggle className="profile-button" variant="outline-light">
                <span className="k-icon k-i-user k-icon-size"/>
              </Dropdown.Toggle>
              <Dropdown.Menu flip={true}>
                <Dropdown.Item href="" className="AsolviNavLinkRight" onClick={passwordFunction}>{t("Standard.password")}</Dropdown.Item>

                <Dropdown.Item href="" className="AsolviNavLinkRight" onClick={showConsents} active={false}>{t("Standard.showConsents")}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="" className="AsolviNavLinkRight" onClick={logOutFunction}>{t("Standard.logout")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  )
}

export default AsolviNavBar;
