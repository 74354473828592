import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useApi = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState({
    token: ""
  });
  const [refreshIndex, setRefreshIndex] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setState({
          token: accessToken
        });
      } catch (error) {
        console.log(`UseApi error: ${error}`);
        setState({
          token: ""
        });
      }
    })();
  }, [refreshIndex, getAccessTokenSilently]);

  return {
    ...state,
    refresh: () => setRefreshIndex(refreshIndex + 1),
  };
};