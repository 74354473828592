import React from "react";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import TaskMachineGroup, { ITaskMachineGroupData } from "../../../Groups/Task/TaskMachineGroup/TaskMachineGroup";
import * as TaskReducer from "../../../../reducers/TaskReducer";
import * as ReactRedux from "react-redux";
import * as Store from "../../../../store/Store";
import { IColumn } from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import * as TaskActions from "../../../../actions/TaskActions";

interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;  
  selectedCustomer?: string;
  TaskVisibilitySettings: TaskReducer.ITaskVisibilitySettings;
  TaskPostCodes: TaskReducer.ITaskPostalCodes;
  TaskMachines: TaskReducer.ITaskMachineItem[];
  extractData?: (data: ITaskMachineGroupData) => void;
  GetTaskLimitedPostalCodes(token: string, countries: string[], code: string, email: string, appUrl: string): (token: string, countries: string[], code: string, email: string) => void;
  GetMachinesList(token: string, customer: string, email: string, appUrl: string): (token: string, customer: string, email: string) => void;
  handleSelectedMachine(machineGuid: string): (machineGuid: string)  => void;
  clearData?: boolean;
  currentAuth0Token: string;
  currentUserEmail: string;
}

const TaskMachineGroupSeedComponent: React.FunctionComponent<IProps> = (props) => {
  const { t, GetTaskLimitedPostalCodes, GetMachinesList, selectedCustomer } = props;
  const machineVisibilitySettings = props.TaskVisibilitySettings.ShowSelectMachine;
  const showMachineId = props.TaskVisibilitySettings.ShowSelectMachine.ShowMachineId;
  const postCodesList = props.TaskPostCodes.PostalCodes;  
  const token = props.currentAuth0Token;
  const email = props.currentUserEmail;
  const { appUrl } = (window as any)["appConfig"];
  const [countries, setCountries] = React.useState<string[]>([]);
  const code = "all";
  
  let machineSearchColumns = [] as IColumn[];
  machineSearchColumns.push({ field: "ModelType", header: t("MachineSelect.ModelType"), width: 170, uniqueKey:"ModelType" });
  machineSearchColumns.push({ field: "MachineNo", header: t("MachineSelect.MachineNo"), width: 170, uniqueKey:"MachineNo" });
  showMachineId && machineSearchColumns.push({ field: "MachineId", header: t("MachineSelect.IdNo"), width: 170, uniqueKey:"" });
  machineSearchColumns.push({ field: "Location", header: t("MachineSelect.Location"), width: 170, uniqueKey:"Location" });
  machineSearchColumns.push({ field: "AddressName", header: t("MachineSelect.VisitName"), width: 200, uniqueKey:"AddressName" });
  machineVisibilitySettings.ShowSortGroup1 && machineVisibilitySettings.SortGroup1Caption.length > 0 && machineSearchColumns.push({ field: "SortGroup1", header: machineVisibilitySettings.SortGroup1Caption, width: 120, uniqueKey:"SortGroup1" });
  machineVisibilitySettings.ShowSortGroup2 && machineVisibilitySettings.SortGroup2Caption.length > 0 && machineSearchColumns.push({ field: "SortGroup2", header: machineVisibilitySettings.SortGroup2Caption, width: 120, uniqueKey:"SortGroup2" });
  machineVisibilitySettings.ShowSortGroup3 && machineVisibilitySettings.SortGroup3Caption.length > 0 && machineSearchColumns.push({ field: "SortGroup3", header: machineVisibilitySettings.SortGroup3Caption, width: 120, uniqueKey:"SortGroup3" });
  machineVisibilitySettings.ShowSortGroup4 && machineVisibilitySettings.SortGroup4Caption.length > 0 && machineSearchColumns.push({ field: "SortGroup4", header: machineVisibilitySettings.SortGroup4Caption, width: 120, uniqueKey:"SortGroup4" });
  machineVisibilitySettings.ShowSortGroup5 && machineVisibilitySettings.SortGroup5Caption.length > 0 && machineSearchColumns.push({ field: "SortGroup5", header: machineVisibilitySettings.SortGroup5Caption, width: 120, uniqueKey:"SortGroup5" }); 

  let postCodeSearchColumns = [] as IColumn[];
  postCodeSearchColumns.push({ field: "Code", header: t("MachineSelect.PostCode"), width: 120, uniqueKey:"Code" });
  postCodeSearchColumns.push({ field: "Description", header: t("MachineSelect.PostLocation"), width: 300, uniqueKey:"Description" });

  React.useEffect(() => {
    if(token && selectedCustomer && countries.length>0){
      GetTaskLimitedPostalCodes(token, countries, code, email, appUrl);
    }
  },[countries, GetTaskLimitedPostalCodes, selectedCustomer, token, email, appUrl]);

  React.useEffect(() => { 
    if (selectedCustomer && props.TaskMachines !== undefined && props.TaskMachines.length>0 && countries.length===0) {
      let newCountries=[] as string[];          
      props.TaskMachines.forEach(machine => {
       if(!newCountries.includes(machine.AddressCountry)){         
          newCountries.push(machine.AddressCountry)
         }
       });
       setCountries(newCountries);       
    }   
  }, [props.TaskMachines, countries.length, selectedCustomer]);


  React.useEffect(() => {
    if(token && selectedCustomer && selectedCustomer !== ""){
      GetMachinesList(token, selectedCustomer, email, appUrl);      
    }
  }, [token, GetMachinesList, selectedCustomer, email, appUrl]);
  
  return (
    <TaskMachineGroup
      machinesList={props.TaskMachines}
      machineSelectColumns={machineSearchColumns}
      postCodeItems={postCodesList}
      postCodeSelectColumns={postCodeSearchColumns}
      extractData={props.extractData}
      handleSelectedMachine={props.handleSelectedMachine}
      clearData={props.clearData}
    />
  )
}

const mapStateToProps = (store: Store.IAppState) => {
  return {
    TaskVisibilitySettings: store.taskState.TaskVisibilitySettings,
    TaskPostCodes: store.taskState.TaskPostalCodes,
    TaskMachines: store.taskState.TaskMachines.machinesList,       
    selectedCustomer: store.taskState.SelectedCustomer ?? undefined,
    currentAuth0Token: store.generalState.General.currentAuth0Token,
    currentUserEmail: store.generalState.General.currentUserEmail
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    GetTaskLimitedPostalCodes: (token: string, countries: string[], code: string, email: string, appUrl: string) => dispatch(TaskActions.GetTaskLimitedPostalCodes(token, countries, code, email, appUrl)),
    GetMachinesList: (token: string, customer: string, email: string, appUrl: string) => dispatch(TaskActions.GetMachinesList(token, customer, email, appUrl)),
    handleSelectedMachine: (machineGuid: string) =>
                dispatch(TaskActions.HandleSelectedMachine(machineGuid)),
  }
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ReactI18Next.withTranslation()(TaskMachineGroupSeedComponent));
