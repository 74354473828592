export const ExternalAPIs = {
  TestApi: () => `https://jsonplaceholder.typicode.com/posts/1`,
  pwdReset: () => `/dbconnections/change_password`
}

export const CustomerWebAPIs = {
  GetAvailableDbs: () => `api/Settings/GetDbInfo`,
  GetCustomerWebSettings: (email: string) => `api/Settings/GetCustomerWebSettings/${email}`,
  GetContactList: (email: string) => `api/Settings/GetContacts/${email}`,
  GetContactDropDownList: (email: string) => `api/Settings/GetContactsDropdown/${email}`,
  GetBuildInformation: (email: string) => `api/Settings/GetBuildSettings/${email}`,
  GetMenuItems: (email: string, customerGuid: string) => `api/GeneralSettings/GetMenuItems/${email}/${customerGuid}`,
  GetDepartments: (email: string) => `api/GeneralSettings/GetDepartments/${email}`,
  GetCustomerInfo: (customerGuid: string, email: string) => `api/Settings/GetCustomerInfo/${customerGuid}/${email}`,
  GetAvailableRadioButtonProps: (email: string, customerGuid: string) => `api/Task/GetAvailableRadioButtonProps/${email}/${customerGuid}`,
  GetAvailableInfoLabels: (email: string) => `api/Task/GetAvailableInfoLabels/${email}`,
  GetVisibilitySettings: (email: string) => `api/Task/GetVisibilitySettings/${email}`,
  GetLimitedPostalCodes: (code: string, email: string) => `api/Task/GetLimitedPostalCodes/${code}/${email}`,
  GetSymptomCodesAll: (email: string, taskType: number) => `api/Task/GetSymptomCodesAll/${email}/${taskType}`,
  GetSymptomCodes2: (selectedSymptomCode1: string, email: string, taskType: number) => `api/Task/GetSymptomCodes2/${selectedSymptomCode1}/${email}/${taskType}`,
  GetSymptomCodes3: (selectedSymptomCode1: string, selectedSymptomCode2: string, email: string, taskType: number) => `api/Task/GetSymptomCodes3/${selectedSymptomCode1}/${selectedSymptomCode2}/${email}/${taskType}`,
  GetSymptomCodes4: (selectedSymptomCode1: string, selectedSymptomCode2: string, email: string, taskType: number) => `api/Task/GetSymptomCodes4/${selectedSymptomCode1}/${selectedSymptomCode2}/${email}/${taskType}`,
  CreateTask: (email: string, taskType: string, culture: string, customerGuid: string) => `api/Task/CreateTask/${email}/${taskType}/${culture}/${customerGuid}`,
  GetCustomersLinkedToMain: (customerGuid: string, email: string) => `api/Customer/GetCustomersLinkedToMain/${customerGuid}/${email}`,
  GetCustomerProperties: (customerGuid: string, email: string) => `api/Task/GetCustomerProperties/${customerGuid}/${email}`,
  GetMachineProperties: (machineGuid: string, email: string) => `api/Task/GetMachineProperties/${machineGuid}/${email}`,
  GetContacts: (customerGuid: string, machineGuid: string | null, email: string, taskType: number) => `api/Task/GetContacts/${customerGuid}/${machineGuid}/${email}/${taskType}`,
  GetMachinesList: (customerGuid: string, email: string) => `api/Machine/GetMachines/${customerGuid}/${email}`,
  UpdateContact: (email: string) => `api/Customer/UpdateContact/${email}`,
  GetTaskLog: (customerGuid: string, email: string) => `api/Task/GetLog/${customerGuid}/${email}`,
  GetCustomLoginLink: (culture: string) => `api/GeneralSettings/GetCustomLoginLink/${culture}`,
  GetCustomerFilterSortGroups: (customerGuid: string, email: string) => `api/Customer/GetFilterSortGroups/${customerGuid}/${email}`,
  GetVisitAddresses: (customerGuid: string, email: string) => `api/Customer/GetVisitAddresses/${customerGuid}/${email}`,
}

export const MeterLoginAPIs = {
  GetFooterAndBarInfo: (token: string) => `api/MeterReading/GetBuildSettingsAndCustomerInfo/${token}`,
  GetMachinesAndMeters: (token: string) => `api/MeterReading/GetAvailableMachinesAndMeters/${token}`,
  SubmitMetersReading: (language: string, token: string) => `api/MeterReading/InsertMachineMeterReading/${language}/${token}`,
}

export const MeterReadingAPIs = {
  GetSettings: (email: string) => `api/MeterReading/GetSettings/${email}`,
  GetMachinesAndMeters: (customerGuid: string, email: string) => `api/MeterReading/GetAvailableMachinesAndMeters/${customerGuid}/${email}`,
  SubmitMetersReading: (language: string, customerGuid: string, email: string) => `api/MeterReading/InsertMachineMeterReading/${language}/${customerGuid}/${email}`,
  GetAvailableMetersOnOrder: (customerGuid: string, machineGuid: string, email: string) => `api/MeterReading/GetAvailableMetersOnOrder/${customerGuid}/${machineGuid}/${email}`,
  SubmitMetersReadingOnOrder: (language: string, customerGuid: string, email: string) => `api/MeterReading/InsertMachineMeterReadingOrder/${language}/${customerGuid}/${email}`,
}

export const MachineAPIs = {
  GetMachines: (customerGuid: string, email: string) => `api/Machine/GetMachines/${customerGuid}/${email}`,
  GetMachinesSearch: (customerGuid: string, email: string) => `api/Machine/GetMachinesSearch/${customerGuid}/${email}`,
  GetFilterModels: (customerGuid: string, email: string) => `api/Machine/GetFilterModels/${customerGuid}/${email}`,
  GetFilterPropertyCodes: (customerGuid: string, email: string) => `api/Machine/GetFilterPropertyCodes/${customerGuid}/${email}`,
  GetMachineSortGroups: (email: string) => `api/Machine/GetSortGroups/${email}`,
  GetCountries: (email: string) => `api/Machine/GetCountries/${email}`,
  InsertMachine: (email: string) => `api/Machine/InsertMachine/${email}`,
  UpdateMachine: (email: string) => `api/Machine/UpdateMachine/${email}`,
  GetPropertyCodes: (email: string) => `api/Machine/GetPropertyCodes/${email}`,
  GetModels: (email: string) => `api/Machine/GetModels/${email}`,
  GetMachineVisibilitySettings: (customerGuid: string, email: string) => `api/Machine/GetMachineVisibilitySettings/${customerGuid}/${email}`,
}

export const OrderAPIs = {
  GetQuickOrders: (machineGuid: string, email: string) => `api/Order/GetQuickOrderArticlesForMachine/${machineGuid}/${email}`,
  GetVisibilitySettings: (email: string) => `api/Order/GetVisibilitySettings/${email}`,
  InsertOrder: (email: string, culture: string) => `api/Order/InsertOrder/${email}/${culture}`,
  GetMachines: (customerGuid: string, email: string) => `api/Order/GetMachines/${customerGuid}/${email}`,
  GetPriceAndDiscount: (machineGuid: string, email: string, articleGuid: string, qty: number) => `api/Order/GetPriceAndDiscount/${machineGuid}/${email}/${articleGuid}/${qty}`,
  GetOrderLog: (email: string) => `api/Order/GetOrders/${email}`,
  GetCustomersLinkedToMain: (customerGuid: string, email: string) => `api/Customer/GetCustomersLinkedToMain/${customerGuid}/${email}`,
}

export const CustomerAPIs = {
  GetContacts: (customerGuid: string, email: string) => `api/Customer/GetContacts/${customerGuid}/${email}`,
  UpdateContact: (email: string) => `api/Customer/UpdateContact/${email}`,
  GetCustomerFilterSortGroups: (customerGuid: string, email: string) => `api/Customer/GetFilterSortGroups/${customerGuid}/${email}`,
  GetVisitAddresses: (customerGuid: string, email: string) => `api/Customer/GetVisitAddresses/${customerGuid}/${email}`,
  GetInvoiceAddresses: (customerGuid: string, email: string) => `api/Customer/GetInvoiceAddresses/${customerGuid}/${email}`,
}

export const ConsentAPIs = {
  GetConsentsByUser: (email: string) => `api/Consent/GetConsents/${email}`,
  GetConsentAndAvailableByUser: (email: string) => `api/Consent/GetConsentsAndAvailable/${email}`,
  GetAvailableConsentsByUser: (email: string) => `api/Consent/GetAvailable/${email}`,
  WithdrawConsent: () => `api/Consent/WithdrawConsent`,
  SaveConsent: () => `api/Consent/SaveConsent`,
}
