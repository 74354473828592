import * as Redux from 'redux';
import * as MeterReadingActions from "../actions/MeterReadingActions";

export interface IMeterReading {
    settings: string;
    machinesAndMeters: string;
    error?: string;
}

export interface IMeterReadingState {
    readonly MeterReading: IMeterReading;
}

const initialMeterReadingstate: IMeterReadingState = {
    MeterReading:{
        settings: "",
        machinesAndMeters: "",
        error: undefined,
    }
}

export const MeterReadingReducer: Redux.Reducer<IMeterReadingState, MeterReadingActions.MeterReadingActions> = (
    state = initialMeterReadingstate,
    action
) => {
    switch (action.type){
        case MeterReadingActions.MeterReadingActionTypes.GETSETTINGS : {
            return {
                ...state,
                MeterReading: { ...state.MeterReading, settings: action.MeterReading.settings }
            };
        }
        case MeterReadingActions.MeterReadingActionTypes.GETMACHINESANDMETERS : {
            return {
                ...state,
                MeterReading: { ...state.MeterReading, machinesAndMeters: action.MeterReading.machinesAndMeters}
            };
        }
        case MeterReadingActions.MeterReadingActionTypes.SUBMITMETERSREADING : {
            return{
                ...state,
                MeterReading: {...state.MeterReading, machinesAndMeters: action.MeterReading.machinesAndMeters}
            };
        }
        case MeterReadingActions.MeterReadingActionTypes.SETRESPONSEERROR: {
            return{
                ...state,
                MeterReading: { ...state.MeterReading, error: action.MeterReading.error}
            };
        }
        default: {
            return state;
        }
    }
}