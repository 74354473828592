import * as React from "react";
import "./AsolviButton.css";
import { Button } from '@progress/kendo-react-buttons';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import "@progress/kendo-theme-material/dist/all.scss";

//Kendo ButtonType only accepts Type instead of plain string
export type ButtonType = "button" | "submit" | "reset" | undefined;

export interface Props {
  buttonText?: string,
  buttonType: ButtonType,
  buttonAction: () => void,
  className?: string,
  buttonIconName?: string,
  buttonDisabled?: boolean,
  disabled?: boolean,
}

class AsolviButton extends React.Component<Props, object>
{
  render() {
    const { buttonText, buttonType, className, buttonAction, buttonIconName, buttonDisabled, children } = this.props;
    return (
      <Row>
        <Col sm={12}>
          <Button
            className={className}
            onClick={buttonAction}
            type={buttonType}
            icon={buttonIconName}
            disabled={ buttonDisabled}
          >
            {children ? children : buttonText}
          </Button>
        </Col>
      </Row>
    );
  }
}

export default AsolviButton;