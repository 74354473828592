import * as React from "react";
import * as Store from "../../../../store/Store";
import * as ReactRedux from "react-redux";
import TaskInfoGroup from "../../../Groups/Task/TaskInfoGroup/TaskInfoGroup";
import * as TaskReducer from "../../../../reducers/TaskReducer";
import {ITaskInfoGroupData} from "../../../Groups/Task/TaskInfoGroup/TaskInfoGroup";
import * as TaskActions from "../../../../actions/TaskActions";

interface IProps {
    TaskInfoLabels: TaskReducer.ITaskInfoLabels;
    TaskVisibilitySettings: TaskReducer.ITaskVisibilitySettings;
    ShowTaskInfo: boolean;
    getTaskInfoLabels(token: string, email:string, appUrl: string): (token: string, email:string, appUrl: string)  => void;
    currentAuth0Token: string;
    currentUserEmail: string;
    extractData?: (data : ITaskInfoGroupData) => void;
    clearData?: boolean;    
}

const TaskInfoGroupSeedComponent: React.FunctionComponent<IProps> = (Props) => { 
    const { getTaskInfoLabels } = Props;
    const token = Props.currentAuth0Token;
    const email = Props.currentUserEmail;
    const { appUrl } = (window as any)["appConfig"];
    
    let projectInfoLabel:string[] = ["","","","","",""];
    let projectInfoShow:boolean[] = [true,true,true,true,true,true];
    
    React.useEffect(()=>{        
        if(token && Props.ShowTaskInfo){            
            getTaskInfoLabels(token, email, appUrl);
        }    
    }, [token, email, appUrl, getTaskInfoLabels, Props.ShowTaskInfo]);
    
    if(Props.TaskInfoLabels !== undefined && Props.TaskInfoLabels.labelList !== undefined && Props.TaskInfoLabels.labelList.length>0){
        projectInfoLabel = Props.TaskInfoLabels.labelList;
    }
    
    if(Props.TaskVisibilitySettings !== undefined && Props.TaskVisibilitySettings.ShowInfoFields !== undefined){  
        projectInfoShow[0]  = Props.TaskVisibilitySettings.ShowInfoFields.ShowInfoField1;
        projectInfoShow[1]  = Props.TaskVisibilitySettings.ShowInfoFields.ShowInfoField2;
        projectInfoShow[2]  = Props.TaskVisibilitySettings.ShowInfoFields.ShowInfoField3;
        projectInfoShow[3]  = Props.TaskVisibilitySettings.ShowInfoFields.ShowInfoField4;
        projectInfoShow[4]  = Props.TaskVisibilitySettings.ShowInfoFields.ShowInfoField5;
        projectInfoShow[5]  = Props.TaskVisibilitySettings.ShowInfoFields.ShowInfoField6;
    }
    
    return (
        <>
        <TaskInfoGroup 
                    projectInfoLabel =  {projectInfoLabel}
                    projectInfoShow = {projectInfoShow} 
                    extractData={Props.extractData}
                    clearData={Props.clearData}                       
            />
        </>
  )
}

const mapStateToProps = (store: Store.IAppState) => {
    return {    
        TaskInfoLabels: store.taskState.TaskInfoLabels,
        TaskVisibilitySettings: store.taskState.TaskVisibilitySettings,
        ShowTaskInfo: store.taskState.TaskVisibilitySettings.ShowInfoFields.Group,
        currentAuth0Token: store.generalState.General.currentAuth0Token,
        currentUserEmail: store.generalState.General.currentUserEmail
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getTaskInfoLabels: (token: string, email:string, appUrl: string) => dispatch(TaskActions.GetAvailableInfoLabels(token, email, appUrl)),
    }
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(TaskInfoGroupSeedComponent)