import * as React from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./AsolviFadeInOut.module.css";

export interface IProps {
  show: boolean;
  lazyChildren?: boolean;
  onEnter?: () => void;
  onEntered?: () => void;
  onExit?: () => void;
  onExited?: () => void;
}

const AsolviFadeInOut: React.FunctionComponent<IProps> = (props) => {
    const { show = false, lazyChildren = false, onEnter, onEntered, onExit, onExited } = props;
    return (
        <CSSTransition 
            in={show}
            appear={true}
            mountOnEnter={lazyChildren}
            timeout={200} 
            classNames={{
                enter: styles['fadeInOut-enter'],
                enterActive: styles['fadeInOut-enter-active'],
                appear: styles['fadeInOut-enter'],
                appearActive: styles['fadeInOut-enter-active'],
                exit: styles['fadeInOut-exit'],
                exitActive: styles['fadeInOut-exit-active']
            }} 
            unmountOnExit={lazyChildren}
            onEnter={onEnter}
            onEntered={onEntered}
            onExit={onExit}
            onExited={onExited}>
            {props.children}
        </CSSTransition>
    )
};

export default AsolviFadeInOut;