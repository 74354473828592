import * as React from "react";
import "./MeterReadingPage.css";
import Container from "react-bootstrap/Container";
import { IMachineInfo } from "../../MeterReport/seedComponents/Pages/MeterReportingPageSeedComponent";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import * as I18Next from "i18next";
import * as ReactI18Next from 'react-i18next';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Row, Col } from "react-bootstrap";
import MachineSearchGrid from "../../MeterReport/Elements/MachineSearchGrid/MachineSearchGrid";
import { IMeterReportingSettings } from "../../../sources/MachineMeterReportingSettings";

export interface Props extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  machineSearchGridItems: IMachineInfo[]
  selectedMachineMeters: IMachineInfo;
  acceptButtonAction: () => void;
  meterGridItemChange: (event: any) => void;
  meterGridEditField: string;
  settings: IMeterReportingSettings;
  onMachineSearchRowDoubleClick: (event: any) => void;
  showSearchGrid: boolean;
  selectButtonAction: () => void;
};

class MeterReportingPage extends React.Component<Props, object>{
  render() {
    const { t, acceptButtonAction, machineSearchGridItems, onMachineSearchRowDoubleClick, showSearchGrid, selectedMachineMeters, selectButtonAction, settings } = this.props;
    const searchButtonText = selectedMachineMeters.machineGuid !== "" ?
      t("MeterReporting.modelType") + " " + selectedMachineMeters.modelType + " / " +
      t("Standard.machine") + " " + selectedMachineMeters.machineNo +
      (selectedMachineMeters.location ? " / " + t("MeterReporting.location") + " " + selectedMachineMeters.location : "")
      : t("MeterReporting.selectMachinePrompt");

    if(machineSearchGridItems && machineSearchGridItems.length > 0) {
      return (
        <div className="MeterReadingOuterContainer">
          <Container className="MeterReadingContainer" fluid>
            <Row className="meterReadingTopRow">
              <AsolviButton buttonText={searchButtonText} buttonType="button"
                buttonIconName={showSearchGrid ? "arrow-chevron-up" : "arrow-chevron-down"}
                buttonAction={selectButtonAction}></AsolviButton>
              {showSearchGrid ? <MachineSearchGrid gridData={machineSearchGridItems} showMachineId={settings.ShowMachineId} onRowClick={onMachineSearchRowDoubleClick} /> : null}
            </Row>
            <Row className="meterReadingGridRow">
              <Grid className="meterReadingGrid"
                data={[...this.props.selectedMachineMeters.meters]}
                onItemChange={this.props.meterGridItemChange}
                editField="inEdit"
              >
                <Column field="meterType" title={t("MeterReporting.meterType")} editable={false} width="120px" />
                <Column field="meterDescription" title={t("MeterReporting.description")} editable={false} />
                <Column field="oldReadingDateFormatted" title={t("MeterReporting.oldReadingDate")} editable={false} />
                <Column field="oldCounter" title={t("MeterReporting.oldCounter")} editable={false} format="{0:n}" />
                <Column field="newReadingDateFormatted" title={t("MeterReporting.newReadingDate")} editor="date" editable={!settings.MeterReadingDateReadOnly} format="{0:d}" />
                <Column field="newCounter" title={t("MeterReporting.newCounter")} editor="numeric" format="{0:n}" />
              </Grid>
            </Row>
            <Row className="buttonRow">
              <Col></Col>
              <Col xs={2}>
                <AsolviButton className="k-primary" buttonText={t("MeterReporting.acceptButtonText")} buttonType="submit"
                  buttonAction={acceptButtonAction} buttonDisabled={((selectedMachineMeters.machineGuid === ""))}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      return (
        <div className="MeterReadingOuterContainer">
          <Container className="MeterReadingContainer" fluid>
            <div className="meterReadingEmptyRow">
              <p>{t("MeterReporting.noOpenMeterReadingLetters")}</p>
            </div>
          </Container>
        </div>
      );
    }
  }
}

export default ReactI18Next.withTranslation()(MeterReportingPage)