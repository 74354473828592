import React, { FC } from "react";
import { IConsent } from "../../seedComponents/Pages/ConsentsPageSeedComponent";

import styles from "./ConsentItem.module.css";
import ConsentStatus from "./ConsentStatus";
import { useInternationalization } from "@progress/kendo-react-intl";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import { useTranslation } from "react-i18next";

export interface Props {
  consent: IConsent;
  displayConsent: (consent: IConsent) => void;
  onWithdrawConsent: (consentId: number) => void;
  onGiveConsent: (consentId: number) => void;
}


const ConsentItem: FC<Props> = ({ consent, displayConsent, onWithdrawConsent, onGiveConsent }) => {
  const intlService = useInternationalization();
  const { t } = useTranslation();
  const { ValidTo, ValidFrom, Accepted, ConsentWithdrawn, Name, Id } = consent;

  let validFrom = ValidFrom ? intlService.formatDate(ValidFrom) : ""
  if (isToday(ValidFrom))
    validFrom = t('Consents.Today')

  let validTo = ValidTo ? intlService.formatDate(ValidTo) : ""
  if (isToday(ValidTo))
    validTo = t('Consents.Today')


  if (ConsentWithdrawn)
    return (
      <div className={ styles.container }>
        <span className={ styles.date }>{ validFrom }</span>
        <span className={`${ styles.title } ${ styles.titleWithdrawn }`}>{ Name }</span>
        <ConsentStatus status={ Accepted } withdrawn={ConsentWithdrawn} />
        <span className={ styles.date }>{ validTo }</span>
        <span/>
      </div>
    )
  else return (
    <div className={ styles.container }>
      <span className={ styles.date }>{ validFrom }</span>
      <span className={ styles.title } onClick={ () => displayConsent(consent) }>{ Name }</span>
      <ConsentStatus status={ Accepted } withdrawn={ConsentWithdrawn} />
      <span className={ styles.date }>{ validTo }</span>
      {
        Accepted
        ? <AsolviButton buttonType="submit" buttonAction={() => onWithdrawConsent(Id)}>
            {t("Consents.WithdrawConsent")}
          </AsolviButton>
        : <AsolviButton className="k-primary" buttonType="submit" buttonAction={() => onGiveConsent(Id)}>
              {t("Consents.GiveConsent")}
            </AsolviButton>
      }
    </div>
  )
}

export default ConsentItem;

function isToday(date: Date): boolean {
  if (!date)
    return false;

  const today = new Date()
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
}
