import * as React from 'react';
import { useSelector } from 'react-redux';
import * as Store from '../../../../store/Store';
import OrderLogResultGroup from '../../../Groups/Order/OrderLogResultGroup/OrderLogResultGroup';

interface IProps {  
}

const OrderLogResultSeedComponent: React.FunctionComponent<IProps> = (Props) => {
  const orderData = useSelector((state: Store.IAppState) => state.orderState.OrderLog.orderLog);
  const loadingLog = useSelector((state: Store.IAppState) => state.orderState.OrderLog.loadingLog);
  const showDiscount = useSelector((state: Store.IAppState) => state.orderState.Order.visibilitySettings.ShowAddOrderLine.ShowDiscount);
  const showSalesPrice = useSelector((state: Store.IAppState) => state.orderState.Order.visibilitySettings.ShowAddOrderLine.ShowSalesPrice);
  const showVat = useSelector((state: Store.IAppState) => state.orderState.Order.visibilitySettings.ShowAddOrderLine.ShowVat);
    
  return (
    <>    
    <OrderLogResultGroup      
      orderData={orderData}
      loadingLog={loadingLog}
      showSalesPrice={showSalesPrice}      
      showDiscount={showDiscount}      
      showVat={showVat}      
    />
    </>
  );
  
}

export default OrderLogResultSeedComponent;

