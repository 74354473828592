import * as React from 'react';
import "./OrderPage.css"; 
import Container from "react-bootstrap/Container";
import AsolviTabLayout from '../../Elements/AsolviTabLayout/AsolviTabLayout';
import {ITabItem} from '../../Elements/AsolviTabLayout/AsolviTabLayout';
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import OrderCreatePageSeedComponent from '../../seedComponents/Pages/OrderCreatePageSeedComponent';
import OrderLogPageSeedComponent from '../../seedComponents/Pages/OrderLogPageSeedComponent';


export interface IProps extends ReactI18Next.WithTranslation{
    t: I18Next.TFunction;
    showOrderLog: boolean;
    showOrderCreateNew: boolean;
    loggedInName: string;
}

const OrderPage : React.FunctionComponent<IProps> = (Props) =>
{
    const { t } = Props;
    let tabs = [] as ITabItem[];
    let i = -1;
    if(Props.showOrderCreateNew){
        i++;
        tabs.push({ index: i, itemTitle: t("Order.New"), itemBody:  <OrderCreatePageSeedComponent loggedInName={Props.loggedInName}/>});           
    }
    if(Props.showOrderLog){
        i++;
        tabs.push({ index: i, itemTitle: t("Order.Log"), itemBody:  <OrderLogPageSeedComponent/>});
    }
    return (
        <Container fluid className="OrderPageContainer">
            <AsolviTabLayout childs={tabs}/>            
        </Container>
    );   
}

export default ReactI18Next.withTranslation()(OrderPage);