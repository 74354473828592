import { TextArea } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels"
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import  './AsolviTextArea.css';

interface IProps {
    rows: number,
    labelText: string,
    inputText?: string,
    onChange: (e: any) => void,
    isReadOnly?: boolean,
    labelColSize?: number,
    valueColSize?: number
}

const AsolviTextArea: React.FunctionComponent<IProps> = (props) => {
    const { rows, inputText, labelText, onChange, labelColSize = 4, valueColSize = 8 } = props;
    return (
        <Row>
            {labelColSize > 0 && (
            <Col sm={labelColSize}>
                <Label className="asolviLabelText">{ labelText }</Label>
            </Col>
            )}
            <Col sm={valueColSize}>
                <TextArea rows={rows} value={inputText} onChange={onChange} readOnly={props.isReadOnly} />
            </Col>
        </Row>    
    )
}

export default AsolviTextArea;