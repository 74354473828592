import * as React from 'react';
import "./TaskPage.css"; 
import Container from "react-bootstrap/Container";
import AsolviTabLayout from '../../Elements/AsolviTabLayout/AsolviTabLayout';
import {ITabItem} from '../../Elements/AsolviTabLayout/AsolviTabLayout';
import TaskCreatePageSeedComponent from '../../seedComponents/Pages/TaskCreatePageSeedComponent';
import TaskLogPage from '../TaskLogPage/TaskLogPage';
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';

export interface IProps extends ReactI18Next.WithTranslation{
    t: I18Next.TFunction;
    showServiceLog: boolean;
    showHelpdeskLog: boolean;
    showServiceCreateNew: boolean;
    showHelpdeskCreateNew: boolean;
}

const TaskPage : React.FunctionComponent<IProps> = (Props) =>
{
    const { t } = Props;
    let tabs = [] as ITabItem[];
    let i = -1;
    if(Props.showServiceCreateNew || Props.showHelpdeskCreateNew){
        i++;
        tabs.push({ index: i, itemTitle: t("TaskCreate.Title"), itemBody:  <TaskCreatePageSeedComponent/>});           
    }
    if(Props.showServiceLog || Props.showHelpdeskLog){
        i++;
        tabs.push({ index: i, itemTitle: t("TaskLog.Title"), itemBody:  <TaskLogPage showServiceLog={Props.showServiceLog} showHelpdeskLog={Props.showHelpdeskLog}/>});
    }   
    
    return (
        <Container fluid className="TaskPageContainer">
            <AsolviTabLayout childs={tabs}/>            
        </Container>
    );   
}

export default ReactI18Next.withTranslation()(TaskPage);
