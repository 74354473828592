import React from "react";
import TaskPropertiesGroup from "../../../Groups/Task/TaskPropertiesGroup/TaskPropertiesGroup";
import * as Store from "../../../../store/Store";
import * as TaskReducer from "../../../../reducers/TaskReducer";
import * as ReactRedux from "react-redux";
import * as TaskActions from "../../../../actions/TaskActions";

interface IProps
{
    TaskVisibilitySettings: TaskReducer.ITaskVisibilitySettings;
    loggedInCustomer: string;
    selectedMachine?: string;
    selectedCustomer?: string;
    customerProperties: TaskReducer.ITaskProperyItem[];
    machineProperties: TaskReducer.ITaskProperyItem[];
    getCustomerProperties(token: string, email:string, appUrl: string, customerGuid: string): (token: string, email:string)  => void;
    getMachineProperties(token: string, email: string, appUrl: string, machineGuid: string): (token: string, email: string) => void;
    currentAuth0Token: string;
    currentUserEmail: string;
}

const TaskPropertiesGroupSeedComponent : React.FunctionComponent<IProps> = (Props) => {    
    const {selectedMachine, selectedCustomer, loggedInCustomer, getCustomerProperties, getMachineProperties} = Props;
    const showMachineProperties = Props.TaskVisibilitySettings.ShowProperties.ShowMachineProperties;
    const token = Props.currentAuth0Token;
    const email = Props.currentUserEmail;
      

    const { appUrl } = (window as any)["appConfig"];

    React.useEffect(()=>{ 
        if(token && selectedCustomer)
        {
            getCustomerProperties(token, email, appUrl, selectedCustomer && selectedCustomer !== "" ? selectedCustomer : loggedInCustomer); 
        }                       
    },[token, email, appUrl, selectedCustomer, loggedInCustomer, getCustomerProperties]);
    
    React.useEffect(()=>{               
        if (token && showMachineProperties) {                   
            getMachineProperties(token, email, appUrl, selectedMachine?? "");            
        }
    },[token, email, appUrl, selectedMachine, getMachineProperties, showMachineProperties]);

    return (    
        <TaskPropertiesGroup showMachine = {showMachineProperties} customerProperties = {Props.customerProperties} machineProperties = {Props.machineProperties} />        
    )
}

const mapStateToProps = (store: Store.IAppState) => {
    return {    
        loggedInCustomer: store.generalState.General.activeCustomer,
        selectedMachine: store.taskState.SelectedMachine?? undefined,
        selectedCustomer: store.taskState.SelectedCustomer?? undefined,
        customerProperties: store.taskState.TaskCustomerProperties,
        machineProperties: store.taskState.TaskMachineProperties,
        TaskVisibilitySettings: store.taskState.TaskVisibilitySettings,
        currentAuth0Token: store.generalState.General.currentAuth0Token,
        currentUserEmail: store.generalState.General.currentUserEmail,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {        
        getCustomerProperties: (token: string, email:string, appUrl: string, customerGuid: string) => 
                dispatch(TaskActions.GetTaskCustomerProperties(token, email, appUrl, customerGuid)),
        getMachineProperties: (token: string, email:string, appUrl: string, machineGuid: string) => 
                dispatch(TaskActions.GetTaskMachineProperties(token, email, appUrl, machineGuid)),      
    }
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(TaskPropertiesGroupSeedComponent);