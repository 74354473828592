import * as React from 'react';
import TaskCreatePage from "../../Pages/TaskCreatePage/TaskCreatePage";
import * as Store from "../../../store/Store";
import * as TaskReducer from "../../../reducers/TaskReducer";
import * as TaskActions from "../../../actions/TaskActions";
import * as ReactRedux from "react-redux";
import {ITaskGroupData} from "../../Pages/TaskCreatePage/TaskCreatePage";
import AsolviNotification from "../../Elements/AsolviNotification/AsolviNotification";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from "i18next";
import { useCallback } from 'react';
import {RadioButtonProps} from '@progress/kendo-react-inputs';
import i18n from '../../../i18n';



interface IProps extends ReactI18Next.WithTranslation {
    t: I18Next.TFunction;
    TaskVisibilitySettings: TaskReducer.ITaskVisibilitySettings;
    loggedInCustomer: string,
    getTaskRadioButtonProps(token: string, email:string, customer:string, appUrl: string): (token: string, email:string)  => void;    
    getTaskVisibilitySettings(token: string, email:string, appUrl: string): (token: string, email:string)  => void;
    createNewTask(token: string, email:string, taskType:string, appUrl: string, body: ICreateTaskInfo, culture: string, loggedInCustomer: string): (token: string, email:string, taskType:string)  => void;
    TaskRadioButtonProps: TaskReducer.ITaskRadioButtonProps;
    TaskInfoLabels: TaskReducer.ITaskInfoLabels;
    CreateTask: TaskReducer.ICreateTask;
    resetCreateTaskResponse(projectNo: string | undefined, error: string | undefined) : (projectNo: string | undefined, error: string | undefined) => void;    
    handleSelectedCustomer(customerGuid: string): (customerGuid: string) => void;
    currentAuth0Token: string;
    currentUserEmail: string;
}

export enum TaskTypeEnums {
  Service = 1,
  Helpdesk = 2
}

export interface ITaskDocument{
  Name: string | undefined;
  Image: string | undefined;
}

export interface ICreateTaskInfo {
    MachineGuid: string;
    ContactName: string;
    ContactPhoneNo: string;
    ContactFaxNo: string;
    MachineLocation: string;
    SymptomText: string;
    SymptomCode1: string;
    SymptomCode2: string;
    SymptomCode3: string;
    SymptomCode4: string;
    CustomerGuid: string;
    TaskTitle: string;
    Addressname: string;
    Address1: string;
    Address2: string;
    Address3: string;
    PostCode: string;
    PostDescription: string;
    PhoneNo: string;
    ContactEmail: string;
    Info01: string | undefined;
    Info02: string | undefined;
    Info03: string | undefined;
    Info04: string | undefined;
    Info05: string | undefined;
    Info06: string | undefined;
    CustomerReferenceInfo: string;
    Documents: ITaskDocument[] | undefined;
    ClientTimeZoneOffset: number | undefined;
}

const TaskCreatePageSeedComponent: React.FunctionComponent<IProps> = (Props) => {
    const { t, getTaskRadioButtonProps, getTaskVisibilitySettings, resetCreateTaskResponse, 
          handleSelectedCustomer, loggedInCustomer } = Props;
    const token = Props.currentAuth0Token;
    const email = Props.currentUserEmail;
    const { appUrl } = (window as any)["appConfig"];

    const [showNotify, setShowNotify] = React.useState<boolean>(false);
    const [notifyMessage, setNotifyMessage] = React.useState<string>("");
    const [notifyStyle, setNotifyStyle] = React.useState<any>(undefined);
    const [clearData, setClearData] = React.useState<boolean>(false);
    
    const [TaskGroupData, setTaskGroupData]= React.useState<ITaskGroupData>();
    const [Documents, setDocuments] = React.useState<ITaskDocument[]>([]);
    
    const [radioGroupData, setRadioGroupData] =  React.useState<RadioButtonProps[]>([]);
      
    React.useEffect(()=>{
      if(token){
         getTaskRadioButtonProps(token, email, loggedInCustomer, appUrl);         
         getTaskVisibilitySettings(token, email, appUrl);
      }
    }, [token, email, appUrl, loggedInCustomer, getTaskRadioButtonProps, getTaskVisibilitySettings]);

    React.useEffect(() => {
      if(Props.TaskRadioButtonProps?.radioButtonProps){
        let props: RadioButtonProps[] = Props.TaskRadioButtonProps?.radioButtonProps;
        props.map(prop => {
          prop.label = t(""+prop.label);
          return prop;
        });
        setRadioGroupData(props); 
      }
    },[t, Props.TaskRadioButtonProps])

    const uploadGroupData = TaskGroupData?.UploadGroupData;

    const handleCloseNotification = useCallback(() => {
      resetCreateTaskResponse("", "");
      setShowNotify(false);
    },[resetCreateTaskResponse]);

    React.useEffect(() => {
        setDocuments([]);

        if (uploadGroupData?.Files !== undefined) {          
          uploadGroupData.Files.forEach(item => {
              if(Props.TaskVisibilitySettings.MaxFileSize>0 && item.size !== undefined && item.size>Props.TaskVisibilitySettings.MaxFileSize){
                let invalidMaxFileSize = t("TaskCreate.ErrorInvalidMaxFileSize")+ " >" + String(Props.TaskVisibilitySettings.MaxFileSize === undefined ? "0":Props.TaskVisibilitySettings.MaxFileSize/1024)+" KB";
                setNotifyMessage(invalidMaxFileSize);
                setNotifyStyle('error');
                setShowNotify(true);
                setTimeout(() => {
                  handleCloseNotification();
                }, 6000);         
              }
              const reader = new FileReader();
                reader.onloadend = () => {
                    setDocuments(prevDocs => [...prevDocs, {
                        Name: item.name,
                        Image: reader.result as string
                    }]);
                };
                reader.readAsDataURL(item.getRawFile!());                           
          });
        }
    }, [uploadGroupData, Props.TaskVisibilitySettings.MaxFileSize, handleCloseNotification, t]);
    
    React.useEffect(()=>{
        if(Props.CreateTask?.error === "" && Props.CreateTask?.ProjectNo !== undefined && Props.CreateTask?.ProjectNo !== ""){
          setNotifyMessage(t("TaskCreate.NewJobCreated") + " " + Props.CreateTask.ProjectNo);
          setNotifyStyle('success');
          setShowNotify(true);
          //Clear screen?
          setClearData(true);
          setTimeout(() => {
            handleCloseNotification();
          }, 6000);          
        }else if (Props.CreateTask?.error !== undefined && Props.CreateTask?.error !== ""){

          let error = "";            
            switch (String(Props.CreateTask.error)) {
                case "ErrorMissingCustomer":
                case "ErrorMissingMachine": 
                case "ErrorMissingSymptomCode1": 
                case "ErrorMissingSymptomCode2": 
                case "ErrorMissingSymptomCode3":
                case "ErrorMissingSymptomCode4":
                case "ErrorMissingSymptomText":
                case "ErrorMissingTitle":
                case "ErrorMissingContactName":
                case "ErrorMissingContactPhone":
                case "ErrorMissingContactEmail":
                case "ErrorInvalidEmail":  
                {
                    error = t("TaskCreate." + String(Props.CreateTask.error));
                    break;
                }  
                case "ErrorInvalidMaxFileSize":
                {
                    error = t("TaskCreate." + String(Props.CreateTask.error))+ " >" + String(Props.TaskVisibilitySettings.MaxFileSize === undefined ? "0":Props.TaskVisibilitySettings.MaxFileSize/1024)+" KB";
                    break;
                }                       
                default: {                    
                    error = Props.CreateTask.error;
                }

            }

          setNotifyMessage(error);
          setNotifyStyle('error');
          setShowNotify(true);
        }
      },[Props.CreateTask, handleCloseNotification, t, Props.TaskVisibilitySettings.MaxFileSize]);

    const handleSubmitButtonClicked = async () => {
        setShowNotify(false);
        setClearData(false);
        resetCreateTaskResponse("", "");
        
        let newJob = {CustomerGuid: (TaskGroupData?.CustomerGroupData?.CustomerGuid !== undefined) && (TaskGroupData?.CustomerGroupData?.CustomerGuid?.length > 0) ? TaskGroupData?.CustomerGroupData?.CustomerGuid : loggedInCustomer,
                    Address1: TaskGroupData?.MachineGroupData?.Address1 ?? TaskGroupData?.CustomerGroupData?.Address1,
                    Address2: TaskGroupData?.MachineGroupData?.Address2 ?? TaskGroupData?.CustomerGroupData?.Address2,
                    Address3: TaskGroupData?.MachineGroupData?.Address3 ?? "",
                    Addressname: TaskGroupData?.MachineGroupData?.AddressName ?? TaskGroupData?.CustomerGroupData?.CustomerName,
                    ContactEmail: TaskGroupData?.ContactGroupData?.Email ?? "",
                    ContactFaxNo: TaskGroupData?.ContactGroupData?.FaxNo ?? "",
                    ContactName: TaskGroupData?.ContactGroupData?.FullName ?? "",
                    ContactPhoneNo: TaskGroupData?.ContactGroupData?.Phone ?? "",
                    CustomerReferenceInfo: TaskGroupData?.SymptomGroupData?.referenceInfo ?? "",
                    MachineGuid: TaskGroupData?.MachineGroupData?.MachineGuid ?? "", 
                    MachineLocation: TaskGroupData?.MachineGroupData?.Location ?? "", 
                    PhoneNo: "",
                    PostCode: TaskGroupData?.MachineGroupData?.PostalCode ?? TaskGroupData?.CustomerGroupData?.PostalCode,
                    PostDescription: TaskGroupData?.MachineGroupData?.PostalDescription ?? TaskGroupData?.CustomerGroupData?.PostDescription,
                    SymptomCode1: TaskGroupData?.SymptomGroupData?.symptomCode1?.Code ?? "",
                    SymptomCode2: TaskGroupData?.SymptomGroupData?.symptomCode2?.Code ?? "",
                    SymptomCode3: TaskGroupData?.SymptomGroupData?.symptomCode3?.Code ?? "",
                    SymptomCode4: TaskGroupData?.SymptomGroupData?.symptomCode4?.Code ?? "",
                    SymptomText: TaskGroupData?.SymptomGroupData?.symptomText ?? "",
                    TaskTitle: TaskGroupData?.SymptomGroupData?.taskTitle ?? "",
                    Info01: TaskGroupData?.InfoGroupData?.ProjectInfo01 ?? "",
                    Info02: TaskGroupData?.InfoGroupData?.ProjectInfo02 ?? "",
                    Info03: TaskGroupData?.InfoGroupData?.ProjectInfo03 ?? "",
                    Info04: TaskGroupData?.InfoGroupData?.ProjectInfo04 ?? "",
                    Info05: TaskGroupData?.InfoGroupData?.ProjectInfo05 ?? "",
                    Info06: TaskGroupData?.InfoGroupData?.ProjectInfo06 ?? "",
                    Documents: Documents,
                    ClientTimeZoneOffset: (new Date()).getTimezoneOffset()
          } as ICreateTaskInfo;


          Props.createNewTask(token, email, TaskTypeEnums[TaskGroupData?.TaskType], appUrl, newJob, i18n.language, loggedInCustomer)
    }
    
    React.useEffect(() => {
      if (Props.TaskVisibilitySettings.ShowSelectCustomer === false) {
        handleSelectedCustomer(loggedInCustomer);
      }
    }, [Props.TaskVisibilitySettings.ShowSelectCustomer, handleSelectedCustomer, loggedInCustomer])

    if (Props.TaskVisibilitySettings !== undefined)
    {
      return (  
        <>
        <TaskCreatePage
            radioGroupData={radioGroupData}
            showContactGroup={true}
            showCustomerGroup={Props.TaskVisibilitySettings.ShowSelectCustomer}
            showInfoGroup={Props.TaskVisibilitySettings.ShowInfoFields.Group}
            showMachineGroup={Props.TaskVisibilitySettings.ShowSelectMachine.Group}
            showPropertiesGroup={Props.TaskVisibilitySettings.ShowProperties.Group}
            showSymptomCodeGroup={true}
            showUploadGroup={Props.TaskVisibilitySettings.ShowUploadFiles}
            submitButtonAction={handleSubmitButtonClicked}
            extractData={setTaskGroupData}
            clearData={clearData}
            clearButtonAction={() => {setClearData(false);  
                                      setTimeout(() => {
                                        setClearData(true);
                                      }, 300)}}
        />
        <AsolviNotification
        message = {notifyMessage}
        showNotify = {showNotify}
        showIcon = {true}
        closeAction = {handleCloseNotification}
        style = {notifyStyle} /> 
        </>
        );
      }
      else {
          return null;
      }
    
}

const mapStateToProps = (store: Store.IAppState) => {
    return {
        TaskRadioButtonProps: store.taskState.TaskRadioButtonProps,
        TaskVisibilitySettings: store.taskState.TaskVisibilitySettings,
        TaskInfoLabels: store.taskState.TaskInfoLabels,
        CreateTask: store.taskState.CreateTask,
        loggedInCustomer: store.generalState.General.activeCustomer,
        currentAuth0Token: store.generalState.General.currentAuth0Token,
        currentUserEmail: store.generalState.General.currentUserEmail,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
      getTaskRadioButtonProps: (token: string, email:string, customer:string, appUrl: string) => dispatch(TaskActions.GetAvailableRadioButtonProps(token, email, customer, appUrl)),      
      getTaskVisibilitySettings: (token: string, email:string, appUrl: string) => dispatch(TaskActions.GetTaskVisibilitySettings(token, email, appUrl)),   
      createNewTask: (token: string, email:string, taskType:string, appUrl: string, body: ICreateTaskInfo, culture: string, loggedInCustomer: string) => dispatch(TaskActions.SubmitNewTask(token, email, taskType, appUrl, body, culture, loggedInCustomer)),   
      resetCreateTaskResponse: (projectNo: string, error: string) =>    
                dispatch(TaskActions.ResetCreateTaskResponse(projectNo, error)), 
      handleSelectedCustomer: (customerGuid: string) =>
                dispatch(TaskActions.TaskHandleSelectedCustomer(customerGuid)), 
    }
  }

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ReactI18Next.withTranslation()(TaskCreatePageSeedComponent));
