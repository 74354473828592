import * as React from 'react';
import "@progress/kendo-theme-material/dist/all.scss";
import "./AsolviAdvancedDropdownList.css";
import { ComboBoxChangeEvent, ComboBoxFilterChangeEvent, MultiColumnComboBox } from '@progress/kendo-react-dropdowns';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor, orderBy } from '@progress/kendo-data-query';
import { Tooltip } from '@progress/kendo-react-tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export interface IColumn{
  field: string;
  header: string;
  width: number;
  uniqueKey: string;
}

export interface ITypedColumn<T> extends IColumn {
  field: Extract<keyof T, string>;
}

export interface IAsolviAdvancedDropdownListChangeEvent<T> extends ComboBoxChangeEvent {
  value: T;
}

declare interface ILabelSizes {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}
export interface IProps<T>{
  columns: IColumn[];
  data: Array<T>;
  labelText: string;
  shownText: Extract<keyof T, string>;
  onSelect?: (e: IAsolviAdvancedDropdownListChangeEvent<T>) => void;
  dataItemKey?: Extract<keyof T, string>;
  selectedItem?: Partial<T>;
  required?: boolean;
  disabled?: boolean;
  labelSize?: number | ILabelSizes;
  clearButton?: boolean;
}

const pageSize = 10;
function AsolviAdvancedDropdownList<T>(Props: IProps<T>) {
  const [filteredData, setFilteredData] = React.useState<Array<T>>([]);

  React.useEffect(() => {
    if(Props.data){
      setFilteredData(orderBy(Props.data, [{ field: "position", dir: "asc" }]));
    }
  }, [Props.data]);

  const handleFilterChange = (event: ComboBoxFilterChangeEvent) => {
    if (event) {
      if (event.filter.value !== undefined && event.filter.value.length > 0)
      {
        const newFilter = {
            logic: 'or' as string,
            filters: Array<FilterDescriptor>()
        };
        Props.columns.forEach(element => {
            newFilter.filters.push({
                field: element.field,
                operator: "contains",
                value: event.filter.value,
                ignoreCase: true
            })
        });
        setFilteredData(orderBy(filterBy(Props.data, newFilter as CompositeFilterDescriptor), [{ field: "position", dir: "asc" }]));

      } else {
          setFilteredData(orderBy(Props.data, [{ field: "position", dir: "asc" }]));
      }
    }
  };

  const [state, setState] = React.useState({
    virtualData: filteredData.slice(0, pageSize),
    skip: 0,
    take: pageSize,
  });

  const onPageChange = (e: any) => {
    const skip = e.page.skip;
    const take = e.page.take;
    const data = filteredData.slice(skip, skip + take);

    const newState = {
      ...state,
      virtualData: data,
      skip: skip,
    };

    setState(newState);
  };

  const virtualComboSettings = {
    data: state.virtualData,
    virtual: {
      skip: state.skip,
      pageSize: pageSize,
      total: filteredData.length,
    },
    onPageChange: onPageChange,
  };

  React.useEffect(() => {
    setState({
      virtualData: filteredData.slice(0, pageSize),
      skip: 0,
      take: pageSize,
    });
  }, [filteredData]);

  const generateTooltipContent = (cols: IColumn[], row: object) =>{
    let toolTipContent = "";
    for(let[key,value] of Object.entries(row))
    {
      const thisCol = cols.find(item => item.field === key);
      if (thisCol && thisCol.header) {
        if (value === null)
          value = "";

        toolTipContent = toolTipContent + thisCol.header + ": " + value + " "
      }
    }
    return toolTipContent;
  }
  const itemRender = (Li: any, props: any) => {
    const children = Props.columns.map((col, i) => {
      return (
        <span
          className="k-cell"
          style={{ width: col.width }}
          key={col.uniqueKey}
        >
              {props.dataItem[col.field]}
            </span>
      );
    });
    return (
      <Li.type {...Li.props} title={generateTooltipContent(Props.columns, props.dataItem)}>
        {children}
      </Li.type>
    );
  };

  let defaultValue: Partial<T> | string | number | undefined;
  let firstValue = Props.data?.[0];
  if(firstValue !== undefined || firstValue !== null) {
    if(typeof firstValue === 'string')
      defaultValue = '';
    else if(typeof firstValue === 'number')
      defaultValue = undefined;
    else if(typeof firstValue === 'object')
      defaultValue = {};
  }

  let labelSize = Props.labelSize ?? 4;
    if(typeof labelSize !== "object") {
        labelSize = {
            xs: labelSize,
        };
    }
    let inputSize = {
        xs: labelSize.xs ? 12 - labelSize.xs : undefined,
        sm: labelSize.sm ? 12 - labelSize.sm : undefined,
        md: labelSize.md ? 12 - labelSize.md : undefined,
        lg: labelSize.lg ? 12 - labelSize.lg : undefined,
        xl: labelSize.xl ? 12 - labelSize.xl : undefined
    }

  return (
    <div>
      <Row
        style={{alignItems: 'baseline'}}
      >
        <Col xs={labelSize.xs} sm={labelSize.sm} md={labelSize.md} lg={labelSize.lg} xl={labelSize.xl}>
          <label>
            {Props.labelText}
          </label>
        </Col>
        <Col  xs={inputSize.xs} sm={inputSize.sm} md={inputSize.md} lg={inputSize.lg} xl={inputSize.xl}>
          <Tooltip
            openDelay={1000}
            position={"top"}
            anchorElement={"target"}
            parentTitle={true}>
            <MultiColumnComboBox
              {...virtualComboSettings}
              textField={Props.shownText}
              columns={Props.columns}
              filterable={true}
              onFilterChange={handleFilterChange}
              itemRender={itemRender}
              onChange={Props.onSelect}
              dataItemKey={Props.dataItemKey}
              //value={Props.selectedItem ?? defaultValue}
              value={Props.selectedItem ?? (Props.required?null:defaultValue)}
              required = {Props.required ?? false}
              disabled = {Props.disabled ?? false}
              clearButton={Props.clearButton}
            />
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
}

export default AsolviAdvancedDropdownList;
