import * as React from 'react';
import TaskUploadGroup from "../../../Groups/Task/TaskUploadGroup/TaskUploadGroup";
import { ITaskUploadGroupData } from "../../../Groups/Task/TaskUploadGroup/TaskUploadGroup";
import * as ReactRedux from "react-redux";
import * as Store from "../../../../store/Store";
import * as ReactI18Next from 'react-i18next';
import * as TaskReducer from "../../../../reducers/TaskReducer";

interface IProps extends ReactI18Next.WithTranslation{
    extractData?: (data: ITaskUploadGroupData) => void;
    clearData?: boolean;
    TaskVisibilitySettings: TaskReducer.ITaskVisibilitySettings; 
    currentAuth0Token: string;
}

const TaskUploadGroupSeedComponent: React.FunctionComponent<IProps> = (Props) => {
    return(
        <TaskUploadGroup 
            currentAuth0Token={Props.currentAuth0Token}
            MaxFileSize={Props.TaskVisibilitySettings.MaxFileSize}
            extractData = {Props.extractData}
            clearData={Props.clearData}
        />
    );
}

const mapStateToProps = (store: Store.IAppState) => {
    return {
        currentAuth0Token: store.generalState.General.currentAuth0Token,
        TaskVisibilitySettings: store.taskState.TaskVisibilitySettings
    };
};


export default ReactRedux.connect(mapStateToProps)(ReactI18Next.withTranslation()(TaskUploadGroupSeedComponent));