import * as React from "react";
import "./AsolviIframe.css"

export interface Props{
  frameUrl: string
}


// This element is not wrapped in COL and Row since it is temporary
class AsolviIframe extends React.Component<Props, object>{
  render() {
    const { frameUrl } = this.props;
    return (
      <div className="asolviIframeContainer">
        <iframe title="AsolviIframe" className="asolviIframe" src={frameUrl} />
      </div>
    )
  }
}

export default AsolviIframe;