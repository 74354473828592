import * as React from 'react';
import './AsolviTabLayout.css';
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout';

export interface ITabItem{
    index: number,
    itemTitle: string,
    itemBody: React.ReactNode;
}

export interface Iprops{
    childs: ITabItem[],
}

const AsolviTabLayout: React.FunctionComponent<Iprops> = (Props) =>{
    const [selected, setSelected] = React.useState(0);

    const handleSelect = (e: any) =>{
        setSelected(e.selected);
    };

    return(
        <TabStrip className="AsolviTabLayout" selected={selected} onSelect={handleSelect}>
            {Props.childs.map((child) => 
                <TabStripTab title={child.itemTitle} key={child.index}>
                    {child.itemBody}
                </TabStripTab>
            )}
        </TabStrip>
    )
}

export default AsolviTabLayout;