import * as React from "react";
import "./HomePage.css";
import Container from "react-bootstrap/Container";
import DrawerGroupSeedComponent from './../../seedComponents/Groups/DrawerGroupSeedComponent';

export interface Props{
}

class HomePage extends React.Component<Props, object>{
  render() {   

    return (
      <Container className="HomePageContainer" fluid>
        <DrawerGroupSeedComponent />
      </Container>
    );
  }
}

export default HomePage