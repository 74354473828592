import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Store from '../../../../store/Store';
import { setSearchFilter } from '../../../../slices/MachineSlice';
import axios from 'axios';
import * as apiEndPoints from '../../../../sources/apiEndPoints';

import MachineFilter from '../../../Groups/Machine/MachineFilter/MachineFilter';
import { IMachineCustomerItem, IMachineModelItem, ICustomerSortGroupItem, ICodeDescription , IMachineVisibilitySettings} from '../../Pages/MachinePageSeedComponent';

interface IProps {  
  visibilitySettings: IMachineVisibilitySettings;
}

const MachineFilterSeedComponent: React.FunctionComponent<IProps> = (Props) => {
  const { appUrl } = (window as any)["appConfig"];
  const { visibilitySettings} = Props;
  const General = useSelector((state: Store.IAppState) => state.generalState.General);
  const searchData = useSelector((state: Store.IAppState) => state.machineState.Machine.searchData);
  const dispatch = useDispatch();
  
  const { currentAuth0Token, currentUserEmail, activeCustomer } = General;

  let [ customers, setCustomers ] = React.useState<IMachineCustomerItem[]>([]);
  let [ sortGroups, setSortGroups ] = React.useState<ICustomerSortGroupItem[]>([]);
  let [ models, setModels ] = React.useState<IMachineModelItem[]>([]);
  let [ machineProperties, setMachineProperties ] = React.useState<ICodeDescription[]>([]);
  
  React.useEffect(() => {
    if(!currentAuth0Token) return;
    if(!appUrl) return;
    if(!activeCustomer) return;
    if(!currentUserEmail) return;

    getCustomers(currentAuth0Token, appUrl, activeCustomer, currentUserEmail)
      .then(x => checkSearchDataCustomer(x))
      .then(x => setCustomers(x));
    getCustomerSortGroups(currentAuth0Token, appUrl, activeCustomer, currentUserEmail)
      .then(x => checkSearchDataSortGroups(x))
      .then(x => setSortGroups(x));
    getModels(currentAuth0Token, appUrl, activeCustomer, currentUserEmail)
      .then(x => checkSearchDataModel(x))
      .then(x => setModels(x));
    getMachineFilterProperties(currentAuth0Token, appUrl, activeCustomer, currentUserEmail)
      .then(x => checkSearchDataMachineFilterProperties(x))
      .then(x => setMachineProperties(x));    
  }, [currentAuth0Token, appUrl, activeCustomer, currentUserEmail]);

  return (
    <>
    {(visibilitySettings?.ShowFilter ?? false) &&
    <MachineFilter
      searchData={searchData}
      customers={customers}
      sortGroup1Item={sortGroups[0]}
      sortGroup2Item={sortGroups[1]}
      sortGroup3Item={sortGroups[2]}
      sortGroup4Item={sortGroups[3]}
      sortGroup5Item={sortGroups[4]}
      models={models}
      machineProperties={machineProperties}
      setSearchFilter={(searchData) => dispatch(setSearchFilter(searchData))}
    />}
    </>
  );

  function checkSearchDataCustomer(customers: IMachineCustomerItem[]): IMachineCustomerItem[] {
    if(!searchData.CustomerGuid) return customers;

    let existingCustomer = customers.find(x => x.CustomerGuid === searchData.CustomerGuid);
    if(!existingCustomer) {
      setSearchFilter({
        ...searchData,
        CustomerGuid: undefined
      });
    }
    return customers;
  }

  function checkSearchDataSortGroups(sortGroups: ICustomerSortGroupItem[]): ICustomerSortGroupItem[] {
    let newSearchData = { ...searchData };
    
    let sortGroup1 = sortGroups[0] ?? { SortGroupValues: [] };
    if(searchData.CustomerSortGroup1 && !sortGroup1.SortGroupValues.find(x => x.Code === searchData.CustomerSortGroup1)) {
      newSearchData.CustomerSortGroup1 = undefined;
    }

    let sortGroup2 = sortGroups[1] ?? { SortGroupValues: [] };
    if(searchData.CustomerSortGroup2 && !sortGroup2.SortGroupValues.find(x => x.Code === searchData.CustomerSortGroup2)) {
      newSearchData.CustomerSortGroup2 = undefined;
    }

    let sortGroup3 = sortGroups[2] ?? { SortGroupValues: [] };
    if(searchData.CustomerSortGroup3 && !sortGroup3.SortGroupValues.find(x => x.Code === searchData.CustomerSortGroup3)) {
      newSearchData.CustomerSortGroup3 = undefined;
    }

    let sortGroup4 = sortGroups[3] ?? { SortGroupValues: [] };
    if(searchData.CustomerSortGroup4 && !sortGroup4.SortGroupValues.find(x => x.Code === searchData.CustomerSortGroup4)) {
      newSearchData.CustomerSortGroup4 = undefined;
    }

    let sortGroup5 = sortGroups[4] ?? { SortGroupValues: [] };
    if(searchData.CustomerSortGroup5 && !sortGroup5.SortGroupValues.find(x => x.Code === searchData.CustomerSortGroup5)) {
      newSearchData.CustomerSortGroup5 = undefined;
    }
    
    return sortGroups;
  }
  
  function checkSearchDataModel(models: IMachineModelItem[]): IMachineModelItem[] {
    if(!searchData.ModelGuid) return models;

    let existingModel = models.find(x => x.ModelGuid === searchData.ModelGuid);
    if(!existingModel) {
      setSearchFilter({
        ...searchData,
        ModelGuid: undefined
      });
    }
    return models;
  }

  function checkSearchDataMachineFilterProperties(properties: ICodeDescription[]): ICodeDescription[] {
    let newSearchData = { ...searchData };
    if(searchData.PropertyFilterCode1 && !properties.find(x => x.Code === searchData.PropertyFilterCode1)) {
      newSearchData.PropertyFilterCode1 = undefined;
      newSearchData.PropertyFilterValue1 = undefined;
    }
    if(searchData.PropertyFilterCode2 && !properties.find(x => x.Code === searchData.PropertyFilterCode2)) {
      newSearchData.PropertyFilterCode2 = undefined;
      newSearchData.PropertyFilterValue2 = undefined;
    }
    setSearchFilter(newSearchData);
    return properties;
  }
}

async function getCustomers(token: string, appUrl: string, customerGuid: string, email: string): Promise<IMachineCustomerItem[]> {
  const url = appUrl + apiEndPoints.CustomerWebAPIs.GetCustomersLinkedToMain(customerGuid, email);
  const response = await axios.get(url, { headers: { token }});
  let result = response.data as IMachineCustomerItem[];
  result.forEach(x => x.CustomerNoAndName = [x.CustomerNo, x.CustomerName].filter(x => x).join(' - '));
  return result;
}

async function getCustomerSortGroups(token: string, appUrl: string, customerGuid: string, email: string): Promise<ICustomerSortGroupItem[]> {
  const url = appUrl + apiEndPoints.CustomerWebAPIs.GetCustomerFilterSortGroups(customerGuid, email);
  const response = await axios.get(url, { headers: { token }});
  let result = response.data as ICustomerSortGroupItem[];
  result.sort((a,b) => a.sortGroupNo - b.sortGroupNo);
  return result;
}

async function getModels(token: string, appUrl: string, customerGuid: string, email: string): Promise<IMachineModelItem[]> {
  const url = appUrl + apiEndPoints.MachineAPIs.GetFilterModels(customerGuid, email);
  const response = await axios.get(url, { headers: { token }});
  return response.data as IMachineModelItem[];
}

async function getMachineFilterProperties(token: string, appUrl: string, customerGuid: string, email: string): Promise<ICodeDescription[]> {
  const url = appUrl + apiEndPoints.MachineAPIs.GetFilterPropertyCodes(customerGuid, email);
  const response = await axios.get(url, { headers: { token }});
  return (response.data as ICodeDescription[]).filter(x => x.Code);
}

export default MachineFilterSeedComponent;

