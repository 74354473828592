import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ConsentStatus.module.css";

export interface Props {
  status: boolean;
  withdrawn?: boolean;
}

const ConsentStatus: FC<Props> = ({ status, withdrawn}) => {
  const { t } = useTranslation();

  let className;
  if (withdrawn)
    className = styles.withdrawn
  else {
    className = status
      ? styles.accepted
      : styles.declined
  }

  return (
    <span
      className={className}
    >
      { status ? t("Consents.Accepted") : t("Consents.Declined") }
    </span>
  );
};

export default ConsentStatus;