import * as React from 'react';
import "./OrderLogFilterGroup.css";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import AsolviInput from "../../../Elements/AsolviInput/AsolviInput";
import AsolviRadioButtonGroup from '../../../Elements/AsolviRadioButton/AsolviRadioButtonGroup';
import * as ReactI18Next from 'react-i18next';
import AsolviDatepicker from '../../../Elements/AsolviDatepicker/AsolviDatepicker';
import { ICodeDescription } from '../../../seedComponents/Pages/MachinePageSeedComponent';
import { IOrderSearchDataContract } from '../../../seedComponents/Pages/OrderLogPageSeedComponent';
import MachinePropertyFilter from '../../Machine/MachineFilter/MachinePropertyFilter';
import * as I18Next from "i18next";

export interface IProps extends ReactI18Next.WithTranslation {
    t: I18Next.TFunction;     
    showMachineId: boolean;    
    machineProperties: ICodeDescription[];
    searchData: IOrderSearchDataContract;
    setSearchFilter: (searchData: IOrderSearchDataContract) => void;
}

const OrderLogFilterGroup: React.FunctionComponent<IProps> = (Props) => {
    const { t, machineProperties, searchData, setSearchFilter } = Props;    
        
    const updateSearchData = (newSearchData: Partial<IOrderSearchDataContract>) => {
        let merged = { ...searchData, ...newSearchData };
        setSearchFilter(merged);
      };

      let propertyRows = [
        <Row key="Property1">
          <Col>
            <MachinePropertyFilter
              label={t("Machine.Property1")}
              codes={machineProperties}
              selectedCode={searchData.PropertyFilterCode1}
              selectedValue={searchData.PropertyFilterValue1}
              onChange={(value: { Code?: string, Value?: string }) => {
                updateSearchData({ PropertyFilterCode1: value?.Code, PropertyFilterValue1: value?.Value })
              }}
            />
          </Col>
        </Row>,
        <Row key="Property2">
          <Col>
            <MachinePropertyFilter
              label={t("Machine.Property2")}
              codes={machineProperties}
              selectedCode={searchData.PropertyFilterCode2}
              selectedValue={searchData.PropertyFilterValue2}
              onChange={(value: { Code?: string, Value?: string }) => {
                updateSearchData({ PropertyFilterCode2: value?.Code, PropertyFilterValue2: value?.Value })
              }}
            />
          </Col>
        </Row>
      ];

    return (        
        <Container fluid className="filterGroup">                    
            <Row>                                
                <Col sm={6}>
                    <AsolviInput labelText = {t("OrderLogFilter.OrderNo")} 
                                onChange={(e: any) => updateSearchData({ OrderNo: e.target.value })}                                 
                                inputValue={searchData.OrderNo}
                    />                
                    <AsolviInput labelText = {t("OrderLogFilter.MachineNo")} 
                                onChange={(e: any) => updateSearchData({ MachineNo: e.target.value })} 
                                inputValue={searchData.MachineNo}
                    />                    
                    <AsolviInput labelText = {t("OrderLogFilter.ModelType")} 
                                onChange={(e: any) => updateSearchData({ ModelType: e.target.value })} 
                                inputValue={searchData.ModelType}
                    />                                    
                    { Props.showMachineId && <AsolviInput labelText = {t("OrderLogFilter.MachineIdNo")} 
                                                    onChange={(e: any) => updateSearchData({ MachineIdNo: e.target.value })} 
                                                    inputValue={searchData.MachineIdNo}
                                            />            
                    }
                    <AsolviInput labelText = {t("OrderLogFilter.ReferenceInfo")} 
                                onChange={(e: any) => updateSearchData({ CustomerReferenceInfo: e.target.value })} 
                                inputValue={searchData.CustomerReferenceInfo}
                    />      
                    { machineProperties?.length ? propertyRows : null }                                                                       
                </Col>    
                <Col sm={2}> 
                    <span>{t("OrderLogFilter.SelectPeriod")}</span>
                    <AsolviRadioButtonGroup 
                        groupId="myfilter"
                        optionsList=
                        {[
                            {id: "0", label: t("OrderLogFilter.NoDateLimit")},
                            {id: "1", label: t("OrderLogFilter.ManualDateLimit")},
                            {id: "2", label: t("OrderLogFilter.ThisMonthDateLimit")},
                            {id: "3", label: t("OrderLogFilter.Last30DaysDateLimit")},
                            {id: "4", label: t("OrderLogFilter.ThisYearDateLimit")},
                            {id: "5", label: t("OrderLogFilter.Last365DaysDateLimit")},
                        ]} defaultValue={searchData.DatePeriod ?? "2"}
                        onChange={(e: any) => updateSearchData({ DatePeriod: e })}                         
                    />
                </Col>                  
                <Col sm={4}>                   
                    {
                        searchData.DatePeriod === "1" && (
                    <>
                        <AsolviDatepicker labelText={t("OrderLogFilter.DateFrom")}
                                         inputValue={searchData.DateFrom}
                                         onSelect={(e: any) => updateSearchData({ DateFrom: e.target.value })} 
                        />
                       
                        <AsolviDatepicker labelText={t("OrderLogFilter.DateTo")}
                                        inputValue={searchData.DateTo}
                                        onSelect={(e: any) => updateSearchData({ DateTo: e.target.value })} 
                        />              
                    </>
                        )
                    }
                </Col>
            </Row>            
        </Container>                                   
        );
}

export default ReactI18Next.withTranslation()(OrderLogFilterGroup);