import * as Redux from 'redux';
import * as ReduxThunk from 'redux-thunk';
import * as SettingsReducer from "../reducers/SettingsReducer";
import * as Axios from 'axios';
import * as ApiEndPoints from "../sources/apiEndPoints";
import * as GeneralActions from "../actions/GeneralActions";


export enum SettingsActionTypes {
    GETAVAIALBLEDBS = "GETAVAIALBLEDBS",
    GETDEFAULTSETTINGS = "GETDEFAULTSETTINGS",
    GETCONTACTLIST = "GETCONTACTLIST",
    GETCONTACTDROPDOWNLIST ="GETCONTACTDROPDOWNLIST",
    GETBUILDINFORMATION = "GETBUILDINFORMATION",
    GETMENUITEMS = "GETMENUITEMS",
    GETDEPARTMENTS = "GETDEPARTMENTS"
}

export interface ISettingsGetAvailableDbs {
    type: SettingsActionTypes.GETAVAIALBLEDBS;
    Settings: SettingsReducer.ISettings;
}

export interface ISettingsGetDefaultSettings {
    type: SettingsActionTypes.GETDEFAULTSETTINGS;
    Settings: SettingsReducer.ISettings;
}

export interface ISettingsGetContactList {
    type: SettingsActionTypes.GETCONTACTLIST;
    Settings: SettingsReducer.ISettings;
}

export interface ISettingsGetContactDropdownList {
    type: SettingsActionTypes.GETCONTACTDROPDOWNLIST;
    Settings: SettingsReducer.ISettings;
}

export interface ISettingsGetBuildInformation {
    type: SettingsActionTypes.GETBUILDINFORMATION;
    Settings: SettingsReducer.ISettings;
}

export interface ISettingsGetMenuItems {
    type: SettingsActionTypes.GETMENUITEMS;
    Settings: SettingsReducer.ISettings;
}

export interface ISettingsGetDepartments {
    type: SettingsActionTypes.GETDEPARTMENTS;
    Settings: SettingsReducer.ISettings;
}

export type SettingsActions = ISettingsGetAvailableDbs | ISettingsGetDefaultSettings | ISettingsGetContactList | ISettingsGetContactDropdownList | ISettingsGetBuildInformation | ISettingsGetMenuItems | ISettingsGetDepartments;


export const GetAvailableDbs: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetAvailableDbs>
    > = (appUrl: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {

                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetAvailableDbs());
                dispatch({
                    Settings: { dbInfo: JSON.stringify(response.data) },
                    type: SettingsActionTypes.GETAVAIALBLEDBS
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { dbInfo: null },
                    type: SettingsActionTypes.GETAVAIALBLEDBS
                });
            }

        }
    };

export const GetAvailableSettings: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetDefaultSettings>
    > = (token: string, appUrl: string, email: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetCustomerWebSettings(email), { headers: { token: token } });
                dispatch({
                    Settings: { defaultSettings: JSON.stringify(response.data) },
                    type: SettingsActionTypes.GETDEFAULTSETTINGS
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { defaultSettings: null },
                    type: SettingsActionTypes.GETDEFAULTSETTINGS
                });
            }
        }
    };

export const GetContactList: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetContactList>
    > = (token: string, email: string, appUrl: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetContactList(email), { headers: { token: token } });
                dispatch({
                    Settings: { contactList: JSON.stringify(response.data) },
                    type: SettingsActionTypes.GETCONTACTLIST
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { contactList: null },
                    type: SettingsActionTypes.GETCONTACTLIST
                });
            }
        }
    };

export const GetContactDropDownList: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetContactDropdownList>
    > = (token: string, email: string, appUrl: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetContactDropDownList(email), { headers: { token: token } });
                if (response.data.length === 1) {
                    dispatch({
                        General: { activeCustomer: response.data[0].itemId },
                        type: GeneralActions.GeneralActionTypes.HANDLESELECTEDCUSTOMER
                      });                    
                }

                dispatch({
                    Settings: { contactDropdownList: JSON.stringify(response.data) },
                    type: SettingsActionTypes.GETCONTACTDROPDOWNLIST
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { contactDropdownList: null },
                    type: SettingsActionTypes.GETCONTACTDROPDOWNLIST
                });
            }
        }
    };

export const GetBuildInformation: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetBuildInformation>
    > = (token: string, email : string, appUrl: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetBuildInformation(email), { headers: { token: token } });
                dispatch({
                    Settings: { buildInfo: JSON.stringify(response.data) },
                    type: SettingsActionTypes.GETBUILDINFORMATION
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { buildInfo: null },
                    type: SettingsActionTypes.GETBUILDINFORMATION
                });
            }
        }
    };

export const GetMenuItems: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetMenuItems>
    > = (token: string, email: string, customer:string, appUrl: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetMenuItems(email, customer), { headers: { token: token } });
                dispatch({
                    Settings: { menuItems: JSON.stringify(response.data) },
                    type: SettingsActionTypes.GETMENUITEMS
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { menuItems: null },
                    type: SettingsActionTypes.GETMENUITEMS
                });
            }
        }
    }

export const GetDepartments: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetDepartments>
    > = (token: string, email: string, appUrl: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetDepartments(email), { headers: { token: token } });
                dispatch({
                    Settings: { departmentCodes: response.data},
                    type: SettingsActionTypes.GETDEPARTMENTS
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { departmentCodes: null },
                    type: SettingsActionTypes.GETDEPARTMENTS
                });
            }
        }
    }
