import React from "react";
import * as ReactI18Next from "react-i18next";
import * as I18Next from "i18next";
import AsolviAdvancedDropdownList from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import { IMachineCustomerItem } from "../../../seedComponents/Pages/MachinePageSeedComponent";

export interface Props extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  customers: IMachineCustomerItem[];
  selectedCustomerGuid?: string;
  onCustomerSelected: (e: any) => void;
}

const CustomerFilter: React.FunctionComponent<Props> = (Props) => {
  const { t, customers, selectedCustomerGuid } = Props;
  let selectedCustomer = customers.find(x => x.CustomerGuid === selectedCustomerGuid);

  const onCustomerSelected = (customer: IMachineCustomerItem) => {
    Props.onCustomerSelected(customer);
  };

  const customerColumns = [
    {
      field: "CustomerNo",
      header: t("Machine.CustomerNumber"),
      width: 150,
      uniqueKey: "CustomerNo"
    },
    {
      field: "CustomerName",
      header: t("Machine.CustomerName"),
      width: 250,
      uniqueKey: "CustomerName"
    },
    {
      field: "Address1",
      header: t("Machine.CustomerAddress1"),
      width: 350,
      uniqueKey: "Address1"
    },
    {
      field: "PostDescription",
      header: t("Machine.CustomerCity"),
      width: 200,
      uniqueKey: "PostDescription"
    },
    {
      field: "AddressPhone",
      header: t("Machine.CustomerPhoneNumber"),
      width: 150,
      uniqueKey: "AddressPhone"
    },
  ];

  return (
    <>
      <AsolviAdvancedDropdownList
        labelText={t("Machine.Customer")}
        shownText="CustomerNoAndName"
        columns={customerColumns}
        data={customers}
        onSelect={(e:any) => { onCustomerSelected(e.value); }}
        selectedItem={selectedCustomer}
        />
    </>
  );
};

export default ReactI18Next.withTranslation()(CustomerFilter);