import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./InvoiceableCell.module.css";

const InvoiceableCell = (props: GridCellProps) => {
  const { t } = useTranslation();
  const contract = props.dataItem.Invoiceable;
  const icon = contract === false ? (
    <span className="k-icon k-i-checkbox-checked k-icon-size" />
  ) : (
    <span className="k-icon k-i-checkbox k-icon-size" />
  );

  const box = contract === false ? (
    <span className={styles.included}>{t("Order.Included")}</span>
  ) : (
    ""
  );

  return (
    <td>
      {box}
    </td>
  );
};

export default InvoiceableCell;