import * as React from 'react';
import "./AsolviCheckbox.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Checkbox } from "@progress/kendo-react-inputs";
import "@progress/kendo-theme-material/dist/all.scss";

export interface IProps{
    value: boolean,    
    label: string,    
    onChange?: (e: any) => void;
    disabled?: boolean
}

const AsolviCheckbox: React.FunctionComponent<IProps> = (props) => {
    
    return (        
        <Row>
            <Col sm={12}>
                <Checkbox                    
                    value={props.value}                    
                    label={props.label}
                    onChange={props.onChange}
                    disabled = {props.disabled}
                />     
            </Col>  
        </Row>
    );
}

export default AsolviCheckbox;