import React, { useCallback, useState } from 'react';
import "./OrderLogResultGroup.css";
import { Col, Row, Spinner } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import * as ReactI18Next from 'react-i18next';
import { IOrderLogDataContract } from '../../../seedComponents/Pages/OrderLogPageSeedComponent';
import * as I18Next from "i18next";
import AsolviPanelBar, { IPanelItem } from '../../../Elements/AsolviPanelBar/AsolviPanelBar';
import OrderLogResultDetailGroup from './OrderLogResultDetailGroup';
import { useInternationalization } from '@progress/kendo-react-intl';
import AsolviLabel from '../../../Elements/AsolviLabel/AsolviLabel';
import { parseDate } from '@telerik/kendo-intl';
import AsolviFadeInOut from '../../../Elements/AsolviFadeInOut/AsolviFadeInOut';
import AsolviButton from '../../../Elements/AsolviButton/AsolviButton';

export interface IProps extends ReactI18Next.WithTranslation {
    t: I18Next.TFunction;         
    loadingLog: boolean;
    orderData: IOrderLogDataContract[];  
    showSalesPrice: boolean;
    showDiscount: boolean;
    showVat: boolean;   
}

const OrderLogResultGroup: React.FunctionComponent<IProps> = (Props) => {
    const { t, loadingLog, orderData, showSalesPrice, showDiscount, showVat } = Props;    
    const intlService = useInternationalization();
    const [expandAll, setExpandAll] = useState<boolean>(false);
    const [keyHack, setKeyHack] = useState<number>(0); // Kendo panel bar needs newer version to be in controlled mode. Can only set initial state now, but key forces a full rerender
    
    let children: Array<IPanelItem> = [];    
    let i = 0;
    orderData.forEach(element => {      
      let invoiceDate = parseDate(element.InvoiceDate.toString());
      children.push({itemTitle: t("Order.OrderResultLabel") + " " + element.OrderNo + " (" + intlService.formatDate(invoiceDate)  + ")"
                      , expanded: expandAll
                      ,key: element.OrderNo, 
                      ItemBody: <OrderLogResultDetailGroup orderData={element} 
                                      showSalesPrice={showSalesPrice} showDiscount={showDiscount} showVat={showVat} />
                    });
      i++;
    });

    const toggleAll = useCallback(() => {
      setExpandAll(!expandAll);
      setKeyHack(1 - keyHack);
    }, [keyHack, expandAll, setExpandAll]);

    return (
      <Container fluid className="OrderLogResultContainer"> 
        <Row>
          <Col sm={1} style={{ marginLeft: "1px" }}><AsolviButton buttonAction={() => toggleAll()} buttonType="button" buttonIconName={expandAll ? "chevron-up" : "chevron-down"} /></Col>
          <Col sm={11}></Col>
        </Row>
       <Row>
        <Col sm={12}>       
          <AsolviFadeInOut show={loadingLog} lazyChildren>
            <div className="OrderLogOverlay">
              <Spinner
                animation="border" variant="dark" 
                />
            </div>
          </AsolviFadeInOut>
          <AsolviPanelBar key={keyHack} childs={children} />
        </Col>
        </Row>
        <Row className="OrderLogSumRow">
          <Col sm={6}>
            <AsolviLabel labelText={t("Order.Total")} value={i} />
          </Col>      
        </Row>
      </Container>                             
    );
}

export default ReactI18Next.withTranslation()(OrderLogResultGroup);