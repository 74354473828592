import * as React from 'react';
import * as ReactI18Next from 'react-i18next';
import * as ReactRedux from 'react-redux';
import * as I18Next from 'i18next';
import * as SettingsReducer from '../../../../reducers/SettingsReducer';
import * as SettingsActions from "../../../../actions/SettingsActions";
import * as GeneralReducer from '../../../../reducers/GeneralReducer';
import * as Store from '../../../../store/Store';
import { updateMachine, insertMachine, clearMachine } from '../../../../slices/MachineSlice';
import axios from 'axios';
import * as apiEndPoints from '../../../../sources/apiEndPoints';
import MachineDetailsGroup from '../../../Groups/Machine/MachineDetailsGroup';
import { IMachineInfo, IMachineModelItem, ICustomerSortGroupItem, ICodeDescription, ICreateMachine, IMachinePropertyItem, ICustomerVisitAddress, IMachineVisibilitySettings } from '../../Pages/MachinePageSeedComponent';
import * as TaskReducer from "../../../../reducers/TaskReducer";
import * as TaskActions from "../../../../actions/TaskActions";
import { TaskTypeEnums } from '../../Pages/TaskCreatePageSeedComponent';
import AsolviNotification from "../../../Elements/AsolviNotification/AsolviNotification";
import { useCallback } from 'react';
import { IPropertyCodes } from '../../../Groups/Machine/MachinePropertiesGroup';

interface IProps extends ReactI18Next.WithTranslation {
    t: I18Next.TFunction;
    General: GeneralReducer.IGeneral;
    Settings: SettingsReducer.ISettings;
    loggedInCustomer: string,
    machineData?: IMachineInfo;
    setMachineData: (machineData: IMachineInfo) => void;
    customerList: TaskReducer.ITaskCustomerItem[];
    contactList: TaskReducer.ITaskContactGroupData[];
    getContacts(token: string, email:string, appUrl: string, customerGuid: string, machineGuid: string, taskType: number): (token: string, email:string)  => void;
    GetTaskLimitedPostalCodes(token: string, countries: string[], code: string, email: string, appUrl: string): (token: string, countries: string[], code: string, email: string) => void;
    getDepartments(token: string, email:string, appUrl: string): (token: string, email:string)  => void;
    getCustomerList(token: string, email:string, appUrl: string, mainCustomerGuid: string): (token: string, email:string)  => void;
    closeButtonAction: () => void;
    currentAuth0Token: string; 
    currentUserEmail: string;
    TaskPostCodes: TaskReducer.ITaskPostalCodes;
    CreateMachine: ICreateMachine;
    updateListAction: () => void;
    visibilitySettings: IMachineVisibilitySettings;
}



const MachineDetailsSeedComponent: React.FunctionComponent<IProps> = (Props) => {
    const { appUrl } = (window as any)["appConfig"];
    const dispatch = ReactRedux.useDispatch();
    const { t, getContacts, loggedInCustomer, GetTaskLimitedPostalCodes, getCustomerList , getDepartments, machineData, setMachineData, visibilitySettings} = Props;
    const token = Props.currentAuth0Token;
    const email = Props.currentUserEmail;
    const postCodesList = Props.TaskPostCodes.PostalCodes; 
    const departmentCodesList = Props.Settings.departmentCodes;     
    const [countries, setCountries] = React.useState<ICodeDescription[]>([]);
    let [ models, setModels ] = React.useState<IMachineModelItem[]>([]);
    let [ sortGroups, setSortGroups ] = React.useState<ICustomerSortGroupItem[]>([]);
    let [ machineProperties, setMachineProperties ] = React.useState<IMachinePropertyItem[]>([]);
    let [ propertyCodes, setPropertyCodes ] = React.useState<IPropertyCodes[]>([]);
    const [showNotify, setShowNotify] = React.useState<boolean>(false);
    const [notifyMessage, setNotifyMessage] = React.useState<string>("");
    const [notifyStyle, setNotifyStyle] = React.useState<any>(undefined);
    const [visitAddresses, setVisitAddresses] = React.useState<ICustomerVisitAddress[]>([]);
    
    const code = "all";

    const machineGuid = machineData?.MachineGuid;
    const machineCustomerGuid = machineData?.CustomerGuid;
    const showMachineProperties = visibilitySettings?.ShowProperties?? false;
    const machineCountry = machineData?.AddressCountry;

    const handleCloseNotification = useCallback(() => {   
        setShowNotify(false);
      },[]);


    const handleCloseButtonAction = async () => {
        setShowNotify(false);
        dispatch(clearMachine());
        Props.closeButtonAction();
        Props.updateListAction();
    }

    let machineNo = machineData?.MachineNo;

    React.useEffect(()=>{
        if(Props.CreateMachine?.error === undefined || Props.CreateMachine?.error === ""){
            if(Props.CreateMachine?.Result === true){ 
                if(Props.CreateMachine?.IsMachineExist){
                    setNotifyMessage(t("Machine.MachineUpdated") + ". " + t("Machine.MachineNo")+ ": " + machineNo);
                    setNotifyStyle('success');
                    setShowNotify(true);
                }else{      
                    setNotifyMessage(t("Machine.MachineCreated") + ". " + t("Machine.MachineNo")+ ": " + machineNo);
                    setNotifyStyle('success');
                    setShowNotify(true);
                }
                setTimeout(() => {
                    handleCloseNotification();
                }, 6000);   
            }else if(Props.CreateMachine?.Result === false) {            
                setNotifyMessage(t("Machine.MachineNo") + " " + machineNo+ " "+ ((Props.CreateMachine?.IsMachineExist)?t("Machine.MachineExists"):""));
                setNotifyStyle('error');
                setShowNotify(true);         
            }       
        }else{
          let error = "";            
            switch (String(Props.CreateMachine?.error)) {
                case "ErrorMissingPostalCodeOrDescription":
                case "ErrorMissingCountry": 
                case "ErrorMissingMachineGuid": 
                case "ErrorMissingMachineNo": 
                case "ErrorMissingCustomer":
                case "ErrorMissingModel":
                case "ErrorMissingDept":
                case "ErrorMissingTitle":
                case "ErrorMissingInstallationDate":
                case "ErrorMissingLocation":
                case "ErrorMissingSortGroup1":
                case "ErrorMissingSortGroup2":
                case "ErrorMissingSortGroup3":  
                case "ErrorMissingSortGroup4":  
                case "ErrorMissingSortGroup5":  
                case "ErrorMissingContact":  
                case "ErrorMissingAddressRow":   
                {
                    error = t("Machine." + String(Props.CreateMachine?.error));
                    break;
                }           
                default: {                                   
                    error = Props.CreateMachine?.error;
                }

            }

          setNotifyMessage(error);
          setNotifyStyle('error');
          setShowNotify(true);
        }
    },[Props, Props.CreateMachine, handleCloseNotification, machineNo, t]);

    React.useEffect(()=>{
        if(token){
            getModels(token, appUrl, email).then(x => setModels(x));            
            getDepartments(token, email, appUrl);
            getCountries(token, appUrl, email).then(x => setCountries(x));
            getMachineSortGroups(token, appUrl, email).then(x => setSortGroups(x));         
            getPropertyCodes(token, appUrl, email).then(x => setPropertyCodes(x));            
        }       
    },[token, email, appUrl, getDepartments]); 

    React.useEffect(()=>{
        if(token){                   
            getCustomerList(token, email, appUrl, loggedInCustomer);              
            if (showMachineProperties) {
                getMachineProperties(token, appUrl, machineGuid ?? "", email).then(x => setMachineProperties(x));
            }
        }       
    }, [token, email, appUrl, loggedInCustomer, machineGuid, showMachineProperties, getCustomerList]);

    React.useEffect(()=>{
        if(token){                   
            getContacts(token, email, appUrl, machineCustomerGuid ?? loggedInCustomer, machineGuid ?? "", TaskTypeEnums.Service);
            getVisitAddresses(token, appUrl, machineCustomerGuid ?? loggedInCustomer, email).then(x => setVisitAddresses(x));
        }       
    },[token, email, appUrl, machineCustomerGuid, loggedInCustomer, machineGuid, getContacts]); 

    React.useEffect(() => {
        if(token && countries.length>0){
          GetTaskLimitedPostalCodes(token,machineCountry ? machineCountry.split(",") : countries.map(prop => {               
            return prop.Code;
          }), code, email, appUrl);
        }
      },[machineCountry, countries, GetTaskLimitedPostalCodes, token, email, appUrl]);

    React.useEffect(()=>{
        if(token && Props.customerList.length>0){
            if(machineData !== undefined && machineData.CustomerGuid === undefined && machineData.CustomerName === undefined){
                let customer = Props.customerList?.find(ex => ex.CustomerGuid === loggedInCustomer)
                machineData.CustomerGuid = customer?.CustomerGuid;
                machineData.CustomerName = customer?.CustomerName;
            } 
        }          
    },[Props.customerList, token, email, appUrl, machineData, loggedInCustomer]);

     const handleSubmitButtonClicked = async () => { 
        setShowNotify(false);
        dispatch(clearMachine());
        if(machineData){ 
            if(machineData?.CustomerGuid === undefined && machineData?.CustomerName){
                let customer = Props.customerList?.find(ex => ex.CustomerName === machineData?.CustomerName)
                machineData.CustomerGuid = customer?.CustomerGuid;
            } 
            if(machineData.CustomerGuid === undefined){                 
                setNotifyMessage(t("Machine.ErrorMissingCustomer"));
                setNotifyStyle('error');
                setShowNotify(true);                                   
            }else if(machineData.CustomerGuid){
                if(machineData.MachineGuid){
                    dispatch(updateMachine({token, appUrl, email, body:machineData}))
                }else{
                    dispatch(insertMachine({token, appUrl, email, body:machineData}))                   
                }
            }
        }
    } 

    return (
        <>
        <MachineDetailsGroup 
        submitButtonAction={handleSubmitButtonClicked} 
        closeButtonAction={handleCloseButtonAction}
        postCodeItems={postCodesList}
        customers={Props.customerList}
        departmentCodeItems={departmentCodesList}
        models={models}
        sortGroup1Item={sortGroups[0]}
        sortGroup2Item={sortGroups[1]}
        sortGroup3Item={sortGroups[2]}
        sortGroup4Item={sortGroups[3]}
        sortGroup5Item={sortGroups[4]}
        machineProperties={machineProperties}
        propertyCodes={propertyCodes}
        contactList={Props.contactList}
        machineData={machineData}
        countries={countries}
        visitAddresses={visitAddresses}
        visibilitySettings={visibilitySettings}
        setMachineData={setMachineData}
    />
    <AsolviNotification
        message = {notifyMessage}
        showNotify = {showNotify}
        showIcon = {true}
        closeAction = {handleCloseNotification}
        style = {notifyStyle} /> 
        </>
    );
} 

async function getMachineSortGroups(token: string, appUrl: string, email: string): Promise<ICustomerSortGroupItem[]> {
    const url = appUrl + apiEndPoints.MachineAPIs.GetMachineSortGroups(email);
    const response = await axios.get(url, { headers: { token }});
    let result = response.data as ICustomerSortGroupItem[];
    result.sort((a,b) => a.sortGroupNo - b.sortGroupNo);
    return result;
}

async function getModels(token: string, appUrl: string, email: string): Promise<IMachineModelItem[]> {
    const url = appUrl + apiEndPoints.MachineAPIs.GetModels(email);
    const response = await axios.get(url, { headers: { token }});
    return response.data as IMachineModelItem[];
}

async function getMachineProperties(token: string, appUrl: string, machineGuid: string, email: string): Promise<IMachinePropertyItem[]> {    
    let data = [] as IMachinePropertyItem[];
    if (machineGuid !== "") {
        const url = appUrl + apiEndPoints.CustomerWebAPIs.GetMachineProperties(machineGuid, email);
        const response = await axios.get(url, { headers: { token }});
        data = response.data as IMachinePropertyItem[];
    }    
    return data;
}

async function getPropertyCodes(token: string, appUrl: string, email: string): Promise<IPropertyCodes[]> {
    const url = appUrl + apiEndPoints.MachineAPIs.GetPropertyCodes(email);
    const response = await axios.get(url, { headers: { token }});
    return response.data as IPropertyCodes[];
}

async function getCountries(token: string, appUrl: string, email: string): Promise<ICodeDescription[]> {
    const url = appUrl + apiEndPoints.MachineAPIs.GetCountries(email);
    const response = await axios.get(url, { headers: { token }});
    return (response.data as ICodeDescription[]).filter(x => x.Code);
}

async function getVisitAddresses(token: string, appUrl: string, customerGuid: string, email: string): Promise<ICustomerVisitAddress[]> {
    const url = appUrl + apiEndPoints.CustomerWebAPIs.GetVisitAddresses(customerGuid, email);
    const response = await axios.get(url, { headers: { token }});
    let result = response.data as ICustomerVisitAddress[];
    result.sort((a,b) => a.RowNo - b.RowNo);
    return result;
}

const mapStateToProps = (store: Store.IAppState) => {
    return {
        General: store.generalState.General,
        Settings: store.settingsState.Settings,
        loggedInCustomer: store.generalState.General.activeCustomer,
        currentAuth0Token: store.generalState.General.currentAuth0Token,
        currentUserEmail: store.generalState.General.currentUserEmail,
        TaskPostCodes: store.taskState.TaskPostalCodes,  
        customerList: store.taskState.TaskCustomers,
        DepartmentPostCodes: store.settingsState.Settings.departmentCodes,
        contactList: store.taskState.TaskContacts, 
        CreateMachine: store.machineState.CreateMachine 
    };
}
    
const mapDispatchToProps = (dispatch: any) => {
    return {
        getContacts: (token: string, email:string, appUrl: string, customerGuid: string, machineGuid: string, taskType: number) => 
        dispatch(TaskActions.GetTaskContacts(token, email, appUrl, customerGuid, machineGuid, taskType)),  
        getDepartments: (token: string, email:string, appUrl: string) => dispatch(SettingsActions.GetDepartments(token, email, appUrl)),
        getCustomerList: (token: string, email:string, appUrl: string, loggedInCustomer: string) => dispatch(TaskActions.GetTaskCustomerList(token, email, appUrl, loggedInCustomer)),   
        GetTaskLimitedPostalCodes: (token: string, countries: string[], code: string, email: string, appUrl: string) => dispatch(TaskActions.GetTaskLimitedPostalCodes(token, countries, code, email, appUrl)),
    };
};

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ReactI18Next.withTranslation()(MachineDetailsSeedComponent));

