import { useInternationalization } from "@progress/kendo-react-intl";
import React, { CSSProperties } from "react";
import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IOrderLine, IOrderVisibilitySettings } from "../../../slices/OrderSlice";
import AsolviLabel from "../../Elements/AsolviLabel/AsolviLabel";

declare interface Props {
    lines: IOrderLine[];
    visibilitySettings: IOrderVisibilitySettings;
    style?: CSSProperties;
}

const OrderSumGroup: FC<Props> = ({lines, visibilitySettings, style}) => {
    const { t } = useTranslation();
    const intlService = useInternationalization();
    const totalQty = lines.reduce(
        (total, currentValue) => Number((total + (currentValue?.Qty ?? 0)).toFixed(2)), 0
    );
    const totalSalesPrice = lines.reduce(
        (total, currentValue) => Number((total + (currentValue.Invoiceable ? (currentValue?.SumPriceWithDiscount ?? 0) : 0)).toFixed(2)), 0
    );
    const totalVatCode = lines.reduce(
        (total, currentValue) => Number((total + (currentValue.Invoiceable ? (currentValue?.SumPriceWithDiscount ?? 0) : 0) * (currentValue?.VatCode / 100 ?? 0)).toFixed(2)), 0
    );

    return (
        <Row style={style}>
            {visibilitySettings.ShowAddOrderLine?.ShowSalesPrice &&
                <Col sm={2}>
                    <AsolviLabel labelText={t("Order.Total") + ": "}
                        value={intlService.formatNumber((totalSalesPrice ?? 0), "n2")}
                        labelColSize={5}
                        valueColSize={3}
                    />
                </Col>
            }
            {visibilitySettings.ShowAddOrderLine?.ShowSalesPrice && visibilitySettings.ShowAddOrderLine?.ShowVat &&
                <Col sm={2}>
                    <AsolviLabel labelText={t("Order.VAT") + ": "}
                        value={intlService.formatNumber((totalVatCode ?? 0), "n2")}
                        labelColSize={5}
                        valueColSize={3}
                    />
                </Col>
            }
            <Col sm={2}>
                <AsolviLabel labelText={t("Order.Articles") + ": "}
                    value={(totalQty ?? 0)}
                    labelColSize={6}
                    valueColSize={3}
                />
            </Col>
        </Row>
    );
}

export default OrderSumGroup;