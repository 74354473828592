import * as React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import * as Store from "../../../store/Store";
import * as ReactRedux from "react-redux";
import MeterReadingPage from "../../Pages/MeterReadingPage/MeterReadingPage";
import * as GeneralReducer from "../../../reducers/GeneralReducer";
import * as MeterReadingReducer from "../../../reducers/MeterReadingReducer";
import * as MachinesMetersItems from "../../../sources/MachinesMetersitems";
import * as MeterReadingActions from "../../../actions/MeterReadingActions";
import AsolviNotification from "../../Elements/AsolviNotification/AsolviNotification";
import * as I18Next from "i18next";
import * as ReactI18Next from 'react-i18next';
import i18n from "../../../i18n";
import { parseDate } from '@telerik/kendo-intl';
import MachineMeterReportingSettings from "../../../sources/MachineMeterReportingSettings";

interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  MeterReading: MeterReadingReducer.IMeterReading;
  General: GeneralReducer.IGeneral;
  getSettings: (token: string, appUrl: string, email: string) => void;
  getMachinesAndMeters: (token: string, appUrl: string, customerGuid: string, email: string) => void;
  submitMetersReading: (token: string, appUrl: string, language: string, customerGuid: string, email: string, body: IMachineInfo) => void;
}

interface IGridState {
  data : IMeterInfo[]
}

export interface IMeterInfo {
  meterType: string;
  meterDescription: string;
  meterGuid: string;
  oldCounter?: number;
  oldReadingDate?: string;
  oldReadingDateFormatted?: string;
  newCounter?: number;
  newReadingDate: string;
  newReadingDateFormatted: Date;
  inEdit: boolean
}
 
export interface IMachineInfo {
  modelType: string;
  machineNo: string;
  machineGuid: string;
  idNo?: string;
  machineAddress?: string;
  location?: string;
  meters: IMeterInfo[];
}


  const MeterReadingPageSeedComponent: React.FunctionComponent<IProps> = (Props) => {
    const { t, getSettings, getMachinesAndMeters } = Props;
    const error = Props.MeterReading.error;
    const meterGridEditField = "inEdit";
    const settings = MachineMeterReportingSettings(Props.MeterReading.settings);
    const { appUrl } = (window as any)["appConfig"];
    const machinesList = MachinesMetersItems.default(Props.MeterReading.machinesAndMeters);
    
    const emptyMachine: IMachineInfo = { modelType: "", machineNo: "", machineGuid: "", meters: [], location: "", machineAddress: ""};
    const [selectedMachine, setSelectedMachine] = React.useState(emptyMachine);
    const [gridState, setGridState] = React.useState<IGridState>({ data: selectedMachine.meters });
    const [showSearchGrid, setShowSearchGrid] = React.useState<boolean>(false);
    const [showNotify, setShowNotify] = React.useState<boolean>(false);
    const [notifyMessage, setNotifyMessage] = React.useState<string>("");
    const [notifyStyle, setNotifyStyle] = React.useState<any>(undefined);

    React.useEffect(()=>{
      Props.MeterReading.error = undefined;
      getSettings(Props.General.currentAuth0Token, appUrl, Props.General.currentUserEmail);
      getMachinesAndMeters(Props.General.currentAuth0Token, appUrl, Props.General.activeCustomer, Props.General.currentUserEmail);
    },[Props.General.currentAuth0Token, Props.MeterReading.error, appUrl, Props.General.activeCustomer, Props.General.currentUserEmail, getSettings, getMachinesAndMeters]);

    React.useEffect(()=>{
      if(error === ""){
        setSelectedMachine(emptyMachine);
        setNotifyMessage(t("MeterReporting.meterReportSuccess"));
        setNotifyStyle('success');
        setShowNotify(true);
      } else if (error !== undefined && error !== ""){
        setNotifyMessage(t(error));
        setNotifyStyle('error');
        setShowNotify(true);
      }
    },[error, t, emptyMachine]);

    const handleSelectedMachine = (event: string) => {
      Props.MeterReading.error = undefined;
      setShowNotify(false);
      const ix = machinesList.map(m => { return m.machineGuid }).indexOf(event);
      setSelectedMachine(machinesList[ix]);
    }
    
    const handleAcceptButtonClicked = () => {
      // post meters to backend
      Props.MeterReading.error = undefined;
      Props.submitMetersReading(Props.General.currentAuth0Token, appUrl, i18n.language, Props.General.activeCustomer, Props.General.currentUserEmail, selectedMachine);
    }

    const handleMeterGridItemChange = (event: any) => {
      Props.MeterReading.error = undefined;
      setShowNotify(false);
      const inEditID = event.dataItem.meterGuid;
      const editingDate = (event.field === "newReadingDateFormatted");

      const data = gridState.data.map((item: IMeterInfo) =>
        editingDate ?
          item.meterGuid === inEditID ? { ...item, [event.field]: event.value, "newReadingDate": parseDate(event.value).toDateString() } : item
        :
          item.meterGuid === inEditID ? { ...item, [event.field]: event.value } : item
      );
      setGridState({ data });

      let updatedMachine = selectedMachine;
      const index = updatedMachine.meters.findIndex((record: IMeterInfo) => record.meterGuid === inEditID);
      editingDate ?
        updatedMachine.meters[index] = { ...updatedMachine.meters[index], [event.field]: event.value, "newReadingDate": parseDate(event.value).toDateString() }
      :
        updatedMachine.meters[index] = { ...updatedMachine.meters[index], [event.field]: event.value }

      setSelectedMachine(updatedMachine);
    };
      
    const onMachineSearchRowClick = (e: any) => {
      handleSelectedMachine(e.dataItem.machineGuid);
      setShowSearchGrid(false);
    };

    const handleSelectButtonClicked = () => {
      setShowSearchGrid(!showSearchGrid);
    }

    const handleCloseNotification = () => {
      Props.MeterReading.error = undefined;
      setShowNotify(false);
    }

    return (
      <>
        <AsolviNotification
          message = {notifyMessage}
          showNotify = {showNotify}
          showIcon = {true}
          closeAction = {handleCloseNotification}
          style = {notifyStyle}/>
          <MeterReadingPage
            machineSearchGridItems={machinesList}
            acceptButtonAction={handleAcceptButtonClicked}
            selectedMachineMeters={selectedMachine}
            meterGridItemChange={handleMeterGridItemChange}
            meterGridEditField={meterGridEditField}
            settings={settings}
            onMachineSearchRowDoubleClick={onMachineSearchRowClick}
            showSearchGrid={showSearchGrid}
            selectButtonAction={handleSelectButtonClicked}
          />
      </>
    )
}
const mapStateToProps = (store: Store.IAppState) => {
  return {
    General: store.generalState.General,
    MeterReading: store.meterReadingState.MeterReading,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: (token: string, appUrl: string, email: string) => dispatch(MeterReadingActions.GetSettings(token, appUrl, email)),
    getMachinesAndMeters: (token: string, appUrl: string, customerGuid: string, email: string) => dispatch(MeterReadingActions.GetMachinesAndMeters(token, appUrl, customerGuid, email)),
    submitMetersReading: (token: string, appUrl: string, language: string, customerGuid: string, email: string, body: IMachineInfo) => dispatch(MeterReadingActions.SubmitMetersReading(token, appUrl, language, customerGuid, email, body))
  }
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ReactI18Next.withTranslation()(MeterReadingPageSeedComponent));