import React from "react";
import styles from "./MachineFilter.module.css";
import * as I18Next from "i18next";
import * as ReactI18Next from 'react-i18next';
import { Col, Container, Row } from "react-bootstrap";

import AsolviInput from "../../../Elements/AsolviInput/AsolviInput";
import CustomerFilter from "./CustomerFilter";
import { ICodeDescription, ICustomerSortGroupItem, IMachineCustomerItem, IMachineModelItem, IMachineSearchDataContract } from "../../../seedComponents/Pages/MachinePageSeedComponent";
import AsolviDateRangePicker from "../../../Elements/AsolviDateRangePicker/AsolviDateRangePicker";
import ModelsFilter from "./ModelsFilter";
import CustomerSortGroupFilter from "./CustomerSortGroupFilter";
import MachinePropertyFilter from "./MachinePropertyFilter";

export interface Props extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  searchData: IMachineSearchDataContract;
  customers: IMachineCustomerItem[];
  sortGroup1Item?: ICustomerSortGroupItem;
  sortGroup2Item?: ICustomerSortGroupItem;
  sortGroup3Item?: ICustomerSortGroupItem;
  sortGroup4Item?: ICustomerSortGroupItem;
  sortGroup5Item?: ICustomerSortGroupItem;
  models: IMachineModelItem[];
  machineProperties: ICodeDescription[];
  setSearchFilter: (searchData: IMachineSearchDataContract) => void;
}

const MachineFilter: React.FunctionComponent<Props> = (Props) => {
  let { t, searchData, setSearchFilter, customers, models, sortGroup1Item, sortGroup2Item, sortGroup3Item, sortGroup4Item, sortGroup5Item, machineProperties } = Props;

  const updateSearchData = (newSearchData: Partial<IMachineSearchDataContract>) => {
    let merged = { ...searchData, ...newSearchData };
    setSearchFilter(merged);
  };

  let sortGroupRows = [];
  if (sortGroup1Item?.SortGroupValues?.length) {
    sortGroupRows.push(
      <Row key="sortGroup1Item">
        <Col>
          <CustomerSortGroupFilter
            sortGroup={sortGroup1Item}
            selectedCode={searchData.CustomerSortGroup1}
            onSelected={(code: ICodeDescription) => updateSearchData({ CustomerSortGroup1: code?.Code })}
          />
        </Col>
      </Row>
    );
  }
  if (sortGroup2Item?.SortGroupValues?.length) {
    sortGroupRows.push(
      <Row key="sortGroup2Item">
        <Col>
          <CustomerSortGroupFilter
            sortGroup={sortGroup2Item}
            selectedCode={searchData.CustomerSortGroup2}
            onSelected={(code: ICodeDescription) => updateSearchData({ CustomerSortGroup2: code?.Code })}
          />
        </Col>
      </Row>
    );
  }
  if (sortGroup3Item?.SortGroupValues?.length) {
    sortGroupRows.push(
      <Row key="sortGroup3Item">
        <Col>
          <CustomerSortGroupFilter
            sortGroup={sortGroup3Item}
            selectedCode={searchData.CustomerSortGroup3}
            onSelected={(code: ICodeDescription) => updateSearchData({ CustomerSortGroup3: code?.Code })}
          />
        </Col>
      </Row>
    );
  }
  if (sortGroup4Item?.SortGroupValues?.length) {
    sortGroupRows.push(
      <Row key="sortGroup4Item">
        <Col>
          <CustomerSortGroupFilter
            sortGroup={sortGroup4Item}
            selectedCode={searchData.CustomerSortGroup4}
            onSelected={(code: ICodeDescription) => updateSearchData({ CustomerSortGroup4: code?.Code })}
          />
        </Col>
      </Row>
    );
  }
  if (sortGroup5Item?.SortGroupValues?.length) {
    sortGroupRows.push(
      <Row key="sortGroup5Item">
        <Col>
          <CustomerSortGroupFilter
            sortGroup={sortGroup5Item}
            selectedCode={searchData.CustomerSortGroup5}
            onSelected={(code: ICodeDescription) => updateSearchData({ CustomerSortGroup5: code?.Code })}
          />
        </Col>
      </Row>
    );
  }

  let propertyRows = [
    <Row key="Property1">
      <Col>
        <MachinePropertyFilter
          label={t("Machine.Property1")}
          codes={machineProperties}
          selectedCode={searchData.PropertyFilterCode1}
          selectedValue={searchData.PropertyFilterValue1}
          onChange={(value: { Code?: string, Value?: string }) => {
            updateSearchData({ PropertyFilterCode1: value?.Code, PropertyFilterValue1: value?.Value })
          }}
        />
      </Col>
    </Row>,
    <Row key="Property2">
      <Col>
        <MachinePropertyFilter
          label={t("Machine.Property2")}
          codes={machineProperties}
          selectedCode={searchData.PropertyFilterCode2}
          selectedValue={searchData.PropertyFilterValue2}
          onChange={(value: { Code?: string, Value?: string }) => {
            updateSearchData({ PropertyFilterCode2: value?.Code, PropertyFilterValue2: value?.Value })
          }}
        />
      </Col>
    </Row>
  ];

  return (
    <Container fluid className={styles.machineGridFilter}>
      <Row>
        <Col sm={12} md={6}>
          <Row>
            <Col>
              <CustomerFilter
                customers={customers}
                selectedCustomerGuid={searchData.CustomerGuid}
                onCustomerSelected={(e: IMachineCustomerItem) => updateSearchData({ CustomerGuid: e?.CustomerGuid })}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <AsolviInput
                labelText={t("Machine.SearchText")}
                inputValue={searchData.SearchText}
                onChange={(e: any) => updateSearchData({ SearchText: e.value })}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ModelsFilter
                models={models}
                selectedModelGuid={searchData.ModelGuid}
                onModelSelected={(model: IMachineModelItem) => updateSearchData({ ModelGuid: model?.ModelGuid })}
              />
            </Col>
          </Row>
          { sortGroupRows }
          <Row>
            <Col>
              <AsolviDateRangePicker
                labelText={t("Machine.InstallationDate")}
                fromDate={searchData.InstallationDateFrom}
                toDate={searchData.InstallationDateTo}
                onSelect={(e) => updateSearchData({ InstallationDateFrom: e.value.fromDate, InstallationDateTo: e.value.toDate })}
              />
            </Col>
          </Row>
          { machineProperties?.length ? propertyRows : null }
        </Col>
      </Row>
    </Container>
  );
}

export default ReactI18Next.withTranslation()(MachineFilter);