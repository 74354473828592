import React from "react";
import * as ReactI18Next from "react-i18next";
import * as I18Next from "i18next";
import AsolviAdvancedDropdownList, { IAsolviAdvancedDropdownListChangeEvent } from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import { ICodeDescription } from "../../../seedComponents/Pages/MachinePageSeedComponent";

import styles from './MachinePropertyFilter.module.css';
import { Input } from "@progress/kendo-react-inputs";

export interface Props extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  label: string;
  codes: ICodeDescription[];
  selectedCode?: string;
  selectedValue?: string;
  onChange: (e: { Code?: string, Value?: string }) => void
}

const MachinePropertyFilter: React.FunctionComponent<Props> = (Props) => {
  const { t, label, codes, selectedCode, selectedValue, onChange } = Props;
  let property = codes.find(x => x.Code === selectedCode);

  const codeColumns = [
    {
      field: "Code",
      header: t("Machine.PropertyCode"),
      width: 150,
      uniqueKey: "Code"
    },
    {
      field: "Description",
      header: t("Machine.PropertyDescription"),
      width: 400,
      uniqueKey: "Description"
    }
  ];

  const onCodeChange = (e: IAsolviAdvancedDropdownListChangeEvent<ICodeDescription>) => {
    let value = e.value;
    if(!value?.Code)
      return onChange({ Code: undefined, Value: undefined });
    return onChange({ Code: e.value?.Code, Value: selectedValue });
  }

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        {label}
      </div>
      <div className={styles.code}>
        <AsolviAdvancedDropdownList
          labelText=""
          shownText="CodeAndDescription"
          columns={codeColumns}
          data={codes}
          onSelect={onCodeChange}
          selectedItem={property}
          />
      </div>
      <div className={styles.value}>
        <Input
          value={ selectedValue ?? '' }
          onChange={(e) => onChange({ Code: selectedCode, Value: e.value })}
          disabled={ !selectedCode }
          />
      </div>
    </div>
  );
};

export default ReactI18Next.withTranslation()(MachinePropertyFilter);