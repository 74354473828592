import * as React from "react";
import "./MeterReportingPage.css";
import "../../../Auxiliary/AsolviNavBar/AsolviNavBar.css"
import Container from "react-bootstrap/Container";
import { IMachineInfo } from "../../seedComponents/Pages/MeterReportingPageSeedComponent";
import AsolviButton from "../../../Elements/AsolviButton/AsolviButton";
import * as I18Next from "i18next";
import * as ReactI18Next from 'react-i18next';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Row, Col } from "react-bootstrap";
import MachineSearchGrid from "../../Elements/MachineSearchGrid/MachineSearchGrid";
import NavBar from "react-bootstrap/NavBar";
import FooterGroup from "../../../Groups/FooterGroup/FooterGroup";
import logoImage from "../../../../assets/Images/evatic_logo.png";
import { IMeterReportingSettings } from "../../../../sources/MachineMeterReportingSettings";

export interface  Props extends ReactI18Next.WithTranslation{
  t: I18Next.TFunction;
  machineSearchGridItems: IMachineInfo[]
  selectedMachineMeters: IMachineInfo;
  acceptButtonAction: () => void;
  meterGridItemChange: (event: any) => void;
  meterGridEditField: string;
  footerAndBarInfo: IMeterReportingSettings;
  onMachineSearchRowDoubleClick: (event: any) => void;
  showSearchGrid: boolean;
  selectButtonAction: () => void;
};

class MeterReportingPage extends React.Component<Props, object>{
  render() {
    const { t, acceptButtonAction, footerAndBarInfo, machineSearchGridItems, onMachineSearchRowDoubleClick, showSearchGrid, selectedMachineMeters,
          selectButtonAction } = this.props;
    const searchButtonText = selectedMachineMeters.machineGuid !== "" ?
      t("MeterReporting.modelType") + " " + selectedMachineMeters.modelType + " / " +
      t("Standard.machine") + " " + selectedMachineMeters.machineNo + 
      (selectedMachineMeters.location ? " / " + t("MeterReporting.location") + " " + selectedMachineMeters.location : "")
      : t("MeterReporting.selectMachinePrompt");
    return (
      <Container className="MeterReportingContainer" fluid>
        <Col>
          <Row>
            <Col className="header">
              <NavBar className="meterReadingNavBar" expand="lg" sticky="top">
                <div className = "AsolviNavCustomerInfo">
                  <div className = "AsolviNavCustomerInfoDiv">
                  <span>{t("Standard.customerInfo")} </span><span>{ footerAndBarInfo.CustomerName + ' (' + footerAndBarInfo.CustomerNo + ')' }</span>
                  </div>
                  <div className = "AsolviNavCustomerInfoDiv">
                    <span>{t("Standard.customerInfoOrgNo")} </span><span>{ footerAndBarInfo.OrgNo }</span>            
                  </div>
                </div>
              </NavBar>
            </Col>
          </Row>
          <Row className="mainRow">
              <Col> 
                  <Row>
                    <AsolviButton buttonText={searchButtonText} buttonType="button"
                      buttonIconName={showSearchGrid ? "arrow-chevron-up" : "arrow-chevron-down"}
                      buttonAction={selectButtonAction}></AsolviButton>
                    { showSearchGrid ? <MachineSearchGrid gridData={machineSearchGridItems} showMachineId={footerAndBarInfo.ShowMachineId} onRowClick={onMachineSearchRowDoubleClick} /> : null }
                  </Row>
                  <Row>
                    <Grid className="meterGrid"
                        data={[...this.props.selectedMachineMeters.meters]}
                        onItemChange={this.props.meterGridItemChange}
                        editField="inEdit"
                      >
                        <Column field="meterType" title={t("MeterReporting.meterType")} editable={false} width="120px" />
                        <Column field="meterDescription" title={t("MeterReporting.description")} editable={false} />
                        <Column field="oldReadingDateFormatted" title={t("MeterReporting.oldReadingDate")} editable={false} />
                        <Column field="oldCounter" title={t("MeterReporting.oldCounter")} editable={false} format="{0:n}" />                    
                        <Column field="newReadingDateFormatted" title={t("MeterReporting.newReadingDate")} editor="date" editable={!footerAndBarInfo.MeterReadingDateReadOnly} format="{0:d}" />                   
                        <Column field="newCounter" title={t("MeterReporting.newCounter")} editor="numeric" format="{0:n}" />
                    </Grid>
                  </Row>
                  <Row className="buttonRow">
                    <Col></Col>
                    <Col xs={2}>
                      <AsolviButton className="k-primary" buttonText={t("MeterReporting.acceptButtonText")} buttonType="submit"
                        buttonAction={acceptButtonAction} buttonDisabled={( (selectedMachineMeters.machineGuid === "") )}
                      />
                    </Col>
                </Row>
              </Col>    
          </Row>
          <Row className="footer">
            <FooterGroup logoUrl={logoImage}
              appName={"Evatic Customer Web / " + t("MeterReporting.pageHeader")}
              buildNumber={footerAndBarInfo.Version}
              buildDate={footerAndBarInfo.VersionDate}
              companyName={footerAndBarInfo.CompanyName}
              year={(new Date(footerAndBarInfo.VersionDate).getFullYear().toString())}
            />
            </Row>
          </Col>  
      </Container>
    );
  }
}

export default ReactI18Next.withTranslation()(MeterReportingPage)