import * as React from 'react';
import "./AsolviRadioButton.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {RadioButton } from '@progress/kendo-react-inputs';
import "@progress/kendo-theme-material/dist/all.scss";

export interface IProps{
    groupId: string,
    itemId: string,
    label: string,
    selectedId: string,    
    onChange?: (e: any) => void;
}

const AsolviRadioButton: React.FunctionComponent<IProps> = (props) => {
    
    return (        
        <Row>
            <Col sm={12}>
                <RadioButton
                    name={props.groupId}                                                      
                    value={props.itemId}
                    checked={props.selectedId === props.itemId}
                    label={props.label}
                    onChange={props.onChange}
                />     
            </Col>  
        </Row>
    );
}

export default AsolviRadioButton;