import * as React from "react";
import "./FooterGroup.css";
import AsolviFooter from "../../Auxiliary/AsolviFooter/AsolviFooter";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from "i18next";

export interface Props extends ReactI18Next.WithTranslation {
    t: I18Next.TFunction;
    logoUrl: string;
    appName: string;
    buildNumber: string;
    buildDate: string;
    companyName: string;
    year: string;
}

class FooterGroup extends React.Component<Props, Object>{
    render() {
        const { logoUrl, appName, buildNumber, buildDate, companyName, year } = this.props;
        return (
            <>
                <AsolviFooter
                    logoUrl={logoUrl} appName={appName} buildNumber={buildNumber} buildDate={buildDate} companyName={companyName} year={year}
                />
            </>
        );
    }
}

export default ReactI18Next.withTranslation()(FooterGroup);