import * as Redux from 'redux';
import * as ReduxThunk from 'redux-thunk';
import * as TaskReducer from "../reducers/TaskReducer";
import * as Axios from 'axios';
import * as ApiEndPoints from "../sources/apiEndPoints";
import { ICreateTaskInfo } from '../components/seedComponents/Pages/TaskCreatePageSeedComponent';




export enum TaskActionTypes {
  GETRADIOBUTTONLIST = "GetRadioButtonList",
  GETINFOLABELLIST = "GetInfoLabelList",
  GETTASKVISIBILITYSETTINGS = "GetTaskVisibilitySettings",
  GETLIMITEDPOSTALCODES = "GetLimitedPostalCodes",
  GETMACHINESLIST = "GetMachinesList",
  GETSYMPTOMCODES = "GetSymptomCodes",
  GETSYMPTOMCODES2 = "GetSymptomCodes2",
  GETSYMPTOMCODES3 = "GetSymptomCodes3",
  GETSYMPTOMCODES4 = "GetSymptomCodes4",
  SUBMITNEWTASK = "SubmitNewTask",
  TASKRESPONSEERROR = "TaskResponseError",
  GETCUSTOMERLIST = "GetCustomerList",
  GETCUSTOMERPROPERTIES = "GetCustomerProperties",
  GETMACHINEPROPERTIES = "GetMachineProperties",
  HANDLESELECTEDTASKCUSTOMER = "HandleSelectedTaskCustomer",
  GETCONTACTS = "GetContacts",
  SUBMITUPDATECONTACT = "UpdateContact",
  UPDATECONTACTRESPONSEERROR = "UpdateContactResponseError",
  HANDLESELECTEDMACHINE = "HandleSelectedMachine",
  GETTASKLOG = "GetTaskLog",
  SETTASKLOGLOADING = "SetTaskLogLoading"
}

export interface ITaskGetVisibilitySettings {
  type: TaskActionTypes.GETTASKVISIBILITYSETTINGS;
  TaskVisibilitySettings: TaskReducer.ITaskVisibilitySettings;
}

export interface ITaskGetRadioButtonList {
  type: TaskActionTypes.GETRADIOBUTTONLIST;
  TaskRadioButtonProps: TaskReducer.ITaskRadioButtonProps;
}

export interface ITaskGetInfoLabelList {
  type: TaskActionTypes.GETINFOLABELLIST;
  TaskInfoLabels: TaskReducer.ITaskInfoLabels;
}

export interface ITaskGetMachinesList {
  type: TaskActionTypes.GETMACHINESLIST;
  TaskMachines: TaskReducer.ITaskMachines;
}

export interface ITaskGetLimitedPostalCodes {
  type: TaskActionTypes.GETLIMITEDPOSTALCODES;
  TaskPostalCodes: TaskReducer.ITaskPostalCodes
}

export interface ITaskGetSymptomCodes {
  type: TaskActionTypes.GETSYMPTOMCODES;
  TaskSymptomCodes: TaskReducer.ITaskSymptomCodeItems
}

export interface ITaskGetSymptomCodes2 {
  type: TaskActionTypes.GETSYMPTOMCODES2;
  SymptomCode2Items: TaskReducer.ITaskSymptomCode[]
}

export interface ITaskGetSymptomCodes3 {
  type: TaskActionTypes.GETSYMPTOMCODES3;
  SymptomCode3Items: TaskReducer.ITaskSymptomCode[]
}

export interface ITaskGetSymptomCodes4 {
  type: TaskActionTypes.GETSYMPTOMCODES4;
  SymptomCode4Items: TaskReducer.ITaskSymptomCode[]
}

export interface ISubmitNewTask {
  type: TaskActionTypes.SUBMITNEWTASK | TaskActionTypes.TASKRESPONSEERROR;
  CreateTask: TaskReducer.ICreateTask;
}

export interface ITaskGetCustomerList {
  type: TaskActionTypes.GETCUSTOMERLIST;
  TaskCustomers: TaskReducer.ITaskCustomerItem[]
}

export interface ITaskGetCustomerProperties {
  type: TaskActionTypes.GETCUSTOMERPROPERTIES;
  TaskCustomerProperties: TaskReducer.ITaskProperyItem[]
}

export interface ITaskGetMachineProperties {
  type: TaskActionTypes.GETMACHINEPROPERTIES;
  TaskMachineProperties: TaskReducer.ITaskProperyItem[]
}

export interface ITaskHandleSelectedCustomer {
  type: TaskActionTypes.HANDLESELECTEDTASKCUSTOMER;
  SelectedCustomer: string
}

export interface ITaskGetContacts {
  type: TaskActionTypes.GETCONTACTS;
  TaskContacts: TaskReducer.ITaskContactGroupData[]
}

interface ITaskUpdateContact{
  type: TaskActionTypes.SUBMITUPDATECONTACT | TaskActionTypes.UPDATECONTACTRESPONSEERROR;
  TaskUpdateContact: TaskReducer.ITaskUpdateContact;
}

export interface ITaskHandleSelectedMachine {
  type: TaskActionTypes.HANDLESELECTEDMACHINE;
  SelectedMachine: string
}

export interface ITaskGetLog {
  type: TaskActionTypes.GETTASKLOG;
  TaskLog: TaskReducer.ITaskLogItem[]
}

export interface ITaskSetLoading {
  type: TaskActionTypes.SETTASKLOGLOADING;
  Loading: boolean
}

export type TaskActions = ITaskGetRadioButtonList | ITaskGetInfoLabelList | ITaskGetVisibilitySettings | ITaskGetLimitedPostalCodes |
  ITaskGetMachinesList |
  ITaskGetSymptomCodes | ITaskGetSymptomCodes2 | ITaskGetSymptomCodes3 | ITaskGetSymptomCodes4 |
  ISubmitNewTask | ITaskGetCustomerList |
  ITaskGetCustomerProperties | ITaskGetMachineProperties |
  ITaskHandleSelectedCustomer | ITaskGetContacts |
  ITaskUpdateContact | ITaskHandleSelectedMachine |
  ITaskGetLog | ITaskSetLoading;

export const GetAvailableRadioButtonProps: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetRadioButtonList>
  > = (token: string, email: string, customer:string, appUrl: string) => {
  return async (dispatch: Redux.Dispatch) => {
    try {
      const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetAvailableRadioButtonProps(email, customer), {headers: {token: token}});
      dispatch({
        TaskRadioButtonProps: { radioButtonProps: response.data},
        type: TaskActionTypes.GETRADIOBUTTONLIST
      });
    } catch (err) {
      console.log("Error: "+ err);
    }

  }

};


export const GetAvailableInfoLabels: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetInfoLabelList>
  > = (token: string, email: string, appUrl: string) => {
  return async (dispatch: Redux.Dispatch) => {
    try {
      const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetAvailableInfoLabels(email), {headers: {token: token}});
      dispatch({
        TaskInfoLabels: { labelList: response.data},
        type: TaskActionTypes.GETINFOLABELLIST
      });
    } catch (err) {
      console.log("Error: "+ err);
    }

  }

};

export const GetTaskVisibilitySettings: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetVisibilitySettings>
  > = (token: string, email: string, appUrl: string) => {
  return async (dispatch: Redux.Dispatch) => {
    try {
      const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetVisibilitySettings(email), {headers: {token: token}});
      dispatch({
        TaskVisibilitySettings: {...response.data},
        type: TaskActionTypes.GETTASKVISIBILITYSETTINGS
      });
    } catch (err) {
      console.log("Error: "+ err);
    }

  }

};

export const GetTaskLimitedPostalCodes: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetLimitedPostalCodes>
  > = (token: string, countries: string[], code: string, email: string, appUrl: string) => {
  return async (dispatch: Redux.Dispatch) => {
    try {
      const response = await Axios.default.post(appUrl + ApiEndPoints.CustomerWebAPIs.GetLimitedPostalCodes(code, email), countries, {headers: {token: token}});
      dispatch({
        TaskPostalCodes: {PostalCodes: response.data},
        type: TaskActionTypes.GETLIMITEDPOSTALCODES
      });
    }catch(err) {
      console.log("Error: "+ err);
    }
  }
};

export const GetMachinesList: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetMachinesList>
  > = (token: string, customer: string, email: string, appUrl: string) => {

  return async (dispatch: Redux.Dispatch) => {
    try {
      const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetMachinesList(customer, email), {headers: {token: token}});
      dispatch({
        TaskMachines: { machinesList: response.data },
        type: TaskActionTypes.GETMACHINESLIST
      });
    } catch (err) {
      console.log("Error: "+ err);
    }

  }
};

export const GetTaskSymptomCodes: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetSymptomCodes>
  > = (token: string, email: string, appUrl: string, taskType?: number) => {

  if (typeof taskType === 'undefined') {
    taskType = 1;
  }

  return async (dispatch: Redux.Dispatch) => {
    try {
      const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetSymptomCodesAll(email, taskType!), {headers: {token: token}});
      dispatch({
        TaskSymptomCodes: {...response.data},
        type: TaskActionTypes.GETSYMPTOMCODES
      });
    } catch (err) {
      console.log("Error: "+ err);
    }

  }

};

export const GetTaskSymptomCodes2: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetSymptomCodes2>
  > = (token: string, email: string, appUrl: string, selectedSymptomCode1: string, taskType?: number) => {

  return async (dispatch: Redux.Dispatch) => {

    if (typeof taskType === 'undefined') {
      taskType = 1;
    }

    if (selectedSymptomCode1 !== undefined && selectedSymptomCode1.length > 0) {
      try {
        const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetSymptomCodes2(selectedSymptomCode1, email, taskType), {headers: {token: token}});
        dispatch({
          SymptomCode2Items: response.data,
          type: TaskActionTypes.GETSYMPTOMCODES2
        });
      } catch (err) {
        dispatch({
          SymptomCode2Items: [],
          type: TaskActionTypes.GETSYMPTOMCODES2
        });
        console.log("Error: "+ err);
      }
    } else {
      dispatch({
        SymptomCode2Items: [],
        type: TaskActionTypes.GETSYMPTOMCODES2
      });
    }
  }

};

export const GetTaskSymptomCodes3: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetSymptomCodes3>
  > = (token: string, email: string, appUrl: string, selectedSymptomCode1: string, selectedSymptomCode2: string, taskType?: number) => {

  return async (dispatch: Redux.Dispatch) => {

    if (typeof taskType === 'undefined') {
      taskType = 1;
    }

    if (selectedSymptomCode1 !== undefined && selectedSymptomCode1.length > 0 && selectedSymptomCode2 !== undefined && selectedSymptomCode2.length > 0) {
      try {
        const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetSymptomCodes3(selectedSymptomCode1, selectedSymptomCode2, email, taskType), {headers: {token: token}});
        dispatch({
          SymptomCode3Items: response.data,
          type: TaskActionTypes.GETSYMPTOMCODES3
        });
      } catch (err) {
        dispatch({
          SymptomCode3Items: [],
          type: TaskActionTypes.GETSYMPTOMCODES3
        });
        console.log("Error: "+ err);
      }
    } else {
      dispatch({
        SymptomCode3Items: [],
        type: TaskActionTypes.GETSYMPTOMCODES3
      });
    }

  }

};

export const GetTaskSymptomCodes4: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetSymptomCodes3>
  > = (token: string, email: string, appUrl: string, selectedSymptomCode1: string, selectedSymptomCode2: string, selectedSymptomCode3: string, taskType?: number) => {

  return async (dispatch: Redux.Dispatch) => {
    if (typeof taskType === 'undefined') {
      taskType = 1;
    }

    if (selectedSymptomCode1 !== undefined && selectedSymptomCode1.length > 0
      && selectedSymptomCode2 !== undefined && selectedSymptomCode2.length > 0
      && selectedSymptomCode3 !== undefined && selectedSymptomCode3.length > 0) {
      try {
        const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetSymptomCodes4(selectedSymptomCode1, selectedSymptomCode2, email, taskType), {headers: {token: token}});
        dispatch({
          SymptomCode4Items: response.data,
          type: TaskActionTypes.GETSYMPTOMCODES4
        });
      } catch (err) {
        dispatch({
          SymptomCode4Items: [],
          type: TaskActionTypes.GETSYMPTOMCODES4
        });
        console.log("Error: "+ err);
      }
    } else {
      dispatch({
        SymptomCode4Items: [],
        type: TaskActionTypes.GETSYMPTOMCODES4
      });
    }

  }

};

export const SubmitNewTask: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ISubmitNewTask>
  > = (token: string, email: string, taskType:string, appUrl: string, body: ICreateTaskInfo, culture?: string, loggedInCustomer?: string ) => {
  return async (dispatch: Redux.Dispatch) => {

    if (typeof culture === 'undefined') {
      culture = "";
    }
    if (typeof loggedInCustomer === 'undefined') {
      loggedInCustomer = "";
    }

    try {
      const response = await Axios.default.post(appUrl + ApiEndPoints.CustomerWebAPIs.CreateTask(email, taskType, culture, loggedInCustomer), body, { headers: { token: token } });

      dispatch({
        CreateTask: response.data,
        type: TaskActionTypes.SUBMITNEWTASK
      });
      dispatch({
        CreateTask: {error: ""},
        type: TaskActionTypes.TASKRESPONSEERROR
      })
    }catch(err){
      dispatch({
        CreateTask: {error: err.response.data.DataContract.Message},
        type: TaskActionTypes.TASKRESPONSEERROR
      })
    }
  }
};

export const ResetCreateTaskResponse: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, TaskReducer.ITaskState, null, ISubmitNewTask>
  > = (projectNo: string, error:string) => {
  return (dispatch: Redux.Dispatch) => {
    dispatch({
      CreateTask: {error: error === "" ? undefined : error},
      type: TaskActionTypes.TASKRESPONSEERROR
    });
    dispatch({
      CreateTask: { ProjectNo: projectNo === "" ? undefined : projectNo},
      type: TaskActionTypes.SUBMITNEWTASK
    });
  };
};

export const GetTaskCustomerList: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetCustomerList>
  > = (
    token: string,
    email: string,
    appUrl: string,
    mainCustomerGuid: string
  ) => {
  return async (dispatch: Redux.Dispatch) => {
    try {
      const response = await Axios.default.get(
        appUrl + ApiEndPoints.CustomerWebAPIs.GetCustomersLinkedToMain(mainCustomerGuid, email),
        {headers: {token: token}}
      );
      dispatch({
        TaskCustomers: response.data,
        type: TaskActionTypes.GETCUSTOMERLIST
      });
    } catch (err) {
      console.log("Error: "+ err);
    }

  }

};

export const GetTaskCustomerProperties: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetCustomerProperties>
  > = (token: string, email: string, appUrl: string, customerGuid: string) => {
  return async (dispatch: Redux.Dispatch) => {
    try {
      const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetCustomerProperties(customerGuid, email), {headers: {token: token}});
      dispatch({
        TaskCustomerProperties: response.data,
        type: TaskActionTypes.GETCUSTOMERPROPERTIES
      });
    } catch (err) {
      dispatch({
        TaskCustomerProperties: [],
        type: TaskActionTypes.GETCUSTOMERPROPERTIES
      });
      console.log("Error: "+ err);
    }

  }

};

export const GetTaskMachineProperties: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetMachineProperties>
  > = (token: string, email: string, appUrl: string, machineGuid: string) => {
  return async (dispatch: Redux.Dispatch) => {
    if (machineGuid !== "") {
      try {
        const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetMachineProperties(machineGuid, email), {headers: {token: token}});
        dispatch({
          TaskMachineProperties: response.data,
          type: TaskActionTypes.GETMACHINEPROPERTIES
        });
      } catch (err) {
        dispatch({
          TaskMachineProperties: [],
          type: TaskActionTypes.GETMACHINEPROPERTIES
        });
        console.log("Error: "+ err);
      }
    } else {
      dispatch({
        TaskMachineProperties: [],
        type: TaskActionTypes.GETMACHINEPROPERTIES
      });
    }
  }
};

export const TaskHandleSelectedCustomer: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, TaskReducer.ITaskState, null, ITaskHandleSelectedCustomer>
  > = (selectedCustomer:string) => {
  return (dispatch: Redux.Dispatch) => {
    dispatch({
      SelectedCustomer: selectedCustomer,
      type: TaskActionTypes.HANDLESELECTEDTASKCUSTOMER
    })
  };
};

export const GetTaskContacts: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetContacts>
  > = (token: string, email: string, appUrl: string, customerGuid: string, machineGuid: string, taskType?: number) => {
  return async (dispatch: Redux.Dispatch) => {
    if (customerGuid !== "") {

      if (typeof taskType === 'undefined') {
        taskType = 1;
      }

      try {
        const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetContacts(customerGuid, (machineGuid === "" ? null : machineGuid), email, taskType), {headers: {token: token}});
        dispatch({
          TaskContacts: response.data,
          type: TaskActionTypes.GETCONTACTS
        });
      } catch (err) {
        dispatch({
          TaskContacts: [],
          type: TaskActionTypes.GETCONTACTS
        });
        console.log("Error: "+ err);
      }
    } else {
      dispatch({
        TaskContacts: [],
        type: TaskActionTypes.GETCONTACTS
      });
    }
  }
};

export const SubmitTaskUpdateContact: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskUpdateContact>
  > = (token: string, email: string, appUrl: string, body: TaskReducer.ITaskContactGroupData) => {
  return async (dispatch: Redux.Dispatch) => {
    try {
      const response = await Axios.default.post(appUrl + ApiEndPoints.CustomerWebAPIs.UpdateContact(email), body, { headers: { token: token } });
      dispatch({
        TaskUpdateContact: response.data,
        type: TaskActionTypes.SUBMITUPDATECONTACT
      });
      dispatch({
        TaskUpdateContact: {error: ""},
        type: TaskActionTypes.UPDATECONTACTRESPONSEERROR
      })
    }catch(err){
      dispatch({
        TaskUpdateContact: {error: err.response.data.DataContract.Message},
        type: TaskActionTypes.UPDATECONTACTRESPONSEERROR
      })
    }
  }
};

export const ResetTaskUpdateContactResponse: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, TaskReducer.ITaskState, null, ITaskUpdateContact>
  > = (error:string) => {
  return (dispatch: Redux.Dispatch) => {
    dispatch({
      TaskUpdateContact: {error: error},
      type: TaskActionTypes.UPDATECONTACTRESPONSEERROR
    })
  };
};

export const HandleSelectedMachine: Redux.ActionCreator<
  ReduxThunk.ThunkAction<any, TaskReducer.ITaskState, null, ITaskHandleSelectedMachine>
  > = (selectedMachine:string) => {
  return (dispatch: Redux.Dispatch) => {
    dispatch({
      SelectedMachine: selectedMachine,
      type: TaskActionTypes.HANDLESELECTEDMACHINE
    })
  };
};

export const GetTaskLog: Redux.ActionCreator<
  ReduxThunk.ThunkAction<Promise<any>, TaskReducer.ITaskState, null, ITaskGetLog>
  > = (token: string, email: string, appUrl: string, customerGuid: string, filter: TaskReducer.ITaskLogFilter) => {
  return async (dispatch: Redux.Dispatch) => {
    try {
      dispatch({
        Loading: true,
        type: TaskActionTypes.SETTASKLOGLOADING
      });

      const response = await Axios.default.post(appUrl + ApiEndPoints.CustomerWebAPIs.GetTaskLog(customerGuid, email), filter, {headers: {token: token}});
      dispatch({
        TaskLog: response.data,
        type: TaskActionTypes.GETTASKLOG
      });
    } catch (err) {
      dispatch({
        TaskLog: [],
        type: TaskActionTypes.GETTASKLOG
      });
      console.log("Error: "+ err);
    }
  }

};