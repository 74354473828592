import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import * as ApiEndPoints from '../sources/apiEndPoints';
import {
    IArticleInfo,
    ICreateOrder,
    IOrderMachineInfo,
} from "../components/seedComponents/Groups/Order/OrderDetailsGroupSeedComponent";
import {
    IOrderCreateSmallRequestDataContract,
} from "../components/seedComponents/Groups/Order/OrderSummaryGroupSeedComponent";
import {
    IOrderLogDataContract,
    IOrderSearchDataContract,
} from "../components/seedComponents/Pages/OrderLogPageSeedComponent";
import { ICustomerVisitAddress } from "../components/seedComponents/Pages/MachinePageSeedComponent";

const prefix = 'order';

export interface IOrder{
    articles: IArticleInfo[];
    machines: IOrderMachineInfo[];
    shouldShowCurrentOrder: boolean;
    loadingArticles: boolean;
    isLoadingDiscounts: boolean;
    error?: string;
    token: string;
    currentOrder: IOrderCurrentOrder;
    orderNo?: string | undefined;
    orderGuid?: string | undefined;
    visibilitySettings: IOrderVisibilitySettings;
    selectedCustomer: IOrderSelectedCustomer | undefined;
    selectedMachineGuid: string | undefined;
}

export interface IOrderLog {
    searchData: IOrderSearchDataContract;
    orderLog: IOrderLogDataContract[];
    loadingLog: boolean;
    error?: string;
}

export interface IOrderCurrentOrder {
    Heading: IOrderHeading;
    Lines: IOrderLine[];
}

export interface IOrderHeading {
    YourReference?: string;
    CustomerReferenceInfo?: string;
    Information?: string;
    InvoiceAddresRowNo?: number;
    InvoiceCountry?: string;
    InvoicePostLocation?: string;
    InvoicePostalCode?: string;
    InvoiceAddress3?: string;
    InvoiceAddress2?: string;
    Email?: string;
    InvoiceAddress1?: string;
    DeliveryAddresRowNo?: number;
    DeliveryCountry?: string;
    DeliveryPostLocation?: string;
    DeliveryPostalCode?: string;
    DeliveryAddress3?: string;
    DeliveryAddress2?: string;
    DeliveryAddress1?: string;
    DeliveryName?: string;
    CustomerGuid?: string;
    CustomerNo?: string;
    CustomerName?: string;
    InvoiceName?: string;
    MachineAddressAsDeliveryAddress?: boolean;
}

export interface IOrderLine {
    LineNo: number;
    ArticleGuid: string;
    Qty: number;
    MachineGuid: string;
    MachineFriendlyName: string;
    ArticleNo: string;
    Description: string;
    Price: number;
    PriceWithDiscount: number;
    Invoiceable: boolean;
    Quantity: number;
    Discount: number;
    SumPrice: number;
    SumPriceWithDiscount: number;
    DiscountType: DiscountTypeEnum;
    VatCode: number;
}

export interface IOrderVisibilitySettings {
    ShowSelectCustomer: boolean,
    ShowSelectMachine: {
        Group: boolean,
        ShowMachineId: boolean,
        ShowSortGroup1: boolean,
        ShowSortGroup2: boolean,
        ShowSortGroup3: boolean,
        ShowSortGroup4: boolean,
        ShowSortGroup5: boolean,
        SortGroup1Caption: string,
        SortGroup2Caption: string,
        SortGroup3Caption: string,
        SortGroup4Caption: string,
        SortGroup5Caption: string
    },
    ShowProperties: {
        Group: boolean,
        ShowMachineProperties: boolean
    },
    ShowAddOrderLine: {
        ShowSalesPrice: boolean,
        ShowDiscount: boolean,
        ShowVat: boolean,
        MaxQty: number,
        MaxQtyInclOnly: boolean
    },
    ShowApprove: {
        UseMachineAddressAsDelivery: boolean,
        MachineAddressAsDefault: boolean,
        AddressNumber: number,
        ContactListTypeOrder: ContactListType,
        AllowOtherContactOrder: boolean,
        EditDeliveryAddressName: boolean,
        EditDeliveryAddress1: boolean,
        EditDeliveryAddress2: boolean,
        EditDeliveryAddress3: boolean,
        EditDeliveryPostal: boolean,
        EditInvoiceAddressName: boolean,
        EditInvoiceAddress1: boolean,
        EditInvoiceAddress2: boolean,
        EditInvoiceAddress3: boolean,
        EditInvoicePostal: boolean,
        DeliveryAddressNameCaption: string,
        DeliveryAddress1Caption: string, 
        DeliveryAddress2Caption: string,
        DeliveryAddress3Caption: string,
        DeliveryPostInfoCaption: string,
        InvoiceAddressNameCaption: string, 
        InvoiceAddress1Caption: string,
        InvoiceAddress2Caption: string,
        InvoiceAddress3Caption: string,
        InvoicePostInfoCaption: string,
        MandatoryOurReference: boolean,
        MandatoryOrderEMail: boolean
    }
}

export enum ContactListType
{
    Customer = 1,
    Machine = 2,
    Contract = 3
}

export interface IOrderMachines {
    machinesList: IOrderMachineInfo[],
    error?: string 
}

export interface IOrderState{
    readonly Order: IOrder;
    readonly CreateOrder: ICreateOrder;
    readonly OrderLog: IOrderLog;
}

export interface IOrderSelectedCustomer {
    customerGuid: string;
    customerNo: string;
    customerName: string;    
}

const initialState: IOrderState = {
    Order:{
        articles: [],
        machines: [],
        shouldShowCurrentOrder: false,
        loadingArticles: false,
        isLoadingDiscounts: false,
        error: undefined,
        token: "",
        currentOrder: {
            Heading: {} as IOrderHeading,
            Lines: [],
        },
        selectedCustomer: undefined,
        selectedMachineGuid: undefined,
        visibilitySettings:{
            ShowSelectCustomer: false,
            ShowSelectMachine:{
                Group: true,
                ShowMachineId: true,
                ShowSortGroup1: true,
                ShowSortGroup2: true,
                ShowSortGroup3: true,
                ShowSortGroup4: true,
                ShowSortGroup5: true,
                SortGroup1Caption: "SG1",
                SortGroup2Caption: "SG2",
                SortGroup3Caption: "SG3",
                SortGroup4Caption: "SG4",
                SortGroup5Caption: "SG5"
            },
            ShowProperties: {
                Group: true,
                ShowMachineProperties: true
                },
            ShowAddOrderLine: {
                ShowSalesPrice: false,
                ShowDiscount: false,
                ShowVat: false,
                MaxQty: 0,
                MaxQtyInclOnly: false
            },
            ShowApprove: {
                UseMachineAddressAsDelivery: false,
                MachineAddressAsDefault: false,
                AddressNumber: 2,
                ContactListTypeOrder: ContactListType.Contract,
                AllowOtherContactOrder: false,
                EditDeliveryAddressName: false,
                EditDeliveryAddress1: false,
                EditDeliveryAddress2: false,
                EditDeliveryAddress3: false,
                EditDeliveryPostal: false,
                EditInvoiceAddressName: false,
                EditInvoiceAddress1: false,
                EditInvoiceAddress2: false,
                EditInvoiceAddress3: false,
                EditInvoicePostal: false,
                DeliveryAddressNameCaption: "",
                DeliveryAddress1Caption: "", 
                DeliveryAddress2Caption: "",
                DeliveryAddress3Caption: "",
                DeliveryPostInfoCaption: "",
                InvoiceAddressNameCaption: "", 
                InvoiceAddress1Caption: "",
                InvoiceAddress2Caption: "",
                InvoiceAddress3Caption: "",
                InvoicePostInfoCaption: "",
                MandatoryOurReference: false,
                MandatoryOrderEMail: false
            }
        }
    },
    CreateOrder: {
        Success: undefined,
        Company: undefined,
        OrderNo: undefined,
        OrderGuid: undefined,
        ErrorCode: undefined
    },
    OrderLog: {
        loadingLog: false,
        orderLog: [],
        searchData: {
            DatePeriod: "2",
            DateFrom: new Date(), DateTo: new Date()
        },
        error: ""
    }
};

interface IOrderGetVisibilitySettingsArguments {
    token: string;
    appUrl: string;
    email: string;
}

interface IOrderGetQuickOrdersArguments {
    token: string;
    appUrl: string;
    machineGuid: string;
    email: string;
}

interface IOrderMachinesArguments {
    token: string;
    appUrl: string;
    customerGuid: string;
    email: string;
}

interface ISaveOrderArguments {
    token: string,
    appUrl: string,
    email: string,
    culture: string,
    body: IOrderCreateSmallRequestDataContract

}

interface IOrderGetOrderLogArguments {
    token: string;
    appUrl: string;
    email: string;
    searchData: IOrderSearchDataContract
}

export const getMachines = createAsyncThunk(`${prefix}/getMachines`, async ({ token, appUrl, customerGuid, email}: IOrderMachinesArguments) => {
    const response = await Axios.get<IOrderMachineInfo[]>(appUrl + ApiEndPoints.OrderAPIs.GetMachines(customerGuid, email), { headers: { token }});
    return response.data;
});

export const getVisibilitySettings = createAsyncThunk(`${prefix}/getVisibilitySettings`, async ({token, appUrl, email}:IOrderGetVisibilitySettingsArguments) => {
    const response = await Axios.get<IOrderVisibilitySettings>(appUrl + ApiEndPoints.OrderAPIs.GetVisibilitySettings(email), { headers: { token }});
    return response.data;
});

export const getQuickOrders = createAsyncThunk(`${prefix}/getQuickOrders`, async ({ token, appUrl, machineGuid, email}: IOrderGetQuickOrdersArguments) => {
    const response = await Axios.get<IArticleInfo[]>(appUrl + ApiEndPoints.OrderAPIs.GetQuickOrders(machineGuid, email), { headers: { token }});
    return response.data;
});

export const getOrderLog = createAsyncThunk(`${prefix}/getOrderLog`, async ({ token, appUrl, email, searchData}: IOrderGetOrderLogArguments) => {
    const response = await Axios.post<IOrderLogDataContract[]>(appUrl + ApiEndPoints.OrderAPIs.GetOrderLog(email), searchData, { headers: { token }});
    return response.data;
});


export const clearQuickOrders = createAction(`${prefix}/clearQuickOrders`);

export const saveOrder = createAsyncThunk(
  `${prefix}/saveOrder`,
  async ({ token, appUrl, email, culture, body }: ISaveOrderArguments) => {
    try {
        const response = await Axios.post(
          appUrl + ApiEndPoints.OrderAPIs.InsertOrder(email, culture),
          body,
          { headers: { token }}
        );
        return response.data;

    } catch(err) {
        return err;
    }
  }
);

declare enum DiscountTypeEnum {
    None = 0,
    Percentage = 1,
    FixedPrice = 2,
    FixedAmount = 3,
}
declare interface ArticleQuickOrderListItemContract {
    ArticleObjNo: string;
    ArticleNo: string;
    ArticleDescription: string;
    Invoiceable: boolean;
    Qty?: number;
    SalesPrice?: number;
    VatCode?: number;
    SalesPriceWithDiscount?: number;
    Discount?: number;
    DiscountType: DiscountTypeEnum;
}

declare interface IAddOrderLineArguments {
    token: string;
    appUrl: string;
    email: string;
    line: IOrderLine;
}
export const removeOrderLine = createAction<number>(`${prefix}/removeOrderLine`);
export const addOrderLine = createAsyncThunk(`${prefix}/addOrderLine`, async ({ token, appUrl, email, line }: IAddOrderLineArguments, { dispatch }) => {
    line = {
        ...line,
        SumPrice: line.Qty * line.Price,
    };

    // If empty line do not send request
    if(line.Qty <= 0) {
        return line;
    }

    // Fetch discount info for line
    try {
        dispatch(setIsLoadingDiscounts(true));
        let response = await Axios.get<ArticleQuickOrderListItemContract>(appUrl + ApiEndPoints.OrderAPIs.GetPriceAndDiscount(line.MachineGuid, email, line.ArticleGuid, line.Qty), { headers: { token }});
        let data = response.data;

        line = {
            ...line,
            PriceWithDiscount: data.SalesPriceWithDiscount ?? line.Price,
            VatCode: data.VatCode ?? line.VatCode,
            SumPriceWithDiscount: Number(((data.SalesPriceWithDiscount ?? 0) * line.Qty * (line.Invoiceable ? 1 : 0)).toFixed(2)),
            Discount: data.Discount ?? 0,
        };
    } catch(e) {
        console.error(e);
    }
    dispatch(setIsLoadingDiscounts(false));
    return line;
});
export const setOrderLines = createAction<IOrderLine[]>(`${prefix}/setOrderLines`);

export const setIsLoadingDiscounts = createAction<boolean>(`${prefix}/setIsLoadingDiscounts`);

export const updateOrderHeading = createAction<IOrderHeading>(`${prefix}/updateOrderHeading`);
export const updateOrderInvoiceAddress = createAction<ICustomerVisitAddress>(`${prefix}/updateOrderInvoiceAddress`);
export const updateOrderDeliveryAddress = createAction<ICustomerVisitAddress>(`${prefix}/updateOrderDeliveryAddress`);

export const showCurrentOrder = createAction(`${prefix}/showCurrentOrder`);
export const hideCurrentOrder = createAction(`${prefix}/hideCurrentOrder`);
export const clearCurrentOrder = createAction(`${prefix}/clearCurrentOrder`);
export const resetCreateOrderResponse = createAction(`${prefix}/resetCreateOrderResponse`);

export const setSearchFilter = createAction<IOrderSearchDataContract>(`${prefix}/setSearchFilter`);
export const clearSearchFilter = createAction(`${prefix}/clearSearchFilter`);
export const clearOrderLog = createAction(`${prefix}/clearOrderLog`);

export const selectCustomer = createAction<IOrderSelectedCustomer | undefined>(`${prefix}/setSelectedCustomer`);

export const selectMachine = createAction<string | undefined>(`${prefix}/setSelectedMachine`);

const orderSlice = createSlice({
    name: prefix,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMachines.rejected, (state, action) => {         
            state.Order.error = action.error.message;
        });
        builder.addCase(getMachines.fulfilled, (state, action) => {
            let machines = action.payload;
            state.Order.machines = machines;      
        });
        builder.addCase(getQuickOrders.pending, (state) => {
            state.Order.loadingArticles = true;
        });
        builder.addCase(getQuickOrders.rejected, (state, action) => {
            state.Order.loadingArticles = false;
            state.Order.error = action.error.message;
        });
        builder.addCase(getQuickOrders.fulfilled, (state, action) => {
            let articles = action.payload;
            state.Order.articles = articles;
            state.Order.loadingArticles = false;
        });
        builder.addCase(clearQuickOrders, (state) => {
            state.Order.articles = [];
        });
        builder.addCase(removeOrderLine, (state, action) => {
            if(!state.Order.currentOrder.Lines) return;
            let index = state.Order.currentOrder.Lines.findIndex(x => x.LineNo === action.payload);
            if(index >= 0)
                state.Order.currentOrder.Lines?.splice(index, 1);
        });
        builder.addCase(addOrderLine.rejected, () => {

        });
        builder.addCase(addOrderLine.fulfilled, (state, { payload }) => {
            let lines = addOrderLineCore(state.Order.currentOrder.Lines, payload);
            state.Order.currentOrder.Lines = lines;
        });
        builder.addCase(setOrderLines, (state, { payload }) => {
            state.Order.currentOrder.Lines = payload;
        })
        builder.addCase(setIsLoadingDiscounts, (state, { payload }) => {
            state.Order.isLoadingDiscounts = payload;
        });
        builder.addCase(updateOrderHeading, (state, action) => {
            state.Order.currentOrder.Heading = {
                ...state.Order.currentOrder.Heading,
                ...action.payload
            };
        });
        builder.addCase(updateOrderInvoiceAddress, (state, { payload }) => {
            state.Order.currentOrder.Heading.InvoiceAddresRowNo = payload?.RowNo;
            state.Order.currentOrder.Heading.InvoiceName = payload?.AddressName ?? "";
            state.Order.currentOrder.Heading.InvoiceAddress1 = payload?.Address1 ?? "";
            state.Order.currentOrder.Heading.InvoiceAddress2 = payload?.Address2 ?? "";
            state.Order.currentOrder.Heading.InvoiceAddress3 = payload?.Address3 ?? "";
            state.Order.currentOrder.Heading.InvoiceCountry = payload?.Country ?? "";
            state.Order.currentOrder.Heading.InvoicePostalCode = payload?.PostalCode ?? "";
            state.Order.currentOrder.Heading.InvoicePostLocation = payload?.PostalLocation ?? "";
        });
        builder.addCase(updateOrderDeliveryAddress, (state, { payload }) => {
          state.Order.currentOrder.Heading.DeliveryAddresRowNo = payload?.RowNo;
            state.Order.currentOrder.Heading.DeliveryName = payload?.AddressName ?? "";
            state.Order.currentOrder.Heading.DeliveryAddress1 = payload?.Address1 ?? "";
            state.Order.currentOrder.Heading.DeliveryAddress2 = payload?.Address2 ?? "";
            state.Order.currentOrder.Heading.DeliveryAddress3 = payload?.Address3 ?? "";
            state.Order.currentOrder.Heading.DeliveryCountry = payload?.Country ?? "";
            state.Order.currentOrder.Heading.DeliveryPostalCode = payload?.PostalCode ?? "";
            state.Order.currentOrder.Heading.DeliveryPostLocation = payload?.PostalLocation ?? "";
        });
    
        builder.addCase(getVisibilitySettings.rejected, (state, action) => {
            state.Order.error = action.error.message;
        });
        builder.addCase(getVisibilitySettings.fulfilled, (state, action) => {
            let settings = action.payload;
            if(settings.ShowApprove.UseMachineAddressAsDelivery) {
              state.Order.currentOrder.Heading.MachineAddressAsDeliveryAddress = settings.ShowApprove.MachineAddressAsDefault;
            }
            state.Order.visibilitySettings = settings;          
        });
        builder.addCase(saveOrder.rejected, (state, action) => {
            state.CreateOrder.ErrorCode = action.error.message;
        });
        builder.addCase(saveOrder.fulfilled, (state, action) => {
            state.CreateOrder = action.payload;
        });

        builder.addCase(showCurrentOrder, (state) => {
            state.Order.shouldShowCurrentOrder = true;
        });
        builder.addCase(hideCurrentOrder, (state) => {
            state.Order.shouldShowCurrentOrder = false;
        });
        builder.addCase(clearCurrentOrder, (state) => {
            state.Order.currentOrder = {
                Heading: {} as IOrderHeading,
                Lines: [],
            };            
        });    
        builder.addCase(resetCreateOrderResponse, (state) => {
            state.CreateOrder = {
                Success: undefined,
                Company: undefined,
                OrderNo: undefined,
                OrderGuid: undefined,
                ErrorCode: undefined
            };
        });     
        builder.addCase(setSearchFilter, (state, { payload }) => {
            state.OrderLog.searchData = payload;
        });
        builder.addCase(clearSearchFilter, (state) => {
            state.OrderLog.searchData = { DatePeriod: state.OrderLog.searchData.DatePeriod, DateFrom: new Date(), DateTo: new Date() };
        });
        builder.addCase(clearOrderLog, (state) => {
            state.OrderLog.orderLog = [];
        });
        builder.addCase(getOrderLog.pending, (state) => {
            state.OrderLog.loadingLog = true;
        });
        builder.addCase(getOrderLog.rejected, (state, action) => {
            state.OrderLog.loadingLog = false;
            state.OrderLog.error = action.error.message;
        });
        builder.addCase(getOrderLog.fulfilled, (state, action) => {
            let orders = action.payload;
            state.OrderLog.orderLog = orders;
            state.OrderLog.loadingLog = false;
        });
        builder.addCase(selectCustomer, (state, action) => {
            state.Order.selectedCustomer = action.payload;
        });
        builder.addCase(selectMachine, (state, action) => {
            state.Order.selectedMachineGuid = action.payload;
        });
    }
});

function addOrderLineCore(lines: IOrderLine[], line: IOrderLine) {
    let existingIndex = lines.findIndex(x => x.MachineGuid === line.MachineGuid && x.ArticleGuid === line.ArticleGuid);
    if (existingIndex < 0) {
        if (line.Qty > 0) {
            let lastLineNo = lines.reduce((max, line) => Math.max(line.LineNo, max), 0);
            lines.push({ ...line, LineNo: lastLineNo+1 });
        }
    } else {
        let existing = lines[existingIndex];
        let newLine = { ...existing, ...line, LineNo: existing.LineNo, MachineFriendlyName: existing.MachineFriendlyName };
        lines[existingIndex] = newLine;
    }
    lines.sort((a, b) => a.LineNo - b.LineNo);
    return lines;
}

export default orderSlice.reducer;
