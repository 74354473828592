import * as React from "react";
import * as Store from "../../../store/Store";
import DrawerGroup from "../../Groups/DrawerGroup/DrawerGroup";
import * as FrameReducer from "../../../reducers/FrameReducer";
import * as FrameActions from "../../../actions/FrameActions";
import * as SettingsReducer from "../../../reducers/SettingsReducer";
import * as GeneralReducer from "../../../reducers/GeneralReducer";
import * as ReactRedux from 'react-redux';
import * as ReactI18Next from 'react-i18next';
import * as I18Next from "i18next";
import { DrawerSelectEvent } from "@progress/kendo-react-layout/dist/npm/drawer/interfaces/DrawerSelectEvent";

interface IProps extends ReactI18Next.WithTranslation{
  Frame: FrameReducer.IFrame;
  t: I18Next.TFunction;
  Settings: SettingsReducer.ISettings;
  General: GeneralReducer.IGeneral;
  setHomeUrl: (email: string, timeOffSet: string, language: string, selectedDatabaseId: string, accessToken: string, appUrl: string, cust: string, page: string) => void;
}

export interface AsolviDrawerItem {
  key:number,
  index?: number,
  text?: string,
  icon?: string,
  selected?: boolean,
  separator?: boolean,
  route: string,
  path: string,
  component: string
}

const DrawerGroupSeedComponent: React.FunctionComponent<IProps> = (Props) => {
  let t = Props.t;
    
  let items: AsolviDrawerItem[] = [];
  try{
    items = JSON.parse(Props.Settings.menuItems) as AsolviDrawerItem[];
  }catch(err){
    items = [];
  }

  if(items === null){
    items = [];
  }
  
  items.unshift({ key: 0,text: t("Standard.productName"), icon: 'k-i-menu', component:"", route: "/", path: "."});
  let counter = 0;
  items.map(item => {
    item.text = t(""+item.text)
    item.index = counter;
    item.key = counter;
    item.path = item.route;
    counter++;
    return item;
  });
  const token = Props.General.currentAuth0Token;
  const email = Props.General.currentUserEmail;
  
  const calcTimeZoneOffset = (new Date().getTimezoneOffset()).toString();

  const [expanded, setExpanded] = React.useState(true);

  const handleSelect = (ev: DrawerSelectEvent) => {
    const newUrl = items[ev.itemIndex].route !== undefined ? items[ev.itemIndex].route : "";
    if (newUrl === ".") {
      handleClick();
    }
    else {
      const { appUrl } = (window as any)["appConfig"];
      Props.setHomeUrl(email,calcTimeZoneOffset,Props.General.activeLanguage,Props.General.activeDatabase,token,appUrl,Props.General.activeCustomer, items[ev.itemIndex].route);
    }
  };

  const handleClick = () => {setExpanded(!expanded)}
  return (
    <>
      <DrawerGroup frameUrl={Props.Frame.url}
        menuItems={items}
        expanded={expanded}
        onSelect={handleSelect}
      />
    </>
  )
}

const mapStateToProps = (store: Store.IAppState) => {
  return {
    Settings: store.settingsState.Settings,
    General: store.generalState.General,
    Frame: store.frameState.Frame,
  };
};

const mapDispatchToProps = (dispatch: any) => {  
  return {
    setHomeUrl: (email: string, timeOffSet: string, language: string, selectedDatabaseId: string, accessToken: string, appUrl: string, cust: string, page: string) => dispatch(FrameActions.SetHomeUrl(email,timeOffSet,language,selectedDatabaseId, accessToken, appUrl, cust, page)),
     }
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ReactI18Next.withTranslation()(DrawerGroupSeedComponent));

