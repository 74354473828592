import * as React from 'react';
import './TaskLogDetailsGroup.css';
import { Col, Container, Row } from 'react-bootstrap';
import AsolviLabel from '../../../Elements/AsolviLabel/AsolviLabel';
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import AsolviTextArea from '../../../Elements/AsolviTextArea/AsolviTextArea';
import * as TaskReducer from '../../../../reducers/TaskReducer'
import { useInternationalization } from "@progress/kendo-react-intl";

export interface ITaskHistory {
  ProjectTaskAssignmentId: number,
  ProjectNo: string,
  TaskNo: number
}

export interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  taskDetails: TaskReducer.ITaskLogItem;
}

const TaskLogDetailsGroup: React.FunctionComponent<IProps> = (props) => {
  const { t } = props;
  const intl = useInternationalization();

  return (
    <Container fluid>
      <div className="taskLogDetailsGroup">
        <Row>
            <Col sm={3}>     
              <AsolviLabel labelText={t("TaskLog.status")} 
                  value={""}
                  labelColSize={11} 
                  valueColSize={1}                        
              />
            </Col>
            {
              (props.taskDetails.Status?.toString() === "0") &&
                <Col sm={9}> 
                  <span className="k-icon k-i-clock k-icon-size"/> {t("TaskLog.Open")}
                </Col>
            }
            {
              (props.taskDetails.Status?.toString() === "1") &&
                <Col sm={9}> 
                  <span className="k-icon k-i-check-outline k-icon-size"/> {t("TaskLog.Closed")}
                </Col>
            }                   
          </Row>
          <Row>   
            <Col sm={12}>         
              <AsolviLabel labelText={t("TaskLog.receivedDate")} 
                  value={intl.formatDate(props.taskDetails.ReceivedDate, "g")} 
                  labelColSize={3} 
                  valueColSize={9}                        
              />
            </Col>
          </Row> 
          <Row>
            <Col sm={3}>     
              <AsolviLabel labelText={t("TaskLog.taskType")} 
                  value={""}
                   labelColSize={11} 
                   valueColSize={1}                        
              />
            </Col>
            {
              (props.taskDetails.TaskType?.toString() === "1") &&
                <Col sm={9}> 
                  <span className="k-icon k-i-wrench k-icon-size"/> {t("TaskLog.Service")}
                </Col>
            }
            {
              (props.taskDetails.TaskType?.toString() === "2") &&
                <Col sm={9}> 
                  <span className="k-icon k-i-inbox k-icon-size"/> {t("TaskLog.Helpdesk")}
                </Col>
            }                   
          </Row>
          <Row>
            <Col sm={12}>
              <AsolviLabel labelText={t("TaskLog.customerName")} 
                  value={props.taskDetails.CustomerName} 
                  labelColSize={3} 
                  valueColSize={9}  
              />
            </Col>
          </Row><Row>
            <Col sm={12}>
              <AsolviLabel labelText={t("TaskLog.contact")} 
                  value={props.taskDetails.ContactName} 
                  labelColSize={3} 
                  valueColSize={9}  
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <AsolviLabel labelText={t("TaskLog.machineNo")} 
                  value={props.taskDetails.MachineNo} 
                  labelColSize={3} 
                  valueColSize={9}  
              />
            </Col>
          </Row>      
          <Row>
            <Col sm={12}>
              <AsolviLabel labelText={t("TaskLog.modelType")} 
                  value={props.taskDetails.ModelType} 
                  labelColSize={3} 
                  valueColSize={9}  
              />
            </Col>
          </Row>      
      </div>
      <div className="taskLogDetailsGroup">
          <Row>
            <Col sm={12}>
              <AsolviLabel labelText={t("TaskLog.taskTitle")} 
                  value={props.taskDetails.TaskTitle} 
                  labelColSize={3} 
                  valueColSize={9}  
              />
            </Col>
          </Row>      
          <Row>
            <Col sm={3}>
              <AsolviLabel labelText={t("TaskLog.symptom1")} 
                    value={props.taskDetails.Symptom1} 
                    labelColSize={7} 
                    valueColSize={5} 
              />
            </Col>
            <Col sm={3}>
              <AsolviLabel labelText={t("TaskLog.symptom2")} 
                    value={props.taskDetails.Symptom2 ?? ""} 
                    labelColSize={7} 
                    valueColSize={5} 
              />
            </Col>
            <Col sm={3}>
              <AsolviLabel labelText={t("TaskLog.symptom3")} 
                    value={props.taskDetails.Symptom3 ?? ""} 
                    labelColSize={7} 
                    valueColSize={5} 
              />
            </Col>
            {
              (props.taskDetails.TaskType?.toString() === "2") &&
              <Col sm={3}>
                <AsolviLabel labelText={t("TaskLog.symptom4")} 
                      value={props.taskDetails.Symptom4 ?? ""} 
                      labelColSize={7} 
                      valueColSize={5} 
                />
              </Col>
            }
          </Row> 
          <Row>
            <Col sm={12}>
              <AsolviLabel labelText={t("TaskLog.symptomText")} 
                  value={""} 
                  labelColSize={11} 
                  valueColSize={1}  
              />
            </Col>
          </Row>                 
          <Row>
            <Col sm={12}>
              <AsolviTextArea labelText={""} 
                    rows={3} onChange={() => {}} 
                    isReadOnly={true} inputText={props.taskDetails.SymptomText} 
                    labelColSize={0} 
                    valueColSize={12} 
              />
            </Col>
          </Row>
      </div>
      <div className="taskLogDetailsGroup">
        <Row>
            <Col sm={12}>
              <AsolviLabel labelText={t("TaskLog.technician")} 
                  value={props.taskDetails.Technician} 
                  labelColSize={3} 
                  valueColSize={9}  
              />
            </Col>
          </Row>  
          <Row>
            <Col sm={3}>
              <AsolviLabel labelText={t("TaskLog.action1")} 
                  value={props.taskDetails.ActionCode1 ?? ""} 
                  labelColSize={7} 
                  valueColSize={5} 
              />
            </Col>
            <Col sm={3}>
              <AsolviLabel labelText={t("TaskLog.action2")} 
                  value={props.taskDetails.ActionCode2 ?? ""} 
                  labelColSize={7} 
                  valueColSize={5} 
              />
            </Col>
            <Col sm={3}>
              < AsolviLabel labelText={t("TaskLog.action3")} 
                  value={props.taskDetails.ActionCode3 ?? ""} 
                  labelColSize={7} 
                  valueColSize={5}     
              />
            </Col>
            {
              (props.taskDetails.TaskType?.toString() === "2") &&
              <Col sm={3}>
                < AsolviLabel labelText={t("TaskLog.action4")} 
                    value={props.taskDetails.ActionCode4 ?? ""} 
                    labelColSize={7} 
                    valueColSize={5}     
                />
              </Col>
            }
          </Row>
          <Row>
            <Col sm={12}>
              <AsolviLabel labelText={t("TaskLog.actionText")} 
                  value={""} 
                  labelColSize={11} 
                  valueColSize={1}  
              />
            </Col>
          </Row> 
          <Row>
          <Col sm={12}>
              <AsolviTextArea labelText={""} 
                  rows={3} onChange={() => {}} 
                  isReadOnly={true} inputText={props.taskDetails.ActionText} 
                  labelColSize={0} 
                  valueColSize={12}               
              />
            </Col>
          </Row>                
      </div>
    </Container>
  );
}

export default ReactI18Next.withTranslation()(TaskLogDetailsGroup);