import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IConsent } from "../../seedComponents/Pages/ConsentsPageSeedComponent";
import ConsentsCollection from "../../Groups/Consents/ConsentsCollection";
import AsolviModal from "../../Auxiliary/AsolviModal/AsolviModal";
import Consent from "../../Groups/Consents/Consent";
import ConsentStatus from "../../Groups/Consents/ConsentStatus";

import styles from "./ConsentsPage.module.css";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";

interface Props {
  consents: IConsent[];
  onWithdrawConsent: (consentId: number) => void;
  onGiveConsent: (consentId: number) => void;
  loading: boolean;
  onRefresh: () => void;
}

const ConsentsPage: FC<Props> = ({consents, onWithdrawConsent, loading, onGiveConsent, onRefresh}) => {
  const { t } = useTranslation();
  const [consent, setConsent] = useState<IConsent>();

  const displayConsent = (consent: IConsent) => {
    setConsent(consent);
  }

  return (
    <div className={ styles.page }>
      <AsolviModal
        modalSize="xl"
        showModal={ Boolean(consent) }
        shouldCloseOnBackdrop
        scrollable
        handleClose={ () => {
          setConsent(undefined);
        } }
        animation={false}
        header={ consent?.Name && (
          <div style={{
            "display": "flex",
            "justifyContent": "space-between",
            "width": "100%",
            gap: "0.5em",
            alignItems: "center",
            fontSize: "1.2em"
          }}>
            <span>{ consent.Name }</span><ConsentStatus status={ consent.Accepted } />
          </div>
        ) }
        footer={
          <div>
            {
              consent?.Accepted
                ? <AsolviButton
                    buttonType="submit"
                    buttonAction={() => onWithdrawConsent(consent.Id)}
                  >
                    {t("Consents.WithdrawConsent")}
                  </AsolviButton>
                : <AsolviButton
                    buttonType="submit"
                    className="k-primary"
                    buttonAction={() => ('todo')}
                  >
                    {t("Consents.Accept")}
                  </AsolviButton>
            }
          </div>
        }
      >
        { consent ? <Consent consent={ consent } /> : null }
      </AsolviModal>
      <h1 className={ styles.title }>{
        loading
          ? t("Consents.LoadingConsents")
          : <div style={{display: "flex"}}>
              {t("Consents.GivenConsents")}
            <div style={{ marginLeft: "30px" }}><AsolviButton
              buttonType="button"
              buttonAction={onRefresh}
            >
              {t("Consents.Refresh")}
            </AsolviButton></div>
            </div>
      }</h1>
      <ConsentsCollection
        consents={ consents }
        displayConsent={ displayConsent }
        onWithdrawConsent={onWithdrawConsent}
        onGiveConsent={onGiveConsent}
      />
    </div>
  )
}

export default ConsentsPage;
