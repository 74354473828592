import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AsolviLabel from '../../../Elements/AsolviLabel/AsolviLabel';
import "./OrderMachineGroup.css";
import AsolviAdvancedDropdownList, {
  IColumn,
} from "../../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import { useTranslation } from 'react-i18next';
import { IOrderMachineInfo } from "../../../seedComponents/Groups/Order/OrderDetailsGroupSeedComponent";

interface IProps {
  machinesList: IOrderMachineInfo[];
  machineSelectColumns: IColumn[];
  extractData?: (data: IOrderMachineGroupData) => void;
  handleSelectedMachine?: (selectedMachine: string) => void;
  clearData?: boolean;
}

export interface IOrderMachineGroupData {
  MachineGuid: string | undefined,
  MachineNo: string | undefined,
  ModelType: string | undefined,
  AddressName: string | undefined,
  Address1: string | undefined,
  Address2: string | undefined,
  Address3: string | undefined,
  AddressCountry: string | undefined;
  Location: string | undefined,
  PostalCode: string | undefined,
  PostalDescription: string | undefined

}

const OrderMachineGroup: React.FunctionComponent<IProps> = (props) => {
  const { extractData, handleSelectedMachine, machinesList, clearData } = props;
  const { t } = useTranslation();
  const [machineGuid, setMachineGuid] = React.useState<string>();
  const [visitName, setVisitName] = useState<string>();
  const [address1, setAddress1] = useState<string>();
  const [address2, setAddress2] = useState<string>();
  const [address3, setAddress3] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [postalDescription, setPostalDescription] = useState<string>();
  const [machineLocation, setMachineLocation] = useState<string>();
  const [selectedMachine, setSelectedMachine] = useState<IOrderMachineInfo>();

  const updateFields = useCallback((e: any) => {
    setSelectedMachine(e);
    setMachineGuid(e === null ? "" : e.MachineGuid);
    setVisitName(e === null ? "" : e.AddressName);
    setAddress1(e === null ? "" : e.Address1);
    setAddress2(e === null ? "" : e.Address2);
    setAddress3(e === null ? "" : e.Address3);
    setPostalCode(e == null ? "" : e.PostalCode);
    setPostalDescription(e == null ? "" : e.PostalDescription);
    setMachineLocation(e === null ? "" : e.Location);

  },[]);

  useEffect(() => {
    const sendData = () => {
      if(extractData)
      {
        let data = {} as IOrderMachineGroupData;
        data.MachineGuid = selectedMachine?.MachineGuid;
        data.MachineNo = selectedMachine?.MachineNo;
        data.ModelType = selectedMachine?.ModelType;
        data.AddressName = visitName;
        data.Address1 = address1;
        data.Address2 = address2;
        data.Address3 = address3
        data.AddressCountry = selectedMachine?.AddressCountry;
        data.PostalCode = selectedMachine?.PostalCode;
        data.PostalDescription = selectedMachine?.PostalDescription;
        data.Location = machineLocation;

        extractData(data);
      }
    }
    sendData();
  },[selectedMachine, visitName, address1, address2, address3, machineLocation, extractData])


  useEffect(() => {
    if (handleSelectedMachine) {
        handleSelectedMachine(machineGuid?? "");
    }
  }, [machineGuid, handleSelectedMachine]);

  const setNoData = useCallback(() => {
    const nodata = {MachineGuid: "", MachineNo: "",
                AddressName: "", Address1: "", Address2: "", Address3: "",
                Location: "", PostalCode: "",  PostalDescription: "" } as IOrderMachineGroupData;
    updateFields(nodata);
  },[updateFields]);

  useEffect(() => {
    setNoData();
  }, [machinesList, setNoData]);

  useEffect(() => {
    if (clearData?? false) {
      setNoData();
    }
  }, [clearData, setNoData]);
  
  return (
    <Container fluid>
       <div>
         <Row>
           <Col sm={6} >
             <AsolviAdvancedDropdownList
               labelText={t("MachineSelect.SelectLabel")}
               shownText={"MachineNo"}
               columns={ props.machineSelectColumns }
               data={machinesList}
               onSelect={(e: any) => { updateFields(e.target.value); }}
               selectedItem={selectedMachine}
               required={true}
             />
           </Col>
         </Row>
      <Row className="machineAddressPadding">
        
      </Row>
      <Row>
      <Col sm={12} />
        <Col sm={6}>
          <AsolviLabel labelText={t("MachineSelect.VisitName")}
            value={visitName}
            labelColSize={3}
            valueColSize={9}  />
        </Col>
        <Col sm={6}>
          <AsolviLabel labelText={t("MachineSelect.Address1")}
            value={address1}
            labelColSize={3}
            valueColSize={9}  />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <AsolviLabel labelText={t("MachineSelect.Address2")}
            value={address2}
            labelColSize={3}
            valueColSize={9}  />
        </Col>
        <Col sm={6}>
          <AsolviLabel labelText={t("MachineSelect.Address3")}
            value={address3}
            labelColSize={3}
            valueColSize={9}  />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <AsolviLabel labelText={t("MachineSelect.PostCode")}
          value={postalCode + " "}
          labelColSize={3}
          valueColSize={9}  />
        </Col>
        <Col sm={6}>
          <AsolviLabel labelText={t("MachineSelect.PostLocation")}
          value={postalDescription}
          labelColSize={3}
          valueColSize={9}  />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <AsolviLabel labelText={t("MachineSelect.Location")}
          value={machineLocation}
          labelColSize={3}
          valueColSize={9}  />
        </Col>
        <Col sm={6} />
      </Row>
      </div>
    </Container>
  );
}

export default OrderMachineGroup;
