import * as React from "react";
import "./MachineSearchGrid.css";
import * as I18Next from "i18next";
import * as ReactI18Next from 'react-i18next';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { IMachineInfo } from "../../seedComponents/Pages/MeterReportingPageSeedComponent";

export interface Props extends ReactI18Next.WithTranslation{
  t: I18Next.TFunction;
  gridData: IMachineInfo[];
  showMachineId: boolean;
  onRowClick: (event:any) => void;
}


class MachineSearchGrid extends React.Component<Props, object> {
  state = {
          filter: {
              logic: "and",
              filters: []
          }
      };
  render() {
    const { t } = this.props;
    return (
      <Grid className="machineSearchGrid"
        data={filterBy(this.props.gridData, this.state.filter as CompositeFilterDescriptor)}
        scrollable="scrollable"
        filterable
        filter={this.state.filter as CompositeFilterDescriptor}
        onFilterChange={(e) => {
          this.setState({
            filter: e.filter
          });
                
        }}
        onRowClick={this.props.onRowClick}
      >
        <Column field="modelType" title={t("MeterReporting.modelType")} />
        <Column field="machineNo" title={t("MeterReporting.machineNo")}/>
        { this.props.showMachineId ? <Column field="idNo" title={t("MeterReporting.idNo")} /> : null }
        <Column field="location" title={t("MeterReporting.location")} />
        <Column field="machineAddress" title={t("MeterReporting.machineAddress")} />
      </Grid>
    )
  }
}

export default ReactI18Next.withTranslation()(MachineSearchGrid);

