import * as React from "react";
import styles from "./LoginGroup.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import { useTranslation } from 'react-i18next';
import { CustomerWebAPIs } from '../../../sources/apiEndPoints';
import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';



async function getCustomLink(culture: string): Promise<string> {

  const languageCodes: { [key: string]: string} = {
    'nb': 'nb-NO',
    'nn': 'nb-NO',
  }

  culture = languageCodes[culture] ?? culture;

  const { appUrl } = (window as any)["appConfig"];

  const url = appUrl + CustomerWebAPIs.GetCustomLoginLink(culture);
  const response = await axios.get(url);

  return response.data;
}


interface Props {
  loginButtonAction: () => void,
}

export function LoginGroup({loginButtonAction}: Props): ReactElement {
  const { t, i18n } = useTranslation();

  let [customLink, setCustomLink] = useState<string>();

  useEffect(() => {
    getCustomLink(i18n.language)
      .then( customLink => setCustomLink(customLink) )

  }, [i18n.language]);

  return <Container fluid>
    <Row className={ styles.titleRow }>
      <Col>
        <Row>
          <Col>
            <h1><b>{t("Standard.productName")}</b></h1>
          </Col>
        </Row>
      </Col>
    </Row>

    <Row>
      <Col>
        <p>{t("LoginGroupContent.loginGroupInfoText")}</p>
      </Col>
    </Row>

    <Row className={ styles.loginGroupButtonRow }>
      <Col className={ styles.loginGroupButtonColumn } xl="10">
        <AsolviButton
          buttonAction={loginButtonAction}
          buttonText={t("Standard.login")}
          buttonType="button"
        />
      </Col>
    </Row>

    { customLink && <Row>
      <Col>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={customLink}
          className={styles.customLink}
        >
          { t('LoginGroupContent.customLinkText') }
        </a>
      </Col>
    </Row> }
  </Container>
}
